import { createSlice } from '@reduxjs/toolkit';

import { firebaseAuth, realtimeDB } from '../services/firebase';

// Thunk Actions
export const login = (email, password) => async (dispatch) => {
   try {
      console.log('Logging in:', email, password);
      const userCredential = await firebaseAuth.login(email, password);
      console.log('Loggin action success:', userCredential.user.uid);
      // dispatch(loginSuccess(userCredential.user));
      // dispatch(setUser(userCredential.user))
   } catch (error) {
      // Handle login error
      console.log('Login action error:', error);
   }
};

export const register = (email, password) => async (dispatch) => {
   try {
      const userCredential = await firebaseAuth.register(email, password);
      // await realtimeDB.writeData('/users/' + userCredential.user.uid + '/projects', [])
      // TODO: create user entry in DB with 0 projects
      // dispatch(registerSuccess(userCredential.user));
      // dispatch(setUser(userCredential.user))
   } catch (error) {
      // Handle registration error
      console.log('Registration error:', error);
   }
};

export const logout = () => async (dispatch) => {
   try {
      await firebaseAuth.logout();
      // dispatch(logoutSuccess());
   } catch (error) {
      // Handle logout error
      console.log('Logout error:', error);
   }
};

export const sendPasswordResetCode = (email, callbackUrl) => async () => {
   try {
      await firebaseAuth.sendPasswordResetCode(email, callbackUrl);
   } catch (error) {
      // Handle send password reset code error
      console.log('Send password reset code error:', error);
   }
};

export const resetPassword = (securityCode, newPassword) => async () => {
   try {
      await firebaseAuth.resetPassword(securityCode, newPassword);
      console.log('Password reset success');
   } catch (error) {
      // Handle reset password error
      console.log('Reset password error:', error);
   }
};

export const updateUserProfile = (user, payload) => async (dispatch) => {
   try {
      await firebaseAuth.updateUserProfile(user, payload);
      // console.log('Updated user profile')
      // dispatch(updateProfileSuccess(user));
   } catch (error) {
      // Handle update user profile error
      console.log('Update user profile error:', error);
   }
};

const userSlice = createSlice({
   name: 'user',
   initialState: null,
   reducers: {
      // loginSuccess: (state, action) => {
      //     console.log('Logged in user:', action.payload);
      //     return action.payload;
      // },
      // registerSuccess: (state, action) => {
      //     console.log('Registered user:', action.payload);
      //     return action.payload;
      // },
      setUser: (state, action) => {
         return action.payload;
      },
   },
});

export default userSlice.reducer;
export const { setUser } = userSlice.actions;
