import { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import TableContainer from './TableContainer';
import { setTable } from '../../store/DatabaseSlice';
import InputField from './InputField';

const DatabaseSidebar = ({}) => {
   const [inputValue, setInputValue] = useState('');
   const [tables, setTables] = useState({});
   const [filteredTables, setFilteredTables] = useState({});
   const project = useSelector((state) => state.project);
   const database = useSelector((state) => state.database);

   const dispatch = useDispatch();

   useEffect(() => {
      const unsubsctibe = project.watch(`/projects/${project.projectId}/dev/data_model`, (data) => {
         setTables(data);
         if (database.currentTable == {}) {
            dispatch(
               setTable({
                  id: Object.keys(data)[0],
                  name: data[Object.keys(data)[0]].name,
               })
            );
         }
         setFilteredTables(filterData('', data));
      });
      return () => {
         unsubsctibe;
      };
   }, [project.projectId, project.read]);

   const filterData = (filterString, data) => {
      return Object.keys(data).reduce((acc, key) => {
         const item = data[key.toLowerCase()];
         if (
            typeof item === 'object' &&
            item.name.toLowerCase().includes(filterString.toLowerCase())
         ) {
            acc.push({ key, tableName: item });
         }
         return acc;
      }, []);
   };

   const handleInputChange = (text) => {
      setInputValue(text);
      const newFilteredTables = filterData(text, tables);
      setFilteredTables(newFilteredTables);
   };

   return (
      <View style={styles.sidebar}>
         <View style={{ paddingHorizontal: '20px' }}>
            <InputField value={inputValue} onChangeText={handleInputChange} />
         </View>
         <TableContainer tables={filteredTables} />
      </View>
   );
};

export default DatabaseSidebar;

const styles = StyleSheet.create({
   sidebar: {
      width: 300,
      paddingVertical: 20,
      minHeight: '100%',
      borderRightWidth: 1,
      borderTopWidth: 1,
      borderColor: '#E0E4F4',
      backgroundColor: '#FFF',
   },
   innerElements: {
      paddingHorizontal: 20,
   },
});
