import { Entypo } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Picker, TouchableOpacity } from 'react-native';

import DefaultInputContainer from './../../base/DefaultInputContainer';
import AlignSelf from './AlignSelf';
import AlignSelfAuto from './auto/AlignSelfAuto';

const PositionStyling = ({ rootHandleElementUpdate, componentDef, styles, handleUpdate }) => {
   const [layoutType, setLayoutType] = useState(
      componentDef[rootHandleElementUpdate]?.layout?.type || 'auto'
   );

   useEffect(() => {
      setLayoutType(componentDef[rootHandleElementUpdate]?.position?.type || 'auto');
   }, [componentDef[rootHandleElementUpdate]?.position?.type]);

   const [expandLayout, setExpandLayout] = useState(true);

   const layout = () => {
      return (
         <View style={{ flex: 1 }}>
            <View style={layoutStyles.layoutButtons}>
               <TouchableOpacity
                  onPress={() => {
                     setLayoutType('auto');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'position', 'type'], value: 'auto' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     layoutStyles.layoutButton,
                     layoutType == 'auto' ? layoutStyles.layoutButtonSelected : {},
                  ]}>
                  <Text
                     style={
                        layoutType == 'auto' ? layoutStyles.selectedText : layoutStyles.textStyle
                     }>
                     Auto
                  </Text>
               </TouchableOpacity>
               <TouchableOpacity
                  onPress={() => {
                     setLayoutType('relative');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'position', 'type'], value: 'relative' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     layoutStyles.layoutButton,
                     layoutType == 'relative' ? layoutStyles.layoutButtonSelected : {},
                  ]}>
                  <Text
                     style={
                        layoutType == 'relative'
                           ? layoutStyles.selectedText
                           : layoutStyles.textStyle
                     }>
                     Relative
                  </Text>
               </TouchableOpacity>
               <TouchableOpacity
                  onPress={() => {
                     setLayoutType('fixed');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'position', 'type'], value: 'fixed' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     layoutStyles.layoutButton,
                     layoutType == 'fixed' ? layoutStyles.layoutButtonSelected : {},
                  ]}>
                  <Text
                     style={
                        layoutType == 'fixed' ? layoutStyles.selectedText : layoutStyles.textStyle
                     }>
                     Fixed
                  </Text>
               </TouchableOpacity>
               <TouchableOpacity
                  onPress={() => {
                     setLayoutType('absolute');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'position', 'type'], value: 'absolute' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     layoutStyles.layoutButton,
                     layoutType == 'absolute' ? layoutStyles.layoutButtonSelected : {},
                  ]}>
                  <Text
                     style={
                        layoutType == 'absolute'
                           ? layoutStyles.selectedText
                           : layoutStyles.textStyle
                     }>
                     Absolute
                  </Text>
               </TouchableOpacity>
            </View>
            {(layoutType || 'auto') === 'auto' && (
               <View style={[styles.row, { marginBottom: 5 }]}>
                  <AlignSelfAuto
                     rootHandleElementUpdate={rootHandleElementUpdate}
                     handleUpdate={handleUpdate}
                     componentDef={componentDef}
                  />
               </View>
            )}
            {(layoutType || 'relative') === 'relative' && (
               <View style={[styles.row, { marginBottom: 5 }]}>
                  <AlignSelf
                     rootHandleElementUpdate={rootHandleElementUpdate}
                     handleUpdate={handleUpdate}
                     componentDef={componentDef}
                  />
               </View>
            )}
            {(layoutType || 'fixed') === 'fixed' && (
               <>
                  <View style={[styles.row]}>
                     <svg
                        width='70'
                        height='70'
                        viewBox='0 0 70 70'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path d='M35 1L35 18.25' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M35 18.25L35 35.5' stroke='#DFE2EE' strokeWidth='2' />
                        <path d='M35 35.5L35 52.75' stroke='#DFE2EE' strokeWidth='2' />
                        <path d='M35 52.75L35 70' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M1 36L18 36' stroke='#DFE2EE' strokeWidth='2' />
                        <path d='M18 36L35 36' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M35 36L52 36' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M52 36L69 36' stroke='#DFE2EE' strokeWidth='2' />
                        <path
                           d='M19 23C19 20.7909 20.7909 19 23 19H47C49.2091 19 51 20.7909 51 23V47C51 49.2091 49.2091 51 47 51H23C20.7909 51 19 49.2091 19 47V23Z'
                           stroke='#DFE2EE'
                           strokeWidth='2'
                        />
                        <path
                           d='M1 5C1 2.79086 2.79086 1 5 1H65C67.2091 1 69 2.79086 69 5V65C69 67.2091 67.2091 69 65 69H5C2.79086 69 1 67.2091 1 65V5Z'
                           stroke='#DFE2EE'
                           strokeWidth='2'
                        />
                     </svg>
                     <View style={[layoutStyles.column, { width: 80 }]}>
                        <DefaultInputContainer
                           style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'top',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'top',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.top?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.top?.val
                           }
                           icon={
                              <svg
                                 width='24'
                                 height='24'
                                 viewBox='0 0 24 24'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M16 12L12 8M12 8L8 12M12 8V16M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'bottom',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'bottom',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.bottom
                                 ?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.bottom?.val
                           }
                           icon={
                              <svg
                                 width='24'
                                 height='24'
                                 viewBox='0 0 24 24'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M8 12L12 16M12 16L16 12M12 16V8M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                     </View>
                     <View style={[layoutStyles.column, { width: 80 }]}>
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'right',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'right',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.right?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.right?.val
                           }
                           icon={
                              <svg
                                 width='20'
                                 height='20'
                                 viewBox='0 0 20 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M10 14L14 10M14 10L10 6M14 10H6M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'left',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'relative',
                                       'left',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.left?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.relative?.left?.val
                           }
                           icon={
                              <svg
                                 width='20'
                                 height='20'
                                 viewBox='0 0 20 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M10 6L6 10M6 10L10 14M6 10H14M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                     </View>
                  </View>
               </>
            )}
            {(layoutType || 'absolute') === 'absolute' && (
               <>
                  <View style={[styles.row]}>
                     <svg
                        width='70'
                        height='70'
                        viewBox='0 0 70 70'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path d='M35 1L35 18.25' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M35 18.25L35 35.5' stroke='#DFE2EE' strokeWidth='2' />
                        <path d='M35 35.5L35 52.75' stroke='#DFE2EE' strokeWidth='2' />
                        <path d='M35 52.75L35 70' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M1 36L18 36' stroke='#DFE2EE' strokeWidth='2' />
                        <path d='M18 36L35 36' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M35 36L52 36' stroke='#7B87A6' strokeWidth='2' />
                        <path d='M52 36L69 36' stroke='#DFE2EE' strokeWidth='2' />
                        <path
                           d='M19 23C19 20.7909 20.7909 19 23 19H47C49.2091 19 51 20.7909 51 23V47C51 49.2091 49.2091 51 47 51H23C20.7909 51 19 49.2091 19 47V23Z'
                           stroke='#DFE2EE'
                           strokeWidth='2'
                        />
                        <path
                           d='M1 5C1 2.79086 2.79086 1 5 1H65C67.2091 1 69 2.79086 69 5V65C69 67.2091 67.2091 69 65 69H5C2.79086 69 1 67.2091 1 65V5Z'
                           stroke='#DFE2EE'
                           strokeWidth='2'
                        />
                     </svg>
                     <View style={[layoutStyles.column, { width: 80 }]}>
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'top',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'top',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.top?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.top?.val
                           }
                           icon={
                              <svg
                                 width='24'
                                 height='24'
                                 viewBox='0 0 24 24'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M16 12L12 8M12 8L8 12M12 8V16M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'bottom',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'bottom',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.bottom
                                 ?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.bottom?.val
                           }
                           icon={
                              <svg
                                 width='24'
                                 height='24'
                                 viewBox='0 0 24 24'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M8 12L12 16M12 16L16 12M12 16V8M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                     </View>
                     <View style={[layoutStyles.column, { width: 80 }]}>
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'right',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'right',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.right?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.right?.val
                           }
                           icon={
                              <svg
                                 width='20'
                                 height='20'
                                 viewBox='0 0 20 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M10 14L14 10M14 10L10 6M14 10H6M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                        <DefaultInputContainer
                           style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'left',
                                       'unit',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 {
                                    path: [
                                       rootHandleElementUpdate,
                                       'position',
                                       'absolute',
                                       'left',
                                       'val',
                                    ],
                                    value,
                                 },
                              ]);
                           }}
                           unit={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.left?.unit
                           }
                           value={
                              componentDef[rootHandleElementUpdate]?.position?.absolute?.left?.val
                           }
                           icon={
                              <svg
                                 width='20'
                                 height='20'
                                 viewBox='0 0 20 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M10 6L6 10M6 10L10 14M6 10H14M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z'
                                    stroke='#93989B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                     </View>
                  </View>
               </>
            )}
         </View>
      );
   };

   return (
      <View>
         <View style={styles.row}>
            <View style={{ flexDirection: 'column', flex: 1, paddingHorizontal: 20 }}>
               <View style={layoutStyles.expandContainer}>
                  <Text style={[layoutStyles.spacesAndSizesText]}>Position</Text>
                  <TouchableOpacity
                     onPress={() => setExpandLayout(!expandLayout)}
                     style={{ alignItems: 'center', justifyContent: 'center' }}>
                     {expandLayout ? (
                        <Entypo name='chevron-thin-down' size={24} color='#93989B' />
                     ) : (
                        <Entypo name='chevron-thin-up' size={24} color='#93989B' />
                     )}
                  </TouchableOpacity>
               </View>
               {expandLayout && <View>{layout()}</View>}
            </View>
         </View>
      </View>
   );
};

export default PositionStyling;

const layoutStyles = StyleSheet.create({
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   },
   column: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
   },
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10,
   },
   textStyle: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      textAlign: 'center',
      color: '#646D72',
   },
   selectedText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      textAlign: 'center',
      color: '#F5F9FF',
   },
   fdButton: {
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   fdButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   layoutButton: {
      flex: 1,
      width: 86,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   layoutButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   jcButton: {
      flex: 1,
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   jcButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   fdContainer: {
      height: 16,
      width: 16,
   },
   jcContainer: {
      height: 14,
      width: 14,
   },
   layoutButtons: {
      width: 260,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
   },
   flexDirectionButtons: {
      flex: 1,
      width: 90,
      height: 30,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   justifyContentButtons: {
      width: 150,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
});
