import React, { useRef } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity } from 'react-native';

const SearchInputField = ({ customStyles, value, onChangeText, style, ...rest }) => {
   
   const textInputRef = useRef(null);
   const handleFocus = () => {
      if (textInputRef.current) {
         textInputRef.current.focus();
      }
   };

   return (
      <View style={[styles.container, style]}>
         <View style={styles.innerContainer}>
            <TouchableOpacity onPress={handleFocus}>
               <View style={styles.field}>
               <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.75 15.75L12.4875 12.4875M14.25 8.25C14.25 11.5637 11.5637 14.25 8.25 14.25C4.93629 14.25 2.25 11.5637 2.25 8.25C2.25 4.93629 4.93629 2.25 8.25 2.25C11.5637 2.25 14.25 4.93629 14.25 8.25Z" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
                  <TextInput
                     ref={textInputRef}
                     placeholder='Search'
                     placeholderTextColor='#93989B'
                     value={value}
                     onChangeText={onChangeText}
                     autoFocus={false}
                     style={styles.input}
                     {...rest}
                  />
               </View>
            </TouchableOpacity>
         </View>
      </View>
   );
};

export default SearchInputField;

const styles = StyleSheet.create({
   container: {
      width: 300,
      height: 44,
      paddingHorizontal: 20,
   },
   innerContainer: {
      width: 260,
      height: 40,
      paddingHorizontal: 16,
      borderRadius: 8,
      backgroundColor: '#F5F6F8',
      alignItems: 'center',
   },
   field: {
      flex: 1,
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      paddingHorizontal: 16,
      paddingVertical: 12,
      borderRadius: 8,
   },
   input: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
   },
});
