import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

const JustifyContent = ({
   rootHandleElementUpdate,
   componentDef,
   handleUpdate,
   style,
   rotateIcons,
}) => {
   const [distribution, setDistribution] = useState(
      componentDef[rootHandleElementUpdate]?.layout?.distribution || 'start'
   );
   useEffect(() => {
      setDistribution(componentDef[rootHandleElementUpdate]?.layout?.distribution || 'start');
   }, [componentDef[rootHandleElementUpdate]?.layout?.distribution]);

   return (
      <View style={[layoutStyles.justifyContentButtons, style]}>
         <TouchableOpacity
            onPress={() => {
               setDistribution('start');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'distribution'], value: 'start' },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               distribution == 'start' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View
               style={[
                  layoutStyles.jcContainer,
                  rotateIcons ? { transform: [{ rotate: '90deg' }] } : {},
               ]}>
               <svg
                  width='14'
                  height='12'
                  viewBox='0 0 14 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M12.375 1.6875H1.125M8.625 5.125C8.625 4.54257 8.625 4.25136 8.52985 4.02165C8.40298 3.71536 8.15964 3.47202 7.85335 3.34515C7.62364 3.25 7.33243 3.25 6.75 3.25C6.16757 3.25 5.87636 3.25 5.64665 3.34515C5.34036 3.47202 5.09702 3.71536 4.97015 4.02165C4.875 4.25136 4.875 4.54257 4.875 5.125V7V8.875C4.875 9.45743 4.875 9.74864 4.97015 9.97835C5.09702 10.2846 5.34036 10.528 5.64665 10.6548C5.87636 10.75 6.16757 10.75 6.75 10.75C7.33243 10.75 7.62364 10.75 7.85335 10.6548C8.15964 10.528 8.40298 10.2846 8.52985 9.97835C8.625 9.74864 8.625 9.45743 8.625 8.875V7V5.125Z'
                     stroke={distribution == 'start' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setDistribution('center');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'distribution'], value: 'center' },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               distribution == 'center' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View
               style={[
                  layoutStyles.jcContainer,
                  rotateIcons ? { transform: [{ rotate: '90deg' }] } : {},
               ]}>
               <svg
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M1.375 7H5.125M8.875 7V3.25C8.875 2.66757 8.875 2.37636 8.77985 2.14665C8.65298 1.84036 8.40964 1.59702 8.10335 1.47015C7.87364 1.375 7.58243 1.375 7 1.375C6.41757 1.375 6.12636 1.375 5.89665 1.47015C5.59036 1.59702 5.34702 1.84036 5.22015 2.14665C5.125 2.37636 5.125 2.66757 5.125 3.25V7M8.875 7V10.75C8.875 11.3324 8.875 11.6236 8.77985 11.8534C8.65298 12.1596 8.40964 12.403 8.10335 12.5298C7.87364 12.625 7.58243 12.625 7 12.625C6.41757 12.625 6.12636 12.625 5.89665 12.5298C5.59036 12.403 5.34702 12.1596 5.22015 11.8534C5.125 11.6236 5.125 11.3324 5.125 10.75V7M8.875 7H12.625H9.03369'
                     stroke={distribution == 'center' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setDistribution('end');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'distribution'], value: 'end' },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               distribution == 'end' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View
               style={[
                  layoutStyles.jcContainer,
                  rotateIcons ? { transform: [{ rotate: '90deg' }] } : {},
               ]}>
               <svg
                  width='14'
                  height='12'
                  viewBox='0 0 14 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M12.375 10.3125H1.125M8.625 3.125C8.625 2.54257 8.625 2.25136 8.52985 2.02165C8.40298 1.71536 8.15964 1.47202 7.85335 1.34515C7.62364 1.25 7.33243 1.25 6.75 1.25C6.16757 1.25 5.87636 1.25 5.64665 1.34515C5.34036 1.47202 5.09702 1.71536 4.97015 2.02165C4.875 2.25136 4.875 2.54257 4.875 3.125V5V6.875C4.875 7.45743 4.875 7.74864 4.97015 7.97835C5.09702 8.28464 5.34036 8.52798 5.64665 8.65485C5.87636 8.75 6.16757 8.75 6.75 8.75C7.33243 8.75 7.62364 8.75 7.85335 8.65485C8.15964 8.52798 8.40298 8.28464 8.52985 7.97835C8.625 7.74864 8.625 7.45743 8.625 6.875V5V3.125Z'
                     stroke={distribution == 'end' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setDistribution('between');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'distribution'], value: 'between' },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               distribution == 'between' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View
               style={[
                  layoutStyles.jcContainer,
                  rotateIcons ? { transform: [{ rotate: '90deg' }] } : {},
               ]}>
               <svg
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M12.875 12.3125H1.625M12.875 1.6875H1.625M9.125 5.125C9.125 4.54257 9.125 4.25136 9.02985 4.02165C8.90298 3.71536 8.65964 3.47202 8.35335 3.34515C8.12364 3.25 7.83243 3.25 7.25 3.25C6.66757 3.25 6.37636 3.25 6.14665 3.34515C5.84036 3.47202 5.59702 3.71536 5.47015 4.02165C5.375 4.25136 5.375 4.54257 5.375 5.125V5.125C5.375 5.64277 5.79473 6.0625 6.3125 6.0625H8.1875C8.70527 6.0625 9.125 5.64277 9.125 5.125V5.125ZM9.125 8.875C9.125 9.45743 9.125 9.74864 9.02985 9.97835C8.90298 10.2846 8.65964 10.528 8.35335 10.6548C8.12364 10.75 7.83243 10.75 7.25 10.75C6.66757 10.75 6.37636 10.75 6.14665 10.6548C5.84036 10.528 5.59702 10.2846 5.47015 9.97835C5.375 9.74864 5.375 9.45743 5.375 8.875V8.875C5.375 8.35723 5.79473 7.9375 6.3125 7.9375H8.1875C8.70527 7.9375 9.125 8.35723 9.125 8.875V8.875Z'
                     stroke={distribution == 'between' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         {/* <TouchableOpacity
                    onPress={() => {
                    setDistribution('space-evenly');
                    setDirection('space-evenly');
                    handleUpdate([
                        { path: [rootHandleElementUpdate, 'layout', 'auto', 'direction'], value: 'space-evenly' },
                    ]);    
                    }}
                    style={[layoutStyles.jcButton, distribution == 'space-evenly' ? layoutStyles.jcButtonSelected : {}]}>
                    <View style={layoutStyles.jcContainer}>
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.625 1.6875H1.375M8.875 5.125C8.875 4.54257 8.875 4.25136 8.77985 4.02165C8.65298 3.71536 8.40964 3.47202 8.10335 3.34515C7.87364 3.25 7.58243 3.25 7 3.25C6.41757 3.25 6.12636 3.25 5.89665 3.34515C5.59036 3.47202 5.34702 3.71536 5.22015 4.02165C5.125 4.25136 5.125 4.54257 5.125 5.125V7V8.875C5.125 9.45743 5.125 9.74864 5.22015 9.97835C5.34702 10.2846 5.59036 10.528 5.89665 10.6548C6.12636 10.75 6.41757 10.75 7 10.75C7.58243 10.75 7.87364 10.75 8.10335 10.6548C8.40964 10.528 8.65298 10.2846 8.77985 9.97835C8.875 9.74864 8.875 9.45743 8.875 8.875V7V5.125Z" stroke={distribution == 'space-evenly' ? "#F5F9FF" : "#93989B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </View>
                </TouchableOpacity> */}
      </View>
   );
};

export default JustifyContent;

const layoutStyles = StyleSheet.create({
   jcButton: {
      flex: 1,
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   jcButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   jcContainer: {
      height: 30,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center',
   },
   justifyContentButtons: {
      flex: 1,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
});
