import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Pressable, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import actions from './Actions';
import CreateDBAction from './DbActions/CreateDBAction';
import { updateComponent } from '../../store/ProjectSlice';
import Input, { ComponentData } from '../base/InputField';

/**
 * Iterate over component and its children to find all their {ids, data, name}
 * Used for instance to find all the available components inside of a page.
 */
const getComponentChildrenMeta = (components, pageId) => {
   const component = components[pageId];

   if (component.children) {
      return component.children.reduce(
         (acc, child) => [...acc, ...getComponentChildrenMeta(components, child)],
         [
            {
               key: component.composite_key,
               name: component.name,
               data: Object.keys(component.data || {}),
            },
         ]
      );
   }
   return [
      {
         key: component.composite_key,
         name: component.name,
         data: Object.keys(component.data || {}),
      },
   ];
};

const ActionConfigPane = ({ logicWorkflow, closePanel }) => {
   const dispatch = useDispatch();
   const componentKey = useSelector((state) => state.globals.selected);
   const componentDef = useSelector(
      (state) => state.project.definition.components[componentKey[componentKey.length - 1]]
   );
   const { selectedLogicAction, page } = useSelector((state) => state.editor);

   const userStyles = useSelector((state) => state.project?.definition?.design_system?.user_styles);

   const pages = useSelector((state) => state.project.definition.pages);
   const components = useSelector((state) => state.project.definition.components);

   const componentsInCurrentPage = useMemo(() => {
      return getComponentChildrenMeta(components, pages[page].root_component).sort(
         (a, b) => a.data?.length > b.data?.length
      );
   }, [components, page]);

   return (
      <View style={styles.container}>
         <View style={styles.header}>
            <Text style={styles.headerTxt}>Action Editor</Text>
            {/* ({selectedLogicAction}) */}
            <View style={styles.headerButtons}>
               <Pressable
                  style={({ pressed }) => [
                     styles.headerButton,
                     pressed ? styles.buttonPressed : {},
                  ]}
                  onPress={() => console.log('todo')}>
                  <Ionicons name='ellipsis-horizontal-sharp' size={20} color='#646D72' />
               </Pressable>
               <Pressable
                  style={({ pressed }) => [
                     styles.headerButton,
                     { backgroundColor: '#F5F6F8' },
                     pressed ? styles.buttonPressed : {},
                  ]}
                  onPress={closePanel}>
                  <Ionicons name='close-outline' size={20} color='#646D72' />
               </Pressable>
            </View>
         </View>
         <View style={styles.inputs}>
            <Input
               label='Action Type'
               pickedValue={
                  logicWorkflow?.nodes[selectedLogicAction]?.type || 'action.setComponentData'
               }
               pickerOptions={Object.values(actions).map((action) => ({
                  label: action.name,
                  value: action.uid,
               }))}
               handlePickerUpdate={(value) => {
                  dispatch(
                     updateComponent(componentKey, [
                        {
                           path: ['logic', logicWorkflow.uid, 'nodes', selectedLogicAction, 'type'],
                           value,
                        },
                        {
                           path: [
                              'logic',
                              logicWorkflow.uid,
                              'nodes',
                              selectedLogicAction,
                              'parameters',
                           ],
                           value: null,
                        },
                     ])
                  );
               }}
            />
            {(
               actions[logicWorkflow.nodes[selectedLogicAction].type || 'action.setComponentData']
                  ?.parameters || []
            ).map((parameter, index) => {
               switch (parameter.type) {
                  case 'text':
                     return (
                        <Input
                           key={parameter.type + '_' + index}
                           label={parameter.name}
                           placeholder={parameter.uid}
                           value={
                              logicWorkflow?.nodes[selectedLogicAction]?.parameters?.[
                                 parameter.uid
                              ] || ''
                           }
                           handleValueUpdate={(value) => {
                              console.log('Update action parameter', parameter.uid, 'to', value);
                              dispatch(
                                 updateComponent(componentKey, [
                                    {
                                       path: [
                                          'logic',
                                          logicWorkflow.uid,
                                          'nodes',
                                          selectedLogicAction,
                                          'parameters',
                                          parameter.uid,
                                       ],
                                       value,
                                    },
                                 ])
                              );
                           }}
                        />
                     );
                  case 'enum':
                     return (
                        <Input
                           key={parameter.type + '_' + index}
                           label={parameter.name}
                           pickerOptions={parameter.options}
                           handlePickerUpdate={(value) =>
                              dispatch(
                                 updateComponent(componentKey, [
                                    {
                                       path: [
                                          'logic',
                                          logicWorkflow.uid,
                                          'nodes',
                                          selectedLogicAction,
                                          'parameters',
                                          parameter.uid,
                                       ],
                                       value,
                                    },
                                 ])
                              )
                           }
                        />
                     );
                  case 'custom.componentLogic':
                  case 'custom.componentId':
                     return (
                        <Input
                           key={parameter.name}
                           label={parameter.name}
                           // pickedValue={parameter.uid || ''}
                           pickedValue={
                              componentsInCurrentPage.find(
                                 (component) =>
                                    component.key[component.key.length - 1] ===
                                       logicWorkflow?.nodes[selectedLogicAction]?.parameters?.[
                                          parameter.uid
                                       ] || componentDef.uid
                              )?.name ?? ''
                           }
                           pickerOptions={componentsInCurrentPage.map((componentMeta) => ({
                              label: componentMeta.name,
                              value: componentMeta.key[componentMeta.key.length - 1],
                           }))}
                           handlePickerUpdate={(value) => {
                              dispatch(
                                 updateComponent(componentKey, [
                                    {
                                       path: [
                                          'logic',
                                          logicWorkflow.uid,
                                          'nodes',
                                          selectedLogicAction,
                                          'parameters',
                                          parameter.uid,
                                       ],
                                       value,
                                    },
                                 ])
                              );
                           }}
                        />
                     );
                  case 'custom.dbTableName':
                     return (
                        <CreateDBAction
                           parameter={parameter}
                           componentKey={componentKey}
                           logicWorkflow={logicWorkflow}
                           selectedLogicAction={selectedLogicAction}
                           updateComponent={updateComponent}
                        />
                     );
                  case 'custom.dbCreateDbEntry':
                     return (
                        <CreateDBAction
                           parameter={parameter}
                           componentKey={componentKey}
                           logicWorkflow={logicWorkflow}
                           selectedLogicAction={selectedLogicAction}
                           updateComponent={updateComponent}
                        />
                     );
                  case 'custom.projectPages':
                     return (
                        <Input
                           key={parameter.name}
                           label={parameter.name}
                           pickedValue={Object.keys(pages).find(
                              (item) =>
                                 pages[item].uid ===
                                 logicWorkflow?.nodes[selectedLogicAction]?.parameters?.target
                           )}
                           pickerOptions={Object.keys(pages).map((item) => ({
                              label: pages[item].name,
                              value: pages[item].uid,
                           }))}
                           handlePickerUpdate={(value) =>
                              dispatch(
                                 updateComponent(componentKey, [
                                    {
                                       path: [
                                          'logic',
                                          logicWorkflow.uid,
                                          'nodes',
                                          selectedLogicAction,
                                          'parameters',
                                          parameter.uid,
                                       ],
                                       value,
                                    },
                                 ])
                              )
                           }
                        />
                     );
                  case 'custom.componentStyle':
                     return (
                        <Input
                           key={parameter.name}
                           label={parameter.name}
                           pickedValue={Object.keys(userStyles).find(
                              (item) =>
                                 userStyles[item].uid ===
                                 logicWorkflow?.nodes[selectedLogicAction]?.parameters?.target
                           )}
                           pickerOptions={Object.keys(userStyles).map((item) => ({
                              label: userStyles[item].name,
                              value: userStyles[item].uid,
                           }))}
                           handlePickerUpdate={(value) =>
                              dispatch(
                                 updateComponent(componentKey, [
                                    {
                                       path: [
                                          'logic',
                                          logicWorkflow.uid,
                                          'nodes',
                                          selectedLogicAction,
                                          'parameters',
                                          'target',
                                       ],
                                       value,
                                    },
                                 ])
                              )
                           }
                        />
                     );
                  case 'custom.componentData':
                     return (
                        <ComponentData
                           key={parameter.type + '_' + index}
                           actionNode={logicWorkflow.nodes[selectedLogicAction]}
                           handleUpdate={(path, value) =>
                              dispatch(
                                 updateComponent(componentKey, [
                                    {
                                       path: [
                                          'logic',
                                          logicWorkflow.uid,
                                          'nodes',
                                          selectedLogicAction,
                                          'parameters',
                                          path,
                                       ],
                                       value,
                                    },
                                 ])
                              )
                           }
                        />
                     );
                  default:
                     console.log('Unknown parameter type', parameter.type);
                     return <View key={'unknown_' + index} />;
               }
            })}
         </View>
      </View>
   );
};

export default ActionConfigPane;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      backgroundColor: '#fff',
   },
   headerTxt: {
      color: '#262626',
      /* Buttons/Large */
      fontFamily: 'Inter',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '130%' /* 20.8px */,
   },
   header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 4,
      padding: 16,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: '#E0E4F4',
   },
   headerButtons: {
      flexDirection: 'row',
      columnGap: 8,
   },
   headerButton: {
      padding: 4,
      borderRadius: 4,
   },
   inputs: {
      padding: 20,
      gap: 20,
   },
});
