import { actions } from '../slice'
import { askParallelAI } from '../openai'
import { CHAT_STATUS, TCategory, TMessage } from '../types'
import { checkCommandTypeContext } from '../../../consts/prompts'

import { assistantCreatePage } from './createPage'
import { assistantCreateSection } from './createSection'
import { assistantUpdateComponent } from './updateComponent'
import { assistantAnswerQuestion } from './answerQuestion'

export const checkCategory = (text: string, debug=0) => async (
   dispatch: (...args: any[]) => void, 
   getState: () => Record<string, any>
): Promise<void> => {
   if (debug > 2) console.log('[checkCategory] Start')
   dispatch(actions.setChatStatus(CHAT_STATUS.handlingCategory));
   const firstRound = await askParallelAI(checkCommandTypeContext.concat({role:'user', content: text}), 2, debug)
   let category = 'question'
   if (firstRound[0].content === firstRound[1].content) {
      category = validateCategory(firstRound[0].content, debug)
   } else {
      const confirmationRound = await askParallelAI(checkCommandTypeContext.concat({role:'user', content: text}), 3, debug)
      const categoriesScores = [...firstRound, ...confirmationRound].reduce(
         (acc: Record<string, number>, categoryMessage: TMessage) => ({
            ...acc,
            [categoryMessage.content]: (acc[categoryMessage.content] ?? 0) + 1
         }),
         {}
      )
      // return the key with the highest score
      category = validateCategory(
         Object.keys(categoriesScores).reduce((a, b) => categoriesScores[a] > categoriesScores[b] ? a : b)
      );
   }
   if (debug > 3) console.log('Updating category: ', getState().assistant.run.category, '=>', category)
   dispatch(actions.setRunCategory(category));
}
const validateCategory = (category: string, debug=0): TCategory => {
   if (debug > 2) console.log('[validateCategory] Start')
   if (category in CATEGORIES) {
      if (debug > 3) console.log('Validating category: ', category as TCategory)
      return category as TCategory
   }
   if (debug > 0) console.warn('Unknown category: ', category);
   return 'question';
}


export const CATEGORIES: Record<TCategory, (debugLevel: number) => any> = {
   createPage: assistantCreatePage,
   createSection: assistantCreateSection,
   updateComponent: assistantUpdateComponent,
   question: assistantAnswerQuestion,
}