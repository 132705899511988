import React from 'react';
import _ from 'lodash';
import MetaComponent from '../../engine/containers/MetaComponent';

const MetaComponentContainer = (props) => {
   // const cleanDefinition = clearAllLogic(props.definition);
   
   return <MetaComponent componentId={props.componentId} />;
};

export default MetaComponentContainer;
