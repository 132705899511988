import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import TextStyling from './TextStyling/TextStyling';
import ChangeComponentName from './ChangeComponentName';
import DefaultInputContainer from '../base/DefaultInputContainer';

const InputConfig = ({ componentDef, styles, handleUpdate, rootHandleElementUpdate }) => {
   const [placeholder, setPlaceholder] = useState(componentDef.properties?.input?.placeholder || '');
   const [inputType, setInputType] = useState(componentDef.properties?.input?.type || 'text')

   useEffect(() => {
      setPlaceholder(componentDef.properties?.input?.placeholder || '');
   }, [componentDef.properties?.input?.placeholder]);
   useEffect(() => {
      setInputType(componentDef.properties?.input?.type || '');
   }, [componentDef.properties?.input?.type]);

   if (inputType === 'text') {
      return (<>
         <DefaultInputContainer
            style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4', }}
            componentDef={componentDef}
            data={['text', 'document', 'image']}
            onItemPress={(value) => { 
               handleUpdate([{ path: ['properties', 'input', 'type'], value }]);
               setInputType(value);
            }}
            value={inputType}
            // icon={
            //    <svg
            //       width='14'
            //       height='10'
            //       viewBox='0 0 14 10'
            //       fill='none'
            //       xmlns='http://www.w3.org/2000/svg'>
            //       <path
            //          d='M4.75 1.25L1 5L4.75 8.75M9.25 1.25L13 5L9.25 8.75'
            //          stroke='#93989B'
            //          strokeWidth='1.5'
            //          strokeLinecap='round'
            //          strokeLinejoin='round'
            //       />
            //    </svg>
            // }
         />
         <TextStyling
            rootHandleElementUpdate={rootHandleElementUpdate}
            componentDef={componentDef}
            handleUpdate={handleUpdate}
         />
         <View>
            {/* TODO: rename this component. "ChangeComponentName" isn't at all descriptive of what it does */}
            <ChangeComponentName
               placeholder='Placeholder'
               value={placeholder || ''}
               onChangeText={(value) => {
                  handleUpdate([{ path: ['properties', 'input', 'placeholder'], value }]);
                  setPlaceholder(value);
               }}
            />
         </View>
      </>)
   }
   return (
      <DefaultInputContainer
         style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4', }}
         componentDef={componentDef}
         data={['text', 'document', 'image']}
         onItemPress={(value) => { 
            handleUpdate([{ path: ['properties', 'input', 'type'], value }]);
            setInputType(value);
         }}
         value={inputType}
         // icon={
         //    <svg
         //       width='14'
         //       height='10'
         //       viewBox='0 0 14 10'
         //       fill='none'
         //       xmlns='http://www.w3.org/2000/svg'>
         //       <path
         //          d='M4.75 1.25L1 5L4.75 8.75M9.25 1.25L13 5L9.25 8.75'
         //          stroke='#93989B'
         //          strokeWidth='1.5'
         //          strokeLinecap='round'
         //          strokeLinejoin='round'
         //       />
         //    </svg>
         // }
      />
   )
};

export default InputConfig;

const styles = StyleSheet.create({});
