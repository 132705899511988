import { TouchableOpacity } from "react-native";

const SearchButton = ({style}) => {
    return (
        <TouchableOpacity style={[{
            padding: 12,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F5F6F8',
            borderRadius: 8,
            width: 56,
            height: 56,
        }, style]}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'>
                    <path
                        d='M15.75 15.75L12.4875 12.4875M14.25 8.25C14.25 11.5637 11.5637 14.25 8.25 14.25C4.93629 14.25 2.25 11.5637 2.25 8.25C2.25 4.93629 4.93629 2.25 8.25 2.25C11.5637 2.25 14.25 4.93629 14.25 8.25Z'
                        stroke='#646D72'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
        </TouchableOpacity>
    );
};

export default SearchButton;