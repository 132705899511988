import { Entypo } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import DefaultInputContainer from './../../base/DefaultInputContainer';
import JustifyContent from './JustifyContent';
import LayoutDirection from './LayoutDirection';

const LayoutStyling = ({ rootHandleElementUpdate, componentDef, styles, handleUpdate }) => {
   const [flexible, setFlexible] = useState(
      componentDef[rootHandleElementUpdate]?.position?.flexible || ''
   );

   useEffect(() => {
      setFlexible(componentDef[rootHandleElementUpdate]?.position?.flexible || '');
   }, [componentDef[rootHandleElementUpdate]?.position?.flexible]);

   const [expandLayout, setExpandLayout] = useState(true);

   const layout = () => {
      return (
         <View style={{ flex: 1 }}>
            <View style={[layoutStyles.column]}>
               <View style={[styles.row, { marginTop: 5 }]}>
                  <LayoutDirection
                     style={{ width: 125, borderRadius: 8}}
                     rootHandleElementUpdate={rootHandleElementUpdate}
                     handleUpdate={handleUpdate}
                     componentDef={componentDef}
                  />
                  <DefaultInputContainer
                     style={{ marginLeft: 10, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                     componentDef={componentDef}
                     handleUnitUpdate={(value) => {
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'layout', 'row_gap', 'unit'], value },
                        ]);
                     }}
                     onChangeText={(value) => {
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'layout', 'row_gap', 'val'], value },
                        ]);
                     }}
                     unit={componentDef[rootHandleElementUpdate]?.layout?.row_gap?.unit}
                     value={componentDef[rootHandleElementUpdate]?.layout?.row_gap?.val}
                     icon={
                        <svg
                           width='16'
                           height='16'
                           viewBox='0 0 16 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M14.75 1.25H1.25M14.75 14.75H1.25M2.75 8C2.75 7.30109 2.75 6.95163 2.86418 6.67598C3.01642 6.30843 3.30843 6.01642 3.67598 5.86418C3.95163 5.75 4.30109 5.75 5 5.75L11 5.75C11.6989 5.75 12.0484 5.75 12.324 5.86418C12.6916 6.01642 12.9836 6.30843 13.1358 6.67598C13.25 6.95163 13.25 7.30109 13.25 8C13.25 8.69891 13.25 9.04837 13.1358 9.32402C12.9836 9.69157 12.6916 9.98358 12.324 10.1358C12.0484 10.25 11.6989 10.25 11 10.25L5 10.25C4.30109 10.25 3.95163 10.25 3.67597 10.1358C3.30843 9.98358 3.01642 9.69157 2.86418 9.32402C2.75 9.04837 2.75 8.69891 2.75 8Z'
                              stroke='#93989B'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     }
                  />
               </View>
               <View style={[styles.row, { marginTop: 5 }]}>
                  <JustifyContent
                     style={{ width: 125, borderRadius: 8 }}
                     rootHandleElementUpdate={rootHandleElementUpdate}
                     rotateIcons={
                        componentDef[rootHandleElementUpdate]?.layout?.direction != 'column'
                     }
                     handleUpdate={handleUpdate}
                     componentDef={componentDef}
                  />
                  <DefaultInputContainer
                     style={{ marginLeft: 10, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                     componentDef={componentDef}
                     handleUnitUpdate={(value) => {
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'layout', 'column_gap', 'unit'],
                              value,
                           },
                        ]);
                     }}
                     onChangeText={(value) => {
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'layout', 'column_gap', 'val'],
                              value,
                           },
                        ]);
                     }}
                     unit={componentDef[rootHandleElementUpdate]?.layout?.column_gap?.unit}
                     value={componentDef[rootHandleElementUpdate]?.layout?.column_gap?.val}
                     icon={
                        <svg
                           width='16'
                           height='16'
                           viewBox='0 0 16 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M14.75 14.75V1.25M1.25 14.75V1.25M5.75 5V11C5.75 11.6989 5.75 12.0484 5.86418 12.324C6.01642 12.6916 6.30843 12.9836 6.67597 13.1358C6.95163 13.25 7.30109 13.25 8 13.25C8.69891 13.25 9.04837 13.25 9.32402 13.1358C9.69157 12.9836 9.98358 12.6916 10.1358 12.324C10.25 12.0484 10.25 11.6989 10.25 11V5C10.25 4.30109 10.25 3.95163 10.1358 3.67598C9.98358 3.30843 9.69157 3.01642 9.32403 2.86418C9.04837 2.75 8.69891 2.75 8 2.75C7.30109 2.75 6.95163 2.75 6.67598 2.86418C6.30843 3.01642 6.01642 3.30843 5.86418 3.67598C5.75 3.95163 5.75 4.30109 5.75 5Z'
                              stroke='#93989B'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     }
                  />
               </View>
               <View style={[styles.row, { marginTop: 5, }]}>
                  <DefaultInputContainer
                     style={{ width: 260, marginBottom: 30, }}
                     childStyle={{ borderWidth: 1, borderColor: '#E0E4F4' }}
                     componentDef={componentDef}
                     onChangeText={(value) => {
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'position', 'flexible'],
                              value,
                           },
                        ]);
                        setFlexible(value);
                     }}
                     value={flexible}
                     icon={
                        <svg
                           width='24'
                           height='24'
                           viewBox='0 0 24 24'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M16 12L12 8M12 8L8 12M12 8V16M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                              stroke='#93989B'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     }
                  />
               </View>
            </View>
         </View>
      );
   };

   return (
      <View style={[styles.row]}>
         <View style={{ flexDirection: 'column', flex: 1, paddingHorizontal: 20 }}>
            <View style={layoutStyles.expandContainer}>
               <Text style={[layoutStyles.spacesAndSizesText]}>Layout</Text>
               <TouchableOpacity
                  onPress={() => setExpandLayout(!expandLayout)}
                  style={{ alignItems: 'center', justifyContent: 'center' }}>
                  {expandLayout ? (
                     <Entypo name='chevron-thin-down' size={24} color='#93989B' />
                  ) : (
                     <Entypo name='chevron-thin-up' size={24} color='#93989B' />
                  )}
               </TouchableOpacity>
            </View>
            {expandLayout && 
               <View style={{flex: 1}}>
                  {
                     layout()
                  }
               </View>
            }
         </View>
      </View>
   );
};

export default LayoutStyling;

const layoutStyles = StyleSheet.create({
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   },
   column: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
   },
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10,
   },
   textStyle: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      textAlign: 'center',
      color: '#646D72',
   },
   selectedText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      textAlign: 'center',
      color: '#F5F9FF',
   },
   fdButton: {
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   fdButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   layoutButton: {
      flex: 1,
      width: 86,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   layoutButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   jcButton: {
      flex: 1,
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   jcButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   fdContainer: {
      height: 16,
      width: 16,
   },
   jcContainer: {
      height: 14,
      width: 14,
   },
   layoutButtons: {
      width: 260,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
   },
   flexDirectionButtons: {
      flex: 1,
      width: 90,
      height: 30,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   justifyContentButtons: {
      width: 150,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
});
