import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Modal, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const InlineActionsModal = ({
   showModal,
   onToggleModal,
   handleDuplicate,
   handleRemove,
   handleEdit,
   calculateModalPosition,
}) => {
   return (
      <Modal
         transparent
         onRequestClose={() => {
            onToggleModal(false);
         }}
         visible={showModal}>
         <View style={[styles.modalContainer, calculateModalPosition()]}>
            <TouchableOpacity
               style={{
                  width: 10,
                  height: 10,
                  position: 'absolute',
                  top: 10,
                  right: 10,
               }}
               onPress={() => onToggleModal(false)}>
               <svg
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M11 11L1 1M1 11L11 1'
                     stroke='#757677'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </TouchableOpacity>
            <TouchableOpacity
               style={[styles.modalButton, { marginTop: 15 }]}
               onPress={handleDuplicate}>
               <View>
                  <svg
                     width='20'
                     height='20'
                     viewBox='0 0 20 20'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <g clipPath='url(#clip0_2123_36660)'>
                        <path
                           d='M6.66602 6.66699V4.33366C6.66602 3.40024 6.66602 2.93353 6.84767 2.57701C7.00746 2.2634 7.26243 2.00844 7.57603 1.84865C7.93255 1.66699 8.39926 1.66699 9.33268 1.66699H15.666C16.5994 1.66699 17.0661 1.66699 17.4227 1.84865C17.7363 2.00844 17.9912 2.2634 18.151 2.57701C18.3327 2.93353 18.3327 3.40024 18.3327 4.33366V10.667C18.3327 11.6004 18.3327 12.0671 18.151 12.4236C17.9912 12.7372 17.7363 12.9922 17.4227 13.152C17.0661 13.3337 16.5994 13.3337 15.666 13.3337H13.3327M4.33268 18.3337H10.666C11.5994 18.3337 12.0661 18.3337 12.4227 18.152C12.7363 17.9922 12.9912 17.7372 13.151 17.4236C13.3327 17.0671 13.3327 16.6004 13.3327 15.667V9.33366C13.3327 8.40024 13.3327 7.93353 13.151 7.57701C12.9912 7.2634 12.7363 7.00844 12.4227 6.84865C12.0661 6.66699 11.5994 6.66699 10.666 6.66699H4.33268C3.39926 6.66699 2.93255 6.66699 2.57603 6.84865C2.26243 7.00844 2.00746 7.2634 1.84767 7.57701C1.66602 7.93353 1.66602 8.40024 1.66602 9.33366V15.667C1.66602 16.6004 1.66602 17.0671 1.84767 17.4236C2.00746 17.7372 2.26243 17.9922 2.57603 18.152C2.93255 18.3337 3.39926 18.3337 4.33268 18.3337Z'
                           stroke='#50585C'
                           strokeWidth='1.5'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </g>
                     <defs>
                        <clipPath id='clip0_2123_36660'>
                           <rect width='20' height='20' fill='white' />
                        </clipPath>
                     </defs>
                  </svg>
               </View>
               <Text style={styles.modalText}>Duplicate</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalButton} onPress={handleRemove}>
               <View>
                  <svg
                     width='18'
                     height='14'
                     viewBox='0 0 18 14'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M1.01677 7.59464C0.90328 7.41494 0.846535 7.32509 0.81477 7.18651C0.79091 7.08241 0.79091 6.91824 0.81477 6.81415C0.846535 6.67556 0.90328 6.58571 1.01677 6.40601C1.95461 4.92103 4.74617 1.16699 9.00034 1.16699C13.2545 1.16699 16.0461 4.92103 16.9839 6.40601C17.0974 6.58571 17.1541 6.67556 17.1859 6.81415C17.2098 6.91824 17.2098 7.08241 17.1859 7.18651C17.1541 7.32509 17.0974 7.41494 16.9839 7.59464C16.0461 9.07962 13.2545 12.8337 9.00034 12.8337C4.74617 12.8337 1.95461 9.07963 1.01677 7.59464Z'
                        stroke='#50585C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                     <path
                        d='M9.00034 9.50033C10.381 9.50033 11.5003 8.38104 11.5003 7.00033C11.5003 5.61961 10.381 4.50033 9.00034 4.50033C7.61962 4.50033 6.50034 5.61961 6.50034 7.00033C6.50034 8.38104 7.61962 9.50033 9.00034 9.50033Z'
                        stroke='#50585C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                  </svg>
               </View>
               <Text style={styles.modalText}>Remove</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalButton} onPress={handleEdit}>
               <View>
                  <svg
                     width='18'
                     height='14'
                     viewBox='0 0 18 14'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M1.01677 7.59464C0.90328 7.41494 0.846535 7.32509 0.81477 7.18651C0.79091 7.08241 0.79091 6.91824 0.81477 6.81415C0.846535 6.67556 0.90328 6.58571 1.01677 6.40601C1.95461 4.92103 4.74617 1.16699 9.00034 1.16699C13.2545 1.16699 16.0461 4.92103 16.9839 6.40601C17.0974 6.58571 17.1541 6.67556 17.1859 6.81415C17.2098 6.91824 17.2098 7.08241 17.1859 7.18651C17.1541 7.32509 17.0974 7.41494 16.9839 7.59464C16.0461 9.07962 13.2545 12.8337 9.00034 12.8337C4.74617 12.8337 1.95461 9.07963 1.01677 7.59464Z'
                        stroke='#50585C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                     <path
                        d='M9.00034 9.50033C10.381 9.50033 11.5003 8.38104 11.5003 7.00033C11.5003 5.61961 10.381 4.50033 9.00034 4.50033C7.61962 4.50033 6.50034 5.61961 6.50034 7.00033C6.50034 8.38104 7.61962 9.50033 9.00034 9.50033Z'
                        stroke='#50585C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                  </svg>
               </View>
               <Text style={styles.modalText}>Edit</Text>
            </TouchableOpacity>
         </View>
      </Modal>
   );
};

export default InlineActionsModal;

const styles = StyleSheet.create({
   modalContainer: {
      width: 248,
      padding: 10,
      borderColor: '#f5f6f8',
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
   },
   modalText: {
      fontFamily: 'Inter',
      color: '#50585C',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      paddingLeft: 20,
   },
   modalButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
   },
});
