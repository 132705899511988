import _ from 'lodash';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import MetaComponent from '../../../../engine/containers/MetaComponent';

const ElementContainer = ({ selectedCustomStyle, handleUpdate }) => {
   const mainComponent = useSelector(
      (state) => state.project.definition.components['rootElementContainer']
   );

   return (
      <View pointerEvents="none" style={styles.fontMainContainer}>
         {selectedCustomStyle && (
            <>
               <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={styles.titleText}>{selectedCustomStyle?.name}</Text>
                  <TouchableOpacity
                     onPress={(item) => {
                        handleUpdate([
                           {
                              path: ['design_system', 'user_styles', selectedCustomStyle?.uid],
                              value: null,
                           },
                        ]);
                     }}>
                     <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M7 1H13M1 4H19M17 4L16.2987 14.5193C16.1935 16.0975 16.1409 16.8867 15.8 17.485C15.4999 18.0118 15.0472 18.4353 14.5017 18.6997C13.882 19 13.0911 19 11.5093 19H8.49065C6.90891 19 6.11803 19 5.49834 18.6997C4.95276 18.4353 4.50009 18.0118 4.19998 17.485C3.85911 16.8867 3.8065 16.0975 3.70129 14.5193L3 4'
                           stroke='#ABB1CA'
                           strokeWidth='2'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  </TouchableOpacity>
               </View>
               <View style={styles.fontDescContainer}>
                  <MetaComponent componentId={mainComponent?.uid} />
               </View>
            </>
         )}
      </View>
   );
};

export default ElementContainer;

const styles = StyleSheet.create({
   textContainer: {
      marginBottom: 20,
   },
   fontDescContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
   },
   container: {
      paddingHorizontal: 20,
   },
   titleText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 18,
      lineHeight: 21,
      color: '#262626',
   },
   fontMainContainer: {
      width: 378,
      height: 257,
      borderRadius: 8,
      borderWidth: 1,
      paddingTop: 32,
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      marginHorizontal: 20,
   },
});
