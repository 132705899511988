import { createSlice } from '@reduxjs/toolkit';
import {
   CHAT_STATUS, TAssistantSlice, TChatStatus, TCategory, 
   TPatch, TMessage,
} from './types'

const cleanRunState = { prompt: '', enrichedPrompt: '', category: null, patch: [], userConfirmation: false }

/*******************
 *** Store Slice ***
 *******************/
const assistantSlice = createSlice({
   name: 'assistant',
   initialState: {
      chatStatus: CHAT_STATUS.idle,
      run: cleanRunState,
      messages: [],
   },
   reducers: {
      setChatStatus: (
         state: TAssistantSlice, 
         action: { payload: TChatStatus }
      ) => {
         if (!(action.payload in CHAT_STATUS)) {
            console.warn('Unknown chat status', action.payload);
            return;
         }
         state.chatStatus = CHAT_STATUS[action.payload as TChatStatus];
      },
      setRunCategory: (state: TAssistantSlice, action: {payload: TCategory}) => {
         state.run.category = action.payload
      },
      setRunPrompt: (state: TAssistantSlice, action: {payload: string}) => {
         state.run.prompt = action.payload
      },
      setRunEnrichedPrompt: (state: TAssistantSlice, action: {payload: string}) => {
         state.run.enrichedPrompt = action.payload
      },
      setRunUserConfirmation: (state: TAssistantSlice, action: {payload: boolean}) => {
         state.run.userConfirmation = action.payload
      },
      addRunPatch: (state: TAssistantSlice, action: {payload: TPatch}) => {
         state.run.patch = [...state.run.patch, action.payload]
         // state.messages.push(action.payload);
      },
      finishRun: (state: TAssistantSlice) => {
         state.run = cleanRunState;
      },
      resetMessages: (state: TAssistantSlice) => {
         state.messages = [];
      },
      addMessage: (state: TAssistantSlice, action: { payload: TMessage }) => {
         state.messages.push(action.payload);
      }
   }
});

export default assistantSlice.reducer;

export const actions = assistantSlice.actions;
export const {
   setChatStatus, setRunCategory, setRunPrompt, 
   setRunEnrichedPrompt, setRunUserConfirmation, 
   addRunPatch, finishRun, resetMessages, addMessage 
} = assistantSlice.actions
