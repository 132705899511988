import { A } from '@expo/html-elements';
import { Octicons } from '@expo/vector-icons';
import { useState, useEffect } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

const DownloadSelectedButton = ({ onPress }) => {
   const [dataLink, setDataLink] = useState(null);
   const database = useSelector((state) => state.database);

   const { currentTable, selectedRows } = database;

   useEffect(() => {
      const blob = new Blob([JSON.stringify(selectedRows)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      setDataLink(url);
   }, [selectedRows]);

   return (
      <TouchableOpacity onPress={onPress} style={styles.container}>
         <Octicons name='download' size={18} color='#7B87A6' />
         <A style={styles.text} href={dataLink} download={currentTable.name + '.json'}>
            Download selected
         </A>
      </TouchableOpacity>
   );
};

export default DownloadSelectedButton;

const styles = StyleSheet.create({
   container: {
      paddingTop: 6,
      paddingLeft: 16,
      paddingBottom: 6,
      paddingRight: 14,
      borderRadius: 8,
      gap: 10,
      height: 34,
      flexDirection: 'row',
   },
   text: {
      fontFamily: 'Inter',
      color: '#7B87A6',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
});
