import { Pressable, ScrollView, StyleSheet, Text, TextInput, View, Picker } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateComponent, createComponentData } from '../../store/ProjectSlice';

const DataPanel = ({ componentDef }) => {
    const dispatch = useDispatch();

    const projectId = useSelector(state => state.editor.project)
    const componentId = componentDef.uid

    const handleChanges = (pendingChanges) => {
        dispatch(updateComponent(componentDef.composite_key, pendingChanges))
    }

    return (
        <ScrollView>
            <Pressable style={styles.new} onPress={() => dispatch(createComponentData(projectId, componentId))}>
                <Text>Add new</Text>
            </Pressable>
            {
                Object.keys(componentDef.data || {}).map((key) => {
                    return (
                        <View style={styles.item} key={key}>
                            <TextInput
                                style={styles.input}
                                placeholder="Label"
                                value={componentDef.data[key].label || ''}
                                onChangeText={(value) => {
                                    handleChanges([
                                       { path: ['data', key, 'label'], value: value}
                                    ])
                                }}
                            />
                            <Picker
                                selectedValue={componentDef.data[key].type || 'string'}
                                onValueChange={(value) => {
                                    handleChanges([
                                       { path: ['data', key, 'type'], value }
                                    ])
                                }}
                            >
                                <Picker.Item label="Text" value="string" />
                                <Picker.Item label="Number" value="number" />
                                <Picker.Item label="Yes/No" value="boolean" />
                                <Picker.Item label="Object" value="object" />
                            </Picker>
                            <TextInput
                                style={styles.input}
                                placeholder="Default value"
                                value={componentDef.data[key].default || ''}
                                onChangeText={(value) => {
                                    handleChanges([
                                        { path: ['data', key, 'default'], value: value}
                                    ])
                                }}
                            />
                            <Pressable onPress={() => {
                                handleChanges([
                                    { path: ['data', key], value: value}
                                ])
                            }}>
                                <Text style={{ color: 'red' }}>X</Text>
                            </Pressable>
                        </View>
                    )
                })
            }
            {componentDef.type === 'Input' &&
                <View style={styles.item}>
                    <Text style={styles.input}>{'input_value'}</Text>
                    <Picker
                        selectedValue={componentDef.data?.input_value?.type || 'string'}
                        onValueChange={(value) => {
                            handleChanges([
                                { path: ['data', 'input_value', 'type'], value: value}
                            ])
                        }}
                    >
                        <Picker.Item label="Text" value="string" />
                        <Picker.Item label="Number" value="number" />
                        <Picker.Item label="Yes/No" value="boolean" />
                        <Picker.Item label="Object" value="object" />
                    </Picker>
                    <Text style={{ color: 'grey' }}>?</Text>
                </View>
            }
        </ScrollView>
    )
}

export default DataPanel

const styles = StyleSheet.create({
    new: {
        margin: 10,
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 10,
        gap: 10,
        borderTopWidth: 1,
    },
    input: {
        flex: 1,
        flexDirection: 'row',
        height: 40,
        borderWidth: 1,
        // textAlignVertical: 'center',
    },
})