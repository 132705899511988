import { useState } from 'react';
import { TextInput, TouchableOpacity, View, Text } from 'react-native';

const ChangableField = ({ value, onChange, style, containerStyle, ...props }) => {
   const [isEditing, setIsEditing] = useState(false);
   const [inputValue, setInputValue] = useState(value);

   const handleBlur = () => {
      setIsEditing(false);
      onChange(inputValue);
      setInputValue('');
   };

   const handleChange = (text) => {
      setInputValue(text);
   };

   const handleFocus = () => {
      setIsEditing(true);
   };

   return (
      <View style={containerStyle}>
         {isEditing ? (
            <TextInput
               autoFocus
               onBlur={handleBlur}
               onChangeText={handleChange}
               value={inputValue}
               style={style}
               {...props}
            />
         ) : (
            <TouchableOpacity onPress={handleFocus}>
               <Text numberOfLines={1} style={style}>{value}</Text>
            </TouchableOpacity>
         )}
      </View>
   );
};
export default ChangableField;
