import { TSectionBuilderPayload } from "../types";
import { generateComponent } from "../generators";
import { topProductsContext } from "../../../consts/prompts";

export const createFooter = (payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   if (debug > 2) console.log('[CreateFooter] Start')
   const { description, sections, rootId, rootIndex, pageId } = payload;

   const footerDef = await dispatch(generateComponent(
      {
         name: 'Footer Section',
         type: 'box',
         description: description,
         custom_styling: {
            padding: {
               top: { val: 2, unit: 'rf' },
               right: { val: 10, unit: '%' },
               bottom: { val: 2, unit: 'rf' },
               left: { val: 10, unit: '%' },
            },
            background: { type: 'color', color: 'black' },
            layout: {
               direction: 'row',
               distribution: 'center',
            }
         },
      },
      [
         // {path: ['custom_styling', 'background', 'image', 'src'], type: 'image', context: bgImagePrompt }
      ],
      [rootId],
      rootIndex,
      debug
   ))
   const footerContentDef = await dispatch(generateComponent(
      {
         name: 'Footer Content',
         type: 'box',
         description: 'Contains the content (links) of the footer section.',
         custom_styling: {
            height: {
               type: 'fit',
            },
            layout: {
               direction: 'rowWrap',
               distribution: 'between',
               row_gap: { val: 1, unit: 'rf' },
               column_gap: { val: 1, unit: 'rf' },
            },
            position: {
               auto: {
                  position: 'center',
               }
            },
         },
      },
      [],
      footerDef.composite_key,
      0,
      debug
   ))
   // Footer sections links
   for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      if (['header', 'hero', 'footer'].includes(element)) {
         continue
      }
      await dispatch(generateComponent(
         {
            name: 'Link to ' + element,
            type: 'text',
            description: `Link in footer to ${element} section.`,
            custom_styling: {
               text: {
                  color: 'white'
               }
            },
            properties: { text: element }
         },
         [],
         footerContentDef.composite_key,
         index,
         debug
      ))
   }
   // Footer social logos
   const footerSocialsDef = await dispatch(generateComponent(
      {
         name: 'Footer Socials',
         type: 'box',
         description: 'Contains the social links of the footer section.',
         custom_styling: {
            height: {
               type: 'fit',
            },
            layout: {
               direction: 'row',
               distribution: 'center',
               row_gap: { val: 1, unit: 'rf' },
               column_gap: { val: 1, unit: 'rf' },
            },
            position: {
               auto: {
                  position: 'center',
               }
            },
         },
      },
      [],
      footerDef.composite_key,
      1,
      debug
   ))
   const logos = [
      {
         name: 'Instagram',
         image: 'https://static.cdninstagram.com/rsrc.php/v3/yI/r/VsNE-OHk_8a.png'
      },
      {
         name: 'Twitter',
         image: 'https://abs.twimg.com/favicons/twitter.3.ico'
      },
      {
         name: 'facebook',
         image: 'https://static.xx.fbcdn.net/rsrc.php/yT/r/aGT3gskzWBf.ico'
      }
   ]
   for (let index = 0; index < logos.length; index++) {
      const element = logos[index];
      await dispatch(generateComponent(
         {
            name: element.name + ' logo',
            type: 'image',
            description: element.name + ' logo in footer.',
            custom_styling: {
               height: {
                  type: 'fixed',
                  fixed: { val: 2, unit: 'rf' }
               },
               width: {
                  type: 'fixed',
                  fixed: { val: 2, unit: 'rf' }
               },
            },
            properties: { image: element.image }
         },
         [],
         footerSocialsDef.composite_key,
         index,
         debug
      ))
   }
}