import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useState } from 'react';
import ChangableField from '../Changable/ChangableField';
import { useDispatch, useSelector } from 'react-redux';
import { addDataToEdit } from '../../store/DatabaseSlice';

const Cell = ({ text, onPress, row, col, selected, oldValue}) => {
   const [isHovered, setIsHovered] = useState(false);

   const dispatch = useDispatch();
   const project = useSelector((state) => state.project);
   const currentTable = useSelector((state) => state.database.currentTable);
   const { projectId } = project;
   const { id } = currentTable;

   return (
      <View
         style={[styles.container, selected ? {backgroundColor: '#EFF2F9'} : {}]}
         onMouseEnter={() => {
            setIsHovered(true);
         }}
         onMouseLeave={() => {
            setIsHovered(false);
         }}>
         
         <ChangableField
            containerStyle={{padding: 12, textAlign: 'center', justifyContent: 'center', flex: 4,}}
            style={styles.cell}
            value={text}
            onChange={(value) => {
               dispatch(addDataToEdit({
                  cell: `${col}_${row}`,
                  path: `/data/${projectId}/${id}/${row}/`,
                  data: value,
                  col,
                  oldValue: oldValue}))
            }}
         />
         <View style={styles.expandContainer}>
         { isHovered &&
            <TouchableOpacity style={styles.expandButton} onPress={onPress}>
               <Entypo name="resize-full-screen" size={24} color="#FFFFFF" />
            </TouchableOpacity>
         }
         </View>
      </View>
      )
};

export default Cell;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      border: '1px solid #E0E4F4',
   },
   cell: {
      fontSize: 14,
      lineHeight: 18,
   },
   expandContainer: {
      flex: 1,
      width: 30,
   },
   expandButton: {
      backgroundColor: '#B8BDD0', 
      justifyContent: 'center',
      alignItems: 'center',
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      flex: 1
   }
});
