import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Pressable, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import actions from './../Actions';
import InlineLogicInput from './InlineLogicInput';
import { updateComponent } from '../../../store/ProjectSlice';
import Input, { ComponentData } from '../../base/InputField';

const defaultTypes = {
   CreatedAt: 'CreatedAt',
   CreatedBy: 'CreatedBy',
   Id: 'Id',
};

const CreateDBAction = ({
   parameter,
   logicWorkflow,
   selectedLogicAction,
   updateComponent,
   componentKey,
   selectedlogicWorkflow,
}) => {
   const dispatch = useDispatch();

   const assetTypes = useSelector((state) => state.project?.definition?.data_model);
   const currentNode = logicWorkflow?.nodes[selectedLogicAction];
   const namedProperties = assetTypes[currentNode.parameters?.assetType]?.named_properties;

   return (
      <>
         <Input
            label={parameter.name}
            pickedValue={() => {
               return (
                  assetTypes[
                     Object.keys(assetTypes).find((assetType) => {
                        return (
                           logicWorkflow?.nodes[selectedLogicAction]?.parameters?.assetType ===
                           assetType
                        );
                     })
                  ]?.name ?? ''
               );
            }}
            pickerOptions={Object.keys(assetTypes).map((assetType) => ({
               label: assetTypes[assetType]?.name,
               value: assetTypes[assetType]?.id,
            }))}
            handlePickerUpdate={(value) =>
               dispatch(
                  updateComponent(componentKey, [
                     {
                        path: [
                           'logic',
                           logicWorkflow.uid,
                           'nodes',
                           selectedLogicAction,
                           'parameters',
                           parameter.uid,
                        ],
                        value,
                     },
                  ])
               )
            }
         />
         {namedProperties &&
            Object.keys(namedProperties)?.map((property) => {
               const name = namedProperties[property].name;

               if (defaultTypes[name]) return <></>;

               return (
                  <InlineLogicInput
                     name={name}
                     property={property}
                     componentKey={componentKey}
                     logicWorkflow={logicWorkflow}
                     updateComponent={updateComponent}
                     selectedLogicAction={selectedLogicAction}
                  />
               );
            })}
      </>
   );
};

export default CreateDBAction;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      backgroundColor: '#fff',
   },
   headerTxt: {
      color: '#262626',
      /* Buttons/Large */
      fontFamily: 'Inter',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '130%' /* 20.8px */,
   },
   header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 4,
      padding: 16,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: '#E0E4F4',
   },
   headerButtons: {
      flexDirection: 'row',
      columnGap: 8,
   },
   headerButton: {
      padding: 4,
      borderRadius: 4,
   },
   inputs: {
      padding: 20,
      gap: 20,
   },
});
