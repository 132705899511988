import { Ionicons, SimpleLineIcons, MaterialIcons } from '@expo/vector-icons';
// import React, { useState } from 'react';
import { setGlobalVar } from '@reducers/GlobalsSlice';
import { View, StyleSheet, Pressable, Text, TouchableOpacity, Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { setEditorView } from '../store/EditorSlice';
import { logout } from '../store/UserSlice';

const Sidebar = ({ navigation }) => {
   const dispatch = useDispatch();
   // const [isOpen, setOpen] = useState(false);
   const isOpen = false;
   const currentView = useSelector((state) => state.editor.view);
   const gptModal = useSelector((state) => state.globals.gptModal);
   const projectId = useSelector((state) => state.editor.project);

   const isEditing = useSelector((state) => state.project?.isEditing);

   const menuItems = [
      {
         name: 'BUILDER',
         items: [
            { name: 'Editor', icon: 'brush-outline' },
            { name: 'Logic', icon: 'git-network-outline' },
            { name: 'Data', icon: 'server-outline' },
            { name: 'Design System', icon: 'color-palette-outline' },
         ],
      },
      {
         name: 'SETTINGS',
         items: [
            { name: 'Parameters', icon: 'settings-outline' },
            { name: 'Help', icon: 'help-circle-outline' },
         ],
      },
   ];

   return (
      <View style={styles.sidebarContainer}>
         <View>
            {menuItems.map((section, index) => (
               <View key={index} style={[styles.section, index !== 0 ? styles.sectionDivider : {}]}>
                  <Text style={styles.sectionHeader}>{section.name}</Text>
                  <View style={styles.sectionContent}>
                     {section.items.map((item, index) => (
                        <Pressable
                           key={index}
                           style={({ pressed }) => [
                              styles.menuItem,
                              pressed ? styles.menuItemPressed : {},
                              currentView === item.name ? styles.selected : {},
                           ]}
                           onPress={() => dispatch(setEditorView(item.name))} // Use the correct navigation action here
                        >
                           <Ionicons
                              name={item.icon}
                              size={24}
                              color={currentView === item.name ? '#2B55B7' : 'black'}
                           />
                           {isOpen && <Text style={styles.menuItemText}>{item.name}</Text>}
                        </Pressable>
                     ))}
                  </View>
               </View>
            ))}
            <TouchableOpacity
               onPress={() => {
                  dispatch(setGlobalVar({ key: 'gptModal', value: !gptModal }));
               }}>
               <View style={{ marginHorizontal: 35 }}>
                  <SimpleLineIcons name='magic-wand' size={24} color='black' />
               </View>
            </TouchableOpacity>
            <TouchableOpacity
               style={{ marginTop: 20 }}
               onPress={() => {
                  Linking.openURL(
                     `http://demo.budhapp.com?projectId=${projectId}`
                  );
               }}>
               <View style={{ marginHorizontal: 35 }}>
                  <MaterialIcons name='preview' size={24} color='black' />
               </View>
            </TouchableOpacity>
         </View>
         <View style={styles.profile}>
            <Pressable
               style={({ pressed }) => [styles.menuItem, pressed ? styles.menuItemPressed : {}]}
               onPress={() => dispatch(logout())} // Use the correct navigation action here
            >
               <Ionicons name='power-outline' size={24} color='black' />
               {isOpen && <Text style={styles.menuItemText}>Logout</Text>}
            </Pressable>
         </View>
      </View>
   );
};

const styles = StyleSheet.create({
   sidebarContainer: {
      backgroundColor: '#FFFFFF',
      flexDirection: 'column',
      // width: 80,
      justifyContent: 'space-between',
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderColor: '#E0E4F4',
      // Add more styles to match the Figma design
   },
   section: {
      paddingTop: 24,
      alignItems: 'center',
      rowGap: 4,
      marginBottom: 24,
   },
   sectionDivider: {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderColor: '#F1F4FF',
   },
   sectionHeader: {
      color: '#93989B',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
      marginBottom: 16,
      paddingHorizontal: 8,
   },
   sectionContent: {
      paddingHorizontal: 20,
   },
   menuItem: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingVertical: 8,
      paddingHorizontal: 12,
      borderRadius: 6,
      // Add more styles to match the Figma design
   },
   selected: {
      backgroundColor: '#EFF2F9',
   },
   menuItemText: {
      marginTop: 6,
      // Add more styles to match the Figma design
   },
   menuItemPressed: {
      backgroundColor: '#F0F0F0', // Change this color to match your design for a pressed state
   },
   profile: {
      marginBottom: 32,
   },
});

export default Sidebar;
