import { askAI, } from './openai'
import { primaryColourContext } from "../../consts/prompts"
import { updateDesignSystem } from '../ProjectSlice'
import { contrast, colourRatio, colourSkew } from '../../services/colors'

export const createProjectPrimaryColor = (customPrompt: string = '', debug: number = 0) =>  async (
   dispatch: (...args: any[]) => any,
   getState: () => Record<string, any>
): Promise<string> => {
   const currentPage = getState().editor.page
   const pageDescription = getState().project.definition.pages[currentPage]?.description
   // ask chat GPT a color
   let prompt = pageDescription
   if (customPrompt) {
      prompt += '\n\n' + customPrompt
   }
   const colourMessage = await askAI(primaryColourContext.concat({ role: 'user', content: prompt }))
   const hexColorRegex = /#[A-Fa-f0-9]{3,6}/g;
   const colours = colourMessage.content.match(hexColorRegex);
   let primary, secondary, accent = '#fff';
   if (!colours || colours.length !== 3) {
      return '#fff'
   }
   [primary, secondary, accent] = colours
   console.log('[createProjectPrimaryColor] AI generated colour:', colourMessage.content, ', cleaned colour:', primary, secondary, accent)
   // save it as the "primary" color in design system
   await dispatch(updateDesignSystem([
      { 
         path: ['colours', 'primary'],
         value: {
            uid: 'primary',
            name: 'Primary',
            color: primary,
            type: 'Core',
            description: 'Primary colour of the project, generated with AI',
         }
      }, 
      {
         path: ['colours', 'secondary'],
         value: {
            uid: 'secondary',
            name: 'Secondary',
            color: secondary,
            type: 'Core',
            description: 'Secondary colour of the project, generated with AI',
         }
      },
      { 
         path: ['colours', 'accent'],
         value: {
            uid: 'accent',
            name: 'Accent',
            color: accent,
            type: 'Core',
            description: `Accent colour of the project, generated with AI (primary contrast: ${contrast(accent, primary)}, secondary contrast: ${contrast(accent, secondary)})`,
         }
      }
   ]))
   return primary
}

export const createThemeMatrix = (themeColour: string, skewColour: string, accentColour: string, prefix: string = '') => {
   // check contrast => identify theme (light/dark)
   const whiteContrast = contrast('#FFFFFF', themeColour)
   const blackContrast = contrast('#000000', themeColour)
   if (whiteContrast <= 4.5 && blackContrast <= 4.5) {
      console.warn(`Couldn't find a satisfying contrast for ${themeColour}:\nwhite contrast: ${whiteContrast}\nblack contrast: ${blackContrast}`)
      return {}
   }
   const primaryThemeColour = whiteContrast >= blackContrast ? '#fff' : '#000'
   const primaryGreyscaleTarget = whiteContrast >= blackContrast ? '#000' : '#fff'
   const coloredFactor = 0.3
   return {
      [prefix + '_bg']: themeColour,
      [prefix + '_txt']: colourSkew(primaryThemeColour, skewColour, coloredFactor),
      [prefix + '_h1']: colourSkew(colourSkew(primaryThemeColour, primaryGreyscaleTarget, 0.2), skewColour, coloredFactor),
      [prefix + '_h2']: colourSkew(colourSkew(primaryThemeColour, primaryGreyscaleTarget, 0.3), skewColour, coloredFactor),
      [prefix + '_h3']: colourSkew(colourSkew(primaryThemeColour, primaryGreyscaleTarget, 0.5), skewColour, coloredFactor),
   }
}

export const updateDesignToMatchPrimary = (debug: number = 0) =>  async (
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<void> => {
   let primaryColour: string = getState().project.definition.design_system?.colours?.primary?.color ?? '#fff'
   let secondaryColour: string = getState().project.definition.design_system?.colours?.secondary?.color ?? '#000'
   let accentColour: string = getState().project.definition.design_system?.colours?.accent?.color ?? '#FEBA00'
   if (!primaryColour) console.error('Mising primary color')
   if (!secondaryColour) console.error('Mising secondary color')
   if (!accentColour) console.error('Mising accent color')


   // create themes
   const primary = createThemeMatrix(primaryColour, secondaryColour, accentColour, 'primary')
   const secondary = createThemeMatrix(secondaryColour, primaryColour, accentColour, 'secondary')

   // Save new design system
   await dispatch(updateDesignSystem([
      ...Object.keys(primary).map(key => ({
         path: ['colours', key],
         value: { uid: key, name: key.replace('_', ' '), type: 'Primary', color: primary[key], description: 'Primary variants, generated with AI',}
      })),
      ...Object.keys(secondary).map(key => ({
         path: ['colours', key],
         value: { uid: key, name: key.replace('_', ' '), type: 'Secondary', color: secondary[key], description: 'Secondary variants, generated with AI',}
      })),
      { 
         path: ['colours', 'white'],
         value: { uid: 'white', name: 'White', color: "#fff", type: 'Basics', description: 'Basic white', }
      },
      { 
         path: ['colours', 'error'],
         value: { uid: 'error', name: 'Error', color: "#E66969", type: 'Basics', description: 'Basic error colour (red variant)', }
      },
      { 
         path: ['colours', 'success'],
         value: { uid: 'success', name: 'Success', color: "#83E576", type: 'Basics', description: 'Basic success colour (green variant)', }
      },
   ]))

   // Create new styles
   await dispatch(updateDesignSystem([
      { 
         path: ['user_styles', 'primary'],
         value: {
            uid: 'primary',
            name: 'nth layer',
            styling: {
               background: {
                  type: 'color',
                  color: '@primary_bg'
               },
               text: {
                  color: '@primary_txt'
               }
            }
         }
      },
      { 
         path: ['user_styles', 'secondary'],
         value: {
            uid: 'secondary',
            name: 'nth + 1 layer',
            styling: {
               background: {
                  type: 'color',
                  color: '@secondary_bg'
               },
               text: {
                  color: '@secondary_txt'
               }
            }
         }
      },
      { 
         path: ['user_styles', 'primary_button'],
         value: {
            uid: 'primary_button',
            name: 'Primary Button',
            styling: {
               background: {
                  type: 'color',
                  color: '@accent'
               },
               text: {
                  color: '@primary'
               }
            }
         }
      },
      { 
         path: ['user_styles', 'secondary_button'],
         value: { 
            uid: 'secondary_button',
            name: 'Secondary Button',
            styling: {
               background: {
                  type: 'color',
                  color: '@primary'
               },
               text: {
                  color: '@accent'
               },
               border: {
                  top: {
                     width: { val: 1, unit: 'px' },
                     style: 'solid',
                     color: '@accent',
                  },
                  right: {
                     width: { val: 1, unit: 'px' },
                     style: 'solid',
                     color: '@accent',
                  },
                  left: {
                     width: { val: 1, unit: 'px' },
                     style: 'solid',
                     color: '@accent',
                  },
                  bottom: {
                     width: { val: 1, unit: 'px' },
                     style: 'solid',
                     color: '@accent',
                  },
               }
            }
         }
      },
   ]))
}

export const createDesignSystem = (customPrompt: string = '', debug: number = 0) =>  async (
   dispatch: (...args: any[]) => void, 
   getState: () => Record<string, any>
): Promise<any> => {
   await dispatch(createProjectPrimaryColor('', debug))
   dispatch(updateDesignToMatchPrimary())
}