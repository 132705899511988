import React, { useState } from 'react';
import { Picker, StyleSheet, Text, View } from 'react-native';

import DefaultInputContainer from '../../base/DefaultInputContainer';
import TextAlign from './TextAlign';
import { useSelector, useDispatch } from 'react-redux';

const TextStyling = ({ rootHandleElementUpdate, componentDef, handleUpdate }) => {
   const fonts = useSelector((state) => state.project?.definition?.design_system?.fonts) || {};

   return (
      <View>
         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
               componentDef={componentDef}
               data={Object.keys(fonts)?.map(item => fonts[item].name)}
               onItemPress={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'font'], value }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.font}
               icon={
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1 3.25C1 2.55109 1 2.20163 1.11418 1.92597C1.26642 1.55843 1.55843 1.26642 1.92597 1.11418C2.20163 1 2.55109 1 3.25 1H10.75C11.4489 1 11.7984 1 12.074 1.11418C12.4416 1.26642 12.7336 1.55843 12.8858 1.92597C13 2.20163 13 2.55109 13 3.25M4.75 13H9.25M7 1V13" stroke="#93989B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
               }
            />
          </View>
         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               componentDef={componentDef}
               onChangeText={(color) => {
                  const value = color.hasOwnProperty('hex') ? color.hex : color;
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'color'], value }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.color}
               placeholder={'Select text color'}
               isColor={true}
               childLeft={
                  <View style={{
                     width: 34, 
                     height: 30,
                     borderRadius: 4,
                     backgroundColor: componentDef[rootHandleElementUpdate]?.text?.color}}>
                  </View>
               }
            />
         </View>
         <View style={[componentStyle.rowStyling]}>
            <TextAlign
               handleUpdate={handleUpdate}
               componentDef={componentDef}/>
         </View>
         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               placeholder={'Text size'}
               componentDef={componentDef}
               onChangeText={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'size'], value: {val: value, unit: 'px' } }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.size?.val}
               icon={
                  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M3.26944 11L0.445579 0.818182H2.06135L4.04501 8.70312H4.13947L6.20268 0.818182H7.80353L9.86674 8.7081H9.9612L11.9399 0.818182H13.5606L10.7318 11H9.18564L7.04288 3.37358H6.96333L4.82058 11H3.26944Z" fill="#93989B"/>
                  </svg>
               }
            />
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               placeholder={'Line height'}
               componentDef={componentDef}
               onChangeText={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'line_height'], value }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.line_height}
               icon={
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M6.75 9.75H11.25M5.25 12.75L8.45378 5.70168C8.62731 5.31992 8.71407 5.12904 8.8343 5.06982C8.93878 5.01836 9.06122 5.01836 9.1657 5.06982C9.28593 5.12904 9.37269 5.31992 9.54622 5.70168L12.75 12.75M15.75 15.75H2.25M15.75 2.25H2.25" stroke="#93989B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
               }
            />
         </View>

         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               placeholder={'Text size'}
               componentDef={componentDef}
               data={[100, 200, 300, 400, 500, 600, 700, 800]}
               onItemPress={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'weight'], value }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.weight}
               icon={
                  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M3.26944 11L0.445579 0.818182H2.06135L4.04501 8.70312H4.13947L6.20268 0.818182H7.80353L9.86674 8.7081H9.9612L11.9399 0.818182H13.5606L10.7318 11H9.18564L7.04288 3.37358H6.96333L4.82058 11H3.26944Z" fill="#93989B"/>
                  </svg>
               }
            />
         </View>
         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ borderWidth: 1, borderColor: '#E0E4F4' }}
               componentDef={componentDef}
               onChangeText={({hex}) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'shadow', 'color'], value: hex }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.shadow?.color}
               placeholder={'Select shadow color'}
               isColor={true}
               childLeft={
                  <View style={{
                     width: 34, 
                     height: 30,
                     borderRadius: 4,
                     backgroundColor: componentDef[rootHandleElementUpdate]?.text?.shadow?.color}}>
                  </View>
               }
            />
         </View>
         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               placeholder={'Hor'}
               componentDef={componentDef}
               onChangeText={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'shadow', 'horizontal'], value: {val: value, unit: 'px' } }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.shadow?.horizontal?.val}
            />
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               placeholder={'Vertical'}
               componentDef={componentDef}
               onChangeText={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'shadow', 'vertical'], value: {val: value, unit: 'px' } }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.shadow?.vertical?.val}
            />
         </View>
         <View style={[componentStyle.rowStyling]}>
            <DefaultInputContainer
               style={{ flex: 1, borderWidth: 1, borderColor: '#E0E4F4' }}
               placeholder={'Blur'}
               componentDef={componentDef}
               onChangeText={(value) => {
                  handleUpdate([
                     { path: [rootHandleElementUpdate, 'text', 'shadow', 'blur'], value: {val: value, unit: 'px' } }
                  ])
               }}
               value={componentDef[rootHandleElementUpdate]?.text?.shadow?.blur?.val}
            />
         </View>
      </View>
   );
};

export default TextStyling;

const componentStyle = StyleSheet.create({
   rowStyling: {
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 8,
      marginBottom: 4
   },
})