import { Entypo } from '@expo/vector-icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { Modal, StyleSheet, Text, Pressable, View, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import OutsidePressHandler from 'react-native-outside-press';
import DesignSystemListItem from './DesignSystemListItem';

const SelectDesignStyle = ({ componentDef, handleUpdate }) => {
   const [isExpended, setIsExpended] = useState();
   const userStyles = useSelector((state) => state.project?.definition?.design_system?.user_styles);

   return (
      <View style={styles.container}>
         <View style={styles.childContainer}>
            <View
               style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
               }}>
               <View
                  style={{
                     width: 40,
                     justifyContent: 'center',
                     alignItems: 'center',
                     borderRightWidth: 1,
                     borderRightColor: '#E0E4F4',
                  }}>
                  <View style={{ width: 18, height: 18 }}>
                     <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C10.2426 16.5 11.25 15.4926 11.25 14.25V13.875C11.25 13.5267 11.25 13.3525 11.2692 13.2063C11.4022 12.1967 12.1967 11.4022 13.2063 11.2692C13.3525 11.25 13.5267 11.25 13.875 11.25H14.25C15.4926 11.25 16.5 10.2426 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9Z'
                           stroke='#93989B'
                           strokeWidth='1.5'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                        <path
                           d='M5.25 9.75C5.66421 9.75 6 9.41421 6 9C6 8.58579 5.66421 8.25 5.25 8.25C4.83579 8.25 4.5 8.58579 4.5 9C4.5 9.41421 4.83579 9.75 5.25 9.75Z'
                           stroke='#93989B'
                           strokeWidth='1.5'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                        <path
                           d='M12 6.75C12.4142 6.75 12.75 6.41421 12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6C11.25 6.41421 11.5858 6.75 12 6.75Z'
                           stroke='#93989B'
                           strokeWidth='1.5'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                        <path
                           d='M7.5 6C7.91421 6 8.25 5.66421 8.25 5.25C8.25 4.83579 7.91421 4.5 7.5 4.5C7.08579 4.5 6.75 4.83579 6.75 5.25C6.75 5.66421 7.08579 6 7.5 6Z'
                           stroke='#93989B'
                           strokeWidth='1.5'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  </View>
               </View>
               <View
                  style={{
                     flex: 1,
                     flexDirection: 'row',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}>
                  <Text style={styles.text}>
                     {!_.isEmpty(userStyles) && componentDef?.style
                        ? userStyles[componentDef?.style].name
                        : ''}
                  </Text>
                  <Pressable
                     style={{ paddingRight: 10 }}
                     onPress={() => setIsExpended(!isExpended)}>
                     {isExpended ? (
                        <Entypo name='chevron-thin-up' size={15} color='#4A4756' />
                     ) : (
                        <Entypo name='chevron-thin-down' size={15} color='#4A4756' />
                     )}
                  </Pressable>
               </View>
            </View>
         </View>
         {userStyles && isExpended && (
            <OutsidePressHandler
               style={{flex: 1, width: '100%'}}
               onOutsidePress={() => {
                  setIsExpended(false);
               }}>
               <View style={{ flex: 1, width: '100%', paddingHorizontal: 20 }}>
                  <FlatList
                     style={{ width: '100%' }}
                     data={Object.keys(userStyles)}
                     renderItem={({ item }) => (
                        <DesignSystemListItem
                           title={userStyles[item].name}
                           onPress={() => {
                              handleUpdate([{ path: ['style'], value: userStyles[item]?.uid }]);
                              setIsExpended(false);
                           }}
                        />
                     )}
                  />
               </View>
            </OutsidePressHandler>
         )}
      </View>
   );
};

export default SelectDesignStyle;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 4,
      paddingVertical: 8,
      borderRadius: 8,
   },
   childContainer: {
      width: 260,
      height: 30,
      gap: 4,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#E0E4F4',
   },
   text: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      color: '#93989B',
   },
});
