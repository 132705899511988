import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Pressable, Text, View, TouchableOpacity } from 'react-native';
import OutsidePressHandler from 'react-native-outside-press';
import { useDispatch, useSelector } from 'react-redux';

import InlineActionsModal from './InlineActionsModal';
import Input from '../../base/InputField';
import InlineModal from '../InlineModal';

const InlineLogicInput = ({
   name,
   property,
   logicWorkflow,
   selectedLogicAction,
   updateComponent,
   componentKey,
   handleBlur,
}) => {
   const [buttonLayout, setButtonLayout] = useState({
      height: 0,
      width: 0,
      top: 0,
      left: 0,
      x: 0,
      y: 0,
   });

   const dispatch = useDispatch();

   const [isEditing, setIsEditing] = useState(undefined);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [isEditPartModalVisible, setIsEditPartModalVisible] = useState(false);
   const [parts, setParts] = useState([]);
   const [currentPart, setCurrentPart] = useState('');
   const [partsLayout, setPartsLayout] = useState({});
   const [activePartIndex, setActivePartIndex] = useState(undefined);

   const [inputValue, setInputValue] = useState(
      logicWorkflow?.nodes[selectedLogicAction]?.parameters?.data?.[property] || ''
   );

   useEffect(() => {
      setInputValue(logicWorkflow?.nodes[selectedLogicAction]?.parameters?.data?.[property] || '');
      setParts(
         (logicWorkflow?.nodes[selectedLogicAction]?.parameters?.data?.[property] || '').split(
            /(@@[\w\W]*@@)/
         )
      );
   }, [logicWorkflow?.nodes[selectedLogicAction]?.parameters?.data?.[property]]);

   const handleDuplicate = () => {
      const pattern = currentPart;
      const index = inputValue.indexOf(pattern);
      let result = '';
      if (index !== -1) {
         result = inputValue.slice(0, index + pattern.length) + ' ' + inputValue.slice(index);
      } else {
         result = inputValue;
      }

      // handleChange(result);
      console.log(result);
   };

   const handleDelete = () => {
      const pattern = currentPart;
      const index = inputValue.indexOf(pattern);
      let result = '';
      if (index !== -1) {
         result = inputValue.slice(0, index + pattern.length) + ' ' + inputValue.slice(index);
      } else {
         result = inputValue;
      }

      // handleChange(result);
      console.log(result);
   };

   const handleEdit = () => {
      setIsEditPartModalVisible(true);
   };

   const handleChange = (text) => {
      dispatch(
         updateComponent(componentKey, [
            {
               path: [
                  'logic',
                  logicWorkflow.uid,
                  'nodes',
                  selectedLogicAction,
                  'parameters',
                  'data',
                  property,
               ],
               value: text,
            },
         ])
      );
      setInputValue(text);
   };

   const handleFocus = (index) => {
      setIsEditing(index);
   };

   const handleMeasure = (event, index) => {
      setPartsLayout({
         ...partsLayout,
         [index]: event?.nativeEvent?.layout,
      });
   };

   const editInlineLogic = (part, index) => {
      setCurrentPart(part);
      setActivePartIndex(index);
      setIsEditPartModalVisible(true);
   };

   const calculateModalPosition = () => {
      const layout = partsLayout[activePartIndex];

      return {
         top: layout.top + layout.height,
         left: layout.left,
      };
   };

   return (
      <View style={{ flexDirection: 'column' }}>
         <Text style={styles.label}>{name}</Text>
         <OutsidePressHandler
            style={{ width: '100%', flexDirection: 'row' }}
            onOutsidePress={() => {
               setIsEditing(undefined);
            }}>
            {parts?.length === 1 ? (
               <>
                  {isEditing === 'all' ? (
                     <View style={{ width: '90%' }}>
                        <Input
                           style={{ flex: 1 }}
                           onBlur={handleBlur}
                           value={inputValue}
                           handleValueUpdate={(value) => {
                              handleChange(value);
                           }}
                        />
                     </View>
                  ) : (
                     <View style={[styles.inputRow, { width: '90%' }]}>
                        <TouchableOpacity onPress={() => handleFocus('all')}>
                           <Text style={styles.text}>{inputValue || 'No_data'}</Text>
                        </TouchableOpacity>
                     </View>
                  )}
               </>
            ) : (
               <>
                  <View style={[styles.inputRow, { width: '90%' }]}>
                     {parts.map((part, index) => {
                        if (part.startsWith('@@')) {
                           return (
                              <TouchableOpacity
                                 onLayout={(event) => handleMeasure(event, index)}
                                 onPress={() => editInlineLogic(part, index)}>
                                 <Text style={styles.text}>[Inline logic]</Text>
                              </TouchableOpacity>
                           );
                        }
                        if (part) {
                           if (isEditing === index) {
                              return (
                                 <Input
                                    style={{ flex: 1 }}
                                    onBlur={handleBlur}
                                    value={part}
                                    handleValueUpdate={(value) => {
                                       handleChange(value);
                                    }}
                                 />
                              );
                           } else {
                              return (
                                 <TouchableOpacity onPress={() => handleFocus(index)}>
                                    <Text style={styles.text}>{part || 'No_data'}</Text>
                                 </TouchableOpacity>
                              );
                           }
                        }
                     })}
                  </View>
               </>
            )}
            <TouchableOpacity
               style={{ marginLeft: 5, alignSelf: 'center' }}
               onPress={() => {
                  setIsModalVisible(true);
               }}>
               <View
                  style={{
                     width: 20,
                     height: 20,
                     justifyContent: 'center',
                     alignItems: 'center',
                     backgroundColor: '#2B55B7',
                     borderRadius: 4,
                  }}>
                  <svg
                     width='10'
                     height='11'
                     viewBox='0 0 10 11'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M5.00033 1.41699V9.58366M0.916992 5.50033H9.08366'
                        stroke='#F5F9FF'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                  </svg>
               </View>
            </TouchableOpacity>
         </OutsidePressHandler>
         {isModalVisible && (
            <InlineModal
               value={inputValue}
               updateValue={handleChange}
               currentInlineLogic={currentPart.slice(2, -2)}
               onClose={() => setIsModalVisible(false)}
            />
         )}
         {isEditPartModalVisible && (
            <InlineActionsModal
               calculateModalPosition={calculateModalPosition}
               showModal={isEditPartModalVisible}
               onToggleModal={setIsEditPartModalVisible}
               handleDuplicate={handleDuplicate}
               handleEdit={handleEdit}
               handleRemove={handleDelete}
            />
         )}
      </View>
   );
};

export default InlineLogicInput;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      backgroundColor: '#fff',
   },
   inputRow: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      flexShrink: 0,
      flexDirection: 'row',
      gap: 8,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E0E4F4',
      borderStyle: 'solid',
      backgroundColor: '#FFF',
      overflow: 'hidden',
   },
   headerTxt: {
      color: '#262626',
      fontFamily: 'Inter',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '130%',
   },
   header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 4,
      padding: 16,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: '#E0E4F4',
   },
   headerButtons: {
      flexDirection: 'row',
      columnGap: 8,
   },
   headerButton: {
      padding: 4,
      borderRadius: 4,
   },
   inputs: {
      padding: 20,
      gap: 20,
   },
   label: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontHeight: 400,
      lineHeight: '135%',
   },
   text: {
      fontSize: 18,
      lineHeight: '120%',
      outlineStyle: 'none',
      alignItems: 'center',
      justifyContent: 'center',
   },
});
