import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
   Modal,
   StyleSheet,
   TextInput,
   Text,
   Button,
   Pressable,
   View,
   FlatList,
   TouchableWithoutFeedback,
   TouchableOpacity,
   Dimensions,
   Picker,
} from 'react-native';
import OutsidePressHandler from 'react-native-outside-press';
import { useSelector, useDispatch } from 'react-redux';
import ColorPicker, { Panel1, OpacitySlider, HueSlider } from 'reanimated-color-picker';

import DefaultListItem from './DefaultListItem';
import { AutoCompleteInput } from './InputField';

const DefaultInputContainer = ({
   componentDef,
   placeholder,
   isColor,
   childLeft,
   icon,
   onChangeText,
   onItemPress,
   unit,
   value,
   data,
   style,
   childStyle,
   handleUnitUpdate,
}) => {
   const [isExpended, setIsExpended] = useState();
   const [showColorModal, setShowColorModal] = useState(false);
   const [focused, setFocused] = useState(false);
   const [_unit, setUnit] = useState(unit);

   const colours = useSelector((state) => state.project?.definition?.design_system?.colours);

   useEffect(() => {
      setIsExpended(false);
   }, [componentDef]);

   useEffect(() => {
      setUnit(unit);
   }, [unit]);

   const [buttonLayout, setButtonLayout] = useState({
      height: 0,
      width: 0,
      top: 0,
      left: 0,
      x: 0,
      y: 0,
   });

   const handleMeasure = (event) => {
      setButtonLayout(event?.nativeEvent?.layout);
   };

   const calculateModalPosition = () => {
      const top =
         buttonLayout.top > Dimensions.get('window').height
            ? Dimensions.get('window').height - 400
            : buttonLayout.top - 300;
      return {
         top: top - 70,
         left: buttonLayout.left - 300,
      };
   };

   return (
      <OutsidePressHandler
         onLayout={isColor ? handleMeasure : {}}
         style={[styles.container, style]}
         onOutsidePress={() => {
            setFocused(false);
            setIsExpended(false);
            }}>
         <View style={[styles.childContainer, childStyle]}>
            <View
               style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: 30,
               }}>
               {isColor ? (
                  <TouchableOpacity
                     onPress={() => {
                        setShowColorModal(!showColorModal);
                     }}>
                     {childLeft}
                  </TouchableOpacity>
               ) : (
                  childLeft
               )}
               {icon && (
                  <View
                     style={{
                        width: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRightWidth: 1,
                        borderRightColor: '#E0E4F4',
                     }}>
                     <View style={{ alignSelf: 'center' }}>{icon}</View>
                  </View>
               )}
               <View
                  style={{
                     paddingLeft: 10,
                     width: '100%',
                     flex: 1,
                     flexDirection: 'row',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}>
                  {!onChangeText && <Text style={styles.text}>{value}</Text>}
                  {onChangeText && (
                     <TextInput
                        onFocus={() => setFocused(true)}
                        onChangeText={(value) => {
                           onChangeText(isColor ? { hex: value } : value);
                        }}
                        placeholder={placeholder}
                        style={{
                           width: '100%',
                           fontFamily: 'Inter',
                           color: '#93989b',
                           fontSize: 15,
                        }}
                        value={value}
                     />
                  )}
                  {onItemPress && data && (
                     <Pressable
                        style={{ paddingRight: 10 }}
                        onPress={() => setIsExpended(!isExpended)}>
                        {isExpended ? (
                           <Entypo name='chevron-thin-up' size={15} color='#4A4756' />
                        ) : (
                           <Entypo name='chevron-thin-down' size={15} color='#4A4756' />
                        )}
                     </Pressable>
                  )}
                  {focused && handleUnitUpdate && (
                     <Picker
                        style={styles.picker}
                        selectedValue={_unit}
                        onValueChange={(val) => {
                           setUnit(val);
                           handleUnitUpdate(val);
                        }}>
                        <Picker.Item label='px' value='px' />
                        <Picker.Item label='%' value='%' />
                        <Picker.Item label='rf' value='rf' />
                     </Picker>
                  )}
               </View>
            </View>
            {onItemPress && isExpended && (
               <View style={{ width: '100%' }}>
                  <FlatList
                     data={data}
                     renderItem={({ item }) => (
                        <DefaultListItem
                           item={item}
                           onPress={() => {
                              onItemPress(item);
                              setIsExpended(false);
                           }}
                        />
                     )}
                  />
               </View>
            )}
         </View>
         {showColorModal && (
            <Modal transparent visible={showColorModal}>
               <View style={calculateModalPosition()}>
                  <View style={styles.modalContainer}>
                     <ColorPicker onComplete={onChangeText}>
                        <View style={{ width: 280 }}>
                           <Panel1 />
                        </View>
                        <View style={{ width: 280, marginTop: 10 }}>
                           <HueSlider />
                        </View>
                        <View style={{ width: 280, marginTop: 10 }}>
                           <OpacitySlider />
                        </View>
                     </ColorPicker>
                     {colours && (
                        <View style={{ marginTop: 10 }}>
                           <AutoCompleteInput
                              data={Object.keys(colours).map((item) => {
                                 return {
                                    label: colours[item].name,
                                    value: colours[item].uid 
                                    }
                                 })
                              }
                              onSelect={(item) => onChangeText(`@${item.value}`)}
                              textStyles={[styles.input]}
                           />
                        </View>
                     )}
                     <View style={{ marginTop: 10 }}>
                        <Button title='SET' onPress={() => setShowColorModal(false)} />
                     </View>
                  </View>
               </View>
            </Modal>
         )}
      </OutsidePressHandler>
   );
};

export default DefaultInputContainer;

const styles = StyleSheet.create({
   modalContainer: {
      width: 300,
      padding: 10,
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      borderColor: '#f5f6f8',
      borderWidth: 2,
   },
   container: {
      flex: 1,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
   },
   childContainer: {
      width: '100%',
      gap: 4,
      borderRadius: 8,
   },
   text: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      color: '#93989B',
   },
   picker: {
      height: 20,
      width: 30,
      fontSize: 8,
      fontFamily: 'Inter',
      position: 'absolute',
      right: 0,
   },
   input: {
      maxWidth: 300,
      borderBottomWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#fff',
      marginBottom: 10,
      paddingHorizontal: 10,
      paddingVertical: 6,
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18,
      color: '#93989B',
   },
});
