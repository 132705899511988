export const checkNonEmptyObject = (obj) => {
   if (typeof obj !== 'object' || obj) {
      console.error('Error: not a non empty object:', obj);
   }
};

export const checkNonEmptyArray = (arr) => {
   return !Array.isArray(arr) || arr.length === 0
   // if (!Array.isArray(arr) || arr.length === 0) {
   //    console.error('Error: not a non empty array:', arr);
   // }
}

export const checkNonEmptyString = (str) => {
   if (typeof str !== 'string' || str === '') {
      console.error('Error: not a non empty string:', str);
   }
};

export const checkNumber = (nb) => {
   if (typeof nb !== 'number') {
      console.error('Error: not a number:', nb);
   }
};
