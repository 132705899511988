import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, Text, View, Pressable } from 'react-native';

const ActionCard = ({ node }) => {
   return (
      <View style={styles.container}>
         <View style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
               <View style={styles.header}>
                  <Ionicons name='create-outline' size={28} color='#5F89F5' />
                  <Text style={styles.headerText} numberOfLines={1}>
                     {node.type.slice(7)}
                  </Text>
               </View>
               <View style={styles.content}>
                  <Text style={styles.contentText} numberOfLines={2}>
                     {node.name}
                  </Text>
               </View>
            </View>
            <Pressable style={{}} />
         </View>
      </View>
   );
};

export default ActionCard;

const styles = StyleSheet.create({
   container: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#E0E4F4',
      backgroundColor: '#FFF',
      borderRadius: 6,
      alignSelf: 'flex-start',
      width: 150,
      height: 76,
   },
   header: {
      flexDirection: 'row',
      paddingLeft: 6,
      paddingRight: 8,
      height: 32,
      backgroundColor: '#F5F6F8',
      columnGap: 4,
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: '#E0E4F4',
   },
   headerText: {
      color: '#646D72',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
      textOverflow: 'ellipsis',
   },
   content: {
      paddingHorizontal: 8,
      paddingVertical: 6,
   },
   contentText: {
      color: '#93989B',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
      textOverflow: 'ellipsis',
   },
});
