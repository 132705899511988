import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useState } from 'react';

const DataModalRow = ({ cols, currentRow, row}) => {

    return (
    <View style={{width: 503, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <View style={{width: 503, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', borderColor: '#F5F6F8', borderWidth: 1}}>
            <View style={{alignItems: 'center', justifyContent: 'center', height: 40, width: 40, backgroundColor: '#F5F6F8'}}>
                <Entypo name="image" size={24} color="#646D72" />
            </View>
            <View style={{ borderRightColor: '#F5F6F8', borderRightWidth: 1, alignItems: 'center', justifyContent: 'flex-start', flex: 1}}>
                {cols[row].name}
            </View>
            <View style={{alignItems: 'center', justifyContent: 'flex-start', flex: 1}}>
                {currentRow[row]}
            </View>
        </View>
    </View>
      )
};

export default DataModalRow;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      border: '1px solid #E0E4F4',
      width: '100%' - 30
   },
   cell: {
      fontSize: 14,
      lineHeight: 18,
   },
   expandContainer: {
      width: 30,
   },
   expandButton: {
      backgroundColor: '#B8BDD0', 
      justifyContent: 'center',
      alignItems: 'center',
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      flex: 1
   }
});
