import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import {
   View,
   StyleSheet,
   ScrollView,
   TouchableOpacity,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import Background from './Background';
import BordersContainer from './BoxModelConfig';
import ChangeComponentName from './ChangeComponentName';
import LayoutStyling from './LayoutStyling/LayoutStyling';
import PositionStyling from './LayoutStyling/PositionStyling';
import SpacesAndSizes from './SpacesAndSizes';
import TextStyling from './TextStyling/TextStyling';
import WidthHeightBlock from './WidthHeightBlock';
import { sendGridEmail } from '../../services/email';
import DefaultInputContainer from '../base/DefaultInputContainer';
import ExpandableContainer from '../base/ExpandableContainer';
import SelectDesignStyle from '../base/SelectDesignStyle';
import InputConfig from './InputConfig';
import BoxListSettings from './LayoutStyling/BoxListSettings';
import ImageConfig from './ImageConfig';

const LayoutPanel = (props) => {
   const { rootHandleElementUpdate, handleUpdate, componentDef } = props;
   const projectId = useSelector((state) => state.editor.project);

   const [name, setName] = useState('');

   /** Optional properties **/
   const [text, setText] = useState(componentDef?.properties?.text || '');
   // const [placeholder, setPlaceholder] = useState(
   //    componentDef?.properties?.input?.placeholder || ''
   // );
   const [order, setOrder] = useState(
      componentDef[rootHandleElementUpdate]?.position?.auto?.order || ''
   );
   const [textHeight, setTextHeight] = useState(30);
   const debouncedTextHeight = useDebouncedCallback((val) => {
      setTextHeight(val);
   }, 1);

   useEffect(() => {
      setName(componentDef?.name);
   }, [componentDef?.name]);

   // useEffect(() => {
   //    setPlaceholder(componentDef?.properties?.input?.placeholder);
   // }, [componentDef?.properties?.input?.placeholder]);

   useEffect(() => {
      setText(componentDef.properties?.text);
   }, [componentDef?.properties?.text]);

   useEffect(() => {
      setOrder(componentDef[rootHandleElementUpdate]?.position?.auto?.order);
   }, [componentDef[rootHandleElementUpdate]?.position?.auto?.order]);

   if (!projectId) {
      return undefined;
   }

   return (
      <ScrollView contentContainerStyle={styles.container}>
         {componentDef?.type && (
            <>
               <View style={styles.mainContainer}>
                  <ChangeComponentName
                     style={{ paddingHorizontal: 20, paddingBottom: 5, width: '100%' }}
                     placeholder='Component name'
                     value={name || ''}
                     onChangeText={(value) => {
                        handleUpdate([{ path: ['name'], value }]);
                        setName(value);
                     }}
                  />
               </View>
               <View
                  style={[
                     styles.mainContainer,
                     {
                        width: '100%',
                        paddingHorizontal: 20,
                        alignItems: 'center',
                     },
                  ]}>
                  <TouchableOpacity
                     style={{ heigth: 40 }}
                     onPress={() => {
                        const newOrder = order - 1;
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'position', 'auto', 'order'],
                              value: newOrder,
                           },
                        ]);
                        setOrder(newOrder);
                     }}>
                     <Entypo name='chevron-thin-left' size={24} color='#93989B' />
                  </TouchableOpacity>
                  <ChangeComponentName
                     placeholder='Order'
                     value={order || ''}
                     onChangeText={(value) => {
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'position', 'auto', 'order'], value },
                        ]);
                        setOrder(value);
                     }}
                  />
                  <TouchableOpacity
                     style={{ heigth: 40 }}
                     onPress={(value) => {
                        const newOrder = order + 1;
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'position', 'auto', 'order'],
                              value: newOrder,
                           },
                        ]);
                        setOrder(newOrder);
                     }}>
                     <Entypo name='chevron-thin-right' size={24} color='#93989B' />
                  </TouchableOpacity>
               </View>
            </>
         )}
         {componentDef?.type && (
            <View style={styles.mainContainer}>
               <SelectDesignStyle
                  componentDef={componentDef}
                  handleUpdate={handleUpdate} />
            </View>
         )}
         <View style={styles.mainContainer}>
            {componentDef?.type === 'image' && (
               <ImageConfig
                  componentDef={componentDef}
                  handleUpdate={handleUpdate}
               />
            )}
         </View>
         <View style={styles.mainContainer}>
            {componentDef?.type === 'boxList' && (
               <ExpandableContainer title='Box List'>
                  <BoxListSettings
                     componentDef={componentDef}
                     handleUpdate={handleUpdate} />
               </ExpandableContainer>
            )}
            {componentDef?.type === 'input' && (
               <ExpandableContainer title='Input'>
                  {/* <TextStyling
                     rootHandleElementUpdate={rootHandleElementUpdate}
                     componentDef={componentDef}
                     handleUpdate={handleUpdate}
                  />
                  <View>
                     <ChangeComponentName
                        placeholder='Placeholder'
                        value={placeholder || ''}
                        onChangeText={(value) => {
                           handleUpdate([{ path: ['properties', 'input', 'placeholder'], value }]);
                           setPlaceholder(value);
                        }}
                     />
                  </View> */}
                  <InputConfig 
                     componentDef={componentDef}
                     styles={styles}
                     handleUpdate={handleUpdate}
                     rootHandleElementUpdate={rootHandleElementUpdate}
                  />
               </ExpandableContainer>
            )}
         </View>
         <View style={styles.mainContainer}>
            {componentDef?.type === 'text' && (
               <ExpandableContainer title='Text'>
                  <ChangeComponentName
                     style={{ paddingBottom: 10 }}
                     textStyle={{ height: textHeight, fontSize: 10 }}
                     value={text || ''}
                     multiline
                     onChangeText={(value) => {
                        handleUpdate([{ path: ['properties', 'text'], value }]);
                        setText(value);
                     }}
                     onContentSizeChange={(e) => {
                        debouncedTextHeight(
                           Math.min(Math.max(30, e.nativeEvent?.contentSize?.height || 0), 500)
                        );
                     }}
                  />
                  <TextStyling
                     rootHandleElementUpdate={rootHandleElementUpdate}
                     componentDef={componentDef}
                     handleUpdate={handleUpdate}
                  />
               </ExpandableContainer>
            )}
         </View>
         <View style={styles.mainContainer}>
            <BordersContainer
               rootHandleElementUpdate={rootHandleElementUpdate}
               componentDef={componentDef}
               styles={styles}
               handleUpdate={handleUpdate}
            />
         </View>
         <View style={styles.mainContainer}>
            <SpacesAndSizes
               rootHandleElementUpdate={rootHandleElementUpdate}
               componentDef={componentDef}
               styles={styles}
               handleUpdate={handleUpdate}
            />
         </View>
         <View style={styles.mainContainer}>
            <WidthHeightBlock
               rootHandleElementUpdate={rootHandleElementUpdate}
               componentDef={componentDef}
               styles={styles}
               handleUpdate={handleUpdate}
            />
         </View>
         <View style={styles.mainContainer}>
            <PositionStyling
               rootHandleElementUpdate={rootHandleElementUpdate}
               componentDef={componentDef}
               styles={styles}
               handleUpdate={handleUpdate}
            />
         </View>
         <View style={styles.mainContainer}>
            <LayoutStyling
               rootHandleElementUpdate={rootHandleElementUpdate}
               componentDef={componentDef}
               styles={styles}
               handleUpdate={handleUpdate}
            />
         </View>
         <View style={styles.mainContainer}>
            <Background
               rootHandleElementUpdate={rootHandleElementUpdate}
               componentDef={componentDef}
               styles={styles}
               handleUpdate={handleUpdate}
            />
         </View>
      </ScrollView>
   );
};

const styles = StyleSheet.create({
   container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      paddingVertical: 20,
   },
   mainContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   label: {
      fontWeight: 'bold',
      fontSize: 10,
      marginBottom: 4,
   },
   multilineInput: {
      borderWidth: 1,
      minHeight: 30,
      flexGrow: 1,
      height: 'fit-content',
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
   },
   picker: {
      height: 24,
      fontSize: 8,
      marginBottom: 10,
      caret: 'hidden',
   },
   valunit: {
      flexDirection: 'row',
   },
   row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
   },
   button: {
      borderRadius: 8,
      padding: 10,
      height: 30,
      minWidth: 105,
      paddingVertical: 6,
      paddingHorizontal: 30,
      gap: 10,
   },
   buttonOk: {
      backgroundColor: '#2E3137',
   },
});

export default LayoutPanel;
