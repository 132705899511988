import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import DesignSystemMain from '../../components/DesignSystem/DesignSystemMain';

const DesignSystemScreen = () => {
   return (
      <View style={styles.parent}>
         <DesignSystemMain style={styles.section} />
      </View>
   );
};

export default DesignSystemScreen;

const styles = StyleSheet.create({
   parent: {
      flexDirection: 'row',
      flex: '1',
      backgroundColor: '#FFF',
      // minHeight: '100%',
   },
   section: {
      maxWidth: '100%',
      border: '5px solid black',
   },
});
