import { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Text, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import uuid from 'react-native-uuid';

import AddingDataElement from './DataStructureComponents/AddingDataElement';

const AddNewColumn = ({ onPress }) => {
   return (
      <TouchableOpacity
         onPress={onPress}
         style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#DFE2EE',
            height: '16px',
            padding: '2px',
            borderBottomRightRadius: '6px',
            borderBottomLeftRadius: '6px',
         }}>
            <svg
               xmlns='http://www.w3.org/2000/svg'
               width='12'
               height='12'
               viewBox='0 0 12 12'
               fill='none'>
               <path
                  d='M6.5 2.5V9.5M3 6H10'
                  stroke='#646D72'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
      </TouchableOpacity>
   );
};

const DataStructure = () => {
   const project = useSelector((state) => state.project);
   const database = useSelector((state) => state.database);
   const [columns, setColumns] = useState([]);

   const { definition } = project;

   useEffect(() => {
      if (!definition.data_model) return;
      const data = definition.data_model[database.currentTable.id].named_properties;
      
      setColumns(data);
   }, [database.currentTable.id]);

   const handleAddNewColumn = () => {
      setColumns((prev) => {
         return {...prev,
            [uuid.v4()]:{ id: uuid.v4(), type: 'text', name: '', default: '' }
         };
      })
   };
   return (
      <View style={styles.container}>
         {_.isEmpty(columns) ? (
            <Text style={styles.emptyText}>None columns</Text>
         ) : (
            <FlatList
               data={Object.keys(columns)}
               renderItem={({ item }) => (
                  <AddingDataElement
                     style={styles.dataRow}
                     key={item}
                     currId={item}
                     item={columns[item]}
                  />
               )}
            />
         )}
         <AddNewColumn onPress={handleAddNewColumn} />
      </View>
   );
};

export default DataStructure;

const styles = StyleSheet.create({
   container: {
      paddingHorizontal: '20px',
   },
   emptyText: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      border: '0.5px solid #F8F8F8',
   },
   dataRow: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      inner: {
         padding: '12px',
         flex: '1 30px',
         border: '0.5px solid #F8F8F8',
      },
   },
});
