import { Ionicons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';

import ConditionPanel from './componentConfig/ConditionPanel';
import DataPanel from './componentConfig/DataPanel';
import LayoutPanel from './componentConfig/LayoutPanel';
import LogicPanel from './componentConfig/LogicPanel';

const ConfigPane = ({ rootHandleElementUpdate, handleUpdate, componentDef }) => {
   const [panel, setPanel] = useState('Visual');

   const sections = [
      { name: 'Visual', icon: 'brush-outline', component: LayoutPanel },
      { name: 'Logic', icon: 'git-branch-outline', component: LogicPanel },
      { name: 'Condition', icon: 'layers-outline', component: ConditionPanel },
      { name: 'Data', icon: 'server-outline', component: DataPanel },
   ];

   const selectedSection = sections.find((el) => el.name === panel);

   return (
      <View style={styles.container}>
         <View style={styles.header}>
            {sections.map((section, index) => (
               <Pressable
                  key={index + '_' + section.name}
                  style={[styles.headerItem, panel === section.name ? styles.selected : {}]}
                  onPress={() => setPanel(section.name)}>
                  <Ionicons
                     name={section.icon}
                     size={24}
                     color={panel === section.name ? 'white' : 'black'}
                  />
               </Pressable>
            ))}
         </View>
         <ScrollView style={styles.container}>
            {selectedSection ? (
               <selectedSection.component
                  rootHandleElementUpdate={rootHandleElementUpdate}
                  handleUpdate={handleUpdate}
                  componentDef={componentDef}
               />
            ) : (
               <LayoutPanel
                  rootHandleElementUpdate={rootHandleElementUpdate}
                  handleUpdate={handleUpdate}
                  componentDef={componentDef}
               />
            )}
         </ScrollView>
      </View>
   );
};

export default ConfigPane;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      minWidth: 300,
      backgroundColor: 'white',
   },
   header: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: '#FFF',
      paddingHorizontal: 20,
      paddingVertical: 8,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: '#E0E4F4',
   },
   headerItem: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      borderRadius: 5,
   },
   selected: {
      backgroundColor: 'black',
   },
   headerItemText: {
      textAlign: 'center',
   },
   panel: {
      padding: 10,
   },
});
