import { Ionicons } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Pressable, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import LogicPanelWFCard from '../../components/componentConfig/LogicPanelWFCard';
import ActionConfigPane from '../../components/logicEditor/ActionConfigPane';
import WorkflowTree from '../../components/logicEditor/WorkflowTree';
import { closeLogicEditing, setSelectedLogicAction } from '../../store/EditorSlice';
import { updateLogicAction } from '../../store/ProjectSlice';

const LogicWorkflowEditor = ({ logicWorkflow }) => {
   const dispatch = useDispatch();
   const selectedLogicAction = useSelector((state) => state.editor.selectedLogicAction);

   const triggerTypes = [
      { name: 'On Start', type: 'trigger.onStart', icon: 'layers-outline' },
      { name: 'On Click', type: 'trigger.onClick', icon: 'layers-outline' },
      { name: 'On Right Click', type: 'trigger.onAlternateClick', icon: 'layers-outline' },
      { name: 'Conditional', type: 'trigger.condition', icon: 'layers-outline' },
   ];
   const [trigger, setTrigger] = useState(triggerTypes[0]);

   const selected = useSelector((state) => state.globals.selected);

   useEffect(() => {
      console.log('New workflow received', logicWorkflow);
   }, [logicWorkflow]);

   useEffect(() => {
      const tmp_trigger = Object.values(logicWorkflow.nodes || {}).find((node) =>
         node.type.startsWith('trigger.')
      );
      if (tmp_trigger) {
         setTrigger(tmp_trigger);
      }
   }, [
      Object.values(logicWorkflow.nodes || {}).find((node) => node.type.startsWith('trigger.'))
         ?.type,
   ]);

   return (
      <View style={styles.container}>
         <View style={styles.editor}>
            <View style={styles.header}>
               <Text style={styles.headerText}>{logicWorkflow.name}</Text>
               <View style={styles.headerButtons}>
                  <Pressable
                     style={({ pressed }) => [
                        styles.headerButton,
                        pressed ? styles.buttonPressed : {},
                        { width: 40 },
                     ]}>
                     <LogicPanelWFCard
                        style={styles.itemOptions}
                        componentKey={selected}
                        logic={logicWorkflow}
                     />
                  </Pressable>
                  <Pressable
                     style={({ pressed }) => [
                        styles.headerButton,
                        pressed ? styles.buttonPressed : {},
                     ]}
                     onPress={() => dispatch(closeLogicEditing())}>
                     <Ionicons name='close-outline' size={20} color='#646D72' />
                  </Pressable>
               </View>
            </View>
            {trigger && (
               <View style={styles.triggerBar}>
                  {triggerTypes.map((triggerType, index) => (
                     <Pressable
                        key={index}
                        style={({ pressed }) => [
                           styles.triggerBarButton,
                           pressed ? styles.buttonPressed : {},
                           trigger?.type === triggerType.type ? styles.selectedTriggerButton : {},
                        ]}
                        onPress={() => {
                           dispatch(
                              updateLogicAction(logicWorkflow.uid, trigger.uid, {
                                 uid: trigger.uid,
                                 name: triggerType.name,
                                 type: triggerType.type,
                                 parameters: {},
                              })
                           );
                        }}>
                        <Text
                           style={[
                              styles.triggerBarButtonText,
                              trigger.type === triggerType?.type ? styles.selectedTriggerText : {},
                           ]}>
                           {triggerType.name}
                        </Text>
                     </Pressable>
                  ))}
               </View>
            )}
            {trigger && (
               <View style={{ flex: 1 }}>
                  <ScrollView
                     contentContainerStyle={[
                        styles.scrollContainer,
                        logicWorkflow?.connections
                           ? { justifyContent: 'flex-start' }
                           : { justifyContent: 'center' },
                     ]}>
                     <WorkflowTree logicWorkflow={logicWorkflow} trigger={trigger} />
                  </ScrollView>
               </View>
            )}
         </View>
         {selectedLogicAction && (
            <View style={styles.sidePanel}>
               <ActionConfigPane
                  logicWorkflow={logicWorkflow}
                  closePanel={() => setSelectedLogicAction(null)}
               />
            </View>
         )}
      </View>
   );
};

export default LogicWorkflowEditor;

const styles = StyleSheet.create({
   itemOptions: {
      position: 'absolute',
      top: 13,
      right: 12,
   },
   scrollContainer: {
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      paddingHorizontal: 20,
   },
   container: {
      flex: 1,
      flexDirection: 'row',
   },
   sidePanel: {
      flex: 1,
      backgroundColor: '#F8F8F9',
      maxWidth: 300,
   },
   editor: {
      flex: 1,
      backgroundColor: '#F8F8F9',
      paddingHorizontal: 30,
      paddingVertical: 24,
   },
   header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 12,
   },
   headerText: {
      color: '#262626',
      textAlign: 'center',
      // font-feature-settings: 'clig' off, 'liga' off;
      fontFamily: 'Inter',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '110%',
   },
   headerButtons: {
      flexDirection: 'row',
      columnGap: 7,
   },
   headerButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
   },
   buttonPressed: {
      backgroundColor: '#F1F4FF',
   },
   triggerBar: {
      flexDirection: 'row',
      flexShrink: 0,
      alignSelf: 'flex-start', // Add this line
      // justifyContent: 'flex-start',
      padding: 8,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
   },
   triggerBarButton: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 20,
      paddingVertical: 6,
      borderRadius: 6,
   },
   selectedTriggerButton: {
      backgroundColor: '#2E3137',
   },
   triggerBarButtonText: {
      color: '#646D72',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   selectedTriggerText: {
      color: '#F5F9FF',
   },
});
