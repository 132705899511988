import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { useSelector } from 'react-redux';

// Edit mode
import Header from './components/header/Header';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import ProjectScreen from './screens/ProjectScreen';

const Stack = createNativeStackNavigator();

export const Editor = () => {
   const user = useSelector((state) => state.user);
   if (user) {
      return (
         <NavigationContainer>
            <Stack.Navigator
               screenOptions={{
                  headerShown: false,
               }}>
               <Stack.Screen name='Home' component={HomeScreen} />
               <Stack.Screen name='Project' component={ProjectScreen} />
            </Stack.Navigator>
         </NavigationContainer>
      );
   }
   return <LoginScreen />;
};
