import { setChatStatus } from '../slice';
import { CHAT_STATUS, TPageSection, TPatch } from '../types';
import { creatingSectionContext } from '../../../consts/prompts';
import { askAI } from '../openai';
import { camelCase } from '../utils';
import { sectionBuilders } from '../sections';
import { generateComponentContent } from '../generators'

/**
 * Add a new section to an existing page
 * @param debug 
 * @returns 
 */
export const assistantCreateSection = (debug: number = 0) => async (
   dispatch: (...args: any[]) => void, 
   getState: () => Record<string, any>
): Promise<void> => {
   if (debug > 2) console.log('[assistantCreateSection] Start')
   const prompt = getState().assistant.run.prompt

   const definition = getState().project.definition;
   const currentPage = getState().editor.page
   const rootComponentId = definition.pages[currentPage].root_component
   const selectedComponent = getState().globals.selected;

   dispatch(setChatStatus(CHAT_STATUS.handlingPrompt))
   const section = await askAI(
      creatingSectionContext.concat({role: 'user', content: prompt}), 
      debug
   )
   const [sectionTitle, sectionDescription] = section.content.split(': ')
   if (debug > 3) console.log('[assistantCreateSection] Section to create: ' + sectionTitle)

   const sectionType: TPageSection = camelCase(sectionTitle) as TPageSection
   if (!(sectionType in sectionBuilders)) {
      if (debug > 1) console.warn('[CreateSection] Unknown section type:', section)
   } else {
      await dispatch(sectionBuilders[sectionType](
         {
            description: sectionDescription, 
            sections: [sectionType],
            rootId: rootComponentId,
            rootIndex: 0,
            pageId: currentPage,
         }, 
         debug
      ))
   }

   const patchUpdates = getState().assistant.run.patch

   dispatch(setChatStatus(CHAT_STATUS.generatingContent))
   await Promise.all(patchUpdates.map((patchUpdate: TPatch) => dispatch(generateComponentContent(patchUpdate, debug))))

   dispatch(setChatStatus(CHAT_STATUS.idle))
}