import { useState } from 'react';
import { Button, Modal, Dimensions, View, StyleSheet, FlatList, Text, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import ColorPicker, { Panel1, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import DefaultInputContainer from '../../../base/DefaultInputContainer';
import { useSelector } from 'react-redux';

const ColorItem = ({ item, handleUpdate }) => {
   
    const [buttonLayout, setButtonLayout] = useState({
        height: 0,
        width: 0,
        top: 0,
        left: 0,
        x: 0,
        y: 0,
    });
    
    const [showColorModal, setShowColorModal] = useState(false);

    const currentColor = useSelector((state) => state.project.definition.design_system.colours[item.uid]);
    
    const calculateModalPosition = () => {
        const top = buttonLayout.top > Dimensions.get('window').height ? 
            Dimensions.get('window').height : buttonLayout.top;
        return {
            top,
            left: buttonLayout.left,
        }
    };
    
    const handleMeasure = (event) => {
        setButtonLayout(event?.nativeEvent?.layout);
    };

    const handleUpdateColor = ({hex}) => {
        handleUpdate([
            { 
               path: ['design_system', 'colours', item.uid, 'color'], 
               value: hex
            }
         ]
         );
    };

    return (
        <View onLayout={handleMeasure} style={{height: 42, flexDirection: 'row', alignItems: 'center', borderBottomColor: '#F8F8F8', borderBottomWidth: 1}}>
            <View style={styles.colorContainer}>
                <TouchableOpacity style={{padding: 5}} onPress={() => {setShowColorModal(true)}}>
                    <View style={{height: 36, width: 35, backgroundColor: item.color, borderRadius: 10,}}/>
                </TouchableOpacity>
            </View>
            <View style={styles.nameContainer}>
                <Text styles={styles.colorNameText}>{item.name}</Text>
            </View>
            <View style={styles.colorCodeContainer}>
                <Text styles={styles.colorNameValue}>{item?.color?.toUpperCase()}</Text>
            </View>
            <View style={styles.descriptionContainer}>
                <Text styles={styles.colorNameDescription}>{item?.description}</Text>
            </View>
            <View style={styles.deleteContainer}>
                <TouchableOpacity onPress={() => { 
                    handleUpdate([
                        { 
                            path: ['design_system', 'colours', item.uid], 
                            value: null
                        }
                        ]);
                    }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 1H13M1 4H19M17 4L16.2987 14.5193C16.1935 16.0975 16.1409 16.8867 15.8 17.485C15.4999 18.0118 15.0472 18.4353 14.5017 18.6997C13.882 19 13.0911 19 11.5093 19H8.49065C6.90891 19 6.11803 19 5.49834 18.6997C4.95276 18.4353 4.50009 18.0118 4.19998 17.485C3.85911 16.8867 3.8065 16.0975 3.70129 14.5193L3 4" stroke="#ABB1CA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </TouchableOpacity>
            </View>
            {showColorModal &&
                <Modal
                transparent
                visible={showColorModal}>
                    <View style={calculateModalPosition()}>
                        <View style={styles.modalContainer}>
                            <ColorPicker onComplete={handleUpdateColor}>
                            <View style={{width: 280}}>
                                <Panel1 />
                            </View>
                            <View style={{ width: 280, marginTop: 10 }}>
                                <HueSlider />
                            </View>
                            <View style={{ width: 280, marginTop: 10 }}>
                                <OpacitySlider />
                            </View>
                            </ColorPicker>
                            <View style={{ marginTop: 10 }}>
                                <DefaultInputContainer
                                    style={{ borderWidth: 1, borderColor: '#E0E4F4' }}
                                    onChangeText={(value) => {
                                            handleUpdateColor({ hex: value })
                                        }
                                    }
                                    value={currentColor?.color}
                                    icon={
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C10.2426 16.5 11.25 15.4926 11.25 14.25V13.875C11.25 13.5267 11.25 13.3525 11.2692 13.2063C11.4022 12.1967 12.1967 11.4022 13.2063 11.2692C13.3525 11.25 13.5267 11.25 13.875 11.25H14.25C15.4926 11.25 16.5 10.2426 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9Z" stroke="#93989B" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.25 9.75C5.66421 9.75 6 9.41421 6 9C6 8.58579 5.66421 8.25 5.25 8.25C4.83579 8.25 4.5 8.58579 4.5 9C4.5 9.41421 4.83579 9.75 5.25 9.75Z" stroke="#93989B" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 6.75C12.4142 6.75 12.75 6.41421 12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6C11.25 6.41421 11.5858 6.75 12 6.75Z" stroke="#93989B" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5 6C7.91421 6 8.25 5.66421 8.25 5.25C8.25 4.83579 7.91421 4.5 7.5 4.5C7.08579 4.5 6.75 4.83579 6.75 5.25C6.75 5.66421 7.08579 6 7.5 6Z" stroke="#93989B" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    }
                                />
                            </View>
                            <View style={{ marginTop: 10 }}>
                                <Button title='SET' onPress={() => setShowColorModal(false)} />
                            </View>
                        </View>
                    </View>
                </Modal>
            }
        </View>
    )
}


export default ColorItem;

const styles = StyleSheet.create({
   container: {
        paddingHorizontal: 20,
   },
   modalContainer: {
        width: 300,
        padding: 10,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderColor: '#f5f6f8',
        borderWidth: 2,
  },
   emptyText: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        border: '0.5px solid #F8F8F8',
   },
   dataRow: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
   },
   groupText: {
        color: '#93989B',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 19,
   },
   colorNameText: {
        color: '#262626',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 19,
   },
   colorNameValue: {
        color: '#646D72',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 19,
   },
   colorNameDescription: {
        color: '#B8BDD0',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 15,
   },
   colorContainer: {
        borderRightColor: '#F8F8F8',
        borderRightWidth: 1
   },
   nameContainer: {
        borderRightColor: '#F8F8F8',
        borderRightWidth: 1,
        width: 160,
   },
   colorCodeContainer: {
        borderRightColor: '#F8F8F8',
        borderRightWidth: 1,
        width: 100
    },
    descriptionContainer: {
        width: 291
    },
    deleteContainer: {
        width: 30
    }
});
