import { TSectionBuilderPayload, TMessage } from "../types"
import { generateComponent } from "../generators"
import { updateDesignSystem } from "../../ProjectSlice"
import { askAI } from "../openai"
import { cardIllustrationContext } from "../../../consts/prompts"

export const createCardsSection = (itemType: string, context: TMessage[], payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   if (debug > 2) console.log('[createCardsSection] Start')
   const { description, sections, rootId, rootIndex, pageId } = payload;
   const pageContext = getState().project.definition.pages[pageId].description
   
   // Create required styles
   // TODO: generate them dynamically (font, colors, etc)
   const styles = {
      card: {
         uid: 'card',
         name: 'Card',
         styling: {
            height: {
               type: 'expand',
            },
            width: {
               max: { val: 24, unit: 'rf' },
            },
            radius: {
               top_left: { val: 1, unit: 'rf' },
               top_right: { val: 1, unit: 'rf' },
               bottom_right: { val: 1, unit: 'rf' },
               bottom_left: { val: 1, unit: 'rf' },
            },
            background: {
               type: 'color',
               color: '@secondary_bg',
            },
         }
      },
      cardHeader: {
         uid: 'cardHeader',
         name: 'Card Header',
         styling: {
            width: { type: 'expand' },
            height: {
               type: 'fixed',
               fixed: { val: 5, unit: 'rf' },
               // type: 'ratio', 
               // ratio: { width: '1', height: '1' },
            }
         },
      },
      cardContent: {
         uid: 'cardContent',
         name: 'Card content box',
         styling: {
            padding: {
               top: { val: 1, unit: 'rf' },
               right: { val: 1, unit: 'rf' },
               bottom: { val: 1, unit: 'rf' },
               left: { val: 1, unit: 'rf' },
            }
         }
      },
      cardTitle: {
         uid: 'cardTitle',
         name: 'Card Title',
         styling: {
            text: {
               color: "@secondary_h1",
               size: {
                  val: 1.2,
                  unit: "rf"
               },
               font: "Roboto",
               line_height: 1.2,
               alignment: 'center',
            },
            width: {
               type: 'fit'
            }
         }
      },
      cardDescription: {
         uid: 'cardDescription',
         name: 'Card Description',
         styling: {
            text: {
               color: "@secondary_txt",
               size: {
                  val: 1,
                  unit: "rf"
               },
               font: "Roboto",
               line_height: 1.2,
               alignment: 'left',
            },
            width: {
               type: 'fit'
            }
         }
      },
   }
   dispatch(updateDesignSystem([
      { path: ['user_styles', 'card'], value: styles.card },
      { path: ['user_styles', 'cardHeader'], value: styles.cardHeader },
      { path: ['user_styles', 'cardContent'], value: styles.cardContent },
      { path: ['user_styles', 'cardTitle'], value: styles.cardTitle },
      { path: ['user_styles', 'cardDescription'], value: styles.cardDescription },
   ]))
   // create cards box section
   const cardsSectionDef = await dispatch(generateComponent(
      {
         name: itemType + ' section',
         type: 'box',
         description: itemType + ' section with most relevant items',
         custom_styling: {
            height: {
               type: 'fit',
            },
            layout: {
               direction: 'row',
               distribution: 'evenly',
               row_gap: { val: 1, unit: 'rf' },
               column_gap: { val: 1, unit: 'rf' },
            },
            padding: {
               top: { val: 3, unit: 'rf' },
               right: { val: 3, unit: 'rf' },
               bottom: { val: 3, unit: 'rf' },
               left: { val: 3, unit: 'rf' },
            },
            background: {
               type: 'color',
               color: '@primary_bg',
            },
         },
      },
      [],
      [rootId],
      rootIndex,
      debug
   ))
   // create featured products
   const itemsAsStr = (await askAI(
      context.concat({ role: 'user', content: pageContext }), 
      debug
   )).content
   if (debug > 3) console.log('[createCardsSection] top items:', itemsAsStr)
   const items = itemsAsStr.split('\n\n')
   for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const [title, description] = item.split('\n')
      const cardDef = await dispatch(generateComponent(
         {
            name: `${itemType} card ${index + 1}: ${title}`,
            type: 'box',
            description: 'Featured product card ' + index,
            style: 'card'  
         },
         [],
         cardsSectionDef.composite_key,
         index,
         debug
      ))
      await dispatch(generateComponent(
         {
            name: `${itemType} card ${index + 1} illustration`,
            type: 'image',
            description: 'Card ' + (index + 1) + ' image',
            properties: { image: 'generating image' },
            style: 'cardHeader',
         },
         [{path: ['properties', 'image'], type: 'image', context: cardIllustrationContext + '\nProduct: ' + item}],
         cardDef.composite_key,
         0,
         debug
      ))
      const cardsContentDef = await dispatch(generateComponent(
         {
            name: `${itemType} card ${index + 1} content`,
            type: 'box',
            description: 'Card ' + (index + 1) + ' content',
            style: 'cardContent',
         },
         [],
         cardDef.composite_key,
         1,
         debug
      ))
      await dispatch(generateComponent(
         {
            name: `${itemType} card ${index + 1} title`,
            type: 'text',
            description: 'Card ' + (index + 1) + ' title',
            properties: { text: title.replace('"', '').replace(':', '') },
            style: 'cardTitle',
         },
         [],
         cardsContentDef.composite_key,
         0,
         debug
      ))
      await dispatch(generateComponent(
         {
            name: `${itemType} card ${index + 1} description`,
            type: 'text',
            description: 'Card ' + (index + 1) + ' description',
            properties: { text: description },
            style: 'cardDescription',
         },
         [],
         cardsContentDef.composite_key,
         1,
         debug
      ))
   }
}

