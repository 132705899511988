import React, { useState, useEffect } from 'react';
import { Linking } from 'react-native';
import { useDispatch } from 'react-redux';

import { Editor } from './Editor';
import { auth } from './services/editorFirebase';
import { setUser } from './store/UserSlice';

export const Entrypoint = () => {
   const dispatch = useDispatch();
   // Set an initializing state whilst Firebase connects
   // const [initializingUser, setInitializingUser] = useState(true);
   const [domainRoot, setDomainRoot] = useState('');
   // const [environment, setEnvironment] = useState('');
   // const [queryArgs, setQueryArgs] = useState({});

   const handleAuthChange = (user) => {
      dispatch(setUser(user));
      // if (initializingUser) setInitializingUser(false);
   };

   const fetchDomainId = async () => {
      const url = await Linking.getInitialURL();
      const urlWithoutProtocol = url.replace(/^https?:\/\//i, '');
      const pathArgs = urlWithoutProtocol.split('/');
      const pathQuery = pathArgs[pathArgs.length - 1].split('?')[1] || '';
      const queryArgsList = pathQuery.split('&');
      const queryArgsDict = queryArgsList.reduce((acc, curr) => {
         const [key, value] = curr.split('=');
         acc[key] = value;
         return acc;
      }, {});

      setDomainRoot(urlWithoutProtocol.split('/')[0].replaceAll('.', '-'));
      // setEnvironment(urlWithoutProtocol.split('/')[1]);
      // setQueryArgs(queryArgsDict);
   };

   useEffect(() => {
      fetchDomainId();

      return auth.onAuthStateChanged(handleAuthChange);
   }, []);

   if (domainRoot) {
      return <Editor />;
      // if (queryArgs.debug_mode) {
      //    switch (queryArgs.debug_mode) {
      //       case 'edit':
      //          return <Editor />;
      //       case 'view':
      //          return <ViewMode domainRoot={domainRoot} environment={environment} />;
      //       default:
      //          console.error(`Invalid debug_mode query arg: ${queryArgs.debug_mode}`);
      //          return null;
      //    }
      // }
      // if (
      //    domainRoot === 'localhost:19006' ||
      //    domainRoot === 'localhost:19016' ||
      //    domainRoot === 'budhapp.com'
      // ) {
      //    return <Editor />;
      // }
      // return <ViewMode domainRoot={domainRoot} environment={environment} />;
   }
   return null;
};
