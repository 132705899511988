import { Ionicons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { StyleSheet, Text, View, Pressable, TouchableWithoutFeedback } from 'react-native';
import { useDispatch } from 'react-redux';

import { deleteComponentLogic } from '../../store/ProjectSlice';

const LogicPanelWFCard = ({ componentKey, logic, style }) => {
   const dispatch = useDispatch();
   const [menuVisible, setMenuVisible] = useState(false);

   return (
      <View style={style}>
         <Pressable style={styles.ellipsis} onPress={() => setMenuVisible(!menuVisible)}>
            <Ionicons name='ellipsis-horizontal-sharp' size={16} color='black' />
         </Pressable>
         {menuVisible && (
            <TouchableWithoutFeedback onPress={() => setMenuVisible(false)}>
               <View style={styles.menu}>
                  <Pressable onPress={() => console.log('copy', logic.uid)}>
                     <Text>Copy</Text>
                  </Pressable>
                  <Pressable onPress={() => console.log('duplicate')}>
                     <Text>Duplicate</Text>
                  </Pressable>
                  <Pressable
                     onPress={() => dispatch(deleteComponentLogic(componentKey, logic.uid))}>
                     <Text>Delete</Text>
                  </Pressable>
               </View>
            </TouchableWithoutFeedback>
         )}
      </View>
   );
};

export default LogicPanelWFCard;

const styles = StyleSheet.create({
   ellipsis: {
      position: 'absolute',
      top: 0,
      right: 0,
   },
   menu: {
      position: 'absolute',
      backgroundColor: 'white',
      top: 0,
      right: 16,
      padding: 10,
      borderRadius: 5,
      elevation: 5, // for Android
      shadowColor: '#000', // for iOS
      shadowOffset: { width: 0, height: 2 }, // for iOS
      shadowOpacity: 0.25, // for iOS
      shadowRadius: 3.84, // for iOS
   },
});
