export type TDebug = {}
export type TAssistantSlice = {
   chatStatus: TChatStatus,
   run: {
      prompt: string,
      enrichedPrompt: string,
      category: TCategory | null,
      patch: TPatch[],
      userConfirmation: boolean,
   },
   messages: TMessage[],
}

export type TMessage = { role: string, content: string };
export type TChatStatus = 
   | 'idle' 
   | 'awaitingConfirmation' | 'handlingCategory' 
   | 'handlingPrompt' | 'handlingQuestion'
   | 'generatingStructure' | 'generatingContent'
export const CHAT_STATUS: Record<TChatStatus, TChatStatus> = {
   idle: 'idle',
   awaitingConfirmation: 'awaitingConfirmation',
   handlingCategory: 'handlingCategory',
   handlingPrompt: 'handlingPrompt', 
   handlingQuestion: 'handlingQuestion',
   generatingStructure: 'generatingStructure',
   generatingContent: 'generatingContent',
};

export type TTreeNode = { id: string, name: string, description: string, children?: TTreeNode[] };
export type TPatchAction = 'doNothing' | 'createComponent' | 'updateComponent' | 'deleteComponent'
export type TDynamicContentType = 'image' | 'text'
export type TPatch = { 
   uid: string, 
   action: TPatchAction, 
   name: string,
   type: 'component' | 'style'
   content?: TPatchDynamicContent[]
}
export type TPatchDynamicContent = {
   path: string[],
   context: string | TMessage[],
   type: TDynamicContentType,
}

export type TCategory = 'createPage' | 'createSection' | 'updateComponent' | 'question'
export type TSectionBuilderPayload = { description: string, sections: string[], rootId: string, rootIndex: number, pageId: string }
export type TPageSection = 'header' | 'hero' | 
   'problemStatement' | 'featuredProducts' | 'uniqueValueProposition' | 
   'useCases' | 'aboutUs' | 'socialProof' | 'trustSignal' | 'footer'