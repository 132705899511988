export const commonContainers = [
   // 'boxList',
   'box',
   'modal',
   'text',
   'input',
   'image',
   'boxList',
   'map',
];

export const extraElementsComponent = {
   childComponent: {
      children: ['textComponent'],
      composite_key: ['rootElementContainer', 'childComponent'],
      custom_styling: {
         border: {
            bottom: {
               color: '#000000',
               style: 'solid',
               width: {
                  unit: 'px',
                  val: '1',
               },
            },
            left: {
               color: '#000000',
               style: 'solid',
               width: {
                  unit: 'px',
                  val: '1',
               },
            },
            right: {
               color: '#000000',
               style: 'solid',
               width: {
                  unit: 'px',
                  val: '1',
               },
            },
            top: {
               color: '#000000',
               style: 'solid',
               width: {
                  unit: 'px',
                  val: '1',
               },
            },
         },
         height: {
            min: {
               unit: 'px',
               val: '40',
            },
         },
         layout: {
            direction: 'column',
            distribution: 'center',
         },
         position: {
            auto: {
               position: 'center',
            },
         },
         radius: {
            bottom_left: {
               unit: 'px',
               val: '12',
            },
            bottom_right: {
               unit: 'px',
               val: '12',
            },
            top_left: {
               unit: 'px',
               val: '12',
            },
            top_right: {
               unit: 'px',
               val: '12',
            },
         },
         width: {
            fixed: {
               unit: 'px',
               val: '200',
            },
            type: 'fixed',
         },
      },
      name: 'New box component',
      type: 'box',
      uid: 'childComponent',
   },
   rootElementContainer: {
      children: ['childComponent'],
      composite_key: ['rootElementContainer'],
      custom_styling: {
         height: {
            type: 'expand',
         },
         layout: {
            direction: 'column',
            distribution: 'center',
         },
         position: {
            auto: {
               position: 'stretch',
            },
            relative: {
               vertical: 'center',
            },
            type: 'auto',
         },
         width: {
            type: 'expand',
         },
      },
      name: 'Main Container',
      type: 'box',
      uid: 'rootElementContainer',
   },
   textComponent: {
      composite_key: ['rootElementContainer', 'childComponent', 'textComponent'],
      custom_styling: {
         height: {
            type: 'fit',
         },
         text: {
            alignment: 'center',
         },
         width: {
            type: 'fit',
         },
      },
      name: 'New text component',
      properties: {
         text: 'Lorem Ipsum',
      },
      type: 'text',
      uid: 'textComponent',
   },
};
