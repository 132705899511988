import { Ionicons } from '@expo/vector-icons';
import React, { useEffect } from 'react';
import { StyleSheet, Text, View, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Input from './FieldWithUnit';

const getComponentsMeta = (component, components) => {
   if (component.children) {
      return Object.values(component.children).reduce(
         (acc, child) => [...acc, ...getComponentsMeta(components[child], components)],
         [
            {
               key: component.composite_key,
               name: component.name,
               data: Object.keys(component.data || {}),
            },
         ]
      );
   }
   return [
      {
         key: component.composite_key,
         name: component.name,
         data: Object.keys(component.data || {}),
      },
   ];
};

const ComponentData = ({ actionNode, handleUpdate }) => {
   const dispatch = useDispatch();

   const { page } = useSelector((state) => state.editor);
   const { pages, components } = useSelector((state) => state.project.definition);

   const componentsMeta = useSelector((state) =>
      getComponentsMeta(components[pages[page].root_component], components).sort(
         (a, b) => a.data?.length > b.data?.length
      )
   );
   return (
      <View style={styles.container}>
         <Input
            key={actionNode?.parameters?.target}
            label='Component'
            pickedValue={actionNode?.parameters?.target || ''}
            pickerOptions={componentsMeta?.map((componentMeta) => {
               return {
                  label: componentMeta.name,
                  value: componentMeta.key.slice(-1)[0],
               };
            })}
            handlePickerUpdate={(value) => handleUpdate('target', value)}
         />
         <Input
            key={actionNode?.parameters?.data}
            label='Data property'
            placeholder='data'
            value={actionNode?.parameters?.data || ''}
            handleValueUpdate={(value) => {
               handleUpdate('data', value);
            }}
            // pickedValue={actionNode?.parameters?.data || ''}
            // pickerOptions={componentsMeta?.find(el => el.key.slice(-1)[0] === actionNode.parameters.target)?.data || []}
            // handlePickerUpdate={(value) => {
            //       handleUpdate('data', value)
            //    }
            // }
         />
         <Input
            label='New Value'
            value={actionNode?.parameters?.value || ''}
            handleValueUpdate={(value) => handleUpdate('value', value)}
         />
      </View>
   );
};

export default ComponentData;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      flexDirection: 'column',
      width: '100%',
      gap: 8,
   },
   addMore: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: 8,
   },
   addMoreText: {
      color: '#2B55B7',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '130%',
   },
});
