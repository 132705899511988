import React, { useState, useRef, useMemo, useEffect } from 'react';
import { View, TextInput, FlatList, Text, Pressable, StyleSheet } from 'react-native';
import uuid from 'react-native-uuid';
import { useSelector } from 'react-redux';

import { inlineNodes } from '../../logicEditor/InlineActions/InlineActions';

const getComponentChildrenMeta = (components, pageId) => {
   const component = components[pageId];

   if (component.children) {
      return component.children.reduce(
         (acc, child) => [...acc, ...getComponentChildrenMeta(components, child)],
         [
            {
               key: component.composite_key,
               name: component.name,
               data: Object.keys(component.data || {}),
            },
         ]
      );
   }
   return [
      {
         key: component.composite_key,
         name: component.name,
         data: Object.keys(component.data || {}),
      },
   ];
};

const ExpandableAutocompleteInput = ({ data, value, onSelect, currentInlineLogic }) => {
   const [query, setQuery] = useState(
      data.find((el) => el?.value === (typeof value === 'object' ? value.value : ''))?.label || ''
   );

   const [results, setResults] = useState([]);
   const [nextNodes, setNextNodes] = useState(false);
   const [activeRow, setActiveRow] = useState('');
   const [activeIndex, setActiveIndex] = useState('');
   const [focused, setFocused] = useState(false);
   const [subFocused, setSubFocused] = useState(false);
   const flatListRef = useRef(null);

   const [firstEntry, setFirstEntry] = useState('');
   const [selectedNode, setSelectedNode] = useState({ label: '', uid: '' });
   const [targetParam, setTargetParam] = useState('');
   const [currentLogic, setCurrentLogic] = useState(
      currentInlineLogic ? JSON.parse(currentInlineLogic) : ''
   );

   useEffect(() => {
      setCurrentLogic(currentInlineLogic ? JSON.parse(currentInlineLogic) : '');
   }, [currentInlineLogic]);

   const handleSearch = (text) => {
      setQuery(text);
      const regex = new RegExp(`${text.trim()}`, 'i');
      setResults(
         data.filter((option) => {
            const label = typeof option === 'object' ? option.label : option;
            return label.search(regex) >= 0;
         })
      );
   };

   const appendInlineLogic = (text) => {
      const entryUid = uuid.v4();
      const nodeUid = uuid.v4();
      const inlineLogic = {
         nodes: {
            [entryUid]: {
               uid: entryUid,
               name: 'TODO',
               type: firstEntry.type,
               parameters: {
                  target: firstEntry.target,
               },
            },
            [nodeUid]: {
               uid: nodeUid,
               name: selectedNode.label,
               type: selectedNode.value,
               parameters: {
                  target: targetParam,
               },
            },
         },
         connections: {
            [entryUid]: {
               success: {
                  [nodeUid]: {
                     order: 0,
                     to: nodeUid,
                     uid: nodeUid,
                  },
               },
            },
         },
      };

      onSelect(`${value}@@${JSON.stringify(inlineLogic)}@@`);
   };

   const { page } = useSelector((state) => state.editor);
   const pages = useSelector((state) => state.project.definition.pages);
   const components = useSelector((state) => state.project.definition.components);

   const componentsInCurrentPage = useMemo(() => {
      return getComponentChildrenMeta(components, pages[page].root_component).sort(
         (a, b) => a.data?.length > b.data?.length
      );
   }, [components, page]);

   return (
      <View style={styles.container}>
         <Text style={styles.label}>Select entry point</Text>
         <TextInput
            style={styles.input}
            value={query}
            onChangeText={handleSearch}
            onFocus={() => {
               setFocused(true);
               setResults(data);
            }}
            onBlur={() =>
               !results
                  ? setTimeout(() => {
                       setFocused(false);
                    }, 100)
                  : {}
            }
            placeholder='Search...'
         />
         {focused && results.length > 0 && (
            <View style={styles.list}>
               <FlatList
                  ref={flatListRef}
                  data={results}
                  keyExtractor={(item) => item.value}
                  renderItem={({ item, index }) => {
                     const sources = item?.sources?.sources;

                     let data = [];

                     if (typeof sources === 'string') {
                        switch (sources) {
                           case 'custom.Components': {
                              data = componentsInCurrentPage.map((componentMeta) => ({
                                 label: componentMeta.name,
                                 value: componentMeta.key[componentMeta.key.length - 1],
                              }));
                              break;
                           }
                           case '':
                              break;
                        }
                     } else {
                        data = Object.keys(sources).map((subItem) => {
                           return {
                              label: sources[subItem].name,
                              value: sources[subItem].uid,
                           };
                        });
                     }
                     return (
                        <>
                           <Text style={[styles.subItem]}>{item.label}</Text>
                           <FlatList
                              data={data}
                              keyExtractor={(subItem) => subItem.value}
                              renderItem={({ item: subItem, index }) => {
                                 return (
                                    <Pressable
                                       onPress={() => {
                                          setQuery(subItem.label);
                                          setFocused(false);
                                          setNextNodes(true);
                                          setFirstEntry({
                                             type:
                                                typeof sources === 'string'
                                                   ? item.value
                                                   : subItem.value,
                                             target: subItem.value,
                                          });
                                       }}
                                       onMouseEnter={() =>
                                          item.label === setActiveRow(subItem.label + '_' + index)
                                       }>
                                       <Text
                                          style={[
                                             styles.item,
                                             subItem.label + '_' + index === activeRow &&
                                                styles.activeItem,
                                          ]}>
                                          {subItem.label}
                                       </Text>
                                    </Pressable>
                                 );
                              }}
                           />
                        </>
                     );
                  }}
               />
            </View>
         )}
         {nextNodes && (
            <View style={styles.container}>
               <Text style={styles.label}>Select node</Text>
               <TextInput
                  style={styles.input}
                  value={selectedNode?.label}
                  // onChangeText={handleSearch}
                  onFocus={() => {
                     setSubFocused(true);
                  }}
                  // onBlur={() => (!results ? setTimeout(() => {onBlur(); setFocused(false);}, 100) : {})}
                  placeholder='Select node'
               />
               {subFocused && (
                  <FlatList
                     data={Object.keys(inlineNodes).map((entry) => ({
                        label: inlineNodes[entry]?.name,
                        value: inlineNodes[entry]?.uid,
                     }))}
                     keyExtractor={(item) => item.value}
                     renderItem={({ item, index }) => (
                        <Pressable
                           onPress={() => {
                              setSelectedNode(item);
                              // onSelect(item);
                              setSubFocused(false);
                           }}
                           onMouseEnter={() => setActiveIndex(index)}>
                           <Text style={[styles.item, index === activeIndex && styles.activeItem]}>
                              {typeof item === 'object' ? item.label : item}
                           </Text>
                        </Pressable>
                     )}
                  />
               )}
            </View>
         )}
         {selectedNode.label && (
            <View style={styles.container}>
               <Text style={styles.label}>Select target param</Text>
               <TextInput
                  style={styles.input}
                  value={targetParam}
                  onChangeText={setTargetParam}
                  placeholder='Target'
               />
            </View>
         )}
         <Pressable style={[styles.button, styles.buttonOk]} onPress={appendInlineLogic}>
            <Text style={styles.textStyle}>Save</Text>
         </Pressable>
      </View>
   );
};

const styles = StyleSheet.create({
   container: {
      flex: 1,
      zIndex: 1,
   },
   input: {
      marginTop: 4,
      marginBottom: 8,
      paddingHorizontal: 8,
      paddingVertical: 4,
      flexShrink: 0,
      flexDirection: 'row',
      gap: 8,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E0E4F4',
      borderStyle: 'solid',
      backgroundColor: '#FFF',
      overflow: 'hidden',
   },
   list: {
      flex: 1,
      backgroundColor: '#FFF',
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 4,
      maxHeight: 200,
      elevation: 4,
      zIndex: 1000,
   },
   item: {
      backgroundColor: '#FFF',
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#ccc',
   },
   subItem: {
      backgroundColor: 'grey',
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#ccc',
   },
   activeItem: {
      backgroundColor: '#ddd',
   },
   firstItem: {
      borderTopWidth: 1,
      borderTopColor: '#ccc',
   },
   valid: {},
   invalid: {
      borderColor: '#F00',
   },
   button: {
      borderRadius: 8,
      padding: 10,
      height: 30,
      minWidth: 105,
      paddingVertical: 6,
      paddingHorizontal: 30,
      gap: 10,
   },
   buttonOk: {
      backgroundColor: '#2E3137',
   },
   textStyle: {
      color: '#FFFFFF',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   label: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontHeight: 400,
      lineHeight: '135%',
   },
});

export default ExpandableAutocompleteInput;
