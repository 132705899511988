import React, { useRef } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity } from 'react-native';
import DefaultInputContainer from '../base/DefaultInputContainer';

const ChangeComponentName = ({textStyle, value, onChangeText, placeholder, style, multiline, ...rest }) => {
   
   return (
      <View style={[styles.container, style]}>
         <View style={styles.innerContainer}>
            <View style={styles.field}>
               <DefaultInputContainer
                  style={[styles.input, textStyle]}
                  onChangeText={onChangeText}
                  value={value}
                  placeholder={placeholder}
                  placeholderTextColor='#93989B'
                  {...rest}
               />
            </View>
         </View>
      </View>
   );
};

export default ChangeComponentName;

const styles = StyleSheet.create({
   container: {
      
   },
   innerContainer: {
      width: '100%',
      minHeight: 40,
      borderRadius: 8,
      backgroundColor: '#F5F6F8',
      alignItems: 'center',
   },
   field: {
      flex: 1,
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      paddingVertical: 12,
      borderRadius: 8,
   },
   input: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
   },
});
