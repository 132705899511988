import OpenAI from "openai";
import Constants from 'expo-constants';

import { storage } from '../../services/editorFirebase';
import { TMessage } from "./types";

// debug: 0 => none, 1 => critical, 2 => warnings, 3 => functions mapping, 4 => verbose

export const openai = new OpenAI({
   apiKey: Constants?.expoConfig?.extra?.OPENAI_API_KEY,
   dangerouslyAllowBrowser: true,
});
const assistantId = 'asst_ORowCH4zH0K5GhjNTQOJii84';

/**
 * Sends a message to a basic AI chat (gpt.completion)
 * @param message 
 * @returns ChatCompletion answer as a promise
 */
const sendMessageChat = async (messages: TMessage[]): Promise<TMessage>  => {
   const params: OpenAI.Chat.ChatCompletionCreateParams = {
      messages,
      model: 'gpt-3.5-turbo',
   };
   const result = await fetch(
      'https://budhapptool.app.n8n.cloud/webhook/askai',
      {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(params)
      }
   );
   const chatCompletion: OpenAI.Chat.ChatCompletion = await result.json()
   // const chatCompletion: OpenAI.Chat.ChatCompletion = await openai.chat.completions.create(params);
   return chatCompletion.choices[0].message;
}
const createImage = async (prompt: string, filePath: string, debug: number = 0): Promise<string> => {
   const result = await fetch(
      'https://budhapptool.app.n8n.cloud/webhook/createImage',
      {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({ model: "dall-e-3", prompt, file_path: filePath })
      }
   )
   const response: any = await result.json()
   // const response = await openai.images.generate({ model: "dall-e-3", prompt });
   const imageURL = response.name;
   console.warn('--- Debug create image ---\n', response, '\n', imageURL)
   // const imageURL = response.data[0].url;
   if (debug > 2) console.log('Created image', imageURL, ' stored at ' + filePath)
   return imageURL
}
export const createAndStoreImage = async (prompt: string | TMessage[], filePath: string, debug: number = 0): Promise<TMessage> => {
   const cleanPrompt = Array.isArray(prompt) ? prompt.join('\n') : prompt

   const tmpUrl = await createImage(cleanPrompt, filePath, debug)
   try {
      // const URL = await storage.uploadFromURL(filePath, tmpUrl)
      const URL = await storage.urlFromPath(tmpUrl)
      return {role: 'assistant', content: URL}
   } catch (e) {
      if (debug > 1) console.warn('[createAndStoreImage] Couldn\'t create image')
      return {role: 'assistant', content: 'Error creating image. Try again later.'}
   }
}
/**
 * 
 * @param prompt The conversation to send the AI. Can be a string or a list of messages (TMessage).
 * @param context A string to provide system context for the chat.
 * @returns 
 */
export const askAI = async (prompt: string | TMessage[], debug=0): Promise<TMessage> => {
   const res = await askParallelAI(prompt, 1, debug)
   return res[0]
}
/**
 * 
 * @param prompt 
 * @param context 
 * @param parallelisation 
 * @returns 
 */
export const askParallelAI = async (prompt: string | TMessage[], parallelisation: number = 1, debug: number = 0): Promise<TMessage[]> => {
   if (debug > 2) console.log('[askParallelAI] Start')
   if (debug > 3) { console.log('[askParallelAI] User prompt:\n', prompt) }
   
   let messages: TMessage[] = [];
   if (Array.isArray(prompt)) {
      messages = prompt
   } else if (typeof prompt === 'string') {
      messages = stringToChunks(prompt, debug).map(chunk => ({ role: 'user', content: chunk }))
   }
   const promises = [];
   for (let i = 0; i < parallelisation; i++) {
      promises.push(sendMessageChat(messages));
   }
   if (debug > 2) { console.log('[askParallelAI] Asking AI:\n', messages) }
   try {
      const results = await Promise.all(promises)
      if (debug > 3) { console.log('[askParallelAI] Received:\n', results) }
      return results;
   } catch (e) {
      console.error('Error asking AI:', e, '\nDiscussion:', messages);
   }
   return [{
      role: 'assistant',
      content: 'The assistant is having some issues. Please retry later'
   }]
}

/**
 * Splits a string into chunks of a given size
 * @param string
 * @param chunkSize
 * @returns list of chunks
 */
function stringToChunks(text: string, debug: number = 0, chunkSize: number = 32000) {
   try {
      if (debug > 2) console.log('[stringToChunks] Start\n', text)
      const chunks = [];
      while (text.length > chunkSize) {
          chunks.push(text.substring(0, chunkSize));
          text = text.substring(chunkSize, text.length);
      }
      chunks.push(text)
      return chunks
   } catch (e) {
      if (debug > 1) console.error(`Error splitting string "${text}"\n`, e)
      return ['']
   }
}