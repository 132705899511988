import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import DefaultInputContainer from '../base/DefaultInputContainer';
import ExpandableContainer from '../base/ExpandableContainer';
import ImagePicker from '../componentsLibrary/utils/ImagePicker';

const ImageConfig = ({ componentDef, handleUpdate }) => {

    const [imageUrl, setImageUrl] = useState(
        componentDef?.properties?.image || ''
    );

    useEffect(() => {
        setImageUrl(componentDef?.properties?.image);
    }, [componentDef?.properties?.image]);

   return (
    <ExpandableContainer title='Image'>
        <ImagePicker
            style={{ marginHorizontal: 20 }}
            imageURI={componentDef.properties?.image}
            onImageChange={(value) =>
                handleUpdate([{ path: ['properties', 'image'], value }])
            }
        />
        <View style={[styles.container]}>
            <View style={styles.innerContainer}>
                <View style={styles.field}>
                    <DefaultInputContainer
                        style={[styles.input]}
                        onChangeText={(value) => {
                            handleUpdate([
                            { path: ['properties', 'image'], value },
                            ]);
                            setImageUrl(value);
                        }}
                        value={imageUrl}
                        placeholder={'Image url'}
                        placeholderTextColor='#93989B'
                    />
                </View>
            </View>
      </View>
    </ExpandableContainer>
   )
};

export default ImageConfig;

const styles = StyleSheet.create({
    input: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 19,
    },
    innerContainer: {
        width: '100%',
        minHeight: 40,
        borderRadius: 8,
        backgroundColor: '#F5F6F8',
        alignItems: 'center',
    },
    field: {
        flex: 1,
        flexDirection: 'row',
        cursor: 'pointer',
        borderRadius: 8,
    },
});
