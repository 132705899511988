import { StyleSheet, Text, View, Switch, Pressable } from "react-native";
import React, { useState } from "react";
import { Ionicons } from '@expo/vector-icons';
import InputField from "../../base/InputField";

const CardEffect = ({ onUpdate, cardEffect, effectGuidelines,  effect }) => {
   if (!effect || !effectGuidelines.type) {
      return <View>
         <Text>Unknown effect: {effect}</Text>
      </View>;
   }
   
   const getInputType = (type) => {
      try {
         switch (type) {
            case 'boolean':
               return <Switch
                  trackColor={{ false: "#767577", true: "#81b0ff" }}
                  thumbColor={cardEffect ? "#f5dd4b" : "#f4f3f4"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={() => onUpdate([{ 
                     path: effectGuidelines.save_path, 
                     value: (!cardEffect)
                  }])}
                  value={cardEffect}
               />
            case 'number':
               return <InputField
                  value={cardEffect.val}
                  handleValueUpdate={(value) => onUpdate([{
                     path: effectGuidelines.save_path,
                     value: value
                  }])}
                  unit={cardEffect.unit}
                  unitOptions={effectGuidelines.unit_options}
                  handleUnitUpdate={(unit) => onUpdate([{
                     path: effectGuidelines.save_path,
                     value: unit
                  }])}
               />
            case 'categories':
               return <InputField
                  pickedValue={cardEffect}
                  pickerOptions={Object.keys(effectGuidelines.fields || {}).map(
                     fieldKey => ({ label: effectGuidelines.fields[fieldKey].name, value: fieldKey })
                  )}
                  handlePickerUpdate={(value) => onUpdate([{ 
                     path: effectGuidelines.save_path, 
                     value: value
                  }])}
               />
            default:
               return <Text>Unknown effect type: {type}</Text>
         }
      } catch (error) {
         console.error('getInputType error', error);
         return <Text>Type error: {type}</Text>
      }
   }

   switch (effectGuidelines.type) {
      case 'category':
         console.log('effectGuidelines.fields', effectGuidelines.fields)
      case 'section':
         return <View style={styles.section}>
            {Object.keys(effectGuidelines.fields || {}).map((field, index) => (
               <CardEffect 
                  key={index}
                  onUpdate={onUpdate}
                  effect={field}
                  cardEffect={cardEffect[field]}
                  effectGuidelines={effectGuidelines.fields[field]}
               />
            ))}
         </View>
      case 'categories':
         return (
            <View style={styles.effectList}>
               <View style={styles.effect}>
                  <View style={styles.field}>
                     <View style={styles.path}>
                        <Text>{effectGuidelines.name}</Text>
                     </View>
                     <Pressable onPress={() => {}}>
                        <Ionicons
                           name={"trash"}
                           size={16}
                           color={'#ABB1CA'}
                        />
                     </Pressable>
                  </View>
                  <View style={styles.path}>
                     {getInputType(effectGuidelines.type)}
                  </View>
               </View>
               {Object.keys(effectGuidelines.fields || {}).map((field, index) => (
               <CardEffect 
                  key={index}
                  onUpdate={onUpdate}
                  effect={field}
                  cardEffect={cardEffect[field]}
                  effectGuidelines={effectGuidelines.fields[field]}
               />
            ))}
            </View>
         )
      case 'boolean':
      case 'number':
      default:
         return (
            <View style={styles.effect}>
               <View style={styles.field}>
                  <View style={styles.path}>
                     <Text>{effectGuidelines.name}</Text>
                  </View>
                  <Pressable onPress={() => {}}>
                     <Ionicons
                        name={"trash"}
                        size={16}
                        color={'#ABB1CA'}
                     />
                  </Pressable>
               </View>
               <View style={styles.path}>
                  {getInputType(effectGuidelines.type)}
               </View>
            </View>
         )
   }
};

export default CardEffect;

const styles = StyleSheet.create({
   effectList: {
      flexDirection: 'column',
      gap: 12,
   },
   effect: {
      gap: 4,
   },
   field: {
      flexDirection: 'row',
      justifyContent: 'space-between',
   },
   path: {
      flex: 1,
   },
   value: {
      flex: 1,
   },
   delete: {
      color: 'red',
      marginLeft: 4,
   },
});
