import { A } from '@expo/html-elements';
import { Octicons } from '@expo/vector-icons';
import { useState, useEffect } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

const DownloadSelectedButton = ({ onPress }) => {
   const [dataLink, setDataLink] = useState(null);
   const database = useSelector((state) => state.database);

   const { currentTable, selectedRows } = database;

   useEffect(() => {
      const blob = new Blob([JSON.stringify(selectedRows)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      setDataLink(url);
   }, [selectedRows]);

   return (
      <TouchableOpacity onPress={onPress} style={styles.container}>
         <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.125 8.875V9.625C12.125 10.6751 12.125 11.2001 11.9206 11.6012C11.7409 11.954 11.454 12.2409 11.1012 12.4206C10.7001 12.625 10.1751 12.625 9.125 12.625H3.875C2.8249 12.625 2.29985 12.625 1.89877 12.4206C1.54596 12.2409 1.25913 11.954 1.07936 11.6012C0.875 11.2001 0.875 10.6751 0.875 9.625V8.875M9.625 4.5L6.5 1.375M6.5 1.375L3.375 4.5M6.5 1.375V8.875" stroke="#2B55B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         </svg>
         <A style={styles.text} href={dataLink} download={currentTable.name + '.json'}>
            Upload from Figma file
         </A>
      </TouchableOpacity>
   );
};

export default DownloadSelectedButton;

const styles = StyleSheet.create({
   container: {
      paddingTop: 6,
      paddingLeft: 16,
      paddingBottom: 6,
      paddingRight: 14,
      borderRadius: 8,
      gap: 10,
      height: 34,
      flexDirection: 'row',
   },
   text: {
      fontFamily: 'Inter',
      color: '#7B87A6',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
});
