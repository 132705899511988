import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect } from 'react';
import { EventProvider } from 'react-native-outside-press';
import { Provider } from 'react-redux';

import { Entrypoint } from './src/Entrypoint';
import store from './src/store';

export default function App() {
   const [fontsLoaded] = useFonts({
      Inter: require('./assets/fonts/Inter.ttf'),
      'SuisseIntl-Medium': require('./assets/fonts/SuisseIntl-Medium.otf'),
      'SuisseIntl-Regular': require('./assets/fonts/SuisseIntl-Regular.otf'),
      Barlow: require('./assets/fonts/Barlow.ttf'),
      Roboto: require('./assets/fonts/Roboto-Regular.ttf'),
      Lato: require('./assets/fonts/Lato-Regular.ttf'),
      Questrial: require('./assets/fonts/Questrial.ttf'),
      Montserrat: require('./assets/fonts/Montserrat.ttf'),
      'SF Pro Display Light': require('./assets/fonts/SF_Pro_Display_Light.ttf'),
      'SF Pro Display Medium': require('./assets/fonts/SF_Pro_Display_Medium.ttf'),
      'SF Pro Display Regular': require('./assets/fonts/SF_Pro_Display_Regular.ttf'),
   });

   const onLayoutRootView = useCallback(async () => {
      if (fontsLoaded) {
         await SplashScreen.hideAsync();
      }
   }, [fontsLoaded]);
   useEffect(() => {
      setTimeout(() => {
         SplashScreen.hideAsync();
      }, 500);
   }, []);

   if (!fontsLoaded) {
      return null;
   }
   return (
      <Provider store={store}>
         <EventProvider style={{ flex: 1 }}>
            <Entrypoint />
            <StatusBar style='light' translucent={false} />
         </EventProvider>
      </Provider>
   );
}
