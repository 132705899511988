import { createCardsSection } from "./cardsSection";
import { TSectionBuilderPayload } from "../types";
import { uniqueValuePropositionContext } from "../../../consts/prompts";

export const createUniqueValue = (payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   if (debug > 2) console.log('[CreateFeaturedProducts] Start')
   return dispatch(createCardsSection(
      'Benefits',
      uniqueValuePropositionContext,
      payload,
      debug
   ))
}