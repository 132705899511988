export const visualEffectsDefinitions = {
   properties: {
      'is_hidden': {
         type: 'boolean',
         name: 'Is hidden?',
         save_path: ['properties', 'is_hidden']
      },
   },
   styling: {
      'background': {
         type: 'section',
         name: 'Background',
         fields: {
            'type': {
               type: 'categories',
               save_path: ['styling', 'background', 'type'],
               name: 'Background type',
               fields: {
                  none: {
                     type: 'category',
                     name: 'None',
                  },
                  color: {
                     type: 'category',
                     name: 'Color',
                     fields: {
                        color: {
                           save_path: ['styling', 'background', 'color'],
                           type: 'color',
                           id: 'color',
                           name: 'Background Color',
                        },
                     }
                  },
                  image: {
                     type: 'category',
                     name: 'Image',
                     fields: {
                        image: {
                           save_path: ['styling', 'background', 'image'],
                           type: 'image',
                           id: 'image',
                           name: 'Background Image URL',
                        },
                     }
                  },
                  gradient: {
                     type: 'category',
                     name: 'Gradient',
                     fields: {
                        colors: {
                           save_path: ['styling', 'background', 'gradient', 'colors'],
                           type: 'array',
                           name: 'Background Gradient Colors',
                           itemType: 'color',
                        },
                        direction: {
                           save_path: ['styling', 'background', 'gradient', 'direction'],
                           type: 'categories',
                           name: 'Background Gradient Direction',
                           fields: {
                              'to_top': {
                                 type: 'category',
                                 name: 'To Top',
                              },
                              'to_right': {
                                 type: 'category',
                                 name: 'To Right',
                              },
                              'to_bottom': {
                                 type: 'category',
                                 name: 'To Bottom',
                              },
                              'to_left': {
                                 type: 'category',
                                 name: 'To Left',
                              },
                           }
                        }
                     }
                  },
               },
            },
         }
      },
      text: {
         type: 'section',
         name: 'Text',
         fields: {
            size: {
               type: 'number',
               name: 'Font size',
               save_path: ['styling', 'text', 'size'],
               unit_options: ['rf', 'px', '%'],
            },
         }
      }
   }
}

const parseOptions = (nodes) => {
   if (nodes) {
      return Object.values(nodes).reduce(
         (acc, nodeDefinition) => {
            switch (nodeDefinition.type) {
               case 'section':
               case 'category':
                  if (nodeDefinition.fields) {
                     return [...acc, ...parseOptions(nodeDefinition.fields)];
                  }
                  return acc;
               case 'categories':
                  return [
                     ...acc,
                     { 
                        label: nodeDefinition.name, 
                        value: nodeDefinition.save_path.join('.')
                     },
                     ...parseOptions(nodeDefinition.fields)
                  ];
               default:
                  if (typeof nodeDefinition === 'object' && 'save_path' in nodeDefinition) {
                     return [
                        ...acc,
                        { 
                           label: nodeDefinition.name, 
                           value: nodeDefinition.save_path.join('.')
                        }
                     ];
                  }
                  console.warn('Unknown nodeDefinition', nodeDefinition);
                  return acc;
            }
         },
         []
      )
   }
   return []
}

export const visualEffectsOptions = parseOptions({ 
   ...visualEffectsDefinitions.properties,
   ...visualEffectsDefinitions.styling
})
