import { Ionicons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { StyleSheet, Text, View, Pressable, TouchableWithoutFeedback } from 'react-native';
import { useDispatch } from 'react-redux';

import { inlineNodes } from './InlineActions/InlineActions';
import InlineEntrys from './InlineActions/InlineEntrys';
import Input from '../base/InputField';
import ExpandableAutocompleteInput from '../base/InputField/ExpandableAutoCompleteInput';

const InlineModal = ({ onClose, value, updateValue, currentInlineLogic }) => {
   return (
      <View>
         <View style={styles.menu}>
            <Pressable style={{ position: 'absolute', top: 10, right: 10 }} onPress={onClose}>
               <svg
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M11 1L1 11M1 1L11 11'
                     stroke='#93989B'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </Pressable>
            <View style={{ marginTop: 30 }}>
               <ExpandableAutocompleteInput
                  label='When'
                  value={value}
                  currentInlineLogic={currentInlineLogic}
                  data={Object.keys(InlineEntrys).map((entry) => ({
                     label: entry,
                     value: InlineEntrys[entry]?.uid || entry,
                     sources: InlineEntrys[entry],
                  }))}
                  onSelect={(value) => {
                     updateValue(value);
                     onClose();
                  }}
               />
            </View>
         </View>
      </View>
   );
};

export default InlineModal;

const styles = StyleSheet.create({
   ellipsis: {
      position: 'absolute',
      top: 0,
      right: 0,
   },
   menu: {
      position: 'absolute',
      backgroundColor: 'white',
      minWidth: 370,
      top: 0,
      right: 300,
      padding: 10,
      borderRadius: 5,
      elevation: 5, // for Android
      shadowColor: '#000', // for iOS
      shadowOffset: { width: 0, height: 2 }, // for iOS
      shadowOpacity: 0.25, // for iOS
      shadowRadius: 3.84, // for iOS
   },
});
