import { StyleSheet, Text, View, TextInput, Picker, Modal, Pressable } from 'react-native'
import React, { useEffect, useState } from 'react'
import OutsidePressHandler from 'react-native-outside-press';
import RichInput from './RichInput/RichInput';

const FieldWithUnit = ({
    handleValueUpdate, handleUnitUpdate, handleColorUpdate, handleCustomSelectUpdate,
    active = true, label, placeholder, value, unit, color, customSelect, customSelectOptions,
    tabIndex,
}) => {
    const [focused, setFocused] = useState(false)
    const [_value, setValue] = useState(value)
    const [_unit, setUnit] = useState(unit)
    const [_color, setColor] = useState(color)
    const [_customSelect, setCustomSelect] = useState()
    const [colorPickerOpen, setColorPickerOpen] = useState(false)

    const [fieldLayout, setFieldLayout] = useState({ height: 0, width: 0, top: 0, left: 0, x: 0, y: 0 })


    useEffect(() => {
        setValue(value)
    }, [value])
    useEffect(() => {
        setUnit(unit)
    }, [unit])
    useEffect(() => {
        setColor(color)
    }, [color])
    useEffect(() => {
        setCustomSelect(customSelect)
    }, [customSelect])

    const toggleColorPicker = () => {
        setColorPickerOpen(!colorPickerOpen)
    }
    const getActiveStyle = () => {
        if (active === true) {
            return styles.active
        }
        if (active === false) {
            return styles.inactive
        }
        return {}
    }

    const handleMeasure = (event) => {
        setFieldLayout(event?.nativeEvent?.layout)
    };
    const calculateModalPosition = () => ({
        top: fieldLayout.top + fieldLayout.height, // Adjust the top position as needed
        left: fieldLayout.left,
    });
    return (

        <OutsidePressHandler onOutsidePress={() => setFocused(false)}>
            {label &&
                <Text style={styles.label}>{label}</Text>
            }
            <View style={styles.valunit}>
                <TextInput
                    // <RichInput
                    tabIndex={tabIndex}
                    style={[styles.input, getActiveStyle()]}
                    value={_value || ''}
                    placeholder={placeholder}
                    onChangeText={(val) => {
                        setValue(val)
                        handleValueUpdate(val)
                    }}
                    onFocus={() => setFocused(true)}
                />
                {focused && handleUnitUpdate &&
                    <Picker
                        style={styles.picker}
                        selectedValue={_unit}
                        onValueChange={(val) => {
                            setUnit(val)
                            handleUnitUpdate(val)
                        }}>
                        <Picker.Item label="px" value="px" />
                        <Picker.Item label="%" value="%" />
                        <Picker.Item label="rf" value="rf" />
                    </Picker>
                }
                {focused && handleColorUpdate &&
                    <View>
                        <Pressable
                            style={[styles.colorPicker, { backgroundColor: _color }]}
                            onPress={toggleColorPicker}
                            onLayout={handleMeasure}
                        />
                        <Modal
                            animationType="fade"
                            transparent={true}
                            visible={colorPickerOpen}
                            onRequestClose={toggleColorPicker}
                        >
                            <Pressable style={styles.modalOverlay} onPress={toggleColorPicker}>
                                <Pressable style={[styles.colorPickerModal, calculateModalPosition()]}>
                                    <TextInput
                                        // <RichInput
                                        style={[styles.input, styles.active, styles.colorInput, { backgroundColor: 'white' }]}
                                        value={_color || ''}
                                        placeholder={'color'}
                                        onChangeText={(val) => {
                                            setColor(val)
                                            handleColorUpdate(val)
                                        }}
                                    />
                                </Pressable>
                            </Pressable>
                        </Modal>
                    </View>
                }
                {focused && handleCustomSelectUpdate && customSelectOptions &&
                    <Picker
                        style={styles.picker}
                        selectedValue={_customSelect}
                        onValueChange={(val) => {
                            setUnit(val)
                            handleCustomSelectUpdate(val)
                        }}
                    >
                        {customSelectOptions.map((option, i) => {
                            return <Picker.Item key={i} label={option} value={option} />
                        })}
                    </Picker>
                }
            </View>
        </OutsidePressHandler>
    )
}

export default FieldWithUnit

const styles = StyleSheet.create({
    label: {
        fontWeight: 'bold',
        fontSize: 10,
        marginBottom: 4,
    },
    input: {
        fontSize: 8,
        width: 35,
        height: 20,
        textAlign: 'center',
        // borderRadius: 4,
        backgroundColor: 'white',
    },
    active: {
        borderColor: 'black',
        // borderWidth: 1,
        // borderStyle: 'solid',
    },
    inactive: {
        borderColor: 'grey',
        // borderWidth: 1,
        // borderStyle: 'solid',
        color: 'grey',
    },
    picker: {
        height: 20,
        width: 30,
        fontSize: 8,
        fontFamily: 'Inter',
        position: 'absolute',
        right: -30,
    },
    valunit: {
        flexDirection: 'row',
    },
    colorPicker: {
        width: 20,
        height: 20,
        borderRadius: 4,
        borderColor: 'black',
        // borderWidth: 1,
        // borderStyle: 'solid',
    },
    colorInput: {
        width: 60,
    },
    modalOverlay: {
        flex: 1,
    },
    colorPickerModal: {
        position: 'absolute',
        // backgroundColor: '#f2f2f2',
        // borderRadius: 4,
        elevation: 4,
        padding: 2,
    },
})