import uuid from 'react-native-uuid';

import { dbCreateError, dbReadSuccess, dbCreateSuccess, dbReadError } from '../store/ProjectSlice';

export function dbCreate(path: string, data: Record<string, any>) {
   return (dispatch: any, getState: any) => {
      const state: any = getState();

      state.project
         .create(path, data)
         .then((data: object) => {
            dispatch(dbCreateSuccess({ path, data }));
         })
         .catch((error: string) => {
            dispatch(dbCreateError(error));
         });
   };
}

export function deleteSelected() {
   return (dispatch: any, getState: any) => {
      const state: any = getState();

      const { project, database } = state,
         { projectId } = project,
         { selectedRows, currentTable } = database;

      Object.keys(selectedRows).forEach((row, index) => {
         dispatch(dbDelete(`/data/${projectId}/${currentTable.id}/${row}`));
      });
   };
}

export function dbDelete(path: string) {
   return (dispatch: any, getState: any) => {
      const state: any = getState();

      state.project
         .delete(path)
         .then((data: object) => {
            //   dispatch(dbDeleteSuccess({ assetType, uid }));
         })
         .catch((error: string) => {
            //   dispatch(dbDeleteError(error));
         });
   };
}

export function dbUpdateAllFields() {
   return (dispatch: any, getState: any) => {
      const state: any = getState();

      const { project, database: { dataToEdit } } = state;

      Object.keys(dataToEdit).forEach((item, index) => {
         const {path, col, data} = dataToEdit[item];
         project.update(path, {[col]: data});
      })
   };
}

export function dbRead(path: string) {
   return (dispatch: any, getState: any) => {
      const state: any = getState();

      state.project
         .read(path)
         .then((data: object) => {
            dispatch(dbReadSuccess(data));
         })
         .catch((error: string) => {
            dispatch(dbReadError(error));
         });
   };
}

export function createNewDataModel(modelName: string) {
   return (dispatch: any, getState: any) => {
      const state = getState();

      const {
         project: { projectId },
      } = state;

      if (!projectId) return;

      const dataModelId = uuid.v4();
      const idId = uuid.v4();
      const createdAtId = uuid.v4();
      const createdById = uuid.v4();

      dispatch(
         dbCreate(`/projects/${projectId}/dev/data_model/${dataModelId}`, {
            name: modelName,
            id: dataModelId,
            type: 'object',
            named_properties: {
               [idId]: {
                  name: 'Id',
                  type: 'number',
                  default: '',
               },
               [createdAtId]: {
                  name: 'CreatedAt',
                  type: 'datetime',
                  default: '',
               },
               [createdById]: {
                  name: 'CreatedBy',
                  type: 'datetime',
                  default: '',
               },
            },
         })
      );
   };
}

export function addNewUserData(modelData: object) {
   return (dispatch: any, getState: any) => {
      const state = getState(),
         { project, database } = state,
         { projectId } = project,
         { currentTable } = database,
         { id: tableId } = currentTable;

      if (!projectId) return;

      const id = uuid.v4();
      dispatch(dbCreate(`/data/${projectId}/${tableId}/${id}`, modelData));
   };
}

export function handleTableNameChange(value: any) {
   return (dispatch: any, getState: any) => {
      const state = getState();

      const { projectId } = state.project;

      const { currentTable } = state.database;

      state.project
         .update(`/projects/${projectId}/dev/data_model/${currentTable.id}`, {
            name: value,
         })
         .then(() => {
            console.log('updated');
         });
      state.project
         .update(`/data/${projectId}/${currentTable.id}`, {
            name: value,
         })
         .then(() => {
            console.log('updated');
         });
   };
}

export function dbUpdate(path: string, data: Record<string, any>) {
   return (dispatch: any, getState: any) => {
      const state = getState();

      state.project
         .update(path, data)
         .then((data: object) => {
            //   dispatch(dbDeleteSuccess({ assetType, uid }));
         })
         .catch((error: string) => {
            //   dispatch(dbDeleteError(error));
         });
   };
}
// export function dbUpdate(node: any, componentId: string, dataCellRootId: string) {
//   return (dispatch: any, getState: any) => {
//     const state: any = getState();

//     const { assetId, assetType, data } = node.parameters;

//     const calcAssetId = calcInlineLogic(
//       state,
//       assetId,
//       componentId,
//       dataCellRootId
//     );

//     const parsedData = Object.keys(data).reduce((acc: Record<string, any>, key: string) => {
//       acc[calcInlineLogic(state, key, componentId, '')] = calcInlineLogic(
//         state,
//         data[key],
//         componentId,
//         dataCellRootId
//       );
//       return acc;
//     }, {});

//     state.dbService
//       .update(assetType, calcAssetId, parsedData)
//       .then((data: object) => {
//         dispatch(dbUpdateSuccess({ assetType, uid: calcAssetId, data: parsedData }));
//       })
//       .catch((error: string) => {
//         dispatch(dbUpdateError({ error }));
//       });
//   };
// }

// export function dbDelete(node: any, componentId: string, dataCellRootId: string) {
//   return (dispatch: any, getState: any) => {
//     const state: any = getState();

//     const { assetId, assetType } = node.parameters;

//     const uid = calcInlineLogic(
//       state,
//       assetId,
//       componentId,
//       dataCellRootId
//     );

//     state.dbService
//       .delete(assetType, uid)
//       .then((data: object) => {
//         dispatch(dbDeleteSuccess({ assetType, uid }));
//       })
//       .catch((error: string) => {
//         dispatch(dbDeleteError(error));
//       });
//   };
// }

// export function dbUploadFile(node: any) {
//   return (dispatch: any, getState: any) => {
//     const state: any = getState();

//     const { target, binary } = node.parameters;
//     state.dbService
//       .uploadFile(target, binary)
//       .then((data: object) => {
//         dispatch(dbUploadFileSuccess(node.parameters));
//       })
//       .catch((error: string) => {
//         dispatch(dbUploadFileError(error));
//       });
//   };
// }
