export const types = {
   text: 'text',
   number: 'number',
   boolean: 'boolean',
};

const actions = {
   'action.setComponentData': {
      uid: 'action.setComponentData',
      name: 'Update component data',
      parameters: [
         {
            type: 'custom.componentData',
         },
      ],
   },
   'action.setComponentProperty': {
      uid: 'action.setComponentProperty',
      name: 'set component property',
      parameters: [
         {
            name: 'Component',
            type: 'custom.componentId',
            uid: 'uid',
         },
         {
            name: 'Property',
            uid: 'target',
            type: 'text',
         },
         {
            name: 'Value',
            uid: 'value',
            type: 'text',
         },
      ],
   },
   'action.setGlobalVar': {
      uid: 'action.setGlobalVar',
      name: 'Update global variable',
      parameters: [
         {
            name: 'Variable',
            uid: 'target',
            type: 'text',
         },
         {
            name: 'Value',
            uid: 'value',
            type: 'text',
         },
      ],
   },
   'action.navigate': {
      uid: 'action.navigate',
      name: 'Navigate to screen',
      parameters: [
         {
            name: 'Destination',
            uid: 'target',
            type: 'custom.projectPages',
         },
      ],
   },
   'action.callAPI': {
      uid: 'action.callAPI',
      name: 'Call API',
      parameters: [
         {
            name: 'Method',
            uid: 'method',
            type: 'enum',
            options: ['GET', 'POST', 'PUT', 'DELETE'],
         },
         {
            name: 'URL',
            uid: 'url',
            type: 'text',
         },
         {
            name: 'Body',
            uid: 'body',
            type: 'text',
         },
         {
            name: 'Headers (optional)',
            uid: 'headers',
            optional: true,
            type: 'text',
         },
      ],
   },
   'action.updateComponent': {
      uid: 'action.updateComponent',
      name: 'Update component style',
      parameters: [
         {
            name: 'Design system style',
            type: 'custom.componentStyle',
         },
      ],
   },
   'action.triggerComponentLogic': {
      uid: 'action.triggerComponentLogic',
      name: 'Trigger component logic',
      parameters: [
         {
            type: 'custom.componentLogic',
         },
      ],
   },
   'action.scrollToElement': {
      uid: 'action.scrollToElement',
      name: 'Scroll to element',
      parameters: [
         {
            name: 'Component',
            uid: 'target',
            type: 'custom.componentId',
         },
         // {
         //    name: 'Duration',
         //    uid: 'duration',
         //    type: 'text',
         // },
         // {
         //    name: 'Offset',
         //    uid: 'duration',
         //    type: 'text',
         // },
      ],
   },
   'action.updateSchema': {
      uid: 'action.updateSchema',
      name: 'Update schema',
   },
   'action.dbCreate': {
      uid: 'action.dbCreate',
      name: 'Create database entry',
      parameters: [
         {
            name: 'Table',
            uid: 'assetType',
            type: 'custom.dbCreateDbEntry',
         },
      ],
   },
   'inline-entry.dbRead': {
      uid: 'inline-entry.dbRead',
      name: 'Read database entry',
      parameters: [
         {
            name: 'assetType',
            uid: 'target',
            type: 'custom.dbTableName',
         },
         // {
         //    name: 'URL',
         //    uid: 'value',
         //    type: 'text',
         // },
         // {
         //    name: 'Body',
         //    uid: 'body',
         //    type: 'text',
         // },
         // {
         //    name: 'Headers (optional)',
         //    uid: 'headers',
         //    optional: true,
         //    type: 'text',
         // },
      ],
   },
   'action.dbUpdate': {
      uid: 'action.dbUpdate',
      name: 'Update database entry',
   },
   'action.dbDelete': {
      uid: 'action.dbDelete',
      name: 'Delete database entry',
   },
   'action.dbUploadFile': {
      uid: 'action.dbUploadFile',
      name: 'Upload file to database',
   },
};

export default actions;
