import { Entypo } from '@expo/vector-icons';
import { setGlobalVar } from '@reducers/GlobalsSlice';
import React, { useEffect, useState } from 'react';
import {
   FlatList,
   Modal,
   Dimensions,
   View,
   Text,
   TouchableOpacity,
   Pressable,
   StyleSheet,
} from 'react-native';
import DraggableFlatList, {
   ScaleDecorator,
   NestableScrollContainer,
   NestableDraggableFlatList,
} from 'react-native-draggable-flatlist';
import { useDispatch, useSelector } from 'react-redux';

import { updateComponentProperties } from '../../engine/reducers/LocalDataSlice';
import { setHovered } from '../../store/EditorSlice';
import { deleteComponent, duplicateComponent, updateComponent } from '../../store/ProjectSlice';
import ElementIcon from '../base/ElementIcon';

const LayersTree = ({ componentDef, parentDef }) => {
   // TODO handle right-click to open context menu: Create child, Delete, Duplicate, etc.
   // TODO handle drag and drop to reorder children
   const dispatch = useDispatch();

   const componentId = componentDef?.uid;
   const hovered = useSelector((state) => state.editor.hovered);
   // const selectedKey = useSelector((state) => state.editor.selected);
   const selectedKey = useSelector((state) => state.globals.selected);
   const components = useSelector((state) => state.project.definition.components);
   const selectedComponentId = selectedKey && selectedKey[selectedKey.length - 1];
   const localComponents = useSelector((state) => state.local.components);

   const [showModal, setShowModal] = useState(false);

   const [isExpended, setIsExpended] = useState(!parentDef);

   const duplicatedComponent = useSelector((state) => state.globals.duplicatedComponent);

   useEffect(() => {
      setIsExpended(true);
   }, [selectedKey]);

   try {
      const currentChildren = Object.values(componentDef?.children || {}).sort(
         (a, b) =>
            components[a].custom_styling?.position?.auto?.order -
            components[b].custom_styling?.position?.auto?.order
      );

      const handleDelete = () => {
         dispatch(deleteComponent(parentDef, componentId));
      };

      const handleDuplicate = () => {
         dispatch(setGlobalVar({ key: 'duplicatedComponent', value: undefined }));
         dispatch(setGlobalVar({ key: 'duplicatedComponent', value: componentDef }));
         setShowModal(false);
      };

      const insertDuplicatedComponent = () => {
         dispatch(duplicateComponent(componentId, duplicatedComponent));
         setShowModal(false);
      };

      const hideComponent = (value) => {
         if (componentDef.type == 'modal') {
            const isHidden = localComponents[componentDef.uid]?.properties?.is_hidden;
            dispatch(
               updateComponentProperties({
                  uid: componentDef.uid,
                  key: 'is_hidden',
                  value: !isHidden,
               })
            );
         } else
            dispatch(
               updateComponent(componentDef.composite_key, [
                  { path: ['properties', 'is_hidden'], value },
               ])
            );
         setShowModal(false);
      };

      const [buttonLayout, setButtonLayout] = useState({
         height: 0,
         width: 0,
         top: 0,
         left: 0,
         x: 0,
         y: 0,
      });

      const handleMeasure = (event) => {
         setButtonLayout(event?.nativeEvent?.layout);
      };

      const calculateModalPosition = () => {
         const top =
            buttonLayout.top > Dimensions.get('window').height
               ? Dimensions.get('window').height
               : buttonLayout.top;
         return {
            top: top + 15,
            left: buttonLayout.left + 230,
            backgroundColor: '#ffffff',
         };
      };

      return (
         <View
            onLayout={handleMeasure}
            style={[
               {
                  flex: 1,
                  borderLeftColor: '#50585C',
                  borderLeftWidth: 1,
               },
               hovered === componentId ? styles.container : {},
               selectedComponentId === componentId ? { backgroundColor: '#3C6EEF1A' } : {},
            ]}
            onMouseLeave={() => dispatch(setHovered(null))}>
            <View
               style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
               }}>
               <View
                  style={{
                     flex: 1,
                     flexDirection: 'row',
                     justifyContent: 'flex-start',
                     alignItems: 'center',
                  }}>
                  {currentChildren.length > 0 && (
                     <Pressable
                        style={{ paddingLeft: 10 }}
                        onPress={() => {
                           dispatch(
                              setGlobalVar({ key: 'selected', value: componentDef.composite_key })
                           );
                           dispatch(
                              setGlobalVar({ key: 'selectedDefinition', value: componentDef })
                           );
                           setIsExpended(!isExpended);
                        }}>
                        {isExpended ? (
                           <Entypo name='chevron-thin-up' size={15} color='#4A4756' />
                        ) : (
                           <Entypo name='chevron-thin-down' size={15} color='#4A4756' />
                        )}
                     </Pressable>
                  )}
                  <TouchableOpacity
                     style={[styles.label]}
                     onPress={() => {
                        dispatch(
                           setGlobalVar({ key: 'selected', value: componentDef.composite_key })
                        );
                        dispatch(setGlobalVar({ key: 'selectedDefinition', value: componentDef }));
                        setIsExpended(!isExpended);
                     }}>
                     <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                        <View style={{ paddingLeft: 10 }}>
                           <ElementIcon type={componentDef.type} />
                        </View>
                        <Text
                           style={[
                              { paddingLeft: 10 },
                              selectedComponentId === componentId
                                 ? styles.selected
                                 : styles.labeltext,
                           ]}
                           numberOfLines={1}>
                           {componentDef.name || 'Type name'}
                        </Text>
                     </View>
                  </TouchableOpacity>
               </View>
               {showModal && (
                  <Modal
                     transparent
                     onRequestClose={() => {
                        setShowModal(false);
                     }}
                     visible={showModal}>
                     <View style={[styles.modalContainer, calculateModalPosition()]}>
                        <TouchableOpacity
                           style={{
                              width: 10,
                              height: 10,
                              position: 'absolute',
                              top: 10,
                              right: 10,
                           }}
                           onPress={() => setShowModal(false)}>
                           <svg
                              width='12'
                              height='12'
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                 d='M11 11L1 1M1 11L11 1'
                                 stroke='#757677'
                                 strokeWidth='1.5'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                           </svg>
                        </TouchableOpacity>
                        {duplicatedComponent && (
                           <TouchableOpacity
                              style={[styles.modalButton, { marginTop: 15 }]}
                              onPress={insertDuplicatedComponent}>
                              <View>
                                 <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g clipPath='url(#clip0_2123_36660)'>
                                       <path
                                          d='M6.66602 6.66699V4.33366C6.66602 3.40024 6.66602 2.93353 6.84767 2.57701C7.00746 2.2634 7.26243 2.00844 7.57603 1.84865C7.93255 1.66699 8.39926 1.66699 9.33268 1.66699H15.666C16.5994 1.66699 17.0661 1.66699 17.4227 1.84865C17.7363 2.00844 17.9912 2.2634 18.151 2.57701C18.3327 2.93353 18.3327 3.40024 18.3327 4.33366V10.667C18.3327 11.6004 18.3327 12.0671 18.151 12.4236C17.9912 12.7372 17.7363 12.9922 17.4227 13.152C17.0661 13.3337 16.5994 13.3337 15.666 13.3337H13.3327M4.33268 18.3337H10.666C11.5994 18.3337 12.0661 18.3337 12.4227 18.152C12.7363 17.9922 12.9912 17.7372 13.151 17.4236C13.3327 17.0671 13.3327 16.6004 13.3327 15.667V9.33366C13.3327 8.40024 13.3327 7.93353 13.151 7.57701C12.9912 7.2634 12.7363 7.00844 12.4227 6.84865C12.0661 6.66699 11.5994 6.66699 10.666 6.66699H4.33268C3.39926 6.66699 2.93255 6.66699 2.57603 6.84865C2.26243 7.00844 2.00746 7.2634 1.84767 7.57701C1.66602 7.93353 1.66602 8.40024 1.66602 9.33366V15.667C1.66602 16.6004 1.66602 17.0671 1.84767 17.4236C2.00746 17.7372 2.26243 17.9922 2.57603 18.152C2.93255 18.3337 3.39926 18.3337 4.33268 18.3337Z'
                                          stroke='#50585C'
                                          strokeWidth='1.5'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                       />
                                    </g>
                                    <defs>
                                       <clipPath id='clip0_2123_36660'>
                                          <rect width='20' height='20' fill='white' />
                                       </clipPath>
                                    </defs>
                                 </svg>
                              </View>
                              <Text style={styles.modalText}>Paste here</Text>
                           </TouchableOpacity>
                        )}
                        <TouchableOpacity
                           style={[styles.modalButton, { marginTop: 15 }]}
                           onPress={handleDuplicate}>
                           <View>
                              <svg
                                 width='20'
                                 height='20'
                                 viewBox='0 0 20 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <g clipPath='url(#clip0_2123_36660)'>
                                    <path
                                       d='M6.66602 6.66699V4.33366C6.66602 3.40024 6.66602 2.93353 6.84767 2.57701C7.00746 2.2634 7.26243 2.00844 7.57603 1.84865C7.93255 1.66699 8.39926 1.66699 9.33268 1.66699H15.666C16.5994 1.66699 17.0661 1.66699 17.4227 1.84865C17.7363 2.00844 17.9912 2.2634 18.151 2.57701C18.3327 2.93353 18.3327 3.40024 18.3327 4.33366V10.667C18.3327 11.6004 18.3327 12.0671 18.151 12.4236C17.9912 12.7372 17.7363 12.9922 17.4227 13.152C17.0661 13.3337 16.5994 13.3337 15.666 13.3337H13.3327M4.33268 18.3337H10.666C11.5994 18.3337 12.0661 18.3337 12.4227 18.152C12.7363 17.9922 12.9912 17.7372 13.151 17.4236C13.3327 17.0671 13.3327 16.6004 13.3327 15.667V9.33366C13.3327 8.40024 13.3327 7.93353 13.151 7.57701C12.9912 7.2634 12.7363 7.00844 12.4227 6.84865C12.0661 6.66699 11.5994 6.66699 10.666 6.66699H4.33268C3.39926 6.66699 2.93255 6.66699 2.57603 6.84865C2.26243 7.00844 2.00746 7.2634 1.84767 7.57701C1.66602 7.93353 1.66602 8.40024 1.66602 9.33366V15.667C1.66602 16.6004 1.66602 17.0671 1.84767 17.4236C2.00746 17.7372 2.26243 17.9922 2.57603 18.152C2.93255 18.3337 3.39926 18.3337 4.33268 18.3337Z'
                                       stroke='#50585C'
                                       strokeWidth='1.5'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </g>
                                 <defs>
                                    <clipPath id='clip0_2123_36660'>
                                       <rect width='20' height='20' fill='white' />
                                    </clipPath>
                                 </defs>
                              </svg>
                           </View>
                           <Text style={styles.modalText}>Duplicate</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                           style={styles.modalButton}
                           onPress={() => {
                              hideComponent(componentDef.composite_key, true);
                           }}>
                           <View>
                              <svg
                                 width='18'
                                 height='14'
                                 viewBox='0 0 18 14'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M1.01677 7.59464C0.90328 7.41494 0.846535 7.32509 0.81477 7.18651C0.79091 7.08241 0.79091 6.91824 0.81477 6.81415C0.846535 6.67556 0.90328 6.58571 1.01677 6.40601C1.95461 4.92103 4.74617 1.16699 9.00034 1.16699C13.2545 1.16699 16.0461 4.92103 16.9839 6.40601C17.0974 6.58571 17.1541 6.67556 17.1859 6.81415C17.2098 6.91824 17.2098 7.08241 17.1859 7.18651C17.1541 7.32509 17.0974 7.41494 16.9839 7.59464C16.0461 9.07962 13.2545 12.8337 9.00034 12.8337C4.74617 12.8337 1.95461 9.07963 1.01677 7.59464Z'
                                    stroke='#50585C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                                 <path
                                    d='M9.00034 9.50033C10.381 9.50033 11.5003 8.38104 11.5003 7.00033C11.5003 5.61961 10.381 4.50033 9.00034 4.50033C7.61962 4.50033 6.50034 5.61961 6.50034 7.00033C6.50034 8.38104 7.61962 9.50033 9.00034 9.50033Z'
                                    stroke='#50585C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           </View>
                           <Text style={styles.modalText}>Hide</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.modalButton} onPress={() => {}}>
                           <View>
                              <svg
                                 width='16'
                                 height='18'
                                 viewBox='0 0 16 18'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M12.1673 8.16667V5.66667C12.1673 3.36548 10.3018 1.5 8.00065 1.5C5.69946 1.5 3.83398 3.36548 3.83398 5.66667V8.16667M5.33398 16.5H10.6673C12.0674 16.5 12.7675 16.5 13.3023 16.2275C13.7727 15.9878 14.1552 15.6054 14.3948 15.135C14.6673 14.6002 14.6673 13.9001 14.6673 12.5V12.1667C14.6673 10.7665 14.6673 10.0665 14.3948 9.53169C14.1552 9.06128 13.7727 8.67883 13.3023 8.43915C12.7675 8.16667 12.0674 8.16667 10.6673 8.16667H5.33398C3.93385 8.16667 3.23379 8.16667 2.69901 8.43915C2.2286 8.67883 1.84615 9.06128 1.60647 9.53169C1.33398 10.0665 1.33398 10.7665 1.33398 12.1667V12.5C1.33398 13.9001 1.33398 14.6002 1.60647 15.135C1.84615 15.6054 2.2286 15.9878 2.69901 16.2275C3.23379 16.5 3.93385 16.5 5.33398 16.5Z'
                                    stroke='#50585C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           </View>
                           <Text style={styles.modalText}>Lock</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.modalButton} onPress={handleDelete}>
                           <View>
                              <svg
                                 width='18'
                                 height='18'
                                 viewBox='0 0 18 18'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M6.5 1.5H11.5M1.5 4H16.5M14.8333 4L14.2489 12.7661C14.1612 14.0813 14.1174 14.7389 13.8333 15.2375C13.5833 15.6765 13.206 16.0294 12.7514 16.2497C12.235 16.5 11.5759 16.5 10.2578 16.5H7.74221C6.42409 16.5 5.76503 16.5 5.24861 16.2497C4.79396 16.0294 4.41674 15.6765 4.16665 15.2375C3.88259 14.7389 3.83875 14.0813 3.75107 12.7661L3.16667 4M7.33333 7.75V11.9167M10.6667 7.75V11.9167'
                                    stroke='#D15A5A'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           </View>
                           <Text style={[styles.modalText, { color: '#D15A5A' }]}>
                              Delete Component
                           </Text>
                        </TouchableOpacity>
                     </View>
                  </Modal>
               )}
               <View
                  style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  {components[componentId]?.properties?.is_hidden && (
                     <TouchableOpacity
                        style={[styles.modalButton, { marginRight: 10 }]}
                        onPress={() => {
                           hideComponent(false);
                        }}>
                        <View>
                           <svg
                              width='18'
                              height='14'
                              viewBox='0 0 18 14'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                 d='M1.01677 7.59464C0.90328 7.41494 0.846535 7.32509 0.81477 7.18651C0.79091 7.08241 0.79091 6.91824 0.81477 6.81415C0.846535 6.67556 0.90328 6.58571 1.01677 6.40601C1.95461 4.92103 4.74617 1.16699 9.00034 1.16699C13.2545 1.16699 16.0461 4.92103 16.9839 6.40601C17.0974 6.58571 17.1541 6.67556 17.1859 6.81415C17.2098 6.91824 17.2098 7.08241 17.1859 7.18651C17.1541 7.32509 17.0974 7.41494 16.9839 7.59464C16.0461 9.07962 13.2545 12.8337 9.00034 12.8337C4.74617 12.8337 1.95461 9.07963 1.01677 7.59464Z'
                                 stroke='#50585C'
                                 strokeWidth='1.5'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                              <path
                                 d='M9.00034 9.50033C10.381 9.50033 11.5003 8.38104 11.5003 7.00033C11.5003 5.61961 10.381 4.50033 9.00034 4.50033C7.61962 4.50033 6.50034 5.61961 6.50034 7.00033C6.50034 8.38104 7.61962 9.50033 9.00034 9.50033Z'
                                 stroke='#50585C'
                                 strokeWidth='1.5'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                           </svg>
                        </View>
                     </TouchableOpacity>
                  )}
                  {selectedComponentId === componentId && (
                     <Pressable style={{ paddingRight: 15 }} onPress={() => setShowModal(true)}>
                        <svg
                           width='12'
                           height='4'
                           viewBox='0 0 12 4'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M5.99967 2.58366C6.32184 2.58366 6.58301 2.32249 6.58301 2.00033C6.58301 1.67816 6.32184 1.41699 5.99967 1.41699C5.67751 1.41699 5.41634 1.67816 5.41634 2.00033C5.41634 2.32249 5.67751 2.58366 5.99967 2.58366Z'
                              stroke='#646D72'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                           <path
                              d='M10.083 2.58366C10.4052 2.58366 10.6663 2.32249 10.6663 2.00033C10.6663 1.67816 10.4052 1.41699 10.083 1.41699C9.76084 1.41699 9.49968 1.67816 9.49968 2.00033C9.49968 2.32249 9.76084 2.58366 10.083 2.58366Z'
                              stroke='#646D72'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                           <path
                              d='M1.91634 2.58366C2.23851 2.58366 2.49967 2.32249 2.49967 2.00033C2.49967 1.67816 2.23851 1.41699 1.91634 1.41699C1.59418 1.41699 1.33301 1.67816 1.33301 2.00033C1.33301 2.32249 1.59418 2.58366 1.91634 2.58366Z'
                              stroke='#646D72'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     </Pressable>
                  )}
               </View>
            </View>
            {isExpended && (
               <>
                  {selectedKey && selectedKey.includes(componentId) && (
                     <NestableDraggableFlatList
                        keyExtractor={(item) => item}
                        style={styles.list}
                        data={currentChildren}
                        renderItem={({ item }) => {
                           return (
                              <LayersTree
                                 componentDef={components[item]}
                                 parentDef={componentDef}
                                 key={item}
                              />
                           );
                        }}
                     />
                  )}
               </>
            )}
         </View>
      );
   } catch (e) {
      console.error(e);
      console.error('componentId', componentId);
      return null;
   }
};

export default LayersTree;

const styles = StyleSheet.create({
   modalButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
   },
   modalContainer: {
      width: 248,
      padding: 10,
      borderColor: '#f5f6f8',
      borderWidth: 2,
   },
   modalText: {
      fontFamily: 'Inter',
      color: '#50585C',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      paddingLeft: 20,
   },
   container: {
      backgroundColor: '#F5F7F9',
   },
   paddingBottom: {
      height: 3,
   },
   list: {
      marginLeft: 8,
   },
   label: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexShrink: 1,
      height: 30,
   },
   labeltext: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 12,
      color: '#4A4756',
      alignItems: 'center',
   },
   selected: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 12,
      color: '#4A4756',
      alignItems: 'center',
   },
});
