import React from 'react';
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { setTable } from '../../store/DatabaseSlice';

const TableItem = ({item, selected}) => {
   const [isHover, setIsHover] = React.useState(false);
   const dispatch = useDispatch();
   const database = useSelector((state) => state.database);
   const definition = useSelector((state) => state.project.definition);

   const src = item.tableName.id;
   const value = item.tableName.name;

   const currentModel = definition.data_model[src].named_properties;

   return (
      <TouchableOpacity
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => setIsHover(false)}
         onPress={() => {
            if (src !== database.currentTable.id) {
               dispatch(setTable({ id: src, name: value }));
            }
         }}
         style={[styles.mainContainer,
            isHover ? styles.item_hover : styles.item,
            selected ? styles.item_hover : undefined,
            ]}>
         <Text>{value}</Text>
         { (!currentModel || Object.keys(currentModel).length < 4) && 
            <View style={styles.newButton}/>
         }
      </TouchableOpacity>
   );
};

export default TableItem;

const styles = StyleSheet.create({
   mainContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
   },
   newButton: {
      backgroundColor: '#2B55B7',
      width: 8,
      height: 8,
      borderRadius: 16
   },
   item: {
      color: '#50585C',
      paddingHorizontal: '20px',
      paddingVertical: '8px',
      height: '40px',
      cursor: 'pointer',
   },
   item_hover: {
      backgroundColor: '#EFF2F9',
      cursor: 'pointer',

      fontSize: '14px',
      lineHeight: '130%',
      color: '#50585C',
      paddingHorizontal: '20px',
      paddingVertical: '8px',
      height: '40px',
   },
   text: {
      fontSize: '14px',
      lineHeight: '18.2px',
   },
});
