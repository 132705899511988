const colours: Record<string, string> = {
   "aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
    "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
    "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
    "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
    "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
    "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
    "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
    "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
    "honeydew":"#f0fff0","hotpink":"#ff69b4",
    "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
    "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
    "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
    "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
    "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
    "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
    "navajowhite":"#ffdead","navy":"#000080",
    "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
    "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
    "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
    "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
    "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
    "violet":"#ee82ee",
    "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
    "yellow":"#ffff00","yellowgreen":"#9acd32"
}

export const colourValues = (colour: string = ''): [number, number, number, number] => {
   const values = _colourValues(colour)
   const checks = values.map((value, i) => {
      if (i === 3) {
         return value <= 1 && value >= 0
      }
      return value <= 255 && value >= 0
   })
   if (checks.reduce((acc, value) => acc && value, true)) {
      return values
   }
   // Problem
   console.error('Error formatting colour to rgba values', colour, values, checks)
   return values.map((value, i) => {
      if (i === 3) {
         if (value > 1) return 1
         if (value < 0) return 0
         return value
      }
      if (value > 255) return 255
      if (value < 0) return 0
      return value
   })
}
const _colourValues = (colour: string = ''): [number, number, number, number] => {
   if (colour === '') return [255, 255, 255, 1];
   colour = colour.toLowerCase()
   if (colour === 'transparent') return [0, 0, 0, 0];
   if (colour in colours) {
      colour = colours[colour]
   }
   if (colour[0] === '#') {
       if (colour.length < 7) {
           // Convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
           colour = '#' + colour[1] + colour[1] + colour[2] + colour[2] + colour[3] + colour[3] + (colour.length > 4 ? colour[4] + colour[4] : '');
       }
       return [
           parseInt(colour.substring(1, 3), 16),
           parseInt(colour.substring(3, 5), 16),
           parseInt(colour.substring(5, 7), 16),
           colour.length > 7 ? parseInt(colour.substring(7), 16) / 255 : 1
       ];
   }
   if (colour.indexOf('rgb') === 0) {
      if (colour.indexOf('rgba') === -1) colour += ',1'; // Convert 'rgb(R,G,B)' to 'rgb(R,G,B)A'
      const values = (colour.match(/[\\.\\d]+/g) ?? []).map(function (a) {
         return +a;
      });
      if (values.length === 4) {
         return values as [number, number, number, number]
      }
   }
   return [255, 255, 255, 1];
}

export const rgbaToColour = (rgbaValues: [number, number, number, number]) => {
   return '#' + rgbaValues.map((number, i) => {
      let hex = (i === 3 ? Math.round(number * 255): number).toString(16)
      while (hex.length < 2) {
         hex = "0" + hex;
     }
     return hex
   }).join('')
}

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;

const GAMMA = 2.4;

function luminance(r: number, g: number, b: number, t: number) {
  var a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

export const contrast = (colour1: string, colour2: string) => {
   const colourValues1: [number, number, number, number] = colourValues(colour1)
   const colourValues2: [number, number, number, number] = colourValues(colour2)
   const lum1 = luminance(...colourValues1);
   const lum2 = luminance(...colourValues2);
   const brightest = Math.max(lum1, lum2);
   const darkest = Math.min(lum1, lum2);
   return (brightest + 0.05) / (darkest + 0.05);
}

/**
 * Shift the colour towards white [default] or black
 * @param colour 
 * @param ratio number between 0 and 1
 */
export const colourRatio = (colour: string, ratio: number, towardsWhite: boolean = true) => {
   const rgbaValues = colourValues(colour)
   const outputValues = rgbaValues.map((value, i) => {
      if (i === 3) return value
      if (towardsWhite) {
         return 255 - ((255 - i) * ratio)
      }
      return i * ratio
   }) as [number, number, number, number]
   return rgbaToColour(outputValues)
}

/**
 * Shift a color towards another colour, by a given ratio
 * @param colour starting colour
 * @param targetColour target colour
 * @param skewRatio how much of the target should we skew the original colour
 */
export const colourSkew = (colour: string, targetColour: string, skewRatio: number) => {
   const rgbaValues = colourValues(colour)
   console.log('rgba:', rgbaValues)
   const rgbaTarget = colourValues(targetColour)
   console.log('rgba target:', rgbaTarget)
   const outputValues = rgbaValues.map((value, i) => {
      if (i === 3) return value
      const targetValue = rgbaTarget[i]
      if (value > targetValue) {
         return Math.round(value - ((value - targetValue) * skewRatio))
      }
      return Math.round(value + ((targetValue - value) * skewRatio))
   }) as [number, number, number, number]
   console.log('output:\n', outputValues)
   return rgbaToColour(outputValues)
}