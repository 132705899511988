import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TextInput, Picker, Modal, Pressable, TouchableWithoutFeedback} from 'react-native';
import OutsidePressHandler from 'react-native-outside-press';
import AutoCompleteInput from './AutoCompleteInput';
import ExpandableAutoCompleteInput from './ExpandableAutoCompleteInput';

/**
 * 
 * @param {
      label?: string,
      placeholder?: string,
      pickedValue?: string | { label: string, value: string },
      pickerOptions?: Array<string | { label: string, value: string }>,
      handlePickerUpdate?: (value: string) => void,
      value?: string,
      handleValueUpdate?: (value: string) => void,
      unit?: string,
      unitOptions?: Array<string>,
      handleUnitUpdate?: (value: string) => void,
      style?: any,
      pickerStyle?: any,
      textStyle?: any,
      unitStyle?: any,
      placeholderStyle?: any,
      tabIndex?: number,
 * } props
 * @returns 
 */
const FieldWithUnit = ({
   label,
   placeholder,
   handleSubmit,
   pickedValue,
   pickerOptions,
   handlePickerUpdate,
   value,
   handleValueUpdate,
   unit,
   unitOptions,
   handleUnitUpdate,
   style,
   pickerStyle,
   textStyle,
   unitStyle,
   placeholderStyle,
   tabIndex = 0,
   onBlur
}) => {
   const [focused, setFocused] = useState(false);
   const [editUnit, setEditUnit] = useState(false);
   
   return (
      <TouchableWithoutFeedback style={styles.container} onPress={() => {
         setEditUnit(false);
         setFocused(false);
      }}>
         <View style={{flexDirection: 'column'}}>
            {label && <Text style={styles.label}>{label}</Text>}
            <View style={[styles.inputRow, style || {}]}>
               {/* Icon here */}
               {pickerOptions && handlePickerUpdate && (
                  <AutoCompleteInput
                     data={pickerOptions}
                     onBlur={onBlur}
                     onSelect={(item) =>
                        handlePickerUpdate(typeof item === 'object' ? item.value : item)
                     }
                     value={pickedValue}
                     placeholder={placeholder || label || ''}
                     textStyles={[
                        styles.input,
                        textStyle || {},
                        focused ? styles.active : styles.inactive,
                     ]}
                     placeholderStyle={placeholderStyle}
                  />
               )}
               {handleValueUpdate && (
                  <TextInput
                     tabIndex={tabIndex}
                     style={[styles.input, textStyle || {}, focused ? styles.active : styles.inactive]}
                     value={value || ''}
                     placeholder={placeholder || label || ''}
                     onFocus={() => setFocused(true)}
                     onBlur={() => {
                        if(onBlur) {           
                           onBlur();
                        }
                        
                        setFocused(false)
                     }}
                     onChangeText={(val) => {
                        handleValueUpdate(val);
                     }}
                     onSubmitEditing={() => handleSubmit ? handleSubmit(value ?? '') : () => {}}
                  />
               )}
               {unitOptions && (
                  <Pressable onPress={() => setEditUnit(true)}>
                     <Text style={[styles.unit, unitStyle || {}]}>{unit}</Text>
                  </Pressable>
               )}
               {/* Unit editor Modal */}
               {editUnit && (
                  <View style={styles.unitPicker}>
                     {unitOptions.map((option, i) => (
                        <Pressable onPress={() => handleUnitUpdate(option)} style={styles.unit}>
                           <Text style={[styles.unitText, unitStyle || {}]}>{option}</Text>
                        </Pressable>
                     ))}
                  </View>
               )}
            </View>
         </View>
      </TouchableWithoutFeedback>
   );
};

export default FieldWithUnit;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      flexDirection: 'column',
      flexShrink: 0,
      maxWidth: '100%',
      // alignItems: 'flex-start',
      // justifyContent: 'flex-start',
      gap: 4,
   },
   label: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontHeight: 400,
      lineHeight: '135%',
   },
   inputRow: {
      flexShrink: 0,
      flexDirection: 'row',
      gap: 8,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E0E4F4',
      borderStyle: 'solid',
      backgroundColor: '#FFF',
   },
   input: {
      width: '100%',
      flexShrink: 0,
      paddingHorizontal: 8,
      paddingVertical: 4,
      // overflow: 'hidden',
      color: '#646D72',
      textOverflow: 'ellipsis',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '135%',
   },
   unit: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      flex: 0,
   },
   unitText: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '135%',
   },
   active: {
      borderColor: 'blue',
   },
   inactive: {},
});
