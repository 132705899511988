import React, { useState } from 'react';
import { KeyboardAvoidingView, Text, View, TextInput, Pressable, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { login, register } from '../store/UserSlice';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const handleLogin = () => { dispatch(login(email, password)) };

    const handleRegister = () => { dispatch(register(email, password)) };

    return (
        <KeyboardAvoidingView behavior="padding" style={styles.container}>
            <Text accessibilityRole="header" accessibilityLabel="Login Screen" style={styles.title}>Login Screen</Text>
            <TextInput
                style={styles.input}
                placeholder="Email"
                autoFocus
                keyboardType="email-address"
                value={email}
                onChangeText={setEmail}
            />
            <TextInput
                style={styles.input}
                placeholder="Password"
                secureTextEntry
                value={password}
                onChangeText={setPassword}
            />
            <Pressable style={styles.login} onPress={handleLogin}>
                <Text style={{ color: 'white' }}>Login</Text>
            </Pressable>
            <Pressable style={styles.register} onPress={handleRegister}>
                <Text>Register</Text>
            </Pressable>
            <View style={{ height: 100 }}></View>
        </KeyboardAvoidingView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
        padding: 20,
    },
    input: {
        width: '100%',
        maxWidth: 300,
        borderBottomWidth: 1,
        borderColor: '#ccc',
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 6,
    },
    login: {
        backgroundColor: 'rgb(33, 150, 243)',
        width: '100%',
        maxWidth: 200,
        marginTop: 10,
        padding: 8,
        borderRadius: 4,
        alignItems: 'center',
    },
    register: {
        borderColor: 'rgb(33, 150, 243)',
        borderWidth: 1,
        width: '100%',
        maxWidth: 200,
        marginTop: 10,
        padding: 8,
        borderRadius: 4,
        alignItems: 'center',
    },
    buttontext: {
        color: 'white'
    }
});

export default LoginScreen;
