import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Picker, Image, TouchableOpacity } from 'react-native';

import DefaultInputContainer from '../base/DefaultInputContainer';
import ImagePicker from '../componentsLibrary/utils/ImagePicker';

const Background = ({ rootHandleElementUpdate, componentDef, styles, handleUpdate }) => {
   const [backgroundType, setBackgroundType] = useState(
      componentDef[rootHandleElementUpdate]?.background?.type || 'none'
   );

   const [backgroundColorValue, setBackgroundColorValue] = useState(
      componentDef[rootHandleElementUpdate]?.background?.color || ''
   );

   useEffect(() => {
      setBackgroundColorValue(componentDef[rootHandleElementUpdate]?.background?.color);
   }, [componentDef[rootHandleElementUpdate]?.background?.color]);

   useEffect(() => {
      setBackgroundType(componentDef[rootHandleElementUpdate]?.background?.type || 'none');
   }, [componentDef[rootHandleElementUpdate]?.background?.type]);

   const changeBackgroundColor = (color) => {
      const value = color.hasOwnProperty('hex') ? color.hex : color;
      setBackgroundColorValue(value);
      handleUpdate([{ path: [rootHandleElementUpdate, 'background', 'color'], value }]);
   };

   const [expandLayout, setExpandLayout] = useState(true);

   const background = () => {
      return (
         <View style={{ flex: 1 }}>
            <View style={layoutStyles.layoutButtons}>
               <TouchableOpacity
                  onPress={() => {
                     setBackgroundType('none');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'background', 'type'], value: 'none' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     backgroundType == 'none'
                        ? layoutStyles.layoutButtonSelected
                        : layoutStyles.layoutButton,
                  ]}>
                  <Text
                     style={
                        backgroundType == 'none'
                           ? layoutStyles.selectedText
                           : layoutStyles.textStyle
                     }>
                     None
                  </Text>
               </TouchableOpacity>
               <TouchableOpacity
                  onPress={() => {
                     setBackgroundType('color');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'background', 'type'], value: 'color' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     backgroundType == 'color'
                        ? layoutStyles.layoutButtonSelected
                        : layoutStyles.layoutButton,
                  ]}>
                  <Text
                     style={
                        backgroundType == 'color'
                           ? layoutStyles.selectedText
                           : layoutStyles.textStyle
                     }>
                     Color
                  </Text>
               </TouchableOpacity>
               <TouchableOpacity
                  onPress={() => {
                     setBackgroundType('image');
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'background', 'type'], value: 'image' },
                     ]);
                  }}
                  style={[
                     { height: 30 },
                     backgroundType == 'image'
                        ? layoutStyles.layoutButtonSelected
                        : layoutStyles.layoutButton,
                  ]}>
                  <Text
                     style={
                        backgroundType == 'image'
                           ? layoutStyles.selectedText
                           : layoutStyles.textStyle
                     }>
                     Image
                  </Text>
               </TouchableOpacity>
            </View>
         </View>
      );
   };

   return (
      <View>
         <View style={styles.row}>
            <View style={{ flexDirection: 'column', flex: 1, paddingHorizontal: 20 }}>
               <View style={layoutStyles.expandContainer}>
                  <Text style={[layoutStyles.spacesAndSizesText]}>Background</Text>
                  <TouchableOpacity
                     onPress={() => setExpandLayout(!expandLayout)}
                     style={{ alignItems: 'center', justifyContent: 'center' }}>
                     {expandLayout ? (
                        <Entypo name='chevron-thin-down' size={24} color='#93989B' />
                     ) : (
                        <Entypo name='chevron-thin-up' size={24} color='#93989B' />
                     )}
                  </TouchableOpacity>
               </View>
               {expandLayout && <View>{background()}</View>}
               {backgroundType === 'color' && (
                  <View style={[styles.rowStyling]}>
                     <DefaultInputContainer
                        style={{borderWidth: 1, borderColor: '#E0E4F4',}}
                        componentDef={componentDef}
                        onChangeText={changeBackgroundColor}
                        value={backgroundColorValue}
                        placeholder='Select color'
                        isColor
                        childLeft={
                           <View
                              style={{
                                 width: 34,
                                 height: 30,
                                 borderRadius: 4,
                                 backgroundColor:
                                    componentDef[rootHandleElementUpdate]?.background?.color,
                              }}
                           />
                        }
                     />
                  </View>
               )}
               {backgroundType === 'image' && (
                  <View style={[styles.rowStyling]}>
                     <ImagePicker
                        imageURI={componentDef[rootHandleElementUpdate]?.background?.image?.src}
                        onImageChange={(value) =>
                           handleUpdate([
                              {
                                 path: [rootHandleElementUpdate, 'background', 'image', 'src'],
                                 value,
                              },
                           ])
                        }
                     />
                  </View>
               )}
            </View>
         </View>
      </View>
   );
};

export default Background;

const layoutStyles = StyleSheet.create({
   container: {
      flex: 1,
      justifyContent: 'center',
   },
   rowStyling: {
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 8,
      paddingHorizontal: 15,
   },
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   },
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10,
   },
   layoutButtons: {
      width: 260,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
   },
   layoutButton: {
      flex: 1,
      width: 86,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   layoutButtonSelected: {
      flex: 1,
      width: 86,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#7B87A6',
   },
   textStyle: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      textAlign: 'center',
      color: '#646D72',
   },
   selectedText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      textAlign: 'center',
      color: '#F5F9FF',
   },
});
