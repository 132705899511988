import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TextInput, View, Picker } from 'react-native';
import uuid from 'react-native-uuid';

const AddingDataElement = ({ item, currId, style }) => {
    const project = useSelector((state) => state.project);
    const database = useSelector((state) => state.database);

    const {id: currentId, type: currentType, name: currentValue, default: currentDefaultValue} = item;

    const [inputValues, setInputValues] = useState({
        id: currentId,
        name: currentValue,
        type: currentType,
        default: currentDefaultValue,
    });

    const handleInputChange = (type, value) => {
        setInputValues((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    const changeValues = (field, value) => {
        const isCreating = currentValue === '' && currentDefaultValue === '' && currentType === '';

        if (isCreating) {
                project.create(
                    `/projects/${project.projectId}/dev/data_model/${
                        database.currentTable.id
                    }/named_properties/${currId}/`,
                    {
                        ...inputValues
                    }
                );
        } else {
            project.update(
                `/projects/${project.projectId}/dev/data_model/${
                    database.currentTable.id
                }/named_properties/${currId}/`,
                {
                    [field]: value
                }
            );
        }
    };

    return (
        <View style={style}>
            <Picker
                style={style.inner}
                selectedValue={inputValues.type}
                onValueChange={(value) => {
                    handleInputChange('type', value);
                    changeValues('type', value);
                }}>
                    {/*
                    none
                    text
                    decimal
                    numeric
                    tel
                    search
                    email
                    url
                    */}
                <Picker.Item label='Text' value='text' />
                <Picker.Item label='Number' value='number' />
                <Picker.Item label='Boolean' value='fixed' />
                <Picker.Item label='Object' value='object' />
                <Picker.Item label='Password' value='password' />
                <Picker.Item label='Image' value='image' />
                <Picker.Item label='DB reference' value='dbRef' />
                <Picker.Item label='Date' value='date' />
                <Picker.Item label='Time' value='time' />
                <Picker.Item label='DateTime' value='datetime' />
                <Picker.Item label='Address' value='address' />
                <Picker.Item label='Email' value='email' />
                <Picker.Item label='Phone' value='phone' />
                <Picker.Item label='Url' value='url' />
            </Picker>
            <TextInput
                placeholder='Name'
                onChangeText={(value) => handleInputChange('name', value)}
                value={inputValues.name}
                onBlur={(input) => changeValues('name', input.currentTarget.value)}
                style={style.inner}
            />
            <TextInput
                placeholder='Default Value'
                onChangeText={(value) => handleInputChange('default', value)}
                value={inputValues.default}
                onBlur={(input) => changeValues('default', input.currentTarget.value)}
                style={style.inner}
            />
        </View>
    );
};

export default AddingDataElement;
