const inlineEntrys = {
    'Data sources': {
        title: 'Data sources',
        sources: {
            'inline-entry.CurrentUser': {
                uid: 'inline-entry.CurrentUser',
                name: 'Current user',
                parameters: [
                    {
                        type: 'custom.getCurrentUser',
                    },
                ],
                next: 'custom.operators',
            },
            'inline-entry.DoASearchFor': {
                uid: 'inline-entry.DoASearchFor',
                name: 'Do a search for',
                parameters: [
                    {
                        type: 'custom.DoASearchFor',
                    },
                ],
                next: 'custom.operators',
            },
            'inline-entry.GetAnOption': {
                uid: 'inline-entry.GetAnOption',
                name: 'Get An Option',
                parameters: [
                    {
                    type: 'custom.GetAnOption',
                    name: 'Literal',
                    uid: 'value',
                    },
                ],
                next: 'custom.operators',
            },
            'inline-entry.ArbitraryText': {
                uid: 'inline-entry.ArbitraryText',
                name: 'CurrentUser',
                parameters: [
                    {
                    name: 'CurrentUser',
                    uid: 'target',
                    type: 'custom.ArbitraryText',
                    },
                ],
                next: 'custom.operators',
            },
            'inline-entry.TextContition': {
                uid: 'inline-entry.TextContition',
                name: 'TextContition',
                parameters: [
                    {
                    name: 'Text contition',
                    uid: 'target',
                    type: 'custom.TextContition',
                    },
                ],
                next: 'custom.operators',
            },
            'inline-entry.GetDataFromExternalAPI': {
                uid: 'inline-entry.GetDataFromExternalAPI',
                name: 'Get data from external API',
                parameters: [
                    {
                        uid: 'target',
                        name: 'Page data',
                        type: 'custom.pageData',
                    },
                ],
                next: 'custom.operators',
            },
        },
    },
    'Elements': {
        title: 'Elements',
        sources: 'custom.Components',
        uid: 'inline-entry.GetComponent',
        next: 'custom.operators',
    },
    'Breakpoints on this page': {
        title: 'Breakpoints on this page',
        next: 'custom.operators',
        sources: {
            'Current page default breakpoint': {
                uid: 'inline-entry.CurrentPageDefaultBreakpoint',
                name: 'Current page default breakpoint',
            },
        },
    },
    'All other breakpoints': {
        title: 'All other breakpoints',
        sources: {
            'Mobile (320 px)': {
                uid: 320,
                name: 'Mobile (320 px)',
            },
            'Mobile Landscape(768 px)': {
                uid: 768,
                name: 'Mobile Landscape(768 px)',
            },
            'Tablet (992 px)': {
                uid: 992,
                name: 'Tablet (992 px)',
            },
            'Desktop (992 px)': {
                name: 992,
                name: 'Desktop (992 px)',
            }
        },
        next: 'custom.operators',
    },
    'Other data sources': {
        title: 'Other data sources',
        sources: {
            'Website Home URL': {   
                uid: 'inline-entry.WebsiteHomeURL',
                name: 'Website Home URL',
                parameters: [
                    {
                        type: 'custom.operators',
                    },
                ],
                next: 'custom.operators',
            },
            'This URL': {   
                uid: 'inline-entry.ThisURL',
                name: 'This URL',
                parameters: [
                    {
                        type: 'custom.operators',
                    },
                ],
                next: 'custom.operators',
            },
            'Current page width': {   
                uid: 'inline-entry.CurrentPageWidth',
                name: 'Current page width',
                parameters: [
                    {
                        type: 'custom.operators',
                    },
                ],
                next: 'custom.operators',
            },
            'Current page width': {   
                uid: 'inline-entry.Current page scrolling ',
                name: 'Current page width',
                parameters: [
                    {
                        type: 'custom.operators',
                    },
                ],
                next: 'custom.operators',
            },
        },
    },
};

export default inlineEntrys;
 