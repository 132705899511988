import { Text, StyleSheet, View} from 'react-native';
import SortButton from '../DatabaseSection/base/SortButton';

const HeaderItem = ({ text }) => {
   return (
      <View style={styles.container}>
         <Text style={styles.header}>{text}</Text>
         <SortButton style={{paddingLeft: 10}}/>
      </View>
      );
};

export default HeaderItem;

const styles = StyleSheet.create({
   container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F5F6F8',
      padding: 12,
      flex: 1,
      border: '1px solid #E0E4F4',
   },
   header: {
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: 14,
      lineHeight: 18,
   },
});
