import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import FieldWithUnit from './FieldWithUnit';
import ExpandableContainer from '../base/ExpandableContainer';

const SpacesAndSizes = ({ rootHandleElementUpdate, componentDef, handleUpdate }) => {
   const [linkMarginsAndPaddings, setLinkMarginsAndPaddings] = useState(false);

   const changeAllMargins = (value) => {
      handleUpdate([
         { path: [rootHandleElementUpdate, 'margin', 'top'], value: { val: value, unit: 'px' } },
         { path: [rootHandleElementUpdate, 'margin', 'left'], value: { val: value, unit: 'px' } },
         { path: [rootHandleElementUpdate, 'margin', 'bottom'], value: { val: value, unit: 'px' } },
         { path: [rootHandleElementUpdate, 'margin', 'right'], value: { val: value, unit: 'px' } },
      ]);
   };

   const changeAllPaddings = (value) => {
      handleUpdate([
         { path: [rootHandleElementUpdate, 'padding', 'top'], value: { val: value, unit: 'px' } },
         { path: [rootHandleElementUpdate, 'padding', 'left'], value: { val: value, unit: 'px' } },
         {
            path: [rootHandleElementUpdate, 'padding', 'bottom'],
            value: { val: value, unit: 'px' },
         },
         { path: [rootHandleElementUpdate, 'padding', 'right'], value: { val: value, unit: 'px' } },
      ]);
   };

   const [paddingUnified, setPaddingType] = useState(
      componentDef[rootHandleElementUpdate]?.padding?.type !== 'individual'
   );
   const [borderUnified, setBorderType] = useState(
      componentDef[rootHandleElementUpdate]?.border?.type !== 'individual'
   );
   const [marginUnified, setMarginType] = useState(
      componentDef[rootHandleElementUpdate]?.margin?.type !== 'individual'
   );

   const [focused, setFocused] = useState('');

   useEffect(() => {
      setPaddingType(componentDef[rootHandleElementUpdate]?.padding?.type !== 'individual');
   }, [componentDef[rootHandleElementUpdate]?.padding?.type]);
   useEffect(() => {
      setBorderType(componentDef[rootHandleElementUpdate]?.border?.type !== 'individual');
   }, [componentDef[rootHandleElementUpdate]?.border?.type]);
   useEffect(() => {
      setMarginType(componentDef[rootHandleElementUpdate]?.margin?.type !== 'individual');
   }, [componentDef[rootHandleElementUpdate]?.margin?.type]);

   const spacingAndSizes = () => {
      return (
         <View style={{ flex: 1 }}>
            <View style={styles.marginsContainer}>
               <View style={styles.innerBordersContainer}>
                  <Text style={styles.marginText}>Margin</Text>
               </View>
               <View style={[styles.marginTopText, { height: 35 }]}>
                  <FieldWithUnit
                     tabIndex={2}
                     placeholder='top'
                     active={!marginUnified}
                     value={componentDef[rootHandleElementUpdate]?.margin?.top?.val}
                     unit={componentDef[rootHandleElementUpdate]?.margin?.top?.unit}
                     handleValueUpdate={(value) => {
                        if (linkMarginsAndPaddings) changeAllMargins(value);
                        else
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'margin', 'top', 'val'], value },
                           ]);
                     }}
                     handleUnitUpdate={(value) =>
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'margin', 'top', 'unit'], value },
                        ])
                     }
                     setFocused={() => setFocused('margin-top')}
                     focused={focused === 'margin-top'}
                  />
               </View>
               <View style={styles.marginInnerContainer}>
                  <View style={[styles.marginTopText, { width: 50 }]}>
                     <FieldWithUnit
                        tabIndex={2}
                        placeholder='left'
                        active={!marginUnified}
                        value={componentDef[rootHandleElementUpdate]?.margin?.left?.val}
                        unit={componentDef[rootHandleElementUpdate]?.margin?.left?.unit}
                        handleValueUpdate={(value) => {
                           if (linkMarginsAndPaddings) changeAllMargins(value);
                           else
                              handleUpdate([
                                 {
                                    path: [rootHandleElementUpdate, 'margin', 'left', 'val'],
                                    value,
                                 },
                              ]);
                        }}
                        handleUnitUpdate={(value) =>
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'margin', 'left', 'unit'], value },
                           ])
                        }
                        setFocused={() => setFocused('margin-left')}
                        focused={focused === 'margin-left'}
                     />
                  </View>
                  <View style={styles.paddingsContainer}>
                     <View style={styles.innerBordersContainer}>
                        <Text style={styles.marginText}>Padding</Text>
                     </View>
                     <View style={styles.paddingTopContainer}>
                        <View style={[styles.marginTopText]}>
                           <FieldWithUnit
                              tabIndex={4}
                              placeholder='top'
                              style={styles.centered}
                              active={!paddingUnified}
                              value={componentDef[rootHandleElementUpdate]?.padding?.top?.val}
                              unit={componentDef[rootHandleElementUpdate]?.padding?.top?.unit}
                              handleValueUpdate={(value) => {
                                 if (linkMarginsAndPaddings) changeAllPaddings(value);
                                 else
                                    handleUpdate([
                                       {
                                          path: [rootHandleElementUpdate, 'padding', 'top', 'val'],
                                          value,
                                       },
                                    ]);
                              }}
                              handleUnitUpdate={(value) =>
                                 handleUpdate([
                                    {
                                       path: [rootHandleElementUpdate, 'padding', 'top', 'unit'],
                                       value,
                                    },
                                 ])
                              }
                              setFocused={() => setFocused('padding-top')}
                              focused={focused === 'padding-top'}
                           />
                        </View>
                     </View>
                     <View style={styles.paddingInnerContainer}>
                        <View style={[styles.marginTopText, { flex: 1 }]}>
                           <FieldWithUnit
                              tabIndex={4}
                              placeholder='left'
                              active={!paddingUnified}
                              value={componentDef[rootHandleElementUpdate]?.padding?.left?.val}
                              unit={componentDef[rootHandleElementUpdate]?.padding?.left?.unit}
                              handleValueUpdate={(value) => {
                                 if (linkMarginsAndPaddings) changeAllPaddings(value);
                                 else
                                    handleUpdate([
                                       {
                                          path: [rootHandleElementUpdate, 'padding', 'left', 'val'],
                                          value,
                                       },
                                    ]);
                              }}
                              handleUnitUpdate={(value) =>
                                 handleUpdate([
                                    {
                                       path: [rootHandleElementUpdate, 'padding', 'left', 'unit'],
                                       value,
                                    },
                                 ])
                              }
                              setFocused={() => setFocused('padding-left')}
                              focused={focused === 'padding-left'}
                           />
                        </View>
                        <View
                           style={{
                              flex: 1,
                              width: 40,
                              height: 40,
                              borderWidth: 2,
                              borderColor: '#DFE2EE',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 4,
                           }}>
                           <TouchableOpacity
                              onPress={() => setLinkMarginsAndPaddings(!linkMarginsAndPaddings)}
                              style={styles.bordersInnerContainer}>
                              {linkMarginsAndPaddings ? (
                                 <FontAwesome5 name='link' size={15} color='#7B87A6' />
                              ) : (
                                 <FontAwesome5 name='unlink' size={15} color='#93989B' />
                              )}
                           </TouchableOpacity>
                        </View>
                        <View style={[styles.marginTopText, { flex: 1 }]}>
                           <FieldWithUnit
                              tabIndex={4}
                              placeholder='right'
                              active={!paddingUnified}
                              value={componentDef[rootHandleElementUpdate]?.padding?.right?.val}
                              unit={componentDef[rootHandleElementUpdate]?.padding?.right?.unit}
                              handleValueUpdate={(value) => {
                                 if (linkMarginsAndPaddings) changeAllPaddings(value);
                                 else
                                    handleUpdate([
                                       {
                                          path: [
                                             rootHandleElementUpdate,
                                             'padding',
                                             'right',
                                             'val',
                                          ],
                                          value,
                                       },
                                    ]);
                              }}
                              handleUnitUpdate={(value) =>
                                 handleUpdate([
                                    {
                                       path: [rootHandleElementUpdate, 'padding', 'right', 'unit'],
                                       value,
                                    },
                                 ])
                              }
                              setFocused={() => setFocused('padding-right')}
                              focused={focused === 'padding-right'}
                           />
                        </View>
                     </View>
                     <View style={styles.paddingTopContainer}>
                        <View style={[styles.marginTopText, { bottom: 0, marginTop: 10 }]}>
                           <FieldWithUnit
                              tabIndex={4}
                              placeholder='bottom'
                              active={!paddingUnified}
                              value={componentDef[rootHandleElementUpdate]?.padding?.bottom?.val}
                              unit={componentDef[rootHandleElementUpdate]?.padding?.bottom?.unit}
                              handleValueUpdate={(value) => {
                                 if (linkMarginsAndPaddings) changeAllPaddings(value);
                                 else
                                    handleUpdate([
                                       {
                                          path: [
                                             rootHandleElementUpdate,
                                             'padding',
                                             'bottom',
                                             'val',
                                          ],
                                          value,
                                       },
                                    ]);
                              }}
                              handleUnitUpdate={(value) =>
                                 handleUpdate([
                                    {
                                       path: [rootHandleElementUpdate, 'padding', 'bottom', 'unit'],
                                       value,
                                    },
                                 ])
                              }
                              setFocused={() => setFocused('padding-bottom')}
                              focused={focused === 'padding-bottom'}
                           />
                        </View>
                     </View>
                  </View>
                  <View style={[styles.marginTopText, , { width: 50 }]}>
                     <FieldWithUnit
                        tabIndex={2}
                        placeholder='right'
                        active={!marginUnified}
                        value={componentDef[rootHandleElementUpdate]?.margin?.right?.val}
                        unit={componentDef[rootHandleElementUpdate]?.margin?.right?.unit}
                        handleValueUpdate={(value) => {
                           if (linkMarginsAndPaddings) changeAllMargins(value);
                           else
                              handleUpdate([
                                 {
                                    path: [rootHandleElementUpdate, 'margin', 'right', 'val'],
                                    value,
                                 },
                              ]);
                        }}
                        handleUnitUpdate={(value) =>
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'margin', 'right', 'unit'], value },
                           ])
                        }
                        setFocused={() => setFocused('margin-right')}
                        focused={focused === 'margin-right'}
                     />
                  </View>
               </View>
               <View style={[styles.marginTopText, { height: 35 }]}>
                  <FieldWithUnit
                     tabIndex={2}
                     placeholder='bottom'
                     active={!marginUnified}
                     value={componentDef[rootHandleElementUpdate]?.margin?.bottom?.val}
                     unit={componentDef[rootHandleElementUpdate]?.margin?.bottom?.unit}
                     handleValueUpdate={(value) => {
                        if (linkMarginsAndPaddings) changeAllMargins(value);
                        else
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'margin', 'bottom', 'val'], value },
                           ]);
                     }}
                     handleUnitUpdate={(value) =>
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'margin', 'bottom', 'unit'], value },
                        ])
                     }
                     setFocused={() => setFocused('margin-bottom')}
                     focused={focused === 'margin-bottom'}
                  />
               </View>
            </View>
         </View>
      );
   };

   return <ExpandableContainer title='Spacing & Sizes'>{spacingAndSizes()}</ExpandableContainer>;
};

export default SpacesAndSizes;

const styles = StyleSheet.create({
   marginsContainer: {
      width: 260,
      height: 200,
      borderRadius: 4,
      borderWidth: 4,
      borderColor: '#DFE2EE',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
   },
   bordersMainContainer: {
      width: 260,
      height: 100,
      borderRadius: 4,
      borderWidth: 4,
      borderColor: '#DFE2EE',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
   },
   marginInnerContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paddingTopContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paddingInnerContainer: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   bordersInnerContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 20,
   },
   paddingsContainer: {
      width: 150,
      height: 120,
      borderRadius: 4,
      borderWidth: 4,
      backgroundColor: '#F8FAFC',
      borderColor: '#DFE2EE',
   },
   bordersContainer: {
      width: 120,
      height: 40,
      borderRadius: 4,
      borderWidth: 4,
      backgroundColor: '#F8FAFC',
      borderColor: '#DFE2EE',
   },
   innerBordersContainer: {
      height: 17,
      width: 57,
      borderBottomRightRadius: 4,
      backgroundColor: '#DFE2EE',
      top: 0,
      left: 0,
      position: 'absolute',
   },
   marginText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      textAlign: 'center',
      width: 60,
   },
   marginTopText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
   },
   borderRow: {
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 20,
   },
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10,
   },
   row: {
      flex: 1,
      flexDirection: 'row',
      padding: 2,
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'fit-content',
   },
   extend: {
      flexGrow: 1,
      flexShrink: 1,
   },
   fit: {
      flexGrow: 0,
      flexShrink: 1,
   },
   centered: {
      flexDirection: 'row',
      justifyContent: 'center',
   },
   label: {
      fontSize: 8,
   },
   box: {
      flex: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
   },
   input: {
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
      fontSize: 8,
   },
   multilineInput: {
      borderWidth: 1,
      minHeight: 30,
      flexGrow: 1,
      height: 'fit-content',
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
   },
   picker: {
      height: 24,
      fontSize: 8,
      marginBottom: 10,
      caret: 'hidden',
   },
   valunit: {
      flexDirection: 'row',
   },
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   },
});
