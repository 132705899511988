import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import ConfigPane from './../../components/ConfigPane';
import DesignSystemHeader from './DesignSystemHeader';
import Colors from './Tabs/Colors/Colors';
import Elements from './Tabs/Elements/Elements';
import Fonts from './Tabs/Fonts/Fonts';
import Settings from './Tabs/Settings';
import {
   updateProject,
   updateCustomStyle,
   updateChildElementStyle,
} from '../../store/ProjectSlice';

const DesignSystemMain = () => {
   const dispatch = useDispatch();
   const editor = useSelector((state) => state.editor);
   const selectedCustomStyle = useSelector(
      (state) =>
         state.editor?.selectedCustomStyle?.uid 
            ? state.project?.definition?.design_system?.user_styles[
               state.editor?.selectedCustomStyle?.uid
            ] ?? null
            : null
   );

   const rootElementContainer = useSelector(
      (state) => state.project.definition.components['rootElementContainer']
   );

   const { selectedDesignSystemView } = editor;

   const [pendingChanges, setPendingChanges] = useState([]);

   const debouncedUpdate = useDebouncedCallback(() => {
      dispatch(updateProject(pendingChanges));
      setPendingChanges([]);
   }, 300);

   const handleUpdate = (payload = []) => {
      if (payload.length > 0) {
         if (pendingChanges.length > 0) {
            const changes = [...pendingChanges, ...payload];
            setPendingChanges(changes);
         } else {
            setPendingChanges(payload);
         }
         debouncedUpdate();
      }
   };

   // for updating elements
   const [pendingElementsChanges, setPendingElementsChanges] = useState([]);

   const [componentToEdit, setComponentToEdit] = useState({});

   useEffect(() => {
      dispatch(updateChildElementStyle({ styling: selectedCustomStyle?.styling }));
      setComponentToEdit({
         ...rootElementContainer,
         styling: {
            ...selectedCustomStyle?.styling,
         },
      });
   }, [selectedCustomStyle]);

   const debouncedElementsUpdate = useDebouncedCallback(() => {
      dispatch(updateCustomStyle(selectedCustomStyle.uid, pendingElementsChanges));
      setPendingElementsChanges([]);
   }, 300);

   const handleElementsUpdate = (payload = []) => {
      if (payload.length > 0) {
         if (pendingChanges.length > 0) {
            const changes = [...pendingElementsChanges, ...payload];
            setPendingElementsChanges(changes);
         } else {
            setPendingElementsChanges(payload);
         }
         debouncedElementsUpdate();
      }
   };

   const renderTab = () => {
      switch (editor.selectedDesignSystemView) {
         case 'Settings':
            return <Settings handleUpdate={handleUpdate} />;
         case 'Colors':
            return <Colors handleUpdate={handleUpdate} />;
         case 'Fonts':
            return <Fonts handleUpdate={handleUpdate} />;
         case 'Elements':
            return <Elements handleUpdate={handleUpdate} />;
         default:
            return <Settings handleUpdate={handleUpdate} />;
      }
   };

   return (
      <View style={styles.container}>
         <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
               <DesignSystemHeader handleUpdate={handleUpdate} />
               {renderTab()}
            </View>
            {selectedDesignSystemView == 'Elements' && selectedCustomStyle && (
               <View style={styles.rightPanel}>
                  <ConfigPane
                     rootHandleElementUpdate='styling'
                     handleUpdate={handleElementsUpdate}
                     componentDef={componentToEdit}
                  />
               </View>
            )}
         </View>
      </View>
   );
};

export default DesignSystemMain;

const styles = StyleSheet.create({
   container: {
      flex: 1,
   },
   rightPanel: {
      maxWidth: 300,
      flex: 1,
   },
});
