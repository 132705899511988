import React, { useState } from 'react';
import { StyleSheet, Text, View, Switch, TextInput, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';

import CardEffect from './CardEffect';
import InputField from "../../base/InputField";
import { visualEffectsDefinitions, visualEffectsOptions, parseOptions } from './VisualEffectsDefinitions';
import { updateComponent } from "../../../store/ProjectSlice";

const Card = ({ componentDef, conditionalLogic, conditionIndex }) => {
   const dispatch = useDispatch();

   const [optionsOpen, setOptionsOpen] = useState(false);
   const [addingEffect, setAddingEffect] = useState(false);
   const toggleOptions = () => setOptionsOpen(previousState => !previousState);

   // Update logic section (index)
   const updateLogic = (updates) => dispatch(updateComponent(
      componentDef.composite_key,
      updates.map(update => ({
         path: ['visual_logic', conditionIndex, ...update.path],
         value: update.value 
      }))
   ))
   try {
      return (
         <View style={styles.card}>
            <Pressable style={styles.ellipsis} onPress={toggleOptions}>
               <Ionicons
                  name={"ellipsis-horizontal"}
                  size={16}
                  color={'#7B87A6'}
               />
            </Pressable>
            <View style={styles.section}>
               <View style={styles.title}>
                  <Text style={styles.titleText}>When</Text>
                  <Switch
                     trackColor={{ false: "#767577", true: "#81b0ff" }}
                     thumbColor={!conditionalLogic.is_disabled ? "#f5dd4b" : "#f4f3f4"}
                     ios_backgroundColor="#3e3e3e"
                     onValueChange={() => updateLogic([{
                        path: ['is_disabled'],
                        value: !conditionalLogic.is_disabled
                     }])}
                     value={!conditionalLogic.is_disabled}
                  />
               </View>
               <TextInput style={styles.input} value={JSON.stringify(conditionalLogic.condition)} placeholder="Condition" />
            </View>
            <View style={styles.section}>
               {(Object.keys(conditionalLogic.properties || {})).filter(
                  (field) => field in visualEffectsDefinitions.properties
                     && field in conditionalLogic.properties
               ).map((field, index) => (
                  <CardEffect 
                     key={index} 
                     cardEffect={conditionalLogic.properties[field]} 
                     effectGuidelines={visualEffectsDefinitions.properties[field]}
                     effect={field} 
                     onUpdate={updateLogic}
                  /> 
               ))}
               {(Object.keys(conditionalLogic.styling || {})).filter(
                  (field) => field in visualEffectsDefinitions.styling
                     && field in conditionalLogic.styling
               ).map((field, index) => (
                  <CardEffect
                     key={index} 
                     cardEffect={conditionalLogic.styling[field]} 
                     effectGuidelines={visualEffectsDefinitions.styling[field]}
                     effect={field}
                     onUpdate={updateLogic}
                  />
               ))}
            </View>
            {addingEffect &&
               <View style={styles.section}>
                  <InputField
                     pickedValue={''}
                     pickerOptions={visualEffectsOptions}
                     // myArray.filter( ( el ) => !toRemove.includes( el ) );
                     handlePickerUpdate={(path) => {
                        updateLogic([{
                           path: path.split('.'),
                           value: 'put_default_value_here'
                        }])
                        setAddingEffect(false);
                     }}
                  />
               </View>
            }
            <Pressable onPress={() => setAddingEffect(true)} style={styles.addCard}>
               <Ionicons
                  name={"add"}
                  size={18}
                  color={'#2B55B7'}
               />
               <Text style={styles.addText}>Add Effect</Text>
            </Pressable>
         </View>
      );
   } catch (error) {
      console.error(`Error rendering conditional card\nComponentId: ${componentDef.uid}\nlogic id: ${conditionIndex}\nlogic: ${JSON.stringify(conditionalLogic)}\n\n`, error);
   }
};

export default Card;

const styles = StyleSheet.create({
   card: {
      borderRadius: 6,
      padding: 12,
      backgroundColor: '#EFF2F9',
   },
   section: {
      marginBottom: 10,
      gap: 12,
   },
   title: {
      flexDirection: 'row',
      marginBottom: 4,
      gap: 12,
   },
   titleText: {
      color: '#262626',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '135%', /* 18.9px */
   },
   ellipsis: {
      position: 'absolute',
      top: 2,
      right: 6,
   },
   input: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      paddingLeft: 10,
   },
   field: {
      marginBottom: 10,
   },
   addCard: {
      flexDirection: 'row',
      // backgroundColor: '#DFDFED',
      // borderRadius: 6,
      // padding: 6,
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
   },
   addText: {
      color: '#2B55B7',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '130%',
   },
});