import { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import { deselectAll } from '../../../store/DatabaseSlice';

const TableView = (props) => {
   const [rows, setRows] = useState({});
   const [headers, setHeaders] = useState({});
   
   const dispatch = useDispatch();
   const project = useSelector((state) => state.project);
   const currentTable = useSelector((state) => state.database.currentTable);
   const { projectId, definition } = project;
   const { id } = currentTable;

   useEffect(() => {
      setRows({});
      setHeaders({});
      dispatch(deselectAll());
   }, [currentTable]);

   useEffect(() => {
      if (!id) return;

      project.watch(`/data/${projectId}/${id}`, (data) => {
         setHeaders(definition.data_model[id].named_properties);
         setRows(data);
      });
   }, [currentTable, project.read, project.delete]);

   return (
      <View style={styles.container}>
         <DataTable rows={rows} cols={headers} />
      </View>
   );
};

export default TableView;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      paddingHorizontal: 20,
   },
   table: {},
   row: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
   },
   header: {
      fontWeight: 'bold',
      backgroundColor: '#F5F6F8',
      padding: 12,
      flex: 1,
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: 14,
      lineHeight: 18,
      border: '1px solid #E0E4F4',
   },
   cell: {
      padding: 12,
      flex: 1,
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: 14,
      lineHeight: 18,
      border: '1px solid #E0E4F4',
   },
});
