import React, { useRef } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity } from 'react-native';
const SearchSVG = () => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='20'
         height='20'
         viewBox='0 0 18 18'
         fill='none'>
         <path
            d='M15.75 15.75L12.4875 12.4875M14.25 8.25C14.25 11.5637 11.5637 14.25 8.25 14.25C4.93629 14.25 2.25 11.5637 2.25 8.25C2.25 4.93629 4.93629 2.25 8.25 2.25C11.5637 2.25 14.25 4.93629 14.25 8.25Z'
            stroke='#202020'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
         />
      </svg>
   );
};

const InputField = ({ customStyles, value, onChangeText, ...rest }) => {
   const textInputRef = useRef(null);
   const handleFocus = () => {
      if (textInputRef.current) {
         textInputRef.current.focus();
      }
   };

   return (
      <View style={styles.container}>
         <TouchableOpacity onPress={handleFocus}>
            <View style={styles.field}>
               <SearchSVG />
               <TextInput
                  ref={textInputRef}
                  placeholder='Search'
                  value={value}
                  onChangeText={onChangeText}
                  autoFocus={false}
                  style={styles.input}
                  {...rest}
               />
            </View>
         </TouchableOpacity>
      </View>
   );
};

export default InputField;

const styles = StyleSheet.create({
   container: {
      width: 260,
   },
   field: {
      flex: 1,
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      paddingHorizontal: 16,
      paddingVertical: 12,
      borderRadius: 8,
      backgroundColor: '#F5F6F8',
   },
   input: {
      flex: 1,
      height: 20,
      width: 'fit-content',
      outlineStyle: 'none',
   },
});