import { useState, useRef } from 'react';
import { FlatList, StyleSheet, View, Text, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import PlusButton from './PlusButton';
import TableItem from './TableItem';
import { createNewDataModel } from '../../actions/dbActions';
import DefaultModal from '../base/DefaultModal';

const TableContainer = ({ tables }) => {
   const dispatch = useDispatch();
   const [dataModelName, setDataModelName] = useState('');
   const [addDataModelModalVisible, setAddDataModelModalVisible] = useState(false);
   const dataModelInputRef = useRef();
   const currentTable = useSelector((state) => state.database.currentTable);

   return (
      <View>
         <DefaultModal
            visible={addDataModelModalVisible}
            onSuccess={() => {
               setAddDataModelModalVisible(false);
               dispatch(createNewDataModel(dataModelName));
            }}
            onError={() => setAddDataModelModalVisible(false)}
            title='Create new Table'
            description='Table Name'
            okText='OK'
            cancelText='CANCEL'>
            <TextInput
               ref={dataModelInputRef}
               placeholder='Data model name'
               value={dataModelName}
               onChangeText={setDataModelName}
               autoFocus
               style={styles.input}
            />
         </DefaultModal>
         <View style={styles.header}>
            <Text style={styles.title}>All Data bases</Text>
            <PlusButton
               style={styles.plusButton}
               onPress={() => {
                  setAddDataModelModalVisible(true);
               }}
            />
         </View>
         <FlatList
            data={tables}
            renderItem={({ item }) => (
               <TableItem
                  selected={currentTable?.id == item.tableName.id}
                  item={item}
               />
            )}
         />
      </View>
   );
};

export default TableContainer;

const styles = StyleSheet.create({
   title: {
      fontSize: 16,
      paddingVertical: 20,
   },
   input: {
      width: 300,
      borderBottomWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#fff',
      marginBottom: 10,
      paddingHorizontal: 10,
      paddingVertical: 6,
   },
   plusButton: {
      backgroundColor: '#2B55B7',
      borderRadius: 4,
      width: 28,
      height: 28,
      padding: 4,
   },
   header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
   },
});
