import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

import OutsidePressHandler from 'react-native-outside-press';
import InlineLogic from './InlineLogic';


const RichInput = ({ style, value, onChangeText, tabIndex, onFocus, placeholder }) => {
    const [edit, setEdit] = useState(-1)

    // const text = "This is a custom {{\"type\":\"getComponent\",\"target\":\"123\"}} component.\nWe are very happy with your performance: {{\"type\":\"getComponent\",\"target\":\"456\"}}\n\n{{\"type\":\"getComponent\",\"target\":\"789\"}}"
    const parts = value.split(/({{.*?}})/);

    const handleChanges = (changes, index) => {
        console.log('handleChanges', changes, index)
        const newValue = parts.map((part, i) => {
            if (i === index) {
                return changes
            } else {
                return part
            }
        }).join('')
        onChangeText(newValue)
    }

    return (
        <OutsidePressHandler onOutsidePress={() => setEdit(-1)}>
            <Text style={style}>
                <Pressable style={styles.pressables} onPress={() => setEdit(0)}></Pressable>
                {parts.map((part, index) => {
                    if (part.match(/{{.*?}}/)) {
                        // extract the custom value from the part
                        // const inlineLogic = JSON.parse(part.slice(2, -2) || "[]");

                        if (index === edit) {
                            // TODO: Make inline logic editor useable
                            return <TextInput
                                key={index}
                                tabIndex={tabIndex}
                                onFocus={onFocus}
                                placeholder={placeholder || ''}
                                multiline={true}
                                style={[styles.editing, { height: 100 }]}
                                value={part.slice(2, -2) || "[]"}
                                autoFocus={true}
                                onChange={(val) => handleChanges(`{{${val.nativeEvent.text}}}`, index)}
                            />
                            // return <InlineLogic
                            //     key={index}
                            //     logic={inlineLogic}
                            //     style={styles}
                            //     onChange={(newLogic) => handleChanges(newLogic, index)}
                            // />
                        } else {
                            // create a custom view with the custom value as a prop
                            return <Text style={styles.inlineLogic} key={index} onPress={() => setEdit(index)}>
                                {part.slice(2, -2) || "[]"}
                                {/* {
                                    inlineLogic.map((step, stepIndex) =>
                                        <Text key={index + '_' + stepIndex}>
                                            {step.target
                                                ? components[step.target].name + "'s "
                                                : "(edit)"
                                            }
                                        </Text>
                                    )
                                } */}
                            </Text>;
                        }
                    } else {
                        if (index === edit) {
                            return <TextInput
                                tabIndex={tabIndex}
                                onFocus={onFocus}
                                placeholder={placeholder}
                                key={index}
                                multiline={true}
                                style={styles.editing}
                                value={part}
                                autoFocus={true}
                                onChange={(val) => { handleChanges(val.nativeEvent.text, index) }}
                            />
                        } else {
                            // render the regular text part
                            return <Text onPress={() => setEdit(index)} key={index}>{part}</Text>;
                        }
                    }
                })}
                <Pressable style={styles.pressables} onPress={() => setEdit(parts.length - 1)}></Pressable>
            </Text>
        </OutsidePressHandler>
    )
}

export default RichInput

const styles = StyleSheet.create({
    pressables: {
        minWidth: '100%',
        display: 'block',
        minHeight: 8,
    },
    inlineLogic: {
        backgroundColor: 'lightgrey',
        borderWidth: 0,
    },
    editing: {
        backgroundColor: 'lightblue',
        display: 'block',
        width: '100%',
        flex: 1,
        borderWidth: 0,
        borderStyle: 'none',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
    },
})