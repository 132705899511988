import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

const AlignSelf = ({ rootHandleElementUpdate, componentDef, handleUpdate }) => {
   const [distribution, setDistribution] = useState(
      componentDef[rootHandleElementUpdate]?.position?.relative?.vertical || 'start'
   );
   useEffect(() => {
      setDistribution(
         componentDef[rootHandleElementUpdate]?.position?.relative?.vertical || 'start'
      );
   }, [componentDef[rootHandleElementUpdate]?.position?.relative?.vertical]);

   const [alignItemsValue, setAlignItemsValue] = useState(distribution || 'start');

   return (
      <View style={layoutStyles.justifyContentButtons}>
         <TouchableOpacity
            onPress={() => {
               setAlignItemsValue('start');
               handleUpdate([
                  {
                     path: [rootHandleElementUpdate, 'position', 'relative', 'vertical'],
                     value: 'start',
                  },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               alignItemsValue == 'start' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View style={layoutStyles.jcContainer}>
               <svg
                  width='12'
                  height='14'
                  viewBox='0 0 12 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M1.21875 1.375V12.625M4.65625 5.125C4.07382 5.125 3.78261 5.125 3.5529 5.22015C3.24661 5.34702 3.00327 5.59036 2.8764 5.89665C2.78125 6.12636 2.78125 6.41757 2.78125 7C2.78125 7.58243 2.78125 7.87364 2.8764 8.10335C3.00327 8.40964 3.24661 8.65298 3.5529 8.77985C3.78261 8.875 4.07382 8.875 4.65625 8.875H6.53125H8.40625C8.98868 8.875 9.27989 8.875 9.5096 8.77985C9.81589 8.65298 10.0592 8.40964 10.1861 8.10335C10.2812 7.87364 10.2812 7.58243 10.2812 7C10.2812 6.41757 10.2812 6.12636 10.1861 5.89665C10.0592 5.59036 9.81589 5.34702 9.5096 5.22015C9.27989 5.125 8.98868 5.125 8.40625 5.125H6.53125H4.65625Z'
                     stroke={alignItemsValue == 'start' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setAlignItemsValue('center');
               handleUpdate([
                  {
                     path: [rootHandleElementUpdate, 'position', 'relative', 'vertical'],
                     value: 'center',
                  },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               alignItemsValue === 'center' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View style={layoutStyles.jcContainer}>
               <svg
                  width='10'
                  height='14'
                  viewBox='0 0 10 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M5.25 5.125H3.375C2.79257 5.125 2.50136 5.125 2.27165 5.22015C1.96536 5.34702 1.72202 5.59036 1.59515 5.89665C1.5 6.12636 1.5 6.41757 1.5 7C1.5 7.58243 1.5 7.87364 1.59515 8.10335C1.72202 8.40964 1.96536 8.65298 2.27165 8.77985C2.50136 8.875 2.79257 8.875 3.375 8.875H5.25M5.25 5.125H7.125C7.70743 5.125 7.99864 5.125 8.22835 5.22015C8.53464 5.34702 8.77798 5.59036 8.90485 5.89665C9 6.12636 9 6.41757 9 7C9 7.58243 9 7.87364 8.90485 8.10335C8.77798 8.40964 8.53464 8.65298 8.22835 8.77985C7.99864 8.875 7.70743 8.875 7.125 8.875H5.25M5.25 5.125L5.25 1.375M5.25 8.875V12.625'
                     stroke={alignItemsValue == 'center' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setAlignItemsValue('end');
               handleUpdate([
                  {
                     path: [rootHandleElementUpdate, 'position', 'relative', 'vertical'],
                     value: 'end',
                  },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               alignItemsValue === 'end' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View style={layoutStyles.jcContainer}>
               <svg
                  width='12'
                  height='14'
                  viewBox='0 0 12 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M10.2812 1.375V12.625M3.09375 5.125C2.51132 5.125 2.22011 5.125 1.9904 5.22015C1.68411 5.34702 1.44077 5.59036 1.3139 5.89665C1.21875 6.12636 1.21875 6.41757 1.21875 7C1.21875 7.58243 1.21875 7.87364 1.3139 8.10335C1.44077 8.40964 1.68411 8.65298 1.9904 8.77985C2.22011 8.875 2.51132 8.875 3.09375 8.875H4.96875H6.84375C7.42618 8.875 7.71739 8.875 7.9471 8.77985C8.25339 8.65298 8.49673 8.40964 8.6236 8.10335C8.71875 7.87364 8.71875 7.58243 8.71875 7C8.71875 6.41757 8.71875 6.12636 8.6236 5.89665C8.49673 5.59036 8.25339 5.34702 7.9471 5.22015C7.71739 5.125 7.42618 5.125 6.84375 5.125H4.96875H3.09375Z'
                     stroke={alignItemsValue === 'end' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setAlignItemsValue('stretch');
               handleUpdate([
                  {
                     path: [rootHandleElementUpdate, 'position', 'relative', 'vertical'],
                     value: 'stretch',
                  },
               ]);
            }}
            style={[
               layoutStyles.jcButton,
               alignItemsValue === 'stretch' ? layoutStyles.jcButtonSelected : {},
            ]}>
            <View style={layoutStyles.jcContainer}>
               <svg
                  width='13'
                  height='14'
                  viewBox='0 0 13 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M11.5625 1.375V12.625M0.9375 1.375V12.625M4.375 5.125C3.79257 5.125 3.50136 5.125 3.27165 5.22015C2.96536 5.34702 2.72202 5.59036 2.59515 5.89665C2.5 6.12636 2.5 6.41757 2.5 7C2.5 7.58243 2.5 7.87364 2.59515 8.10335C2.72202 8.40964 2.96536 8.65298 3.27165 8.77985C3.50136 8.875 3.79257 8.875 4.375 8.875V8.875C4.89277 8.875 5.3125 8.45527 5.3125 7.9375V6.0625C5.3125 5.54473 4.89277 5.125 4.375 5.125V5.125ZM8.125 5.125C8.70743 5.125 8.99864 5.125 9.22835 5.22015C9.53464 5.34702 9.77798 5.59036 9.90485 5.89665C10 6.12636 10 6.41757 10 7C10 7.58243 10 7.87364 9.90485 8.10335C9.77798 8.40964 9.53464 8.65298 9.22835 8.77985C8.99864 8.875 8.70743 8.875 8.125 8.875V8.875C7.60723 8.875 7.1875 8.45527 7.1875 7.9375V6.0625C7.1875 5.54473 7.60723 5.125 8.125 5.125V5.125Z'
                     stroke={alignItemsValue === 'stretch' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
      </View>
   );
};

export default AlignSelf;

const layoutStyles = StyleSheet.create({
   jcButton: {
      flex: 1,
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   jcButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   jcContainer: {
      height: 30,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center',
   },
   justifyContentButtons: {
      flex: 1,
      width: 150,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
});
