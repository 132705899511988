import { useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import ElementIcon from './../../../base/ElementIcon';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCustomStyle } from './../../../../store/EditorSlice';
import { updateProject, setUpdateElementsStyle} from './../../../../store/ProjectSlice';

const ElementItem = ({ item, handleUpdate }) => {
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const selectedCustomStyle = useSelector((state) => state.editor.selectedCustomStyle);

    return (
        <TouchableOpacity style={[{height: 42, flexDirection: 'row', alignItems: 'center', borderBottomColor: '#F8F8F8', borderBottomWidth: 1},
            item.uid === selectedCustomStyle?.uid || isHovered  ? styles.selectedContainer : {},]}
            onMouseEnter={() => {setIsHovered(true);}}
            onMouseLeave={() => {setIsHovered(false);}}
            onPress={() => {
                    dispatch(setSelectedCustomStyle(item))
                    dispatch(setUpdateElementsStyle({key: 'childComponent', style: item.uid}));
                    //['rootElementContainer', 'childComponent'], [{path: ['style'], value: item.uid || ""}]
               }}>
            <View style={styles.colorContainer}>
                <TouchableOpacity style={{padding: 5}}>
                    <ElementIcon type={item.type} />
                </TouchableOpacity>
            </View>
            <View style={styles.nameContainer}>
                <Text styles={styles.colorNameText}>{item.name}</Text>
            </View>
            <View style={styles.descriptionContainer}>
                <Text styles={styles.colorNameDescription}>{item?.description}</Text>
            </View>
        </TouchableOpacity>
    )
}


export default ElementItem;

const styles = StyleSheet.create({
   selectedContainer: {
        backgroundColor: '#F5F7F9'
   },
   container: {
        paddingHorizontal: 20,
   },
   modalContainer: {
        width: 300,
        padding: 10,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderColor: '#f5f6f8',
        borderWidth: 2,
  },
   emptyText: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        border: '0.5px solid #F8F8F8',
   },
   dataRow: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
   },
   groupText: {
        color: '#93989B',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 19,
   },
   colorNameText: {
        color: '#262626',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 19,
   },
   colorNameValue: {
        color: '#646D72',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 19,
   },
   colorNameDescription: {
        color: '#B8BDD0',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 15,
   },
   colorContainer: {
        borderRightColor: '#F8F8F8',
        borderRightWidth: 1
   },
   nameContainer: {
        borderRightColor: '#F8F8F8',
        borderRightWidth: 1,
        width: 160,
   },
   colorCodeContainer: {
        borderRightColor: '#F8F8F8',
        borderRightWidth: 1,
        width: 100
    },
    descriptionContainer: {
        width: 331
    }
});
