import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, Text, View, Pressable } from "react-native";
import React from "react";
import Card from "./Card";

const ConditionPanel = ({ componentDef, onNewCondition }) => {
   const conditionalLogics = Object.values(componentDef?.visual_logic || {});

   return (
      <View style={styles.container}>
         <Pressable style={styles.buttonNew}>
            <Text style={styles.addNewText}>New condition</Text>
            <Ionicons
               name={"add-outline"}
               size={16}
               color={'#DFDFED'}
            />
         </Pressable>
         <View style={styles.conditions}>
            {Object.keys(conditionalLogics).sort().map((logicIndex, index) => (
               <Card 
                  key={index}
                  conditionIndex={logicIndex}
                  componentDef={componentDef} 
                  conditionalLogic={conditionalLogics[logicIndex]}
               />
            ))}
         </View>
      </View>
   );
};

export default ConditionPanel;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      padding: 20,
      gap: 20,
   },
   buttonNew: {
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 6,
      alignItems: 'center',
      backgroundColor: '#2B55B7',
      paddingHorizontal: 20,
      paddingVertical: 8,
      borderRadius: 6,
   },
   addNewText: {
      color: '#DFDFED',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '130%',
      fontStyle: 'normal',
      fontFamily: 'Inter',
   },
   conditions: {
      gap: 12,
   },
});