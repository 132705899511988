import _ from 'lodash';
import { useEffect, useState, useDispatch } from 'react';
import { View, StyleSheet, FlatList, Text, Dimensions, Pressable } from 'react-native';
import { useSelector } from 'react-redux';

import ColorItems from './ColorItems';
import ColorItem from './ColorItem';

function groupByType(objects) {
   const result = {};
   for (const uid in objects) {
      const object = objects[uid];
      const type = object.type;
      if (!result[type]) {
         result[type] = {};
      }
      result[type][uid] = object;
   }

   return result;
}

const Colors = ({ handleUpdate }) => {
   // const dispatch = useDispatch()
   const colours = useSelector((state) => state.project?.definition?.design_system?.colours);
   const [colors, setColors] = useState(groupByType(colours) || {});

   useEffect(() => {
      setColors(groupByType(colours));
   }, [colours]);

   return (
      <View style={styles.container}>
         <View style={styles.textContainer}>
            <Text style={styles.titleText}>Active Colors</Text>
            <Text style={styles.descText}>Set up custom colors for all devices</Text>
         </View>
         <View style={{ flex: 1 }}>
            {!colours ? (
               <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <Text>Press blue button to add new colour</Text>
               </View>
            ) : (
               <FlatList
                  data={Object.keys(colors)}
                  renderItem={({ item }) => (
                     <View>
                        <Text styles={styles.groupText}>{item}</Text>
                        <ColorItems handleUpdate={handleUpdate} colors={colors[item]} />
                     </View>
                  )}
               />
            )}
         </View>
      </View>
   );
};

export default Colors;

const styles = StyleSheet.create({
   textContainer: {
      marginBottom: 20,
   },
   titleText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 18,
      lineHeight: 21,
      color: '#262626',
   },
   descText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 19,
      color: '#646D72',
   },
   container: {
      paddingHorizontal: '20px',
      flex: 1,
   },
   emptyText: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      border: '0.5px solid #F8F8F8',
   },
   dataRow: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
   },
   groupText: {
      color: '#93989B',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameText: {
      color: '#262626',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameValue: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameDescription: {
      color: '#B8BDD0',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 15,
   },
});
