import React from 'react';
import { FlatList, View, Text, TouchableOpacity, Pressable, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import BasicItem from './BasicItem';
import { commonContainers } from '../../consts/common';


const BasicElements = () => {

   const selectedDef = useSelector((state) => state.globals.selectedDefinition);

   const getAvailableTypes = () => {
      switch (selectedDef?.type)
      {
         case 'box':
         case 'boxList':
         case 'modal':   
            return commonContainers;
         case 'text':
         case 'input':
         case 'image':
         case 'map':
            return [];
         default:
            return commonContainers;
      }
   }

   return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
         <FlatList
               numColumns={2}
               style={styles.list}
               data={commonContainers}
               renderItem={({ item, index }) => 
                  <BasicItem
                     disabled={!selectedDef || !getAvailableTypes().includes(item)}
                     key={item}
                     item={item}/>
               }
         />
      </View>
   );
};

export default BasicElements;

const styles = StyleSheet.create({
   container: {
      backgroundColor: '#F5F7F9'
   },
   paddingBottom: {
      height: 3,
   },
   list: {
      marginLeft: 8,
   },
   label: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexShrink: 1,
      height: 30,
   },
   labeltext: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 12,
      color: '#4A4756',
      alignItems: 'center'
   },
   selected: {      
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 12,
      color: '#4A4756',
      alignItems: 'center'
   },
});