import _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import SearchButton from './base/SearchButton';
import Tab from './base/Tab';
import { setSelectedDesignSystemView } from '../../store/EditorSlice';
import DefaultModal from '../base/DefaultModal';
import uuid from 'react-native-uuid';
import AutoCompleteInput from './../base/InputField/AutoCompleteInput';

import DownloadSelectedButton from './base/DownloadSelectedButton';
import AddRowButton from './base/AddRowButton';
import { commonContainers } from './../../consts/common';

const DesignSystemHeader = ({handleUpdate}) => {
   const dispatch = useDispatch();
   const [addColorModalVisible, setAddColorModalVisible] = useState(false);
   const [addFontModalVisible, setAddFontModalVisible] = useState(false);
   const [addElementModalVisible, setAddElementModalVisible] = useState(false);
   const [googleFonts, setGoogleFonts] = useState([]);
   const editor = useSelector((state) => state.editor);

   const [newFont, setNewFont] = useState('');
   const [newFontType, setNewFontType] = useState('');

   const [colorName, setColorName] = useState();
   const [colorType, setColorType] = useState();
   const [colorDescription, setColorDescription] = useState();

   const [fontDescription, setFontDescription] = useState();

   const [elementName, setElementName] = useState();
   const [elementDescription, setElementDescription] = useState();
   const [elementGroup, setElementGroup] = useState();

   const { selectedDesignSystemView } = editor;

   async function getGoogleFonts() {
      try {
        const response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCdsesLvf5Pxb9PZVYylzxuZ7QUUrxjDYk');
        return await response.json();
      } catch (error) {
        console.error('Error retrieving Google Fonts:', error);
      }
   }

   useLayoutEffect(()=> {
      getGoogleFonts().then((data) => setGoogleFonts(data))
   }, [])
     
   return (
      <View style={styles.container}>
         <View style={styles.headerContainer}>
            <View style={styles.container_header}>
               <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={styles.text}>{'Design System'}</Text>
               </View>
            </View>
            <View style={styles.nav}>
               <View style={styles.tab_section}>
                  <View style={styles.tabs}>
                     <Tab
                        style={selectedDesignSystemView === 'Settings' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setSelectedDesignSystemView('Settings'))}
                        value='Settings'
                     />
                     <Tab
                        style={selectedDesignSystemView === 'Colors' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setSelectedDesignSystemView('Colors'))}
                        value='Colors'
                     />
                     <Tab
                        style={selectedDesignSystemView === 'Fonts' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setSelectedDesignSystemView('Fonts'))}
                        value='Fonts'
                     />
                     <Tab
                        style={selectedDesignSystemView === 'Elements' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setSelectedDesignSystemView('Elements'))}
                        value='Elements'
                     />
                  </View>
                  <SearchButton />
               </View>
               <View style={styles.selectedContainer}>
                 { selectedDesignSystemView == 'Colors' &&
                 <>
                     <DownloadSelectedButton />
                     <AddRowButton
                        title={'Add new color'}
                        onPress={() => {
                           setAddColorModalVisible(true);
                        }}
                     />
                 </>
                 }
                 { selectedDesignSystemView == 'Fonts' &&
                  <AddRowButton
                     title={'Add new font'}
                     onPress={() => {
                        setAddFontModalVisible(true);
                     }}
                  />
                 }
                 { selectedDesignSystemView == 'Elements' &&
                  <AddRowButton
                     title={'Add new element'}
                     onPress={() => {
                        setAddElementModalVisible(true);
                     }}
                  />
                 }
               </View>
            </View>
         </View>
         {addColorModalVisible && (
            <DefaultModal
               visible={addColorModalVisible}
               onSuccess={() => {
                  const colorUid = uuid.v4();
                  handleUpdate([
                     { 
                        path: ['design_system', 'colours', colorUid],
                        value: {
                           "name": colorName,
                           "type": colorType,
                           "description": colorDescription,
                           "uid": colorUid,
                           "color": "#FFFFFF",
                        }
                     }
                  ]);
                  setAddColorModalVisible(false);
               }}
               onError={() => setAddColorModalVisible(false)}
               title='Create new Color'
               description='Enter data for new row.'
               okText='OK'
               cancelText='CANCEL'>
                  <>
                     <View>
                        <Text style={styles.subTitle}>{'Color Name'}</Text>
                        <View style={styles.inputContainer}>
                           <TextInput
                              placeholder={'Create a name to not to loose it'}
                              autoFocus
                              value={colorName}
                              onChangeText={(value) => {
                                 setColorName(value)
                              }}
                              style={styles.input}
                           />
                        </View>
                     </View>
                     <View>
                        <Text style={styles.subTitle}>{'Color Type'}</Text>
                        <View style={styles.inputContainer}>
                           <TextInput
                              placeholder={'This type will be used as a Folder Name'}
                              autoFocus
                              value={colorType}
                              onChangeText={(value) => {
                                 setColorType(value)
                              }}
                              style={styles.input}
                           />
                        </View>
                     </View>
                     <View>
                        <Text style={styles.subTitle}>{'Description'}</Text>
                        <View style={[styles.inputContainer, {minHeight: 70,}]}>
                           <TextInput
                              placeholder={'Create short description'}
                              autoFocus
                              value={colorDescription}
                              onChangeText={(value) => {
                                 setColorDescription(value)
                              }}
                              style={styles.input}
                           />
                        </View>
                     </View>
                  </>
            </DefaultModal>
         )}
         {addFontModalVisible && (
            <DefaultModal
               visible={addFontModalVisible}
               onSuccess={() => {
                  const fontUid = uuid.v4();

                  const data = [
                     { 
                        path: ['design_system', 'fonts', fontUid], 
                        value: {
                           'name': `${newFont}_${newFontType}`,
                           'font': googleFonts.items.filter((font) => font.family == newFont)[0].files[newFontType],
                           'description': fontDescription,
                           'uid': fontUid
                        }
                     }
                  ]

                  handleUpdate(data);
                  setAddFontModalVisible(false);
               }}
               onError={() => setAddFontModalVisible(false)}
               title='Add new Font'
               description='Add new font to your project.'
               okText='OK'
               cancelText='CANCEL'>
                  <>
                     <View>
                        <View style={{marginBottom: 20}}>
                           <Text style={styles.subTitle}>{'Description'}</Text>
                           <View style={[styles.inputContainer]}>
                              <TextInput
                                 placeholder={'Create short description'}
                                 autoFocus
                                 value={fontDescription}
                                 onChangeText={(value) => {
                                    setFontDescription(value)
                                 }}
                                 style={styles.input}
                              />
                           </View>
                        </View>
                        <Text style={styles.subTitle}>{'Select from Google Fonts'}</Text>
                        <View style={styles.inputContainer}>
                           <AutoCompleteInput
                              data={googleFonts.items.map((font) => font.family)}
                              onSelect={(item) => setNewFont(item)}
                              textStyles={[styles.input]}
                           />
                        </View>
                        { newFont && 
                           <>
                              <Text style={styles.subTitle}>{'Select font type'}</Text>
                              <View style={styles.inputContainer}>
                                 <AutoCompleteInput
                                    data={Object.keys(googleFonts.items.filter((font) => font.family == newFont)[0].files)}
                                    onSelect={(item) => {setNewFontType(item)}}
                                    textStyles={[styles.input]}
                                 />
                              </View>
                           </>
                        }
                     </View>
                  </>
            </DefaultModal>
         )}
         {addElementModalVisible && (
            <DefaultModal
               visible={addElementModalVisible}
               onSuccess={() => {
                  const elementUid = uuid.v4();
                  handleUpdate([
                     { 
                        path: ['design_system', 'user_styles', elementUid],
                        value: {
                           "name": elementName,
                           "type": elementGroup,
                           "description": elementDescription,
                           "uid": elementUid,
                        }
                     }
                  ]);
                  setAddElementModalVisible(false);
               }}
               onError={() => setAddElementModalVisible(false)}
               title='Add new Element'
               description='Add new style for components.'
               okText='OK'
               cancelText='CANCEL'>
                  <>
                     <View>
                        <Text style={styles.subTitle}>{'Element Name'}</Text>
                        <View style={styles.inputContainer}>
                           <TextInput
                              placeholder={'Create a name to not to loose it'}
                              autoFocus
                              value={colorName}
                              onChangeText={(value) => {
                                 setElementName(value)
                              }}
                              style={styles.input}
                           />
                        </View>
                     </View>
                     <View>
                        <Text style={styles.subTitle}>{'Description'}</Text>
                        <View style={styles.inputContainer}>
                           <TextInput
                              placeholder={'Create short description'}
                              autoFocus
                              value={colorType}
                              onChangeText={(value) => {
                                 setElementDescription(value)
                              }}
                              style={styles.input}
                           />
                        </View>
                     </View>
                     <View>
                        <Text style={styles.subTitle}>{'Element group'}</Text>
                        <View style={styles.inputContainer}>
                           <TextInput
                              placeholder={'Create short description'}
                              autoFocus
                              value={colorType}
                              onChangeText={(value) => {
                                 setElementGroup(value)
                              }}
                              style={styles.input}
                           />
                        </View>
                     </View>
                  </>
            </DefaultModal>
         )}
      </View>
   );
};

export default DesignSystemHeader;

const styles = StyleSheet.create({
   headerContainer: {
      padding: 20,
      flexDirection: 'column',
      gap: 10,
   },
   container: {},
   container_header: {
      flex: 1,
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      gap: 12,
   },
   button: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 6,
      backgroundColor: '#2B55B7',
      paddingHorizontal: 24,
      paddingVertical: 6,
      borderRadius: 8,
      text: {
         color: '#FFFFFF',
      },
   },
   text: {
      fontSize: 18,
      lineHeight: '120%',
      outlineStyle: 'none',
      alignItems: 'center',
      justifyContent: 'center',
   },
   nav: {
      paddingVertical: 20,
      marginTop: 12,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   selectedContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   tab_section: {
      flexDirection: 'row',
      columnGap: 12,
      height: 56,
   },
   tabs: {
      display: 'flex',
      flexDirection: 'row',
      padding: 8,
      borderRadius: 8,
      backgroundColor: '#F5F6F8',
   },
   tab: {
      paddingVertical: 8,
      paddingHorizontal: 20,
      fontSize: 12,
      color: '#646D72',
      alignItems: 'center',
      justifyContent: 'center',
   },
   tab_active: {
      paddingVertical: 8,
      paddingHorizontal: 20,
      fontSize: 12,
      backgroundColor: '#2E3137',
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      text: {
         color: '#EFF1FE',
      },
   },
   input: {
      width: '100%',
      backgroundColor: '#fff',
      paddingHorizontal: 10,
      paddingVertical: 6,
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18,
      color: '#93989B',
   },
   subTitle: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18
   },
   inputContainer: {
      borderColor: '#E0E4F4',
      borderWidth: 1,
      borderRadius: 8,
      justifyContent: 'center',
   },
});
