import { TPageSection, TSectionBuilderPayload } from "../types"
import { createHeader } from "./header"
import { createHero } from "./hero"
import { createProblemStatement } from "./problemStatement"
import { createFeaturedProducts } from "./featuredProducts"
import { createUniqueValue } from "./uniqueValue"
import { createFooter } from "./footer"

export const sectionBuilders: Record<TPageSection, (payload: TSectionBuilderPayload, debugLevel: number) => any> = {
   header: createHeader,
   hero: createHero,
   problemStatement: createProblemStatement,
   featuredProducts: createFeaturedProducts,
   uniqueValueProposition: createUniqueValue,
   // usecases: createUseCases,
   // aboutUs: createAboutUs,
   // socialProof: createSocialProof,
   // trustSignal: createTrustSignal,
   footer: createFooter,
}