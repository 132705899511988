import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import DatabaseHeader from './DatabaseHeader';
import DataStructure from './Tabs/DataStructure';
import PrivacySettings from './Tabs/PrivacySettings';
import TableView from './Tabs/TableView';
import { clearDataToEdit, setTable } from '../../store/DatabaseSlice';
import DeleteSelectedContainer from './base/DeleteSelectedContainer';
import DefaultModal from '../base/DefaultModal';
import { dbUpdateAllFields } from '../../actions/dbActions';

const DatabaseSection = () => {
   const dispatch = useDispatch();
   const database = useSelector((state) => state.database);
   const project = useSelector((state) => state.project);
   const [showCancelEditModal, setShowCancelEditModal] = useState();
   const { dataToEdit } = database;

   useEffect(() => {
      const unsubscribeDataModel = project.watch(
         `/projects/${project.projectId}/dev/data_model`,
         (data) => {
            Object.keys(data).forEach((el) => {
               if (typeof data[el] === 'object') {
                  dispatch(
                     setTable({
                        id: el,
                        name: data[el].name,
                     })
                  );
               }
            });
         }
      );

      // return () => {
      //     unsubscribeDataModel()
      // }
   }, [project.projectId, project.read]);

   const renderTab = () => {
      switch (database.selectedView) {
         case 'table view':
            return <TableView />;
         case 'data structure':
            return <DataStructure />;
         case 'privacy settings':
            return <PrivacySettings />;
         default:
            return <TableView />;
      }
   };

   return (
      <View style={styles.container}>
         <DatabaseHeader table={database.currentTable} />
         { renderTab() }
         {!_.isEmpty(dataToEdit) &&
            <DeleteSelectedContainer
               dataToEdit={dataToEdit}
               onOkPress={() => {
                     dispatch(dbUpdateAllFields());
                     dispatch(clearDataToEdit());
                  }
               }
               onCancelPress={() => setShowCancelEditModal(true)}/>
         }
         {showCancelEditModal && (
            <DefaultModal
               visible={showCancelEditModal}
               onSuccess={() => {
                  setShowCancelEditModal(false);
               }}
               onError={() => { 
                  setShowCancelEditModal(false);
                  dispatch(clearDataToEdit());
               }}
               title='You have 12 unsaved changes'
               description='Lets save them all to avoid data loss'
               okText='Save'
               cancelText='Continue without saving'/>
         )}
      </View>
   );
};

export default DatabaseSection;

const styles = StyleSheet.create({
   container: {
      flex: 1,
   },
});
