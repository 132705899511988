import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, Text, Pressable, Image, StyleSheet, Modal, Picker } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { useSelector, useDispatch } from 'react-redux';

import { setEditorView } from '../../store/EditorSlice';
import { logout } from '../../store/UserSlice';
import ScreensNavigation from './ScreensNavigation';

const Header = () => {
   const dispatch = useDispatch();
   const user = useSelector((state) => state.user);
   const projectId = useSelector((state) => state.editor.project);
   const [menuVisible, setMenuVisible] = useState(false);

   const currentScreen = useSelector((state) => state.editor.page);
   const projectScreens = useSelector((state) => state.project.definition?.pages);
   const [screensNavVisible, setScreensNavVisible] = useState(false);

   const navigation = useNavigation();
   const routes = useNavigationState((state) => state.routes);
   const previousRouteName = routes.length > 1 ? routes[routes.length - 2].name : '';

   const handleLogout = () => {
      dispatch(logout());
   };

   const handleModifyProfile = () => {
      // Logic for modifying the profile
   };

   const toggleMenu = () => {
      setMenuVisible(!menuVisible);
   };

   const toggleScreensNavVisible = () => {
      setScreensNavVisible(!screensNavVisible);
   };
   return (
      <View style={styles.headerContainer}>
         <View>
            {previousRouteName !== '' && (
               <Pressable onPress={() => navigation.goBack()} style={styles.backButton}>
                  <Text style={styles.previousPageName}>{'< ' + previousRouteName}</Text>
               </Pressable>
            )}
         </View>
         {projectId && currentScreen && (
            <Pressable onPress={toggleScreensNavVisible}>
               <Text style={styles.projectName}>{projectScreens[currentScreen]?.name}</Text>
            </Pressable>
         )}
         {projectId && (
            <View style={styles.tabContainer}>
               {/* <Pressable onPress={() => navigation.navigate('DesignSystem', { projectId })} style={styles.tab}> */}
               <Pressable
                  onPress={() => dispatch(setEditorView('DesignSystem'))}
                  style={styles.tab}>
                  <Text style={styles.tabText}>Design System</Text>
               </Pressable>
               <Pressable onPress={() => dispatch(setEditorView('Editor'))} style={styles.tab}>
                  <Text style={styles.tabText}>Editor</Text>
               </Pressable>
               <Pressable onPress={() => dispatch(setEditorView('Data'))} style={styles.tab}>
                  <Text style={styles.tabText}>Data</Text>
               </Pressable>
               <Pressable onPress={() => dispatch(setEditorView('Settings'))} style={styles.tab}>
                  <Text style={styles.tabText}>Settings</Text>
               </Pressable>
            </View>
         )}
         <Pressable onPress={toggleMenu}>
            {user.photoUrl ? (
               <Image source={user.photoUrl} style={styles.profilePicture} />
            ) : (
               <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                     stroke='#93989B'
                     strokeWidth='2'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            )}
         </Pressable>
         {/* Profile menu */}
         <Modal animationType='fade' transparent visible={menuVisible} onRequestClose={toggleMenu}>
            <Pressable style={styles.modalOverlay} onPress={toggleMenu}>
               <View style={styles.menuContainer}>
                  <Pressable onPress={handleModifyProfile} style={styles.menuItem}>
                     <Text>Modify Profile</Text>
                  </Pressable>
                  <Pressable onPress={handleLogout} style={styles.menuItem}>
                     <Text>Logout</Text>
                  </Pressable>
               </View>
            </Pressable>
         </Modal>
         {/* Screens nav */}
         <Modal
            animationType='fade'
            transparent
            visible={screensNavVisible}
            onRequestClose={toggleScreensNavVisible}>
            <View style={styles.modalOverlay}>
               <ScreensNavigation
                  projectId={projectId}
                  currentScreen={currentScreen}
                  projectScreens={projectScreens}
               />
            </View>
         </Modal>
      </View>
   );
};

export default Header;

const styles = StyleSheet.create({
   modalOverlay: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
   },
   headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 16,
      height: 40,
      backgroundColor: '#f2f2f2',
   },
   tabContainer: {
      flexDirection: 'row',
   },
   tab: {
      paddingHorizontal: 10,
      paddingVertical: 5,
      marginHorizontal: 5,
      borderRadius: 5,
      backgroundColor: '#ccc',
   },
   tabText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
   },
   profilePicture: {
      width: 40,
      height: 40,
      borderRadius: 20,
   },
   menuContainer: {
      position: 'absolute',
      top: 40, // Adjust the top position as needed
      right: 16, // Adjust the right position as needed
      backgroundColor: '#d2d2d2',
      borderRadius: 4,
      elevation: 4,
      padding: 8,
   },
   menuItem: {
      paddingVertical: 8,
   },
   backButton: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 8,
   },
   backButtonText: {
      fontWeight: 'bold',
      color: '#333',
   },
   picker: {
      width: 200,
      height: 44,
      backgroundColor: '#fff',
      borderRadius: 4,
      elevation: 4,
   },
});
