import {
   landingPageTopicSummaryContext,
   landingPageStructureContext,
} from '../../../consts/prompts';
import { deleteComponent, updatePage, updateProject } from '../../ProjectSlice';
import { createProjectPrimaryColor, updateDesignToMatchPrimary } from '../design';
import { generateComponentContent } from '../generators';
import { askAI } from '../openai';
import { sectionBuilders } from '../sections';
import { addMessage, setRunEnrichedPrompt, setChatStatus } from '../slice';
import { CHAT_STATUS, TPageSection, TPatch } from '../types';
import { camelCase } from '../utils';

/**
 * Create a new page from scratch
 * @param debugLevel
 * @returns
 */
export const assistantCreatePage =
   (debug: number = 0) =>
   async (
      dispatch: (...args: any[]) => void,
      getState: () => Record<string, any>
   ): Promise<void> => {
      if (debug > 3) console.log('[CreatePage] Start');
      // const enrichedPrompt = getState().assistant.run.enrichedPrompt
      const prompt = getState().assistant.run.prompt;

      const definition = getState().project.definition;
      const currentPage = getState().editor.page;
      const rootComponentId = definition.pages[currentPage].root_component;
      const selectedComponent = getState().globals.selected;

      // Create the page structure and design system
      const [pageStructureMessage, topicDescriptionMessage] = await Promise.all([
         askAI(landingPageStructureContext + '\n' + prompt, debug),
         askAI(landingPageTopicSummaryContext.concat({ role: 'user', content: prompt }), debug),
      ]);

      dispatch(setChatStatus(CHAT_STATUS.handlingPrompt));

      dispatch(setRunEnrichedPrompt(topicDescriptionMessage.content));

   dispatch(addMessage(topicDescriptionMessage))
   await dispatch(updateProject([{
      path: ['pages', currentPage, 'description'],
      value: topicDescriptionMessage.content
   }]))
   if (debug > 3) console.log('[CreatePage] Saved new page description')
   
   // Create design system
   const primary = await dispatch(createProjectPrimaryColor('', debug))
   dispatch(updateDesignToMatchPrimary())
   // Update page background
   dispatch(updateProject([
      {
         path: ['components', rootComponentId, 'custom_styling', 'background', 'type'],
         value: 'color'
      },
      {
         path: ['components', rootComponentId, 'custom_styling', 'background', 'color'],
         value: primary
      },
   ]))

   const sectionsStr = pageStructureMessage.content.split('\n').filter(line => line !== '');
   const sections = sectionsStr.map(line => line.split(':')[0].trim())
   if (debug > 3) console.log('[CreatePage] Sections:', sections)
   const descriptions = sectionsStr.map(line => (line.split(':')[1] ?? '').trim())

   dispatch(setChatStatus(CHAT_STATUS.generatingStructure))

   let sectionIndex = 0
   for (let index = 0; index < sections.length; index++) {
      const section = camelCase(sections[index])
      if (debug > 3) console.log('[CreatePage] Current section:', section)
      if (!(section in sectionBuilders)) {
         if (debug > 1) console.warn('[CreatePage] Unknown section type:', section)
         continue
      }
      await dispatch(sectionBuilders[section as TPageSection](
         {
            description: descriptions[index], 
            sections,
            rootId: rootComponentId,
            rootIndex: sectionIndex,
            pageId: currentPage,
         }, 
         debug
      ))
      sectionIndex ++
   }

   const patchUpdates = getState().assistant.run.patch

   dispatch(setChatStatus(CHAT_STATUS.generatingContent));
   await Promise.all(
      patchUpdates.map((patchUpdate: TPatch) =>
         dispatch(generateComponentContent(patchUpdate, debug))
      )
   );

   dispatch(setChatStatus(CHAT_STATUS.idle));
};
