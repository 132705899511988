
/**
 * Update an existing component
 * @param debug 
 * @returns 
 */
export const assistantUpdateComponent = (debug: number = 0) => async (
   dispatch: (...args: any[]) => void, 
   getState: () => Record<string, any>
): Promise<void> => {

}