import { FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import FieldWithUnit from './FieldWithUnit';
import DefaultInputContainer from '../base/DefaultInputContainer';
import ExpandableContainer from '../base/ExpandableContainer';

const BordersContainer = ({ rootHandleElementUpdate, componentDef, handleUpdate }) => {
   const [linkBorders, setLinkBorders] = useState(false);

   const changeAllBorderRadius = (value) => {
      handleUpdate([
         {
            path: [rootHandleElementUpdate, 'radius', 'top_left'],
            value: { val: value, unit: 'px' },
         },
         {
            path: [rootHandleElementUpdate, 'radius', 'top_right'],
            value: { val: value, unit: 'px' },
         },
         {
            path: [rootHandleElementUpdate, 'radius', 'bottom_left'],
            value: { val: value, unit: 'px' },
         },
         {
            path: [rootHandleElementUpdate, 'radius', 'bottom_right'],
            value: { val: value, unit: 'px' },
         },
      ]);
   };

   const changeAllBorderWidth = (value) => {
      handleUpdate([
         {
            path: [rootHandleElementUpdate, 'border', 'top', 'width'],
            value: { val: value, unit: 'px' },
         },
         {
            path: [rootHandleElementUpdate, 'border', 'left', 'width'],
            value: { val: value, unit: 'px' },
         },
         {
            path: [rootHandleElementUpdate, 'border', 'bottom', 'width'],
            value: { val: value, unit: 'px' },
         },
         {
            path: [rootHandleElementUpdate, 'border', 'right', 'width'],
            value: { val: value, unit: 'px' },
         },
      ]);
   };

   const changeAllBorderColor = ({ hex }) => {
      handleUpdate([
         { path: [rootHandleElementUpdate, 'border', 'top', 'color'], value: hex },
         { path: [rootHandleElementUpdate, 'border', 'left', 'color'], value: hex },
         { path: [rootHandleElementUpdate, 'border', 'bottom', 'color'], value: hex },
         { path: [rootHandleElementUpdate, 'border', 'right', 'color'], value: hex },
      ]);
   };

   const changeAllBorderStyle = (value) => {
      handleUpdate([
         { path: [rootHandleElementUpdate, 'border', 'top', 'style'], value },
         { path: [rootHandleElementUpdate, 'border', 'left', 'style'], value },
         { path: [rootHandleElementUpdate, 'border', 'bottom', 'style'], value },
         { path: [rootHandleElementUpdate, 'border', 'right', 'style'], value },
      ]);
   };

   const getBackgroundColor = () => {
      return (
         componentDef[rootHandleElementUpdate]?.border?.top?.color ||
         componentDef[rootHandleElementUpdate]?.border?.right?.color ||
         componentDef[rootHandleElementUpdate]?.border?.bottom?.color ||
         componentDef[rootHandleElementUpdate]?.border?.left?.color
      );
   };

   const [borderUnified, setBorderType] = useState(
      componentDef[rootHandleElementUpdate]?.border?.type !== 'individual'
   );

   const [focused, setFocused] = useState('');

   useEffect(() => {
      setBorderType(componentDef[rootHandleElementUpdate]?.border?.type !== 'individual');
   }, [componentDef[rootHandleElementUpdate]?.border?.type]);

   const borderOptions = [
      'none',
      'solid',
      'dotted',
      'dashed',
      'double',
      'groove',
      'ridge',
      'inset',
      'outset',
   ];

   const bordersNew = () => (
      <View style={{ flex: 1 }}>
         <View style={styles.bordersMainContainer}>
            <View style={[styles.borderRow, { marginTop: 5 }]}>
               <FieldWithUnit
                  placeholder='top-left'
                  tabIndex={1}
                  value={componentDef[rootHandleElementUpdate]?.radius?.top_left?.val}
                  unit={componentDef[rootHandleElementUpdate]?.radius?.top_left?.unit}
                  handleValueUpdate={(value) => {
                     if (linkBorders) changeAllBorderRadius(value);
                     else
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'radius', 'top_left', 'val'], value },
                        ]);
                  }}
                  handleUnitUpdate={(value) =>
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'radius', 'top_left', 'unit'], value },
                     ])
                  }
                  active
                  setFocused={() => setFocused('radius-top-left')}
                  focused={focused === 'radius-top-left'}
               />
               <FieldWithUnit
                  tabIndex={3}
                  placeholder='top'
                  active={!borderUnified}
                  value={componentDef[rootHandleElementUpdate]?.border?.top?.width?.val}
                  unit={componentDef[rootHandleElementUpdate]?.border?.top?.width?.unit}
                  handleValueUpdate={(value) => {
                     if (linkBorders) changeAllBorderWidth(value);
                     else
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'border', 'top', 'width', 'val'], value },
                        ]);
                  }}
                  handleUnitUpdate={(value) =>
                     handleUpdate([
                        {
                           path: [rootHandleElementUpdate, 'border', 'top', 'width', 'unit'],
                           value,
                        },
                     ])
                  }
                  setFocused={() => setFocused('border-top')}
                  focused={focused === 'border-top'}
                  // color={componentDef[rootHandleElementUpdate]?.border?.top?.color}
                  // handleColorUpdate={(value) =>
                  //    {
                  //       if(linkBorders)
                  //          changeAllBorderColor(value)
                  //       else
                  //          handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'top', 'color'], value: {val: value, unit: 'px'} }])
                  //    }
                  // }
                  // customSelect={componentDef[rootHandleElementUpdate]?.border?.top?.style}
                  // customSelectOptions={borderOptions}
                  // handleCustomSelectUpdate={(value) =>
                  //    handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'top', 'style'], value }])
                  // }
               />
               <FieldWithUnit
                  placeholder='top-right'
                  tabIndex={1}
                  value={componentDef[rootHandleElementUpdate]?.radius?.top_right?.val}
                  unit={componentDef[rootHandleElementUpdate]?.radius?.top_right?.unit}
                  handleValueUpdate={(value) => {
                     if (linkBorders) changeAllBorderRadius(value);
                     else
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'radius', 'top_right', 'val'], value },
                        ]);
                  }}
                  handleUnitUpdate={(value) =>
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'radius', 'top_right', 'unit'], value },
                     ])
                  }
                  active
                  setFocused={() => setFocused('radius-top-right')}
                  focused={focused === 'radius-top-right'}
               />
            </View>
            <View style={styles.marginInnerContainer}>
               <View style={[styles.marginTopText, { flex: 1 }]}>
                  <FieldWithUnit
                     tabIndex={3}
                     placeholder='left'
                     active={!borderUnified}
                     value={componentDef[rootHandleElementUpdate]?.border?.left?.width?.val}
                     unit={componentDef[rootHandleElementUpdate]?.border?.left?.width?.unit}
                     handleValueUpdate={(value) => {
                        if (linkBorders) changeAllBorderWidth(value);
                        else
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'border', 'left', 'width', 'val'], value },
                           ]);
                     }}
                     handleUnitUpdate={(value) =>
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'border', 'left', 'width', 'unit'],
                              value,
                           },
                        ])
                     }
                     setFocused={() => setFocused('border-left')}
                     focused={focused === 'border-left'}
                     color={componentDef[rootHandleElementUpdate]?.border?.left?.color}
                     // handleColorUpdate={(value) =>
                     //    {
                     //       if(linkBorders)
                     //          changeAllBorderColor(value)
                     //       else
                     //          handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'left', 'color'], value }])
                     //    }
                     // }
                     customSelect={componentDef[rootHandleElementUpdate]?.border?.left?.style}
                     customSelectOptions={borderOptions}
                     // handleCustomSelectUpdate={(value) =>
                     //    handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'left', 'style'], value }])
                     // }
                  />
               </View>
               <View style={[styles.bordersContainer, { flex: 1 }]}>
                  <TouchableOpacity
                     onPress={() => setLinkBorders(!linkBorders)}
                     style={styles.bordersInnerContainer}>
                     {linkBorders ? (
                        <FontAwesome5 name='link' size={15} color='#7B87A6' />
                     ) : (
                        <FontAwesome5 name='unlink' size={15} color='#93989B' />
                     )}
                  </TouchableOpacity>
               </View>
               <View style={[styles.marginTopText, { flex: 1 }]}>
                  <FieldWithUnit
                     tabIndex={3}
                     placeholder='right'
                     active={!borderUnified}
                     value={componentDef[rootHandleElementUpdate]?.border?.right?.width?.val}
                     unit={componentDef[rootHandleElementUpdate]?.border?.right?.width?.unit}
                     handleValueUpdate={(value) => {
                        if (linkBorders) changeAllBorderWidth(value);
                        else
                           handleUpdate([
                              {
                                 path: [rootHandleElementUpdate, 'border', 'right', 'width', 'val'],
                                 value,
                              },
                           ]);
                     }}
                     handleUnitUpdate={(value) =>
                        handleUpdate([
                           {
                              path: [rootHandleElementUpdate, 'border', 'right', 'width', 'unit'],
                              value,
                           },
                        ])
                     }
                     setFocused={() => setFocused('border-right')}
                     focused={focused === 'border-right'}
                     color={componentDef[rootHandleElementUpdate]?.border?.right?.color}
                     // handleColorUpdate={(value) =>
                     //    {
                     //       if(linkBorders)
                     //          changeAllBorderColor(value)
                     //       else
                     //          handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'right', 'color'], value }])
                     //    }
                     // }
                     customSelect={componentDef[rootHandleElementUpdate]?.border?.right?.style}
                     customSelectOptions={borderOptions}
                     // handleCustomSelectUpdate={(value) =>
                     //    handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'right', 'style'], value }])
                     // }
                  />
               </View>
            </View>
            <View style={[styles.borderRow, { marginTop: 5 }]}>
               <FieldWithUnit
                  tabIndex={1}
                  placeholder='bot-left'
                  value={componentDef[rootHandleElementUpdate]?.radius?.bottom_left?.val}
                  unit={componentDef[rootHandleElementUpdate]?.radius?.bottom_left?.unit}
                  handleValueUpdate={(value) => {
                     if (linkBorders) changeAllBorderRadius(value);
                     else
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'radius', 'bottom_left', 'val'], value },
                        ]);
                  }}
                  handleUnitUpdate={(value) =>
                     handleUpdate([
                        { path: [rootHandleElementUpdate, 'radius', 'bottom_left', 'unit'], value },
                     ])
                  }
                  active
                  setFocused={() => setFocused('radius-bottom-left')}
                  focused={focused === 'radius-bottom-left'}
               />
               <FieldWithUnit
                  tabIndex={1}
                  placeholder='bottom'
                  active={!borderUnified}
                  value={componentDef[rootHandleElementUpdate]?.border?.bottom?.width?.val}
                  unit={componentDef[rootHandleElementUpdate]?.border?.bottom?.width?.unit}
                  handleValueUpdate={(value) => {
                     if (linkBorders) changeAllBorderWidth(value);
                     else
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'border', 'top', 'width', 'val'], value },
                        ]);
                  }}
                  handleUnitUpdate={(value) =>
                     handleUpdate([
                        {
                           path: [rootHandleElementUpdate, 'border', 'top', 'width', 'unit'],
                           value,
                        },
                     ])
                  }
                  setFocused={() => setFocused('border-bottom')}
                  focused={focused === 'border-bottom'}
                  color={componentDef[rootHandleElementUpdate]?.border?.bottom?.color}
                  // handleColorUpdate={(value) =>
                  //    {
                  //       if(linkBorders)
                  //          changeAllBorderColor(value)
                  //       else
                  //          handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'bottom', 'color'], value: {val: value, unit: 'px'} }])
                  //    }
                  // }
                  customSelect={componentDef[rootHandleElementUpdate]?.border?.bottom?.style}
                  customSelectOptions={borderOptions}
                  // handleCustomSelectUpdate={(value) =>
                  //    handleUpdate([{ path: [rootHandleElementUpdate, 'border', 'bottom', 'style'], value }])
                  // }
               />
               <FieldWithUnit
                  tabIndex={1}
                  placeholder='bot-right'
                  value={componentDef[rootHandleElementUpdate]?.radius?.bottom_right?.val}
                  unit={componentDef[rootHandleElementUpdate]?.radius?.bottom_right?.unit}
                  handleValueUpdate={(value) => {
                     if (linkBorders) changeAllBorderRadius(value);
                     else
                        handleUpdate([
                           { path: [rootHandleElementUpdate, 'radius', 'bottom_right', 'val'], value },
                        ]);
                  }}
                  handleUnitUpdate={(value) =>
                     handleUpdate([
                        {
                           path: [rootHandleElementUpdate, 'radius', 'bottom_right', 'unit'],
                           value,
                        },
                     ])
                  }
                  active
                  setFocused={() => setFocused('radius-bottom-right')}
                  focused={focused === 'radius-bottom-right'}
               />
            </View>
         </View>
      </View>
   );

   return (
      <ExpandableContainer title='Borders'>
         {bordersNew()}
         <View style={[styles.rowStyling, { marginTop: 5 }]}>
            <DefaultInputContainer
               style={{borderWidth: 1, borderColor: '#E0E4F4',}}
               componentDef={componentDef}
               onItemPress={changeAllBorderStyle}
               value={
                  componentDef[rootHandleElementUpdate]?.border?.top?.style ||
                  componentDef[rootHandleElementUpdate]?.border?.right?.style ||
                  componentDef[rootHandleElementUpdate]?.border?.bottom?.style ||
                  componentDef[rootHandleElementUpdate]?.border?.left?.style
               }
               data={borderOptions}
               icon={
                  <svg
                     width='18'
                     height='18'
                     viewBox='0 0 18 18'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <rect x='1.5' y='12' width='15' height='3.75' rx='1.875' fill='#646D72' />
                     <rect x='1.5' y='8.25' width='15' height='2.25' rx='1.125' fill='#646D72' />
                     <rect x='1.5' y='5.25' width='15' height='1.5' rx='0.75' fill='#646D72' />
                     <rect x='1.5' y='3' width='15' height='0.75' rx='0.375' fill='#646D72' />
                  </svg>
               }
            />
            <DefaultInputContainer
               style={{borderWidth: 1, borderColor: '#E0E4F4',}}
               componentDef={componentDef}
               onChangeText={changeAllBorderWidth}
               data={borderOptions}
               value={
                  componentDef[rootHandleElementUpdate]?.border?.top?.width?.val ||
                  componentDef[rootHandleElementUpdate]?.border?.right?.width?.val ||
                  componentDef[rootHandleElementUpdate]?.border?.bottom?.width?.val ||
                  componentDef[rootHandleElementUpdate]?.border?.left?.width?.val
               }
               icon={
                  <svg
                     width='18'
                     height='18'
                     viewBox='0 0 24 24'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z'
                        stroke='#93989B'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                  </svg>
               }
            />
         </View>
         <View style={[styles.rowStyling, { marginTop: 5 }]}>
            <DefaultInputContainer
               style={{borderWidth: 1, borderColor: '#E0E4F4',}}
               componentDef={componentDef}
               onChangeText={changeAllBorderColor}
               value={getBackgroundColor()}
               isColor
               placeholder='Select border color'
               childLeft={
                  <View
                     style={{
                        width: 34,
                        height: 30,
                        borderRadius: 4,
                        backgroundColor: getBackgroundColor(),
                     }}
                  />
               }
            />
         </View>
      </ExpandableContainer>
   );
};

export default BordersContainer;

const styles = StyleSheet.create({
   marginsContainer: {
      width: 260,
      height: 200,
      borderRadius: 4,
      borderWidth: 4,
      borderColor: '#DFE2EE',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
   },
   bordersMainContainer: {
      width: 260,
      height: 100,
      borderRadius: 4,
      borderWidth: 4,
      borderColor: '#DFE2EE',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
   },
   marginInnerContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paddingTopContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paddingInnerContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 20,
   },
   bordersInnerContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 20,
   },
   paddingsContainer: {
      width: 150,
      height: 120,
      borderRadius: 4,
      borderWidth: 4,
      backgroundColor: '#F8FAFC',
      borderColor: '#DFE2EE',
   },
   bordersContainer: {
      width: 120,
      height: 40,
      borderRadius: 4,
      borderWidth: 4,
      backgroundColor: '#F8FAFC',
      borderColor: '#DFE2EE',
   },
   innerBordersContainer: {
      height: 17,
      width: 57,
      borderBottomRightRadius: 4,
      backgroundColor: '#DFE2EE',
      top: 0,
      left: 0,
      position: 'absolute',
   },
   marginText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      textAlign: 'center',
      width: 60,
   },
   marginTopText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   borderRow: {
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 20,
   },
   rowStyling: {
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 8,
   },
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10,
   },
   row: {
      flex: 1,
      flexDirection: 'row',
      padding: 2,
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'fit-content',
   },
   extend: {
      flexGrow: 1,
      flexShrink: 1,
   },
   fit: {
      flexGrow: 0,
      flexShrink: 1,
   },
   centered: {
      flexDirection: 'row',
      justifyContent: 'center',
   },
   label: {
      fontSize: 8,
   },
   box: {
      flex: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
   },
   input: {
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
      fontSize: 8,
   },
   multilineInput: {
      borderWidth: 1,
      minHeight: 30,
      flexGrow: 1,
      height: 'fit-content',
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
   },
   picker: {
      height: 24,
      fontSize: 8,
      marginBottom: 10,
      caret: 'hidden',
   },
   valunit: {
      flexDirection: 'row',
   },
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   },
});
