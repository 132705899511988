import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { View, Picker, Modal, TextInput, Text, Pressable, StyleSheet, Alert } from 'react-native';
import { setPage } from '../../store/EditorSlice';
import { createPage, updatePage } from '../../store/ProjectSlice';

const ScreensNavigation = ({ projectId, currentScreen, projectScreens }) => {
    const dispatch = useDispatch();

    const [modalVisible, setModalVisible] = useState(false);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    const [newPageName, setNewPageName] = useState(currentScreen ? projectScreens[currentScreen].name : '');

    const handleAddPage = () => {
        setModalVisible(true);
    };

    const handleChangePage = (page) => {
        dispatch(setPage(page));
    }

    const handleDeletePage = (page) => {
        Alert.alert(
            'Delete page',
            `Are you sure you want to delete the page "${page.name}"?`,
            [
                {
                    text: 'Cancel',
                    style: 'cancel',
                },
                {
                    text: 'Delete',
                    style: 'destructive',
                    onPress: () => dispatch(updatePage(projectId, page.uid, {})),
                },
            ],
        );
    };

    const handleSavePage = () => {
        dispatch(createPage(projectId, { name: newPageName }));
        setModalVisible(false);
        setSelectedPage(null);
        setNewPageName('');
    };

    const handleCancelPage = () => {
        setModalVisible(false);
        setSelectedPage(null);
        setNewPageName('');
    };

    const handleContextMenuEdit = () => {
        dispatch(updatePage(projectId, selectedPage.uid, { ...selectedPage, name: newPageName }));
        setModalVisible(false);
        setSelectedPage(null);
        setNewPageName('');
    };

    const handleContextMenuDelete = () => {
        handleDeletePage(selectedPage);
        setModalVisible(false);
        setSelectedPage(null);
        setNewPageName('');
    };

    return (
        <View style={styles.container}>
            <View style={styles.menuContainer}>
                <Text style={styles.modalTitle}>Edit page</Text>
                <TextInput
                    style={styles.modalInput}
                    value={newPageName}
                    onChangeText={setNewPageName}
                    placeholder="Page name"
                />
                <View style={styles.modalButtons}>
                    <Pressable style={styles.modalButton} onPress={handleContextMenuEdit}>
                        <Text style={styles.modalButtonText}>Save</Text>
                    </Pressable>
                    <Pressable style={styles.modalButton} onPress={handleContextMenuDelete}>
                        <Text style={[styles.modalButtonText, styles.modalButtonDeleteText]}>Delete</Text>
                    </Pressable>
                </View>

                <Picker
                    style={styles.picker}
                    selectedValue={currentScreen}
                    onValueChange={(val) => { val === '__new__' ? handleAddPage() : handleChangePage(val) }}
                >
                    {
                        Object.values(projectScreens).map((page) =>
                            <Picker.Item
                                key={page.uid}
                                label={page.name}
                                value={page.uid}
                            />
                        )
                    }
                    <Picker.Item label="Add new page" value="__new__" />
                </Picker>
            </View>
            <Modal
                visible={modalVisible}
                transparent={true}
                animationType="slide"
                onRequestClose={handleCancelPage}
            >
                <View style={styles.modalContainer}>
                    <Text style={styles.modalTitle}>Add new page</Text>
                    <TextInput
                        style={styles.modalInput}
                        value={newPageName}
                        onChangeText={setNewPageName}
                        placeholder="Page name"
                    />
                    <View style={styles.modalButtons}>
                        <Pressable style={styles.modalButton} onPress={handleCancelPage}>
                            <Text style={styles.modalButtonText}>Cancel</Text>
                        </Pressable>
                        <Pressable style={styles.modalButton} onPress={handleSavePage}>
                            <Text style={styles.modalButtonText}>Save</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        minHeight: 60,
        paddingHorizontal: 10,
    },
    menuContainer: {
        flex: 1,
        height: '100%',
        justifyContent: 'center',
    },
    picker: {
        marginTop: 10,
        marginBottom: 10,
        height: '100%',
        width: '100%',
    },
    modalBackground: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: 20,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    modalInput: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        padding: 10,
        marginBottom: 20,
        width: '100%',
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    modalButton: {
        backgroundColor: '#ccc',
        borderRadius: 4,
        padding: 10,
        marginHorizontal: 5,
        flex: 1,
        alignItems: 'center',
    },
    modalButtonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    modalButtonDeleteText: {
        backgroundColor: 'red',
    },
});

export default ScreensNavigation;