import { useState } from 'react';
import { StyleSheet, FlatList } from 'react-native';
import _ from 'lodash';
import ColorItem from './ColorItem';

const ColorItems = ({ colors, handleUpdate}) => {
   if(!colors)
      return <></>;
   return (
      <FlatList
         style={{borderColor: '#E0E4F4', borderWidth: 1, width: 631}}
         data={Object.keys(colors)}
         renderItem={({item}) => 
            <ColorItem handleUpdate={handleUpdate} item={colors[item]} />
        }
      />
   )
}

export default ColorItems;

const styles = StyleSheet.create({
   container: {
      paddingHorizontal: '20px',
   },
   emptyText: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      border: '0.5px solid #F8F8F8',
   },
   dataRow: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
   },
   groupText: {
      color: '#93989B',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameText: {
      color: '#262626',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameValue: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameDescription: {
      color: '#B8BDD0',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 15,
   },
});
