import { createSlice } from '@reduxjs/toolkit';

export const FIELDS = {
   RICH_TEXT: 'RICH_TEXT',
};

const editorSlice = createSlice({
   name: 'editor',
   initialState: {
      project: null,
      view: 'Editor',
      editingLogicId: null,
      page: null,
      hovered: null,
      selected: null,
      editingField: null,
      selectedDesignSystemView: 'Settings',
      selectedCustomStyle: null,
      selectedLogicAction: null,
      
   },
   reducers: {
      setSelectedLogicAction: (state, action) => {
         return {
            ...state,
            selectedLogicAction: action.payload,
         };
      },
      selectEditorProject: (state, action) => {
         if (typeof action.payload === 'string' && action.payload !== '') {
            return {
               ...state,
               project: action.payload,
            };
         }
         console.error(`Error: Trying to set ${action.payload} as editor projectId`);
         return state;
      },
      selectEditorProject: (state, action) => {
         if (typeof action.payload === 'string' && action.payload !== '') {
            return {
               ...state,
               project: action.payload,
            };
         }
         console.error(`Error: Trying to set ${action.payload} as editor projectId`);
         return state;
      },
      setEditorView: (state, action) => {
         if (typeof action.payload === 'string' && action.payload !== '') {
            return {
               ...state,
               view: action.payload,
            };
         }
         if (state && state.view !== '') {
            console.error(`Error: Trying to set ${action.payload} as editor view`);
            return state;
         }
         return { ...state, view: 'Editor' };
      },
      setEditingLogicId: (state, action) => {
         if (typeof action.payload === 'string' && action.payload !== '') {
            return {
               ...state,
               editingLogicId: action.payload,
            };
         }
         console.error(`Error: Trying to set ${action.payload} as currently editing logic ID`);
         return state;
      },
      closeLogicEditing: (state, _) => {
         return {
            ...state,
            editingLogicId: null,
         };
      },
      setPage: (state, action) => {
         if (typeof action.payload === 'string' && action.payload !== '') {
            return {
               ...state,
               page: action.payload,
            };
         }
         console.error(`Error: Trying to set ${action.payload} as editor pageId`);
         return state;
      },
      setSelectedDesignSystemView: (state, action) => {
         return {
            ...state,
            selectedDesignSystemView: action.payload,
         };
      },
      setSelectedCustomStyle: (state, action) => {
         return {
            ...state,
            selectedCustomStyle: action.payload,
         };
      },
      setHovered: (state, action) => {
         // if (typeof action.payload === 'string' && action.payload !== '') {
         return {
            ...state,
            hovered: action.payload,
         };
         // }
         // console.error(`Error: Trying to set ${action.payload} as editor pageId`)
         // return state;
      },
      selectComponent: (state, action) => {
         if (Array.isArray(action.payload) && action.payload.length > 0) {
            if (JSON.stringify(state.selected) === JSON.stringify(action.payload)) {
               return { ...state, selected: null };
            }
            return {
               ...state,
               selected: action.payload,
            };
         }
         console.error(`Error: Trying to set ${action.payload} as editor selected`);
         return state;
      },
      setEditingField: (state, action) => {
         if (
            typeof action.payload === 'object' &&
            action.payload.type &&
            FIELDS[action.payload.type]
         ) {
            return {
               ...state,
               editingField: action.payload,
            };
         }
         return {
            ...state,
            editingField: null,
         };
      },
   },
});

export default editorSlice.reducer;
export const {
   selectEditorProject,
   setEditorView,
   setPage,
   setHovered,
   selectComponent,
   setEditingLogicId,
   closeLogicEditing,
   setSelectedDesignSystemView,
   setSelectedCustomStyle,
   setSelectedLogicAction
} = editorSlice.actions;
