import _ from 'lodash';
import { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedCustomStyle } from './../../../../store/EditorSlice';
import { setUpdateElementsStyle } from './../../../../store/ProjectSlice';
import MetaComponentContainer from './ElementContainer';
import ElementItems from './ElementItems';

function groupByType(objects) {
   const result = {};
   for (const uid in objects) {
      const object = objects[uid];
      const type = object.type;
      if (!result[type]) {
         result[type] = {};
      }
      result[type][uid] = object;
   }

   return result;
}

const Elements = ({ handleUpdate }) => {
   const dispatch = useDispatch();
   const user_styles = useSelector(
      (state) => state.project?.definition?.design_system?.user_styles
   );
   const [userStyles, setUserStyles] = useState(groupByType(user_styles) || {});
   const selectedCustomStyle = useSelector((state) => state.editor.selectedCustomStyle);

   useEffect(() => {
      setUserStyles(groupByType(user_styles));
   }, [user_styles]);

   useEffect(() => {
      if (selectedCustomStyle) {
         dispatch(setSelectedCustomStyle(selectedCustomStyle));
         dispatch(
            setUpdateElementsStyle({ key: 'childComponent', style: selectedCustomStyle.uid })
         );
      }
      // dispatch(updateComponent(['rootElementContainer', 'childComponent'], [{path: ['style'], value: selectedCustomStyle?.uid || ""}]));
   }, [user_styles]);

   return (
      <View style={styles.container}>
         <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flex: 1 }}>
               <View style={styles.textContainer}>
                  <Text style={styles.titleText}>All Elements</Text>
                  <Text style={styles.descText}>
                     You will be able to find all of these items when editing your project
                  </Text>
               </View>
               <FlatList
                  data={Object.keys(userStyles)}
                  renderItem={({ item }) => (
                     <View>
                        <Text styles={styles.groupText}>{item}</Text>
                        <ElementItems handleUpdate={handleUpdate} userStyles={userStyles[item]} />
                     </View>
                  )}
               />
            </View>
            {selectedCustomStyle && (
               <MetaComponentContainer
                  selectedCustomStyle={selectedCustomStyle}
                  handleUpdate={handleUpdate}
               />
            )}
         </View>
      </View>
   );
};

export default Elements;

const styles = StyleSheet.create({
   container: {
      paddingHorizontal: '20px',
   },
   textContainer: {
      marginBottom: 20,
   },
   descText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 19,
      color: '#646D72',
   },
   fontDescContainer: {
      justifyContent: 'center',
      alignItems: 'center',
   },
   titleText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 18,
      lineHeight: 21,
      color: '#262626',
   },
   emptyText: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      border: '0.5px solid #F8F8F8',
   },
   dataRow: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
   },
   groupText: {
      color: '#93989B',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameText: {
      color: '#262626',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameValue: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 19,
   },
   colorNameDescription: {
      color: '#B8BDD0',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 15,
   },
});
