import { Text, TouchableOpacity, StyleSheet} from "react-native";

const Tab = ({ value, onPress, style, ...props }) => {
    return (
        <TouchableOpacity onPress={onPress} style={style} {...props}>
            <Text style={style.text}>{value}</Text>
        </TouchableOpacity>
    );
};

export default Tab;

const styles = StyleSheet.create({
    text: {
        fontSize: '18px',
        lineHeight: '120%',
        outlineStyle: 'none',
    }
});
