import { TSectionBuilderPayload } from "../types";
import { generateComponent } from "../generators";
import { backgroundHeroImageContext, heroTitleContext, imagePromptGenerationContext } from "../../../consts/prompts";
import { askAI } from "../openai";

export const createHero = (payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<void> => {
   if (debug > 2) console.log('[CreateHero] Start')
   const { description, sections, rootId, rootIndex, pageId } = payload;
   const pageContext = getState().project.definition.pages[pageId].description
   // image prompt
   const bgImagePrompt = (await askAI(
      imagePromptGenerationContext.concat({
         role: 'user',
         content: 'A visually stunning item on a white background for the hero image of the following project\'s landing page:\n' 
            + pageContext 
            + '\n\nTHE ITEM SHOULD BE ON THE RIGHT SIDE OF THE PICTURE WITH A UNIFIED WHITE BACKGROUND. !!! THE RIGHT SIDE SHOULD BE EMPTY !!!'
      }),
      debug
   )).content
   // Create Hero
   const heroDef = await dispatch(generateComponent(
      {
         name: 'Hero Section',
         type: 'box',
         description: description,
         custom_styling: {
            height: {
               type: 'fixed',
               fixed: { val: 100, unit: 'vh' },
            },
            margin: {
               top: { val: 1, unit: 'rf' },
               right: { val: 1, unit: 'rf' },
               bottom: { val: 1, unit: 'rf' },
               left: { val: 1, unit: 'rf' },
            },
            radius: {
               top_left: { val: 1, unit: 'rf' },
               top_right: { val: 1, unit: 'rf' },
               bottom_right: { val: 1, unit: 'rf' },
               bottom_left: { val: 1, unit: 'rf' },
            },
            // background: { type: 'image', image: { src: '', cover: true } },
            background: { type: 'color', color: '@secondary_bg' },
            layout: {
               direction: 'row',
               distribution: 'center',
            }
         },
      },
      [{path: ['custom_styling', 'background', 'image', 'src'], type: 'image', context: bgImagePrompt }],
      [rootId],
      rootIndex,
      debug
   ))
   // Create content box
   const heroContentDef = await dispatch(generateComponent(
      {
         name: 'Hero Content',
         type: 'box',
         description: 'Contains the content (title, subtitle) of the Hero section.',
         custom_styling: {
            height: {
               type: 'fit',
            },
            width: {
               type: 'fixed',
               fixed: { val: 50, unit: '%' },
               min_width: { val: 20, unit: 'rf' },
            },
            layout: {
               direction: 'column',
               row_gap: { val: 1.5, unit: 'rf' }
            },
            position: {
               auto: {
                  position: 'center',
               }
            },
            padding: {
               top: { val: 1, unit: 'rf' },
               right: { val: 1, unit: 'rf' },
               bottom: { val: 1, unit: 'rf' },
               left: { val: 1, unit: 'rf' },
            },
         },
      },
      [],
      heroDef.composite_key,
      0,
      debug
   ))
   // Generate title
   const itemsAsStr = (await askAI(
      heroTitleContext.concat({ role: 'user', content: pageContext }), 
      debug
   )).content
   const [title, subtitle] = itemsAsStr.split('\n')
   // Create hero title
   const heroTitleDef = await dispatch(generateComponent(
      {
         name: 'Main Title',
         type: 'text',
         description: `Main title of the app`,
         properties: { text: title },
         custom_styling: {
            width: {
               type: 'fit',
            },
            text: {
               color: '@secondary_h1',
               alignment: "left",
               size: { val: 7, unit: 'rf' },
               line_height: 1
            }
         },
      },
      [],
      heroContentDef.composite_key,
      0,
      debug
   ))
   // Create hero sub title
   const heroSubTitleDef = await dispatch(generateComponent(
      {
         name: 'Subtitle',
         type: 'text',
         description: 'Main subtitle of the app.',
         properties: { text: subtitle },
         custom_styling: {
            width: {
               type: 'fit',
            },
            text: {
               color: '@secondary_h3',
               alignment: "left",
               size: { val: 3, unit: 'rf' },
               line_height: 1
            }
         },
      },
      [],
      heroContentDef.composite_key,
      1,
      debug
   ))
   // Create background image
   // const bgImagePrompt = (await askAI(
   //    imagePromptGenerationContext.concat({
   //       role: 'user',
   //       content: 'A visually stunning item on a transparent background for the hero image of the following project\'s landing page:\n' + prompt
   //    }),
   //    debug
   // )).content
   // const bgImage = await dispatch(generateComponent(
   //    {
   //       name: 'Hero image',
   //       type: 'image',
   //       description: 'Hero background image',
   //       properties: { image: 'https://th.bing.com/th/id/OIP.KhEKUkd4eL9dJObSIXjmCwHaHa?rs=1&pid=ImgDetMain' },
   //       custom_styling: {
   //          position: {
   //             type: 'relative',
   //             relative: { top: 0, right: 0, bottom: 0, }
   //          }
   //       },
   //    },
   //    [{path: ['properties', 'image'], type: 'image', context: bgImagePrompt }],
   //    heroContentDef.composite_key,
   //    1,
   //    debug
   // ))
   const bgImage = await dispatch(generateComponent(
      {
         name: 'Hero image',
         type: 'image',
         description: 'Hero background image',
         properties: { image: 'https://th.bing.com/th/id/OIP.KhEKUkd4eL9dJObSIXjmCwHaHa?rs=1&pid=ImgDetMain' },
         custom_styling: {
            width: {
               type: 'fixed',
               fixed: { val: 50, unit: '%' },
               min_width: { val: 20, unit: 'rf' },
            },
            height: {
               type: 'expand',
            },
            // position: {
            //    type: 'relative',
            //    relative: { top: 0, right: 0, bottom: 0, }
            // }
         },
      },
      [{path: ['properties', 'image'], type: 'image', context: bgImagePrompt }],
      heroDef.composite_key,
      1,
      debug
   ))
}