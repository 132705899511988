import { Entypo } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { updateComponent } from './../../store/ProjectSlice';
import EngineContainer from './EngineContainer';
import LogicWorkflowEditor from './LogicWorkflowEditor';
import ConfigPane from '../../components/ConfigPane';
import ProjectNavigator from '../../components/ProjectNavigator/ProjectNavigator';
import { setPage } from '../../store/EditorSlice';

const { width, height } = Dimensions.get('window'),
   vw = width / 100,
   vh = height / 100;

const ProjectEditor = ({ projectId }) => {
   const dispatch = useDispatch();

   const [initializing, setInitializing] = useState(true);

   const project = useSelector((state) => state.project.definition);
   const pageId = useSelector((state) => state.editor.page);

   const page = project?.pages[pageId];
   const userStyles = useSelector((state) => state.project?.definition?.design_system?.user_styles);

   const editingLogicId = useSelector((state) => state.editor.editingLogicId);
   const selectedKey = useSelector((state) => state.globals.selected);

   const [selectedComponent, setSelectedComponent] = useState(null);

   const [screenSize, setScreenSize] = useState('large');
   const [showRightContainer, setShowRightContainer] = useState(true);

   useEffect(() => {
      setShowRightContainer(true);
   }, [selectedComponent]);

   useEffect(() => {
      if (initializing) {
         dispatch(setPage(project.page_index));
         setInitializing(false);
      }
   }, []);

   useEffect(() => {
      if (Array.isArray(selectedKey) && selectedKey.length > 0) {
         setSelectedComponent(project.components[selectedKey.slice(-1)]);
      }
   }, [selectedKey, project]);

   useEffect(() => {
      const handleResize = () => {
         const { width } = Dimensions.get('window');
         setScreenSize(width < 768 ? 'small' : 'large');
      };
      Dimensions.addEventListener('change', handleResize);

      return () => {
         Dimensions.removeEventListener('change', handleResize);
      };
   }, []);

   useEffect(
      () => () => {
         debouncedUpdate.flush();
      },
      [debouncedUpdate]
   );

   // trigger pending debounced updates on component unmount
   useEffect(
      () => () => {
         debouncedUpdate.flush();
      },
      [debouncedUpdate]
   );

   // optimizing network updates
   const [pendingChanges, setPendingChanges] = useState([]);
   const debouncedUpdate = useDebouncedCallback(() => {
      dispatch(updateComponent(selectedComponent.composite_key, pendingChanges));
      setPendingChanges([]);
   }, 300);

   // TODO: optimisations => use Memo?
   const handleUpdate = (payload = []) => {
      if (payload.length > 0) {
         if (pendingChanges.length > 0) {
            const changes = [...pendingChanges, ...payload];
            setPendingChanges(changes);
         } else {
            setPendingChanges(payload);
         }
         debouncedUpdate();
      }
   };

   const userStyle = selectedComponent?.style ? userStyles[selectedComponent.style]?.styling : {};

   const selected = selectedComponent
      ? {
           ...selectedComponent,
           custom_styling: {
              ...userStyle,
              ...selectedComponent.custom_styling,
           },
        }
      : null;

   if (initializing) {
      return null;
   }

   if (editingLogicId && selectedComponent?.logic?.[editingLogicId]) {
      return <LogicWorkflowEditor logicWorkflow={selectedComponent.logic[editingLogicId]} />;
   }

   return editingLogicId && selectedComponent?.logic?.[editingLogicId] ? (
      <LogicWorkflowEditor logicWorkflow={selectedComponent.logic[editingLogicId]} />
   ) : (
      <View style={styles.container}>
         {/* Left Navigation Panel */}
         {(screenSize === 'large' || screenSize === 'small') && (
            <ProjectNavigator
               handleUpdate={handleUpdate}
               componentDef={project.pages[pageId || project.page_index]}
            />
         )}

         {/* GUI Editor */}
         <View style={[styles.middleContent]}>
            <EngineContainer componentId={page?.root_component} />
         </View>

         {/* Right Properties Panel */}
         {selectedComponent && (
            <View style={styles.rightPanel}>
               <TouchableOpacity
                  onPress={() => setShowRightContainer(!showRightContainer)}
                  style={{
                     alignItems: 'center',
                     justifyContent: 'center',
                     backgroundColor: '#FFFFFF',
                     top: '50%',
                     right: 0,
                     zIndex: 1,
                     height: 50,
                     borderTopLeftRadius: 50,
                     borderTopLeftColor: '#000000',
                     borderBottomLeftRadius: 50,
                     borderBottomLeftColor: '#000000',
                     borderWidth: 1,
                     position: 'absolute',
                  }}>
                  {showRightContainer ? (
                     <Entypo name='chevron-thin-right' size={24} color='#93989B' />
                  ) : (
                     <Entypo name='chevron-thin-left' size={24} color='#93989B' />
                  )}
               </TouchableOpacity>
               {showRightContainer && (
                  <ConfigPane
                     rootHandleElementUpdate='custom_styling'
                     handleUpdate={handleUpdate}
                     componentDef={selected}
                  />
               )}
            </View>
         )}
      </View>
   );
};

export default ProjectEditor;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      flexDirection: 'row',
   },
   toggleButton: {
      position: 'absolute',
      top: 16,
      left: 16,
      zIndex: 2, // Ensure the button is displayed above the overlays
   },
   middleContent: {
      flex: 1,
      position: 'relative',
   },
   leftPanel: {
      width: 300,
      borderColor: '#E0E4F4',
      borderWidth: 1,
   },
   rightPanel: {
      maxWidth: 300,
   },
});
