import React, { useState } from 'react';
import {
   Text,
   StyleSheet,
   View,
   Dimensions,
   TouchableOpacity,
   Modal,
   Pressable,
   TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { setPage } from '../../store/EditorSlice';
import {
   deletePage,
   duplicatePage,
   markPageAsIndex,
   updateProject,
} from '../../store/ProjectSlice';
import DefaultModal from '../base/DefaultModal';

const PageItem = ({ item }) => {
   const [isHover, setIsHover] = useState(false);
   const dispatch = useDispatch();
   const page = useSelector((state) => state.editor.page);

   const page_index = useSelector((state) => state.project.definition.page_index);

   const [showModal, setShowModal] = useState(false);
   const [showChangeNameModal, setShowChangeNameModal] = useState(false);
   const [currentPageName, setCurrentPageName] = useState(item?.name || '');

   const handleDelete = () => {
      dispatch(deletePage(page));
      setShowModal(false);
   };

   const handleMarkPageAsIndex = () => {
      dispatch(markPageAsIndex(page));
      setShowModal(false);
   };

   const duplicateComponent = () => {
      dispatch(duplicatePage(page));
      setShowModal(false);
   };

   const changePageName = () => {
      setShowModal(false);
      setShowChangeNameModal(true);
   };

   const [buttonLayout, setButtonLayout] = useState({
      height: 0,
      width: 0,
      top: 0,
      left: 0,
      x: 0,
      y: 0,
   });

   const handleMeasure = (event) => {
      setButtonLayout(event?.nativeEvent?.layout);
   };

   const calculateModalPosition = () => {
      const top =
         buttonLayout.top > Dimensions.get('window').height
            ? Dimensions.get('window').height
            : buttonLayout.top;
      return {
         top: top + 15,
         left: buttonLayout.left + 230,
         backgroundColor: '#ffffff',
      };
   };

   return (
      <>
         <TouchableOpacity
            onLayout={handleMeasure}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onPress={() => {
               if (item.uid !== page) {
                  dispatch(setPage(item?.uid));
               }
            }}
            style={[
               styles.mainContainer,
               isHover ? styles.item_hover : styles.item,
               item.uid === page ? styles.item_hover : undefined,
            ]}>
            {page_index === item.uid && <View style={styles.newButton} />}
            <Text style={styles.textStyle}>{item.name}</Text>
            <Pressable style={{ paddingRight: 15 }} onPress={() => setShowModal(true)}>
               <svg
                  width='12'
                  height='4'
                  viewBox='0 0 12 4'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M5.99967 2.58366C6.32184 2.58366 6.58301 2.32249 6.58301 2.00033C6.58301 1.67816 6.32184 1.41699 5.99967 1.41699C5.67751 1.41699 5.41634 1.67816 5.41634 2.00033C5.41634 2.32249 5.67751 2.58366 5.99967 2.58366Z'
                     stroke='#646D72'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
                  <path
                     d='M10.083 2.58366C10.4052 2.58366 10.6663 2.32249 10.6663 2.00033C10.6663 1.67816 10.4052 1.41699 10.083 1.41699C9.76084 1.41699 9.49968 1.67816 9.49968 2.00033C9.49968 2.32249 9.76084 2.58366 10.083 2.58366Z'
                     stroke='#646D72'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
                  <path
                     d='M1.91634 2.58366C2.23851 2.58366 2.49967 2.32249 2.49967 2.00033C2.49967 1.67816 2.23851 1.41699 1.91634 1.41699C1.59418 1.41699 1.33301 1.67816 1.33301 2.00033C1.33301 2.32249 1.59418 2.58366 1.91634 2.58366Z'
                     stroke='#646D72'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </Pressable>
         </TouchableOpacity>
         {showChangeNameModal && (
            <DefaultModal
               title='Change name'
               description='Imagine new title'
               visible={showChangeNameModal}
               onSuccess={() => {
                  dispatch(
                     updateProject([{ path: ['pages', page, 'name'], value: currentPageName }])
                  );
                  setShowChangeNameModal(!showChangeNameModal);
               }}
               onError={() => setShowChangeNameModal(!showChangeNameModal)}
               okText='CHANGE NAME'
               cancelText='CANCEL'>
               <TextInput
                  placeholder='Page name'
                  autoFocus
                  value={currentPageName}
                  onChangeText={setCurrentPageName}
                  style={styles.input}
               />
            </DefaultModal>
         )}
         {showModal && (
            <Modal transparent visible={showModal}>
               <View style={[styles.modalContainer, calculateModalPosition()]}>
                  <TouchableOpacity
                     style={{ width: 10, height: 10, position: 'absolute', top: 10, right: 10 }}
                     onPress={() => setShowModal(false)}>
                     <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M11 11L1 1M1 11L11 1'
                           stroke='#757677'
                           strokeWidth='1.5'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  </TouchableOpacity>
                  <TouchableOpacity
                     style={[styles.modalButton, { marginTop: 15 }]}
                     onPress={changePageName}>
                     <View>
                        <svg
                           width='20'
                           height='20'
                           viewBox='0 0 20 20'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <g clipPath='url(#clip0_2123_36660)'>
                              <path
                                 d='M6.66602 6.66699V4.33366C6.66602 3.40024 6.66602 2.93353 6.84767 2.57701C7.00746 2.2634 7.26243 2.00844 7.57603 1.84865C7.93255 1.66699 8.39926 1.66699 9.33268 1.66699H15.666C16.5994 1.66699 17.0661 1.66699 17.4227 1.84865C17.7363 2.00844 17.9912 2.2634 18.151 2.57701C18.3327 2.93353 18.3327 3.40024 18.3327 4.33366V10.667C18.3327 11.6004 18.3327 12.0671 18.151 12.4236C17.9912 12.7372 17.7363 12.9922 17.4227 13.152C17.0661 13.3337 16.5994 13.3337 15.666 13.3337H13.3327M4.33268 18.3337H10.666C11.5994 18.3337 12.0661 18.3337 12.4227 18.152C12.7363 17.9922 12.9912 17.7372 13.151 17.4236C13.3327 17.0671 13.3327 16.6004 13.3327 15.667V9.33366C13.3327 8.40024 13.3327 7.93353 13.151 7.57701C12.9912 7.2634 12.7363 7.00844 12.4227 6.84865C12.0661 6.66699 11.5994 6.66699 10.666 6.66699H4.33268C3.39926 6.66699 2.93255 6.66699 2.57603 6.84865C2.26243 7.00844 2.00746 7.2634 1.84767 7.57701C1.66602 7.93353 1.66602 8.40024 1.66602 9.33366V15.667C1.66602 16.6004 1.66602 17.0671 1.84767 17.4236C2.00746 17.7372 2.26243 17.9922 2.57603 18.152C2.93255 18.3337 3.39926 18.3337 4.33268 18.3337Z'
                                 stroke='#50585C'
                                 strokeWidth='1.5'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                           </g>
                           <defs>
                              <clipPath id='clip0_2123_36660'>
                                 <rect width='20' height='20' fill='white' />
                              </clipPath>
                           </defs>
                        </svg>
                     </View>
                     <Text style={styles.modalText}>Change name</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={[styles.modalButton]} onPress={duplicateComponent}>
                     <View>
                        <svg
                           width='20'
                           height='20'
                           viewBox='0 0 20 20'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <g clipPath='url(#clip0_2123_36660)'>
                              <path
                                 d='M6.66602 6.66699V4.33366C6.66602 3.40024 6.66602 2.93353 6.84767 2.57701C7.00746 2.2634 7.26243 2.00844 7.57603 1.84865C7.93255 1.66699 8.39926 1.66699 9.33268 1.66699H15.666C16.5994 1.66699 17.0661 1.66699 17.4227 1.84865C17.7363 2.00844 17.9912 2.2634 18.151 2.57701C18.3327 2.93353 18.3327 3.40024 18.3327 4.33366V10.667C18.3327 11.6004 18.3327 12.0671 18.151 12.4236C17.9912 12.7372 17.7363 12.9922 17.4227 13.152C17.0661 13.3337 16.5994 13.3337 15.666 13.3337H13.3327M4.33268 18.3337H10.666C11.5994 18.3337 12.0661 18.3337 12.4227 18.152C12.7363 17.9922 12.9912 17.7372 13.151 17.4236C13.3327 17.0671 13.3327 16.6004 13.3327 15.667V9.33366C13.3327 8.40024 13.3327 7.93353 13.151 7.57701C12.9912 7.2634 12.7363 7.00844 12.4227 6.84865C12.0661 6.66699 11.5994 6.66699 10.666 6.66699H4.33268C3.39926 6.66699 2.93255 6.66699 2.57603 6.84865C2.26243 7.00844 2.00746 7.2634 1.84767 7.57701C1.66602 7.93353 1.66602 8.40024 1.66602 9.33366V15.667C1.66602 16.6004 1.66602 17.0671 1.84767 17.4236C2.00746 17.7372 2.26243 17.9922 2.57603 18.152C2.93255 18.3337 3.39926 18.3337 4.33268 18.3337Z'
                                 stroke='#50585C'
                                 strokeWidth='1.5'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                           </g>
                           <defs>
                              <clipPath id='clip0_2123_36660'>
                                 <rect width='20' height='20' fill='white' />
                              </clipPath>
                           </defs>
                        </svg>
                     </View>
                     <Text style={styles.modalText}>Duplicate</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.modalButton} onPress={handleMarkPageAsIndex}>
                     <View>
                        <svg
                           width='22'
                           height='22'
                           viewBox='0 0 22 22'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z'
                              stroke='#93989B'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     </View>
                     <Text style={[styles.modalText]}>Mark as index</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.modalButton} onPress={handleDelete}>
                     <View>
                        <svg
                           width='18'
                           height='18'
                           viewBox='0 0 18 18'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M6.5 1.5H11.5M1.5 4H16.5M14.8333 4L14.2489 12.7661C14.1612 14.0813 14.1174 14.7389 13.8333 15.2375C13.5833 15.6765 13.206 16.0294 12.7514 16.2497C12.235 16.5 11.5759 16.5 10.2578 16.5H7.74221C6.42409 16.5 5.76503 16.5 5.24861 16.2497C4.79396 16.0294 4.41674 15.6765 4.16665 15.2375C3.88259 14.7389 3.83875 14.0813 3.75107 12.7661L3.16667 4M7.33333 7.75V11.9167M10.6667 7.75V11.9167'
                              stroke='#D15A5A'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     </View>
                     <Text style={[styles.modalText, { color: '#D15A5A' }]}>Delete Page</Text>
                  </TouchableOpacity>
               </View>
            </Modal>
         )}
      </>
   );
};

export default PageItem;

const styles = StyleSheet.create({
   modalButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
   },
   modalContainer: {
      width: 248,
      padding: 10,
      borderColor: '#f5f6f8',
      backgroundColor: '#fffffff',
      borderWidth: 2,
   },
   modalText: {
      fontFamily: 'Inter',
      color: '#50585C',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      paddingLeft: 20,
   },
   mainContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   newButton: {
      backgroundColor: '#2B55B7',
      width: 8,
      height: 8,
      borderRadius: 16,
   },
   item: {
      color: '#50585C',
      paddingHorizontal: '20px',
      paddingVertical: '8px',
      height: '40px',
      cursor: 'pointer',
   },
   item_hover: {
      backgroundColor: '#EFF2F9',
      cursor: 'pointer',

      fontSize: '14px',
      lineHeight: '130%',
      color: '#50585C',
      paddingHorizontal: '20px',
      paddingVertical: '8px',
      height: '40px',
   },
   textStyle: {
      color: '#646D72',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
   },
});
