import { setChatStatus, addMessage } from "../slice"
import { CHAT_STATUS } from "../types"
import { askAI } from "../openai"
import { askQuestionContext } from "../../../consts/prompts"

/**
 * Answer the user question
 * @param debug 
 * @returns 
 */
export const assistantAnswerQuestion = (debug: number = 0) => async (
   dispatch: (...args: any[]) => void, 
   getState: () => Record<string, any>
): Promise<void> => {
   if (debug > 2) console.log('[handleQuestion] Start')
   dispatch(setChatStatus(CHAT_STATUS.handlingQuestion))
   const history = getState().assistant.messages
   const answer = await askAI(askQuestionContext + '\n' + history, debug);
   dispatch(addMessage(answer));
}