export const types = {
    text: 'text',
    number: 'number',
    boolean: 'boolean',
 };
 
 const inlineEntrys = {
    'inline-entry.GetGlobalVar': {
       uid: 'inline-entry.GetGlobalVar',
       name: 'Get global variable',
       parameters: [
          {
            type: 'custom.getGlobalVar',
          },
       ],
    },
    'inline-entry.GetComponent': {
       uid: 'inline-entry.GetComponent',
       name: 'Get component',
       parameters: [
          {
             name: 'Component',
             type: 'custom.componentId',
             uid: 'uid',
          },
       ],
    },
    'inline-entry.Litteral': {
       uid: 'inline-entry.Litteral',
       name: 'Litteral',
       parameters: [
          {
            type: 'custom.getLiteral',
            name: 'Literal',
            uid: 'value',
          },
       ],
    },
    'inline-entry.CurrentUser': {
       uid: 'inline-entry.CurrentUser',
       name: 'CurrentUser',
       parameters: [
          {
            name: 'CurrentUser',
            uid: 'target',
            type: 'custom.currentUser',
          },
       ],
    },
    'inline-entry.GetPageData': {
       uid: 'inline-entry.GetPageData',
       name: 'Get page data',
       parameters: [
          {
            uid: 'target',
            name: 'Page data',
            type: 'custom.pageData',
            options: ['Current Page Width']
          },
       ],
    }
 };

 const inlineNodes = {
    'inline-node.GetProperty': {
       uid: 'inline-node.GetProperty',
       name: 'GetProperty',
       parameters: [
          {
             name: 'Property',
             uid: 'target',
             type: 'custom.GetProperty',
          },
       ],
    },
    'inline-node.GetAttribute': {
       uid: 'inline-node.GetAttribute',
       name: 'Get attribute',
       parameters: [
          {
            type: 'custom.getAttribute',
          },
       ],
    },
    'inline-node.Comparison': {
       uid: 'inline-node.Comparison',
       name: 'Comparison',
       parameters: [
          {
            name: 'Component',
            uid: 'target',
            type: 'custom.componentId',
          },
       ],
    },
    'inline-node.GetLastItem': {
       uid: 'inline-node.GetLastItem',
       name: 'Get last item',
       parameters: [
          {
             name: 'Last item',
             uid: 'TODO',
             type: 'TODO',
          },
       ],
    },
    'inline-node.Contains': {
       uid: 'inline-node.Contains',
       name: 'Contains',
       parameters: [
          {
             name: 'In',
             uid: 'value',
             type: 'custom.Contains',
          },
       ],
    },
    'inline-node.Combination': {
       uid: 'inline-node.Combination',
       name: 'Combination',
    },
 };
 
 export { inlineEntrys, inlineNodes };
 