import { createCardsSection } from "./cardsSection";
import { TSectionBuilderPayload } from "../types";
import { topProductsContext } from "../../../consts/prompts";

export const createFeaturedProducts = (payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   if (debug > 2) console.log('[CreateFeaturedProducts] Start')
   return dispatch(createCardsSection(
      'Featured Products',
      topProductsContext,
      payload,
      debug
   ))
}