import _ from 'lodash';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Cell from './Cell';
import HeaderItem from './HeaderItem';
import {
   addSelectedRow,
   removeSelectedRow,
   selectAll,
   deselectAll,
} from '../../store/DatabaseSlice';
import CheckBox from '../DatabaseSection/base/CheckBox';
import DefaultModal from '../base/DefaultModal';
import { useState } from 'react';
import DataModalRow from './DataModalRow';

const DataTable = ({ rows = {}, cols = {} }) => {
   const [showCurrentRowModal, setShowCurrentRowModal] = useState(false);
   const [currentRow, setCurrentRow] = useState(false);
   const dispatch = useDispatch();
   const database = useSelector((state) => state.database);
   const { selectedRows, dataToEdit } = database;
   const allSelected = rows && (Object.keys(rows).length === Object.keys(selectedRows).length);

   return (
      <View style={styles.table}>
         <DefaultModal
            visible={showCurrentRowModal}
            title={'Full Information'}
            onSuccess={() => {
               setShowCurrentRowModal(false);
            }}
            onError={() => setShowCurrentRowModal(false)}>
            {   
               Object.keys(currentRow).map((row, rowIndex) => {
                  return (
                     <DataModalRow
                        cols={cols}
                        row={row}
                        currentRow={currentRow}/>
                  )
               })
            }
         </DefaultModal>
         <View style={styles.row}>
            {cols && (
               <>
                  {!_.isEmpty(rows) && (
                     <View style={styles.header}>
                        <CheckBox
                           partialSelected={!allSelected && Object.keys(selectedRows).length > 0}
                           isChecked={allSelected}
                           disabled={_.isEmpty(rows)}
                           onPress={() => {
                              dispatch(
                                 !allSelected ? selectAll({ rows }) : dispatch(deselectAll())
                              );
                           }}
                        />
                     </View>
                  )}
                  {Object.keys(cols).map((col, index) => (
                     <HeaderItem key={index} text={cols[col].name} />
                  ))}
               </>
            )}
         </View>
         {rows &&
            Object.keys(rows).map((row, rowIndex) => {
               return (
                  <View key={rowIndex} style={[styles.row, selectedRows[row] ? {backgroundColor: '#EFF2F9' } : {}]}>
                     <View style={styles.cell}>
                        <CheckBox
                           isChecked={selectedRows[row]}
                           onPress={() => {
                              if (!selectedRows[row])
                                 dispatch(addSelectedRow({ name: row, data: rows[row] }));
                              else dispatch(removeSelectedRow({ name: row }));
                           }}
                        />
                     </View>

                     {cols &&
                        Object.keys(cols).map((col, colIndex) => {
                           return (
                              <Cell
                                 row={row}
                                 col={col}
                                 key={colIndex}
                                 oldValue={rows[row][col] || cols[col].default}
                                 selected={dataToEdit[`${col}_${row}`]}
                                 text={dataToEdit[`${col}_${row}`]?.data || rows[row][col] || cols[col].default || 'NO DATA'}
                                 onPress={()=> {
                                    setShowCurrentRowModal(true)
                                    setCurrentRow(rows[row])
                                 }}/>
                           )
                        })}
                  </View>
               );
            })}
      </View>
   );
};

export default DataTable;

const styles = StyleSheet.create({
   table: {},
   row: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
   },
   header: {
      backgroundColor: '#F5F6F8',
      padding: 11,
      width: 46,
      textAlign: 'center',
      justifyContent: 'center',
      border: '1px solid #E0E4F4',
   },
   cell: {
      padding: 11,
      width: 46,
      textAlign: 'center',
      justifyContent: 'center',
      border: '1px solid #E0E4F4',
   },
});
