import { useState, useEffect } from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

const Round = (props) => {
   return <View style={styles.roundContainer} />;
};

const Separator = (props) => {
   return <View style={styles.separator} />;
};

const ResponsiveFactor = ({ icon, text, respText, onChangeText, value }) => {
   return (
      <View>
         <View style={styles.respContainer}>
            <View>{icon}</View>
            <View style={styles.mt5}>
               <Text styles={styles.respText}>{text}</Text>
            </View>
            <View style={styles.mt5}>
               <Text styles={styles.widthText}>{respText}</Text>
            </View>
         </View>
         <View style={styles.respFactorCalueContainer}>
            <Text style={styles.respBottomText}>RespFactor</Text>
            <View style={styles.editText}>
               <TextInput value={value} onChangeText={onChangeText} style={styles.inputText} />
               <View>
                  <Text style={styles.pxText}>px</Text>
               </View>
            </View>
         </View>
      </View>
   );
};

const Settings = ({ handleUpdate }) => {
   const project = useSelector((state) => state.project);

   const [mobile, setMobile] = useState(
      project?.definition?.design_system?.responsive_factor?.mobile || 0
   );
   const [tablet, setTablet] = useState(
      project?.definition?.design_system?.responsive_factor?.tablet || 0
   );
   const [xl, setXl] = useState(project?.definition?.design_system?.responsive_factor?.xl || 0);
   const [desktop, setDesktop] = useState(
      project?.definition?.design_system?.responsive_factor?.desktop || 0
   );

   return (
      <View style={styles.container}>
         <View styles={styles.subContainer}>
            <View style={styles.headerContainer}>
               <Text style={styles.title}>Breakpoints</Text>
               <Text style={styles.description}>Set up custom Response Factor for all devices</Text>
            </View>
            <View style={styles.mainContainer}>
               <Round />
               <Separator />
               <ResponsiveFactor
                  value={mobile}
                  text='Phone'
                  respText='0px - 600px'
                  icon={
                     <svg
                        width='28'
                        height='38'
                        viewBox='0 0 28 38'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M13.7497 28.8548H13.7676M6.94134 36.9173H20.558C22.5649 36.9173 23.5683 36.9173 24.3348 36.5268C25.0091 36.1832 25.5572 35.635 25.9008 34.9608C26.2913 34.1943 26.2913 33.1908 26.2913 31.184V6.81732C26.2913 4.81046 26.2913 3.80704 25.9008 3.04052C25.5572 2.36627 25.0091 1.81809 24.3348 1.47454C23.5683 1.08398 22.5649 1.08398 20.558 1.08398H6.94134C4.93449 1.08398 3.93106 1.08398 3.16454 1.47454C2.49029 1.81809 1.94211 2.36627 1.59857 3.04052C1.20801 3.80704 1.20801 4.81046 1.20801 6.81732V31.184C1.20801 33.1908 1.20801 34.1943 1.59857 34.9608C1.94211 35.635 2.49029 36.1832 3.16454 36.5268C3.93106 36.9173 4.93449 36.9173 6.94134 36.9173ZM14.6455 28.8548C14.6455 29.3496 14.2444 29.7507 13.7497 29.7507C13.2549 29.7507 12.8538 29.3496 12.8538 28.8548C12.8538 28.3601 13.2549 27.959 13.7497 27.959C14.2444 27.959 14.6455 28.3601 14.6455 28.8548Z'
                           stroke='#262626'
                           strokeWidth='2'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  }
                  onChangeText={(value) => {
                     handleUpdate([
                        {
                           path: ['design_system', 'responsive_factor', 'mobile'],
                           value,
                        },
                     ]);
                     setMobile(value);
                  }}
               />
               <Separator />
               <Round />
               <Separator />
               <ResponsiveFactor
                  value={tablet}
                  text='Tablet'
                  respText='0px - 600px'
                  icon={
                     <svg
                        width='32'
                        height='38'
                        viewBox='0 0 32 38'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M21.6253 1.08398V3.59232C21.6253 4.59575 21.6253 5.09746 21.43 5.48072C21.2583 5.81784 20.9842 6.09193 20.6471 6.2637C20.2638 6.45898 19.7621 6.45898 18.7587 6.45898H13.742C12.7386 6.45898 12.2369 6.45898 11.8536 6.2637C11.5165 6.09193 11.2424 5.81784 11.0706 5.48072C10.8753 5.09746 10.8753 4.59575 10.8753 3.59232V1.08398M7.65033 36.9173H24.8503C26.8572 36.9173 27.8606 36.9173 28.6271 36.5268C29.3014 36.1832 29.8496 35.635 30.1931 34.9608C30.5837 34.1943 30.5837 33.1908 30.5837 31.184V6.81732C30.5837 4.81046 30.5837 3.80704 30.1931 3.04052C29.8496 2.36627 29.3014 1.81809 28.6271 1.47454C27.8606 1.08398 26.8572 1.08398 24.8503 1.08398H7.65033C5.64347 1.08398 4.64004 1.08398 3.87353 1.47454C3.19928 1.81809 2.6511 2.36627 2.30755 3.04052C1.91699 3.80704 1.91699 4.81046 1.91699 6.81732V31.184C1.91699 33.1908 1.91699 34.1943 2.30755 34.9608C2.6511 35.635 3.19928 36.1832 3.87353 36.5268C4.64004 36.9173 5.64347 36.9173 7.65033 36.9173Z'
                           stroke='#262626'
                           strokeWidth='2'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  }
                  onChangeText={(value) => {
                     handleUpdate([
                        {
                           path: ['design_system', 'responsive_factor', 'tablet'],
                           value,
                        },
                     ]);
                     setTablet(value);
                  }}
               />
               <Separator />
               <Round />
               <Separator />
               <ResponsiveFactor
                  value={xl}
                  text='Laptop'
                  respText='0px - 600px'
                  icon={
                     <svg
                        width='44'
                        height='44'
                        viewBox='0 0 44 44'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M37.8747 29.166V13.3993C37.8747 11.3925 37.8747 10.3891 37.4841 9.62255C37.1406 8.9483 36.5924 8.40012 35.9181 8.05658C35.1516 7.66602 34.1482 7.66602 32.1413 7.66602H11.358C9.35115 7.66602 8.34773 7.66602 7.58121 8.05658C6.90696 8.40012 6.35878 8.9483 6.01523 9.62255C5.62467 10.3891 5.62467 11.3925 5.62467 13.3993V29.166M8.61079 36.3327H34.8886C35.9994 36.3327 36.5548 36.3327 37.0104 36.2106C38.247 35.8792 39.2129 34.9134 39.5442 33.6768C39.6663 33.2211 39.6663 32.6657 39.6663 31.5549C39.6663 30.9995 39.6663 30.7218 39.6053 30.494C39.4396 29.8757 38.9567 29.3927 38.3384 29.2271C38.1106 29.166 37.8329 29.166 37.2775 29.166H6.2219C5.6665 29.166 5.3888 29.166 5.16096 29.2271C4.54267 29.3927 4.05973 29.8757 3.89406 30.494C3.83301 30.7218 3.83301 30.9995 3.83301 31.5549C3.83301 32.6657 3.83301 33.2211 3.95511 33.6768C4.28645 34.9134 5.25233 35.8792 6.48891 36.2106C6.94459 36.3327 7.49999 36.3327 8.61079 36.3327Z'
                           stroke='#262626'
                           strokeWidth='2'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  }
                  onChangeText={(value) => {
                     handleUpdate([
                        {
                           path: ['design_system', 'responsive_factor', 'xl'],
                           value,
                        },
                     ]);
                     setXl(value);
                  }}
               />
               <Separator />
               <Round />
               <Separator />
               <ResponsiveFactor
                  value={desktop}
                  text='Large'
                  respText='0px - 600px'
                  icon={
                     <svg
                        width='28'
                        height='38'
                        viewBox='0 0 28 38'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M14.2497 28.8548H14.2676M7.44134 36.9173H21.058C23.0649 36.9173 24.0683 36.9173 24.8348 36.5268C25.5091 36.1832 26.0572 35.635 26.4008 34.9608C26.7913 34.1943 26.7913 33.1908 26.7913 31.184V6.81732C26.7913 4.81046 26.7913 3.80704 26.4008 3.04052C26.0572 2.36627 25.5091 1.81809 24.8348 1.47454C24.0683 1.08398 23.0649 1.08398 21.058 1.08398H7.44134C5.43449 1.08398 4.43106 1.08398 3.66454 1.47454C2.99029 1.81809 2.44211 2.36627 2.09857 3.04052C1.70801 3.80704 1.70801 4.81046 1.70801 6.81732V31.184C1.70801 33.1908 1.70801 34.1943 2.09857 34.9608C2.44211 35.635 2.99029 36.1832 3.66454 36.5268C4.43106 36.9173 5.43449 36.9173 7.44134 36.9173ZM15.1455 28.8548C15.1455 29.3496 14.7444 29.7507 14.2497 29.7507C13.7549 29.7507 13.3538 29.3496 13.3538 28.8548C13.3538 28.3601 13.7549 27.959 14.2497 27.959C14.7444 27.959 15.1455 28.3601 15.1455 28.8548Z'
                           stroke='#262626'
                           strokeWidth='2'
                           strokeLinecap='round'
                           strokeLinejoin='round'
                        />
                     </svg>
                  }
                  onChangeText={(value) => {
                     handleUpdate([
                        {
                           path: ['design_system', 'responsive_factor', 'desktop'],
                           value,
                        },
                     ]);
                     setDesktop(value);
                  }}
               />
               <Separator />
               <Round />
            </View>
         </View>
      </View>
   );
};

export default Settings;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      paddingHorizontal: 20,
   },
   subContainer: {
      padding: 20,
   },
   headerContainer: {
      flexDirection: 'column',
   },
   title: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 21,
      color: '#262626',
   },
   description: {
      paddingTop: 5,
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      color: '#646D72',
   },
   mainContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 30,
   },
   roundContainer: {
      width: 10,
      height: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#B8BDD0',
   },
   separator: {
      height: 2,
      width: 40,
      backgroundColor: '#B8BDD0',
   },
   responsiveFactor: {
      height: 1,
      backgroundColor: '#B8BDD0',
   },
   respContainer: {
      width: 245,
      height: 165,
      borderColor: '#E0E4F4',
      borderRadius: 12,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
   },
   respText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 20,
      color: '#262626',
   },
   widthText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
   },
   mt5: {
      marginTop: 5,
   },
   respFactorCalueContainer: {
      marginTop: 10,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   respBottomText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#262626',
   },
   editText: {
      width: 100,
      height: 30,
      borderWidth: 1,
      borderRadius: 1,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   pxText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 15,
      color: '#93989B',
      marginRight: 10,
   },
   inputText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      paddingLeft: 10,
      fontSize: 12,
      lineHeight: 15,
      color: '#93989B',
      width: 40,
   },
});
