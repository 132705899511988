import { FontAwesome } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
   View,
   Text,
   StyleSheet,
   Pressable,
   Modal,
   TextInput,
   TouchableOpacity,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import DefaultModal from './base/DefaultModal';
import { dbDelete } from '../actions/dbActions';
import { createProject, readProjectMetadata } from '../store/ProjectsMetadataSlice';

const ProjectTile = ({ projectId }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.user);

   const [projectName, setProjectName] = useState('');
   const [popupVisible, setPopupVisible] = useState(false);
   const [deleteModalVisible, setDeleteModalVisible] = useState(false);
   const [shareModalVisible, setShareModalVisible] = useState(false);

   const togglePopup = () => {
      setPopupVisible(!popupVisible);
   };
   const handleCreateProject = () => {
      dispatch(createProject(user.uid, { name: projectName, admin: { admin_ids: [user.uid] } }));
      togglePopup();
   };

   // case project tile
   const project = useSelector((state) => state.projectsMetadata).find(
      (metadata) => metadata.uid === projectId
   );

   useEffect(() => {
      if (projectId) {
         dispatch(readProjectMetadata(projectId));
      }
   }, []);

   
   if (!projectId) {
      // TODO: prevent error if click on tile before project is fully created
      return (
         <View>
            <Pressable style={styles.container} onPress={togglePopup}>
               <Text style={styles.projectName}>+</Text>
            </Pressable>
            <DefaultModal
               title='Project name'
               description='Enter name for your project'
               visible={popupVisible}
               onSuccess={() => {
                  handleCreateProject();
                  togglePopup();
               }}
               onError={() => togglePopup()}
               okText='CREATE'
               cancelText='CANCEL'>
               <TextInput
                  placeholder='Project name'
                  autoFocus
                  value={projectName}
                  onChangeText={setProjectName}
                  onSubmitEditing={handleCreateProject}
                  style={styles.input}
               />
            </DefaultModal>
         </View>
      );
   }
   if (!project) {
      return null;
   }
   return (
      <>
         <View style={[styles.container, styles.nameCommonContainer]}>
            <Text style={styles.projectName}>{project?.name || '...'}</Text>
            <View style={{ marginLeft: 30 }}>
               {/* <TouchableOpacity onPress={() => setShareModalVisible(true)}>
                  <FontAwesome
                     style={[{ justifyContent: 'center' }]}
                     name='share'
                     size={20}
                     color='blue'
                  />
               </TouchableOpacity> */}
               <TouchableOpacity onPress={() => setDeleteModalVisible(true)}>
                  <FontAwesome
                     style={[{ justifyContent: 'center' }]}
                     name='trash'
                     size={20}
                     color='#ABB1CA'
                  />
               </TouchableOpacity>
            </View>
         </View>
         {/* <DefaultModal
            title='Share your project'
            description='Share project with existing users'
            visible={shareModalVisible}
            onSuccess={() => {
               setShareModalVisible(false);
            }}
            onError={() => setShareModalVisible(false)}
            okText='SHARE'
            cancelText='CANCEL'>
            <TextInput
               autoFocus
               style={styles.input}
               // onChangeText={handleQueryChange}
               placeholder='Type here...'
            />
         </DefaultModal> */}
         <DefaultModal
            visible={deleteModalVisible}
            onSuccess={() => {
               dispatch(dbDelete(`projects/${projectId}`));
               dispatch(dbDelete(`users/${user.uid}/projects/${projectId}`));
               // TODO: delete all files stored on this project bucket
               setDeleteModalVisible(false);
            }}
            onError={() => setDeleteModalVisible(false)}
            okText='OK'
            cancelText='CANCEL'
            title='Warning'
            description={
               'Are you sure you want to delete the project? \nYou will not be able to undo this action.'
            }
         />
      </>
   );
};

const styles = StyleSheet.create({
   container: {
      backgroundColor: '#fff',
      borderRadius: 8,
      padding: 16,
      shadowColor: '#000',
      shadowOffset: {
         width: 0,
         height: 2,
      },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      elevation: 2,
      minHeight: 50,
      minWidth: 200,
      alignItems: 'center',
      justifyContent: 'center',
   },
   nameCommonContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   input: {
      maxWidth: 300,
      borderBottomWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#fff',
      marginBottom: 10,
      paddingHorizontal: 10,
      paddingVertical: 6,
   },
   button: {
      backgroundColor: 'rgb(33, 150, 243)',
      maxWidth: 200,
      padding: 4,
      margin: 4,
      borderRadius: 4,
      alignItems: 'center',
      height: 'fit-content',
   },
   projectName: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#333',
   },
   modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0,0,0,0.1)',
      justifyContent: 'center',
      alignItems: 'center',
   },
   popupContainer: {
      backgroundColor: '#f2f2f2',
      borderRadius: 4,
      elevation: 4,
      padding: 12,
   },
});

export default ProjectTile;
