import { AntDesign } from '@expo/vector-icons';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';

const AddRowButton = ({ onPress, title}) => {
   return (
      <TouchableOpacity onPress={onPress} style={styles.button}>
         <Text style={styles.buttonText}>{ title || 'Add new row'}</Text>
         <AntDesign name='plus' size={10} color='#FFFFFF' />
      </TouchableOpacity>
   );
};

export default AddRowButton;

const styles = StyleSheet.create({
   button: {
      borderRadius: 8,
      paddingTop: 6,
      paddingRight: 24,
      paddingBottom: 6,
      paddingLeft: 30,
      gap: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      backgroundColor: '#2B55B7',
   },
   buttonText: {
      color: '#FFFFFF',
   },
   text: {
      fontSize: 18,
      lineHeight: '120%',
      outlineStyle: 'none',
   },
});
