import _ from 'lodash';
import { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Text, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import FontItem from './FontItem';

// import AddingDataElement from './DataStructureComponents/AddingDataElement';

const Fonts = ({ handleUpdate }) => {
   const fonts = useSelector((state) => state.project?.definition?.design_system?.fonts) || {};

   return (
      <View style={styles.container}>
         <View style={styles.textContainer}>
            <Text style={styles.titleText}>Actives Fonts</Text>
            <Text style={styles.descText}>Set up custom fonts for all devices</Text>
         </View>
         {!fonts ? (
            <></>
         ) : (
            <FlatList
               numColumns={3}
               data={Object.keys(fonts)}
               renderItem={({ item }) => (
                  <View style={{ padding: 5 }}>
                     <FontItem handleUpdate={handleUpdate} {...fonts[item]} />
                  </View>
               )}
            />
         )}
      </View>
   );
};

export default Fonts;

const styles = StyleSheet.create({
   textContainer: {
      marginBottom: 20,
      flex: 1,
   },
   fontDescContainer: {
      justifyContent: 'center',
      alignItems: 'center',
   },
   container: {
      paddingHorizontal: 20,
   },
   titleText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 18,
      lineHeight: 21,
      color: '#262626',
   },
   defaultText: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: 14,
      lineHeight: 18,
      color: '#93989B',
   },
   descText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 19,
      color: '#646D72',
   },
   aaText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 40,
      lineHeight: 50,
      color: '#2A2D41',
   },
   countText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: 15,
      color: '#EFF1FE',
   },
   fontNameText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 18,
      lineHeight: 21,
      color: '#262626',
   },
   fontDescText: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: 14,
      lineHeight: 18,
      color: '#93989B',
   },
   fontMainContainer: {
      width: 418,
      height: 301,
      borderRadius: 8,
      borderWidth: 1,
      paddingTop: 32,
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 20,
   },
});
