import { Entypo } from '@expo/vector-icons';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

const CheckBox = ({ disabled, isChecked, partialSelected, onPress }) => {
   return (
      <TouchableOpacity disabled={disabled}
         style={[
            styles.checkboxContainer,
            styles.container,
            (isChecked || partialSelected) ? styles.checkBoxContainer : {}
         ]}
         onPress={onPress}>
         {
            partialSelected ? 
               <View style={styles.partialSelected}/>
                  :
                  isChecked ? (
                    <Entypo name='check' size={14} color='#FFF' />
                        ) : (
                              <></>
                           )
         }
      </TouchableOpacity>
   );
};

const styles = StyleSheet.create({
   checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
   },
   container: {
      width: 20,
      height: 20,
      borderRadius: 5,
      borderWidth: 1,
      boorderColor: '#E0E4F4',
      justifyContent: 'center',
      alignItems: 'center',
   },
   checkbox: {
      width: 20,
      height: 20,
      color: '#2B55B7',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2B55B7',
   },
   checkboxInner: {
      width: 10,
      height: 2,
      borderRadius: 1,
      backgroundColor: '#2B55B7',
   },
   checkBoxContainer: {
      backgroundColor: '#2B55B7',
   },
   partialSelected: {
      width: 10,
      height: 2,
      backgroundColor: '#FFF',
      borderRadius: 1
   },
});

export default CheckBox;
