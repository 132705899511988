import { useNavigation } from '@react-navigation/native';
import React, { useLayoutEffect, useEffect } from 'react';
import { View, SafeAreaView, Text, Pressable, StyleSheet, ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import ProjectTile from '../components/ProjectTile';
import { selectEditorProject } from '../store/EditorSlice';
import { setProjects } from '../store/ProjectsMetadataSlice';

const HomeScreen = () => {
   const dispatch = useDispatch();

   const navigation = useNavigation();
   const user = useSelector((state) => state.user);
   const projectsMetadata = useSelector((state) => state.projectsMetadata);
   const project = useSelector((state) => state.project);

   useEffect(() => {
      // TODO: enable multiple environments
      const unsubscribe = project.watch('/users/' + user.uid + '/projects', (data) => {
        const projects = Object.keys(data).map((projectId) => ({ uid: projectId }));
        dispatch(setProjects(projects));
      });
      // return () => unsubscribe();
   }, [project.read, project.update, project.delete, project.update]);

   useLayoutEffect(() => {
      navigation.setOptions({
         headerShown: true,
      });
   });

   const handleEditProject = (uid) => {
      dispatch(selectEditorProject(uid));
      navigation.navigate('Project');
   };

   return (
      <SafeAreaView style={styles.home}>
         <View style={styles.container}>
            <Text style={styles.title}>HomeScreen: {user.email}</Text>
            <View style={styles.projectlist}>
               <ProjectTile />
               {projectsMetadata &&
                  projectsMetadata.map((projectMetadata) => (
                     <Pressable
                        key={projectMetadata.uid}
                        onPress={() => handleEditProject(projectMetadata.uid)}>
                        <ProjectTile projectId={projectMetadata.uid} />
                     </Pressable>
                  ))}
            </View>
         </View>
      </SafeAreaView>
   );
};

export default HomeScreen;

const styles = StyleSheet.create({
   home: {
      flex: 1,
      backgroundColor: '#fff',
   },
   container: {
      padding: 20,
   },
   title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 20,
   },
   projectlist: {
      display: 'flex',
      gap: 10,
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: 'auto',
   },
});
