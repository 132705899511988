import { TSectionBuilderPayload} from '../types'
import { updateDesignSystem } from '../../ProjectSlice';
import { generateComponent } from '../generators';
import { logoImageContext } from '../../../consts/prompts';
import { camelCase } from '../utils';

export const createHeader = (payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<void> => {
   if (debug > 2) console.log('[CreateHeader] Start')
   const { description, sections, rootId, pageId } = payload;
   // Create required styles
   // TODO: generate them dynamically (font, colors, etc)
   const styles = {
      logo: {
         uid: 'logo',
         name: 'Logo',
         styling: {
            height: {
               type: 'fixed',
               fixed: { val: 4, unit: 'rf' },
            },
            width: {
               type: 'fixed',
               fixed: { val: 4, unit: 'rf' },
            },
            radius: {
               top_left: { val: 1, unit: 'rf' },
               top_right: { val: 1, unit: 'rf' },
               bottom_right: { val: 1, unit: 'rf' },
               bottom_left: { val: 1, unit: 'rf' },
            },
            margin: { val: 0.5, unit: 'rf' },
         }
      },
      navigationItem: {
         uid: 'navigationItem',
         name: 'Navigation Item',
         styling: {
            text: {
               color: "grey",
               size: {
                  val: 1.2,
                  unit: "rf"
               },
               font: "Roboto",
               line_height: 1.2
            },
            width: {
               type: 'fit'
            }
         }
      },
   }
   dispatch(updateDesignSystem([
      { path: ['user_styles', 'logo'], value: styles.logo },
      { path: ['user_styles', 'navigationItem'], value: styles.navigationItem },
   ]))
   // Create Header
   const headerDef = await dispatch(generateComponent(
      {
         name: 'Header Navigation+Logo',
         type: 'box',
         description: description,
         custom_styling: {
            layout: {
               direction: 'row',
               column_gap: { val: 2, unit: 'rf' },
               distribution: 'between'
            },
            position: {
               type: 'fixed',
               fixed: { top: 0, right: 0, left: 0, }
            },
            width: { type: 'expand' },
            background: { type: 'color', color: '#FFFFFF80' }
         },
      },
      [], // TODO: generate background color dynamically
      [rootId],
      debug
   ))
   
   // Create Logo
   const logoDef = await dispatch(generateComponent(
      {
         name: 'Navigation logo',
         type: 'image',
         description: 'Header Logo, that redirects on home when clicked',
         properties: { image: 'https://th.bing.com/th/id/OIP.KhEKUkd4eL9dJObSIXjmCwHaHa?rs=1&pid=ImgDetMain' },
         style: 'logo',
         logic: {
            navigation_home: {
               uid: "navigation_home",
               nodes: {
                  "onPress": { uid: "onPress", name: "On press", type: "trigger.onClick" },
                  "goHome": {
                     uid: "goHome",
                     name: "Navigate Home",
                     type: "action.navigate",
                     parameters: { "target": pageId }
                  }
               },
               connections: {
                  "onPress": {
                     "success": { "goHome": { "order": 0, "to": "goHome", "uid": "goHome" } }
                  }
               }
            }
         },
      },
      [{path: ['properties', 'image'], type: 'image', context: logoImageContext}],
      headerDef.composite_key,
      0,
      debug
   ))

   // Create navigation
   const navigationDef = await dispatch(generateComponent(
      {
         name: 'Main Navigation',
         type: 'box',
         description: 'Links towards the main parts of the page for quick access',
         custom_styling: {
            layout: {
               direction: 'row',
               column_gap: { val: 1, unit: 'rf' },
               distribution: 'between'
            },
         },
      },
      [],
      headerDef.composite_key,
      1,
      debug
   ))
   // Create navigation items
   let sectionIndex = 0
   for (let index = 0; index < sections.length; index++) {
      const section = sections[index];
      if (['hero', 'header', 'footer'].includes(camelCase(section))) {
         continue
      }
      await dispatch(generateComponent(
         {
            name: `nav: ${section}`,
            type: 'text',
            description: `Navigation element to ${section} section.`,
            properties: { text: section },
            style: 'navigationItem',
         },
         [],
         navigationDef.composite_key,
         sectionIndex,
         debug
      ))
      sectionIndex++;
   }
}