import React from 'react';
import { StyleSheet, Pressable, View, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import NewActionNode from './NewActionNode';
import ActionCard from '../../components/logicEditor/ActionCard';
import { setSelectedLogicAction } from '../../store/EditorSlice';
import { createLogicAction } from '../../store/ProjectSlice';

const WorkflowTree = ({ logicWorkflow, trigger }) => {
   const dispatch = useDispatch();

   const selectedLogicAction = useSelector((state) => state.editor.selectedLogicAction);

   const getNextLines = (parentNode) =>
      Object.values(logicWorkflow.nodes || {})
         .filter((node) => node.uid in (logicWorkflow.connections?.[parentNode.uid]?.success || {}))
         .map((node, index) => (
            <View key={node.uid + '_' + index} style={styles.workflowCenter}>
               <Pressable
                  style={({ pressed }) => [
                     styles.triggerBarButton,
                     pressed ? styles.buttonPressed : {},
                     node.uid === selectedLogicAction ? { backgroundColor: 'red' } : {},
                  ]}
                  onPress={() => {
                     dispatch(setSelectedLogicAction(node.uid));
                  }}>
                  <ActionCard key={index} node={node} />
               </Pressable>
               <NewActionNode
                  // hidePlus={index === Object.keys(logicWorkflow.nodes).length}
                  logicWorkflow={logicWorkflow}
                  previousNode={node}
                  connection='success'
                  style={styles.connectionSegmentBox}
               />
               {getNextLines(node)}
            </View>
         ));

   return (
      <View style={styles.workflow}>
         {logicWorkflow.connections ? (
            <View style={styles.workflowCenter}>
               <NewActionNode
                  logicWorkflow={logicWorkflow}
                  previousNode={trigger}
                  connection='success'
                  style={styles.connectionSegmentBox}
               />
               {getNextLines(trigger)}
            </View>
         ) : (
            <Pressable
               onPress={() =>
                  dispatch(createLogicAction(logicWorkflow.uid, trigger.uid, 'success'))
               }
               style={styles.workflowColumn}>
               <View style={styles.plusContainer}>
                  <svg
                     width='14'
                     height='14'
                     viewBox='0 0 14 14'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M7.00033 1.1665V12.8332M1.16699 6.99984H12.8337'
                        stroke='#183762'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                  </svg>
               </View>
               <Text style={styles.addNewStyle}>Start your Workflow Right here</Text>
            </Pressable>
         )}
      </View>
   );
};

export default WorkflowTree;

const styles = StyleSheet.create({
   addNewStyle: {
      marginTop: 20,
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18,
      color: '#93989B',
   },
   plusContainer: {
      width: 48,
      height: 48,
      borderRadius: 100,
      borderWidth: 1.5,
      borderColor: '#183762',
      alignItems: 'center',
      justifyContent: 'center',
   },
   workflow: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
   },
   workflowColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
   },
   workflowLine: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
   },
   workflowCenter: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   connectionSegmentBox: {
      alignSelf: 'center',
   },
});
