import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import databaseSlice from '../store/DatabaseSlice';
import editorSlice from '../store/EditorSlice';
import projectSlice from '../store/ProjectSlice';
import userReducer from '../store/UserSlice';
import AIAssistantSlice from '../store/AIAssistantSlice';
import GlobalsSlice from '../engine/reducers/GlobalsSlice';
import LocalDataSlice from '../engine/reducers/LocalDataSlice';
import projectsMetadataSlice from '../store/ProjectsMetadataSlice';

// debugger
// import { composeWithDevTools } from 'redux-devtools-extension'

// Create the Redux store
const store = configureStore({
   reducer: {
      user: userReducer,
      editor: editorSlice,
      project: projectSlice,
      database: databaseSlice,
      assistant: AIAssistantSlice,
      projectsMetadata: projectsMetadataSlice,
      
      // Engine reducers
      local: LocalDataSlice,
      globals: GlobalsSlice,
   },
   middleware: [thunk],
});

export default store;