import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const ElementIcon = ({ type }) => {
   switch (type) {
      case 'box':
         return (
            <svg
               width='15'
               height='15'
               viewBox='0 0 28 28'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M9.33333 3.5H9.1C7.13982 3.5 6.15972 3.5 5.41103 3.88148C4.75247 4.21703 4.21703 4.75247 3.88148 5.41103C3.5 6.15972 3.5 7.13982 3.5 9.1V9.33333M9.33333 24.5H9.1C7.13982 24.5 6.15972 24.5 5.41103 24.1185C4.75247 23.783 4.21703 23.2475 3.88148 22.589C3.5 21.8403 3.5 20.8602 3.5 18.9V18.6667M24.5 9.33333V9.1C24.5 7.13982 24.5 6.15972 24.1185 5.41103C23.783 4.75247 23.2475 4.21703 22.589 3.88148C21.8403 3.5 20.8602 3.5 18.9 3.5H18.6667M24.5 18.6667V18.9C24.5 20.8602 24.5 21.8403 24.1185 22.589C23.783 23.2475 23.2475 23.783 22.589 24.1185C21.8403 24.5 20.8602 24.5 18.9 24.5H18.6667'
                  stroke='#646D72'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
         );
      case 'input':
         return (
            <svg
               width='15'
               height='15'
               viewBox='0 0 26 24'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M14.1663 6.16667H5.06634C3.75955 6.16667 3.10616 6.16667 2.60703 6.42099C2.16799 6.64469 1.81103 7.00164 1.58733 7.44069C1.33301 7.93982 1.33301 8.59321 1.33301 9.9V14.1C1.33301 15.4068 1.33301 16.0602 1.58733 16.5593C1.81103 16.9984 2.16799 17.3553 2.60703 17.579C3.10616 17.8333 3.75955 17.8333 5.06634 17.8333H14.1663M18.833 6.16667H20.933C22.2398 6.16667 22.8932 6.16667 23.3923 6.42099C23.8314 6.64469 24.1883 7.00164 24.412 7.44069C24.6663 7.93982 24.6663 8.59321 24.6663 9.9V14.1C24.6663 15.4068 24.6663 16.0602 24.412 16.5593C24.1883 16.9984 23.8314 17.3553 23.3923 17.579C22.8932 17.8333 22.2398 17.8333 20.933 17.8333H18.833M18.833 22.5L18.833 1.5M21.7497 1.50001L15.9163 1.5M21.7497 22.5L15.9163 22.5'
                  stroke='#646D72'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
         );
      case 'text':
         return (
            <svg
               width='15'
               height='15'
               viewBox='0 0 22 22'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M1.66699 5.16699C1.66699 4.0798 1.66699 3.5362 1.84461 3.1074C2.08143 2.53567 2.53567 2.08143 3.1074 1.84461C3.5362 1.66699 4.0798 1.66699 5.16699 1.66699H16.8337C17.9209 1.66699 18.4645 1.66699 18.8933 1.84461C19.465 2.08143 19.9192 2.53567 20.156 3.1074C20.3337 3.5362 20.3337 4.0798 20.3337 5.16699M7.50033 20.3337H14.5003M11.0003 1.66699V20.3337'
                  stroke='#646D72'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
         );
      case 'image':
         return (
            <svg
               width='25'
               height='15'
               viewBox='0 0 24 24'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M22.5 15.5V16.9C22.5 18.8602 22.5 19.8403 22.1185 20.589C21.783 21.2475 21.2475 21.783 20.589 22.1185C19.8403 22.5 18.8602 22.5 16.9 22.5H7.1C5.13982 22.5 4.15972 22.5 3.41103 22.1185C2.75247 21.783 2.21703 21.2475 1.88148 20.589C1.5 19.8403 1.5 18.8602 1.5 16.9V15.5M17.8333 7.33333L12 1.5M12 1.5L6.16667 7.33333M12 1.5V15.5'
                  stroke='#646D72'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
         );
      default:
         return (
            <svg
               width='15'
               height='15'
               viewBox='0 0 28 28'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M9.33333 3.5H9.1C7.13982 3.5 6.15972 3.5 5.41103 3.88148C4.75247 4.21703 4.21703 4.75247 3.88148 5.41103C3.5 6.15972 3.5 7.13982 3.5 9.1V9.33333M9.33333 24.5H9.1C7.13982 24.5 6.15972 24.5 5.41103 24.1185C4.75247 23.783 4.21703 23.2475 3.88148 22.589C3.5 21.8403 3.5 20.8602 3.5 18.9V18.6667M24.5 9.33333V9.1C24.5 7.13982 24.5 6.15972 24.1185 5.41103C23.783 4.75247 23.2475 4.21703 22.589 3.88148C21.8403 3.5 20.8602 3.5 18.9 3.5H18.6667M24.5 18.6667V18.9C24.5 20.8602 24.5 21.8403 24.1185 22.589C23.783 23.2475 23.2475 23.783 22.589 24.1185C21.8403 24.5 20.8602 24.5 18.9 24.5H18.6667'
                  stroke='#646D72'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
         );
   }
};

export default ElementIcon;
