import { StyleSheet, Text, View, Pressable } from 'react-native';
import React, { useEffect, useState } from 'react';
import * as _DocumentPicker from 'expo-document-picker';

const DocumentPicker = ({ fileType = 'any', onFileChange = () => console.error('Error: expecting "onFileChange" function'), fileURI }) => {
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSelectFile = async () => {
        const checkApplicationFileType = (file) => {
            if (file.mimeType !== `application/${fileType}`) {
                setError(`Invalid file type (${file.mimeType}). Expected ${fileType}.`);
            } else {
                setSelectedFile(file);
                setError(null);
            }
        }
        try {
            const result = await _DocumentPicker.getDocumentAsync();
            if (result.canceled === false) {
                const file = result.assets[0];
                switch (fileType) {
                    case 'image': {
                        if (file.mimeType.slice('0, 6') !== 'image/') {
                            setError(`Invalid file type (${file.mimeType}). Expected ${fileType}.`);
                        } else {
                            setSelectedFile(file);
                            setError(null);
                        }
                        break;
                    }
                    case 'json':
                    case 'csv':
                    case 'pdf': {
                        checkApplicationFileType(file);
                        break;
                    }
                    case 'any': {
                        setSelectedFile(file);
                        setError(null);
                        break;
                    }
                    default: {
                        setError(`Invalid file type. Expected ${fileType}.`);
                        break;
                    }
                }
            }
        } catch (error) {
            console.log('Error selecting file:', error);
        }
    };

    useEffect(
        () => {
            if (selectedFile) {
                onFileChange(selectedFile);
            }
        },
        [selectedFile]
    );

    return (
        <View style={styles.container}>
            <Pressable style={styles.button} onPress={handleSelectFile}>
                <Text style={styles.buttonText}>Select file</Text>
            </Pressable>
            {selectedFile && <Text style={styles.selectedFileText}>{selectedFile.name}</Text>}
            {error && <Text style={styles.error}>{error}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: 20,
    },
    button: {
        backgroundColor: '#ccc',
        borderRadius: 4,
        padding: 10,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    selectedFileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#eee',
        borderRadius: 4,
        padding: 10,
    },
    selectedFileText: {
        flex: 1,
    },
    uploadButton: {
        backgroundColor: '#ccc',
        borderRadius: 4,
        padding: 10,
        marginLeft: 10,
    },
    uploadButtonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    error: {
        color: 'red',
    },
});

export default DocumentPicker;