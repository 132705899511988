import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

const LayoutDirection = ({ rootHandleElementUpdate, componentDef, handleUpdate, style }) => {
   const [direction, setDirection] = useState(
      componentDef[rootHandleElementUpdate]?.layout?.direction || 'column'
   );
   useEffect(() => {
      setDirection(componentDef[rootHandleElementUpdate]?.layout?.direction || 'column');
   }, [componentDef[rootHandleElementUpdate]?.layout?.direction]);

   return (
      <View style={[layoutStyles.flexDirectionButtons, style]}>
         <TouchableOpacity
            onPress={() => {
               setDirection('row');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'direction'], value: 'row' },
               ]);
            }}
            style={[
               layoutStyles.fdButton,
               direction == 'row' ? layoutStyles.fdButtonSelected : {},
            ]}>
            <View style={layoutStyles.fdContainer}>
               <svg
                  width='12'
                  height='10'
                  viewBox='0 0 12 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M1 5H11M11 5L7.25 1.25M11 5L7.25 8.75'
                     stroke={direction == 'row' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setDirection('column');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'direction'], value: 'column' },
               ]);
            }}
            style={[
               layoutStyles.fdButton,
               direction == 'column' ? layoutStyles.fdButtonSelected : {},
            ]}>
            <View style={layoutStyles.fdContainer}>
               <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M11.7936 9.51855L7.9998 13.3123L4.20605 9.51855'
                     stroke={direction == 'column' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeMiterlimit='10'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
                  <path
                     d='M8 2.6875V13.2062'
                     stroke={direction == 'column' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeMiterlimit='10'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setDirection('rowWrap');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'direction'], value: 'rowWrap' },
               ]);
            }}
            style={[
               layoutStyles.fdButton,
               direction == 'rowWrap' ? layoutStyles.fdButtonSelected : {},
            ]}>
            <View style={layoutStyles.fdContainer}>
               <svg
                  width='14'
                  height='12'
                  viewBox='0 0 14 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M1.375 1H3.5C5.6002 1 6.65029 1 7.45246 1.40873C8.15807 1.76825 8.73175 2.34193 9.09127 3.04754C9.5 3.8497 9.5 4.8998 9.5 7L9.5 11M9.5 11L6.375 7.875M9.5 11L12.625 7.875'
                     stroke={direction == 'rowWrap' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
         <TouchableOpacity
            onPress={() => {
               setDirection('columnWrap');
               handleUpdate([
                  { path: [rootHandleElementUpdate, 'layout', 'direction'], value: 'columnWrap' },
               ]);
            }}
            style={[
               layoutStyles.fdButton,
               direction == 'columnWrap' ? layoutStyles.fdButtonSelected : {},
            ]}>
            <View style={[layoutStyles.fdContainer, { transform: [{ rotate: '90deg' }] }]}>
               <svg
                  width='14'
                  height='12'
                  viewBox='0 0 14 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M1.375 1H3.5C5.6002 1 6.65029 1 7.45246 1.40873C8.15807 1.76825 8.73175 2.34193 9.09127 3.04754C9.5 3.8497 9.5 4.8998 9.5 7L9.5 11M9.5 11L6.375 7.875M9.5 11L12.625 7.875'
                     stroke={direction == 'columnWrap' ? '#F5F9FF' : '#93989B'}
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </View>
         </TouchableOpacity>
      </View>
   );
};

export default LayoutDirection;

const layoutStyles = StyleSheet.create({
   fdButton: {
      flex: 1,
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   fdButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   fdContainer: {
      height: 30,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center',
   },
   flexDirectionButtons: {
      flex: 1,
      width: 90,
      height: 30,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
});
