import _ from 'lodash';
import React, { useState } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import AddRowButton from './base/AddRowButton';
import DeleteSelectedButton from './base/DeleteSelectedButton';
import DeselectButton from './base/DeselectButton';
import DownloadSelectedButton from './base/DownloadSelectedButton';
import SearchButton from './base/SearchButton';
import Tab from './base/Tab';
import { addNewUserData, deleteSelected, handleTableNameChange } from '../../actions/dbActions';
import { dataTypes } from '../../consts/dataBase';
import { setTab } from '../../store/DatabaseSlice';
import ChangableField from '../Changable/ChangableField';
import DefaultModal from '../base/DefaultModal';

const DatabaseHeader = ({ table }) => {
   const [addDataRowModalVisible, setAddDataRowModalVisible] = useState(false);
   const [deleteModalVisible, setDeleteModalVisible] = useState(false);
   const [newData, setNewData] = useState({});

   const project = useSelector((state) => state.project);
   const dispatch = useDispatch();
   const database = useSelector((state) => state.database);

   const { selectedView, currentTable, selectedRows } = database;
   const { id } = currentTable;

   const {
      definition: { data_model },
   } = project;

   const currTable = id ? data_model[id]?.named_properties : {};

   return (
      <View style={styles.container}>
         {addDataRowModalVisible && (
            <DefaultModal
               visible={addDataRowModalVisible}
               onSuccess={() => {
                  setAddDataRowModalVisible(false);
                  dispatch(addNewUserData(newData));
               }}
               onError={() => setAddDataRowModalVisible(false)}
               title='New data row'
               description='Enter data for new row.'
               okText='OK'
               cancelText='CANCEL'>
               {currTable &&
                  Object.keys(currTable).map((property, index) => {
                     const key = property;

                     return (
                        <TextInput
                           key={key}
                           placeholder={currTable[property].name}
                           autoFocus
                           value={newData[key]}
                           onChangeText={(value) => {
                              setNewData((prevData) => {
                                 return {
                                    ...prevData,
                                    [key]: value,
                                 };
                              });
                           }}
                           style={styles.input}
                        />
                     );
                  })}
            </DefaultModal>
         )}
         {deleteModalVisible && (
            <DefaultModal
               visible={deleteModalVisible}
               onSuccess={() => {
                  setDeleteModalVisible(false);
                  dispatch(deleteSelected());
               }}
               onError={() => setDeleteModalVisible(false)}
               title='Do you really want to delete items?'
               description='You will not be able to recover this data.'
               okText='Discard'
               cancelText='Delete anyway'/>
         )}
         <View style={styles.headerContainer}>
            <View style={styles.container_header}>
               <ChangableField
                  containerStyle={{ alignItems: 'center', justifyContent: 'center' }}
                  style={styles.text}
                  value={table.name}
                  onChange={(value) => {
                     dispatch(handleTableNameChange(value));
                  }}
               />
               {!_.isEmpty(currTable) && (
                  <AddRowButton
                     onPress={() => {
                        setAddDataRowModalVisible(true);
                     }}
                  />
               )}
            </View>
            <View style={styles.nav}>
               <View style={styles.tab_section}>
                  <View style={styles.tabs}>
                     <Tab
                        style={selectedView === 'table view' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setTab('table view'))}
                        value='Table View'
                     />
                     <Tab
                        style={selectedView === 'data structure' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setTab('data structure'))}
                        value='Data Structure'
                     />
                     <Tab
                        style={selectedView === 'privacy settings' ? styles.tab_active : styles.tab}
                        onPress={() => dispatch(setTab('privacy settings'))}
                        value='Privacy Settings'
                     />
                  </View>
                  <SearchButton />
               </View>
               {Object.keys(selectedRows).length > 0 && (
                  <View style={styles.selectedContainer}>
                     <DeselectButton />
                     <DownloadSelectedButton />
                     <DeleteSelectedButton onPress={() => setDeleteModalVisible(true)} />
                  </View>
               )}
            </View>
         </View>
      </View>
   );
};

export default DatabaseHeader;

const styles = StyleSheet.create({
   headerContainer: {
      padding: 20,
      flexDirection: 'column',
      gap: 10,
   },
   container: {},
   container_header: {
      flex: 1,
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      gap: 12,
   },
   button: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 6,
      backgroundColor: '#2B55B7',
      paddingHorizontal: 24,
      paddingVertical: 6,
      borderRadius: 8,
      text: {
         color: '#FFFFFF',
      },
   },
   text: {
      fontSize: 18,
      lineHeight: '120%',
      outlineStyle: 'none',
      alignItems: 'center',
      justifyContent: 'center',
   },
   nav: {
      paddingVertical: 20,
      marginTop: 12,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   selectedContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   tab_section: {
      flexDirection: 'row',
      columnGap: 12,
      height: 56,
   },
   tabs: {
      display: 'flex',
      flexDirection: 'row',
      padding: 8,
      borderRadius: 8,
      backgroundColor: '#F5F6F8',
   },
   tab: {
      paddingVertical: 8,
      paddingHorizontal: 20,
      fontSize: 12,
      color: '#646D72',
      alignItems: 'center',
      justifyContent: 'center',
   },
   tab_active: {
      paddingVertical: 8,
      paddingHorizontal: 20,
      fontSize: 12,
      backgroundColor: '#2E3137',
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      text: {
         color: '#EFF1FE',
      },
   },
   input: {
      maxWidth: 300,
      borderBottomWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#fff',
      marginBottom: 10,
      paddingHorizontal: 10,
      paddingVertical: 6,
   },
});
