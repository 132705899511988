import { Entypo } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import DefaultInputContainer from './../../base/DefaultInputContainer';

const BoxListSettings = ({ componentDef, handleUpdate }) => {
    const [numColumns, setNumColumns] = useState(
        componentDef?.properties?.numColumns || ''
    );
  
    useEffect(() => {
        setNumColumns(componentDef?.properties?.numColumns || '');
    }, [componentDef?.properties?.numColumns]);

    return (
        <DefaultInputContainer
            style={{ width: 260, marginBottom: 30, }}
            childStyle={{ borderWidth: 1, borderColor: '#E0E4F4' }}
            componentDef={componentDef}
            onChangeText={(value) => {
            handleUpdate([
                {
                    path: ['properties', 'numColumns'],
                    value,
                },
            ]);
            setNumColumns(value);
            }}
            value={numColumns}
            icon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 10H3M21 18H3M21 6H3M21 14H3" stroke="#93989B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />
   );
};

export default BoxListSettings;

const styles = StyleSheet.create({
   
});
