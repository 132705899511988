import * as ExpoImagePicker from 'expo-image-picker';
import React, { useState, useEffect } from 'react';
import { Button, Image, TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import uuid from 'react-native-uuid';
import { useSelector } from 'react-redux';

import { storage } from '../../../services/firebase';

export default function ImagePicker({style, imageURI, onImageChange}) {
   const projectId = useSelector((state) => state.editor.project);

   const [image, setImage] = useState({ uri: imageURI });

   useEffect(()=>{
      setImage(prev => ({...prev, uri: imageURI}));
   },[imageURI])

   useEffect(()=>{
      Image.getSize(imageURI, (width, height) => {setImage(prev => ({...prev, imageWidth: width, imageHeight: height}))});
   },[image.uri])

   const pickImage = async () => {
      // No permissions request is necessary for launching the image library
      const result = await ExpoImagePicker.launchImageLibraryAsync({
         mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
         allowsEditing: true,
         aspect: [4, 3],
         quality: 1,
      });

      console.log(result);

      if (!result.canceled) {
         if (result.uri) {
            let fileExtension = '';
            switch (result.uri.substring(11, 15)) {
               case 'png;':
                  fileExtension = '.png';
                  break;
               case 'jpeg':
               case 'jpg;':
                  fileExtension = '.jpg';
                  break;
               default:
                  break;
            }
            const uri = await storage.uploadFile(
               'public/' + projectId + '/' + uuid.v4() + fileExtension,
               result?.assets[0].uri
            );

            setImage({ ...result, uri });
         } else if (result.assets) {
            let fileExtension = '';
            switch (result.assets[0].uri.substring(11, 15)) {
               case 'png;':
                  fileExtension = '.png';
                  break;
               case 'jpeg':
               case 'jpg;':
                  fileExtension = '.jpg';
                  break;
               default:
                  break;
            }
            const uri = await storage.uploadFile(
               'public/' + projectId + '/' + uuid.v4() + fileExtension,
               result?.assets[0].uri
            );
            setImage({ ...result, uri });
         } else {
            console.warn('Could not get image uri');
         }
      }
   };

   useEffect(() => {
      if (image?.uri && image.uri !== imageURI) {
          onImageChange(image.uri)
      }
  }, [image])

   // TODO: allow scrolling through already uploaded images
   return (
      <View
         style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
         }}>
         <View style={{ flex: 1 }}>
            {image.uri ? (
               <Image source={{ uri: image.uri }} style={{ width: 102, height: 72 }} />
            ) : (
               <Image
                  source={require('./../../../../assets/defaultImage.png')}
                  style={{ width: 102, height: 72 }}
               />
            )}
            <TouchableOpacity
               style={{ position: 'absolute', top: 10, left: 10 }}
               onPress={pickImage}>
               <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M0 4C0 1.79086 1.79086 0 4 0H26C28.2091 0 30 1.79086 30 4V26C30 28.2091 28.2091 30 26 30H4C1.79086 30 0 28.2091 0 26V4Z'
                     fill='#F5F6F8'
                  />
                  <path
                     d='M15 20.3125C17.934 20.3125 20.3125 17.934 20.3125 15C20.3125 13.234 19.4508 11.6693 18.125 10.7034M15.625 21.5L14.375 20.25L15.625 19M15 9.6875C12.066 9.6875 9.6875 12.066 9.6875 15C9.6875 16.766 10.5492 18.3307 11.875 19.2966M14.375 11L15.625 9.75L14.375 8.5'
                     stroke='#93989B'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </TouchableOpacity>
         </View>
         <View style={{ flex: 1 }}>
            <Text>{"NO DATA"}</Text>
            <Text>{`${image?.imageHeight || 0}px x ${
               image?.imageWidth || 0
            }px`}</Text>
            <Text>{`0 Mb`}</Text>
         </View>
      </View>
   );
}

const styles = StyleSheet.create({
   button: {
      borderRadius: 8,
      padding: 10,
      height: 30,
      minWidth: 105,
      paddingVertical: 6,
      paddingHorizontal: 30,
      gap: 10,
   },
   buttonOk: {
      backgroundColor: '#2E3137',
   },
});
