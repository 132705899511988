import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

const DesignSystemListItem = ({title, handleUpdate, onPress}) => {
   const [isHover, setIsHover] = React.useState(false);

   return (
      <TouchableOpacity
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => setIsHover(false)}
         onPress={onPress}
         style={[styles.mainContainer,
            isHover ? styles.item_hover : styles.item,
            ]}>
            <View style={styles.childContainer}>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Text style={styles.text}>
                            { title }
                        </Text>
                    </View>
                    </View>
                </View>
      </TouchableOpacity>
   );
};

export default DesignSystemListItem;

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        flexDirection: 'row',
     },
    childContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#E0E4F4',
    },
    item: {
        color: '#50585C',
        cursor: 'pointer',
    },
    item_hover: {
        backgroundColor: '#EFF2F9',
        cursor: 'pointer',

        fontSize: '14px',
        lineHeight: '130%',
        color: '#50585C',
    },
    text: {
        paddingLeft: 10,
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 19,
        color: '#93989B',
    },
});
