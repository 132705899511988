import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';

import DefaultInputContainer from '../base/DefaultInputContainer';
import ExpandableContainer from '../base/ExpandableContainer';

const WidthHeightBlock = ({ rootHandleElementUpdate, componentDef, handleUpdate }) => {
   const [widthType, setWidthType] = useState(
      componentDef[rootHandleElementUpdate]?.width?.type || 'expand'
   );
   const [heightType, setHeightType] = useState(
      componentDef[rootHandleElementUpdate]?.height?.type || 'expand'
   );

   const [widthFixValue, setWidthFixValue] = useState(
      componentDef[rootHandleElementUpdate]?.width?.fixed?.val || ''
   );

   useEffect(() => {
      setWidthFixValue(componentDef[rootHandleElementUpdate]?.width?.fixed?.val || '');
   }, [componentDef[rootHandleElementUpdate]?.width?.fixed?.val]);

   useEffect(() => {
      setHeightType(componentDef[rootHandleElementUpdate]?.height?.type || 'expand');
   }, [componentDef[rootHandleElementUpdate]?.height?.type]);

   useEffect(() => {
      setWidthType(componentDef[rootHandleElementUpdate]?.width?.type || 'expand');
   }, [componentDef[rootHandleElementUpdate]?.height?.type]);

   const changeChangeWidthType = (value) => { 
      handleUpdate([{ path: [rootHandleElementUpdate, 'width', 'type'], value }]);
      setWidthType(value);
   };

   const changeChangeHeightType = (value) => {
      handleUpdate([{ path: [rootHandleElementUpdate, 'height', 'type'], value }]);
      setHeightType(value);
   };

   return (
      <ExpandableContainer title='Width and height'>
         <View style={{ flex: 1 }}>
            <View style={styles.row}>
               <View style={[styles.column, { gap: 5, marginRight: 2, }]}>
                  <DefaultInputContainer
                     style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4', }}
                     componentDef={componentDef}
                     data={['expand', 'fit', 'fixed', 'ratio']}
                     onItemPress={changeChangeWidthType}
                     value={widthType}
                     icon={
                        <svg
                           width='14'
                           height='10'
                           viewBox='0 0 14 10'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M4.75 1.25L1 5L4.75 8.75M9.25 1.25L13 5L9.25 8.75'
                              stroke='#93989B'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     }
                  />
                  {widthType == 'fixed' && (
                     <DefaultInputContainer
                        style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4',}}
                        componentDef={componentDef}
                        unit={componentDef[rootHandleElementUpdate]?.width?.fixed?.unit}
                        value={widthFixValue}
                        handleUnitUpdate={(value) => {
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'width', 'fixed', 'unit'], value },
                           ]);
                        }}
                        onChangeText={(value) => {
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'width', 'fixed', 'val'], value },
                           ]);
                        }}
                        icon={
                           <svg
                              width='14'
                              height='11'
                              viewBox='0 0 14 11'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                 d='M3.26944 11L0.445579 0.818182H2.06135L4.04501 8.70312H4.13947L6.20268 0.818182H7.80353L9.86674 8.7081H9.9612L11.9399 0.818182H13.5606L10.7318 11H9.18564L7.04288 3.37358H6.96333L4.82058 11H3.26944Z'
                                 fill='#93989B'
                              />
                           </svg>
                        }
                     />
                  )}
                  {widthType == 'ratio' ? (
                     <DefaultInputContainer
                        style={{borderWidth: 1, borderColor: '#E0E4F4',}}
                        componentDef={componentDef}
                        onChangeText={(value) =>
                           handleUpdate([
                              {
                                 path: [rootHandleElementUpdate, 'height', 'ratio', 'width'],
                                 value,
                              },
                           ])
                        }
                        data={[...Array(17).keys()]}
                        value={componentDef[rootHandleElementUpdate].height?.ratio?.width}
                        icon={
                           <svg
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                 d='M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z'
                                 stroke='#93989B'
                                 strokeWidth='2'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                           </svg>
                        }
                     />
                  ) : (
                     <>
                        <DefaultInputContainer
                           style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 { path: [rootHandleElementUpdate, 'width', 'min', 'unit'], value },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 { path: [rootHandleElementUpdate, 'width', 'min', 'val'], value },
                              ]);
                           }}
                           unit={componentDef[rootHandleElementUpdate]?.width?.min?.unit}
                           value={componentDef[rootHandleElementUpdate]?.width?.min?.val}
                           icon={
                              <svg
                                 width='18'
                                 height='16'
                                 viewBox='0 0 18 16'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M9 1.25V14.75M16.5 8H11.625M11.625 8L14.625 11M11.625 8L14.625 5M1.5 8H6.375M6.375 8L3.375 11M6.375 8L3.375 5'
                                    stroke='#646D72'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                        <DefaultInputContainer
                           style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 { path: [rootHandleElementUpdate, 'width', 'max', 'unit'], value },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 { path: [rootHandleElementUpdate, 'width', 'max', 'val'], value },
                              ]);
                           }}
                           unit={componentDef[rootHandleElementUpdate]?.width?.max?.unit}
                           value={componentDef[rootHandleElementUpdate]?.width?.max?.val}
                           icon={
                              <svg
                                 width='16'
                                 height='16'
                                 viewBox='0 0 16 16'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M14.75 14.75V1.25M1.25 14.75V1.25M3.875 8H12.125M12.125 10.25L12.125 5.75M3.875 10.25L3.875 5.75'
                                    stroke='#93989B'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                     </>
                  )}
               </View>
               <View style={[styles.column, { gap: 5, marginLeft: 2, }]}>
                  <DefaultInputContainer
                     style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                     componentDef={componentDef}
                     data={['expand', 'fit', 'fixed', 'ratio']}
                     onItemPress={changeChangeHeightType}
                     value={heightType}
                     icon={
                        <svg
                           width='10'
                           height='14'
                           viewBox='0 0 10 14'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M1.25 9.25L5 13L8.75 9.25M1.25 4.75L5 1L8.75 4.75'
                              stroke='#93989B'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     }
                  />
                  {heightType === 'fixed' && (
                     <DefaultInputContainer
                        style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                        componentDef={componentDef}
                        handleUnitUpdate={(value) => {
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'height', 'fixed', 'unit'], value },
                           ]);
                        }}
                        onChangeText={(value) => {
                           handleUpdate([
                              { path: [rootHandleElementUpdate, 'height', 'fixed', 'val'], value },
                           ]);
                        }}
                        unit={componentDef[rootHandleElementUpdate]?.height?.fixed?.unit}
                        value={componentDef[rootHandleElementUpdate]?.height?.fixed?.val}
                        icon={
                           <svg
                              width='10'
                              height='11'
                              viewBox='0 0 10 11'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                 d='M0.923295 11V0.818182H2.45952V5.2429H7.53551V0.818182H9.0767V11H7.53551V6.56037H2.45952V11H0.923295Z'
                                 fill='#93989B'
                              />
                           </svg>
                        }
                     />
                  )}

                  {heightType === 'ratio' ? (
                     <DefaultInputContainer
                        style={{ borderWidth: 1, borderColor: '#E0E4F4' }}
                        componentDef={componentDef}
                        onChangeText={(value) =>
                           handleUpdate([
                              {
                                 path: [rootHandleElementUpdate, 'height', 'ratio', 'height'],
                                 value,
                              },
                           ])
                        }
                        data={[...Array(17).keys()]}
                        value={componentDef[rootHandleElementUpdate].height?.ratio?.height}
                        icon={
                           <svg
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                 d='M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z'
                                 stroke='#93989B'
                                 strokeWidth='2'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                              />
                           </svg>
                        }
                     />
                  ) : (
                     <>
                        <DefaultInputContainer
                           style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [rootHandleElementUpdate, 'height', 'min', 'unit'],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 { path: [rootHandleElementUpdate, 'height', 'min', 'val'], value },
                              ]);
                           }}
                           unit={componentDef[rootHandleElementUpdate]?.height?.min?.unit}
                           value={componentDef[rootHandleElementUpdate]?.height?.min?.val}
                           icon={
                              <svg
                                 width='16'
                                 height='18'
                                 viewBox='0 0 16 18'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M1.25 9H14.75M8 1.5V6.375M8 6.375L11 3.375M8 6.375L5 3.375M8 16.5V11.625M8 11.625L11 14.625M8 11.625L5 14.625'
                                    stroke='#646D72'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                        <DefaultInputContainer
                           style={{ flex: 1, width: 125, borderWidth: 1, borderColor: '#E0E4F4' }}
                           componentDef={componentDef}
                           handleUnitUpdate={(value) => {
                              handleUpdate([
                                 {
                                    path: [rootHandleElementUpdate, 'height', 'max', 'unit'],
                                    value,
                                 },
                              ]);
                           }}
                           onChangeText={(value) => {
                              handleUpdate([
                                 { path: [rootHandleElementUpdate, 'height', 'max', 'val'], value },
                              ]);
                           }}
                           unit={componentDef[rootHandleElementUpdate]?.height?.max?.unit}
                           value={componentDef[rootHandleElementUpdate]?.height?.max?.val}
                           icon={
                              <svg
                                 width='16'
                                 height='16'
                                 viewBox='0 0 16 16'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M14.75 1.25H1.25M14.75 14.75H1.25M8 12.125L8 3.87503M10.2501 3.87501L5.75 3.875M10.2501 12.125L5.75 12.125'
                                    stroke='#93989B'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                 />
                              </svg>
                           }
                        />
                     </>
                  )}
               </View>
            </View>
         </View>
      </ExpandableContainer>
   );
};

export default WidthHeightBlock;

const styles = StyleSheet.create({
   marginsContainer: {
      width: 260,
      height: 200,
      borderRadius: 4,
      borderWidth: 4,
      borderColor: '#DFE2EE',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
   },
   bordersMainContainer: {
      width: 260,
      height: 100,
      borderRadius: 4,
      borderWidth: 4,
      borderColor: '#DFE2EE',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
   },
   marginInnerContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paddingTopContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paddingsContainer: {
      width: 150,
      height: 120,
      borderRadius: 4,
      borderWidth: 4,
      backgroundColor: '#F8FAFC',
      borderColor: '#DFE2EE',
   },
   bordersContainer: {
      width: 120,
      height: 40,
      borderRadius: 4,
      borderWidth: 4,
      backgroundColor: '#F8FAFC',
      borderColor: '#DFE2EE',
   },
   innerBordersContainer: {
      height: 17,
      width: 57,
      borderBottomRightRadius: 4,
      backgroundColor: '#DFE2EE',
      top: 0,
      left: 0,
      position: 'absolute',
   },
   marginText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      textAlign: 'center',
      width: 60,
   },
   marginTopText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   borderRow: {
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#646D72',
      fontSize: 10,
      lineHeight: 13,
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
   },
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10,
   },
   row: {
      flex: 1,
      flexDirection: 'row',
      padding: 2,
      justifyContent: 'center',
      alignItems: 'flex-start',
   },
   column: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
   },
   extend: {
      flexGrow: 1,
      flexShrink: 1,
   },
   fit: {
      flexGrow: 0,
      flexShrink: 1,
   },
   centered: {
      flexDirection: 'row',
      justifyContent: 'center',
   },
   label: {
      fontSize: 8,
   },
   box: {
      flex: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
   },
   input: {
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
      fontSize: 8,
   },
   multilineInput: {
      borderWidth: 1,
      minHeight: 30,
      flexGrow: 1,
      height: 'fit-content',
      borderColor: '#ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 16,
   },
   picker: {
      height: 24,
      fontSize: 8,
      marginBottom: 10,
      caret: 'hidden',
   },
   valunit: {
      flexDirection: 'row',
   },
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   },
});
