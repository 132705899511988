import { TPatch, TPatchDynamicContent, TMessage } from "./types"
import { addRunPatch } from "./slice"
import { createComponent, updateComponent } from "../ProjectSlice"
import { askAI, createAndStoreImage } from "./openai"

export const generateComponent = (
   component: any,
   dynamicContent: TPatchDynamicContent[],
   parentKey: string[],
   position: number,
   debug: number = 0
) => async (
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   const results: Record<string, any> = await dispatch(createComponent(parentKey, position, component))
   if (debug > 3) console.log(`[GenerateComponent] Created ${component.name}:`, results)
   const componentDef = Object.values(results).find(componentDef => componentDef.uid !== parentKey[parentKey.length - 1])
   if (!componentDef) {
      if (debug > 1) console.error(`[GenerateComponent] Error when creating ${component.name}`, results)
      return
   }
   dispatch(addRunPatch({
      uid: componentDef.uid,
      name: componentDef.name,
      action: 'createComponent',
      type: 'component',
      content: dynamicContent,
   }))
   return componentDef
}
export const generateComponentContent = (patch: TPatch, debug: number = 0) => async (
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   // const contentGenerators = {
   //    image: createAndStoreImage,
   //    text: askAI
   // }
   const prompt: string = getState().assistant.run.prompt
   const enrichedPrompt: string = getState().assistant.run.enrichedPrompt
   return Promise.all((patch.content ?? []).map(async (dynamicContent: TPatchDynamicContent): Promise<any> => {
      let completePrompt: string | TMessage[]
      if (typeof dynamicContent.context === 'string') {
         completePrompt = dynamicContent.context + (enrichedPrompt || prompt)
      } else {
         completePrompt = dynamicContent.context.concat({ role: 'user', content: enrichedPrompt })
      }

      switch (dynamicContent.type) {
         case 'image': {
            const projectId = getState().editor.project
            const filePath = `public/${projectId}/${patch.uid}-${dynamicContent.path.join('-')}`
            const generatedValue = await createAndStoreImage(completePrompt, filePath, debug)
            if (debug > 2) console.log('[GenerateComponentContent] Generated', generatedValue)
            return dispatch(updateComponent([patch.uid], [{path: dynamicContent.path, value: generatedValue.content}]))
         }
         case 'text': {
            const generatedValue = await askAI(completePrompt, debug)
            if (debug > 2) console.log('[GenerateComponentContent] Generated', generatedValue)
            return dispatch(updateComponent([patch.uid], [{path: dynamicContent.path, value: generatedValue.content}]))
         }
      }
      // const generatedValue = await contentGenerators[dynamicContent.type](completePrompt, debug)
      // if (debug > 2) console.log('[GenerateComponentContent] Generated', generatedValue)
      // return dispatch(updateComponent([patch.uid], [{path: dynamicContent.path, value: generatedValue.content}]))
   }))
}