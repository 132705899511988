import { createSlice } from '@reduxjs/toolkit';

const databaseSlice = createSlice({
   name: 'database',
   initialState: {
      selectedView: 'table view',
      currentTable: {},
      selectedRows: {},
      dataToEdit: {},
   },
   reducers: {
      setTable: (state, action) => {
         if (typeof action.payload == 'object' && action.payload !== '') {
            return {
               ...state,
               currentTable: action.payload,
            };
         }
      },
      setTab: (state, action) => {
         if (typeof action.payload == 'string' && action.payload !== '') {
            return {
               ...state,
               selectedView: action.payload,
            };
         }
      },
      addSelectedRow: (state, action) => {
         const { name, data } = action.payload;
         return {
            ...state,
            selectedRows: {
               ...state.selectedRows,
               [name]: data,
            },
         };
      },
      removeSelectedRow: (state, action) => {
         const { name } = action.payload;
         const selectedRows = { ...state.selectedRows };
         delete selectedRows[name];
         return {
            ...state,
            selectedRows: {
               ...selectedRows,
            },
         };
      },
      selectAll: (state, action) => {
         const { rows } = action.payload;
         return {
            ...state,
            selectedRows: rows,
         };
      },
      deselectAll: (state, action) => {
         return {
            ...state,
            selectedRows: {},
         };
      },
      addDataToEdit: (state, action) => {
         const { cell, path, data, oldValue, col} = action.payload;
         return {
            ...state,
            dataToEdit: {
               ...state.dataToEdit,
               [cell]: {
                  col,
                  path,
                  data,
                  oldValue
               },
            },
         };
      },
      removeDataToEdit: (state, action) => {
         const { path } = action.payload;
         const dataToEdit = { ...state.dataToEdit };
         delete dataToEdit[path];

         return {
            ...state,
            dataToEdit: {
               ...dataToEdit,
            },
         };
      },
      clearDataToEdit: (state, action) => {
         return {
            ...state,
            dataToEdit: {},
         };
      },
   },
});

export default databaseSlice.reducer;

export const { setTable, setTab, 
   addSelectedRow, removeSelectedRow, selectAll, deselectAll,
   addDataToEdit, removeDataToEdit, clearDataToEdit } =
   databaseSlice.actions;
