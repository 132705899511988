import { setGlobalVar } from '@reducers/GlobalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import uuid from 'react-native-uuid';

import { extraElementsComponent } from './../consts/common';
import { setPage } from './EditorSlice';
// import { bubbleDB } from '../services/editorBubble';
import { checkNonEmptyArray } from './check_utils';
import { realtimeDB, firestoreDB } from '../services/editorFirebase';
import { useStore } from 'react-redux';

function getAllChildUids(componentUid, components) {
   function traverseChildren(uid) {
      const component = components[uid];

      if (component && component.children) {
         return component.children.reduce((childUids, childUid) => {
            return [...childUids, childUid, ...traverseChildren(childUid)];
         }, []);
      }
      return [];
   }
   return [componentUid, ...traverseChildren(componentUid)];
}

function duplicate(component, parent, components) {
   let updatedComponents = {};

   function handleDuplicate(componentDef, parentDef = null) {
      const duplUuid = uuid.v4();

      if (parentDef && parentDef.uid === parent.uid && !updatedComponents[parent.uid]) {
         const parentComponent = {
            ...parentDef,
            children: parentDef.children ? [...parentDef.children, duplUuid] : [duplUuid],
         };
         updatedComponents[parentComponent.uid] = parentComponent;
      }

      const duplicatedComponent = {
         ...componentDef,
         uid: duplUuid,
         composite_key: parentDef ? [...parentDef.composite_key, duplUuid] : [duplUuid],
      };

      if (duplicatedComponent.children) {
         const newComponentsUid = duplicatedComponent.children.map((childUid) => {
            const newChild = handleDuplicate(components[childUid], duplicatedComponent);
            return newChild.uid;
         });

         updatedComponents = {
            ...updatedComponents,
            [duplUuid]: {
               ...duplicatedComponent,
               children: newComponentsUid,
            },
         };
      } else {
         updatedComponents[duplUuid] = duplicatedComponent;
      }

      console.log(duplicatedComponent);

      return duplicatedComponent;
   }

   handleDuplicate(component, parent);

   return {
      parentComponent: parent?.uid ? updatedComponents[parent.uid] : null,
      updatedComponents,
   };
}

// Thunk Actions
/**
 *
 * @param {string[]} componentKey expects an array of strings
 * @param {{path: string[], value: *}[]} payload expects a list of {path, value}
 * @returns
 */
export const updateComponent = (componentKey, payload) => async (dispatch, getState) => {
   if (checkNonEmptyArray(componentKey)) {
      console.warn('Couldn\'t create component with these arguments:\ncomponentKey:',componentKey,'\npayload:',payload)
      return;
   }
   const updates = payload.map((item) => ({
      path: ['components', componentKey[componentKey.length - 1], ...(item.path || item.key)],
      value: 'value' in item ? item.value : item.val,
   }));

   dispatch(updateProject(updates));

   return getState().project.definition.components[componentKey.slice(-1)];
};

export const updateCustomStyle = (styleName, payload) => async (dispatch) => {
   const updates = payload.map((item) => ({
      path: ['design_system', 'user_styles', styleName, ...item.path],
      value: item.value,
   }));

   dispatch(updateProject(updates));
};

export const markPageAsIndex = (page) => async (dispatch, getState) => {
   const updates = [
      {
         path: ['page_index'],
         value: page,
      },
   ];

   dispatch(updateProject(updates));
};

export const createComponentData = (projectId, componentId) => async (dispatch) => {
   const updates = {};
   const dataId = uuid.v4();
   const value = { uid: dataId, label: 'New Data' };
   // updates['/projects/' + projectId + '/dev/components/' + componentId + '/data/' + dataId] = value;
   dispatch(
      updateProject([
         {
            path: ['components', componentId, 'data', dataId],
            value,
         },
      ])
   );
   // realtimeDB.updateData(updates);
};

export const deleteComponentLogic = (componentKey, logicId) => async (dispatch, getState) => {
   const path_root = '/projects/' + getState().editor.project + '/dev/components/';
   return realtimeDB.delete(
      path_root + componentKey[componentKey.length - 1] + '/logic/' + logicId
   );
};

export const createComponentLogic =
   (componentKey, executionOrder = 0, name = 'On Click') =>
   async (dispatch, getState) => {
      const logicId = uuid.v4();
      const newLogic = {
         uid: logicId,
         name,
         execution_order: executionOrder,
         nodes: {
            [logicId + '_trigger']: {
               uid: logicId + '_trigger',
               name: 'On press',
               type: 'trigger.onClick',
            },
         },
         connections: {},
      };
      const updates = [
         {
            path: ['logic', logicId],
            value: newLogic,
         },
      ];
      console.log('Creating logic', logicId, newLogic);
      dispatch(updateComponent(componentKey, updates));
   };

export const updateLogicAction = (logicId, actionId, payload) => async (dispatch, getState) => {
   const state = getState();
   const {
      globals: { selected },
   } = state;
   const { name } = payload;

   const updates = [
      {
         path: ['logic', logicId, 'name'],
         value: name,
      },
      {
         path: ['logic', logicId, 'nodes', actionId],
         value: payload,
      },
   ];

   dispatch(updateComponent(selected, updates));
};

export const createLogicAction =
   (logicId, parentNodeId, parentOutput = 'success') =>
   async (dispatch, getState) => {
      const componentKey = getState().globals.selected;
      const componentDef =
         getState().project.definition.components[componentKey[componentKey.length - 1]];
      const actionId = uuid.v4();

      const action = {
         uid: actionId,
         name: 'New Action',
         type: 'action.callAPI',
         parameters: {
            url: '',
            method: 'GET',
         },
      };
      const updates = [
         {
            path: ['logic', logicId, 'nodes', actionId],
            value: action,
         },
         {
            path: ['logic', logicId, 'connections', parentNodeId, parentOutput],
            value: { [actionId]: { order: 0, to: actionId, uid: actionId } },
         },
      ];

      if (componentDef.logic[logicId]?.connections?.[parentNodeId]?.[parentOutput]) {
         const existingConnection = Object.keys(
            componentDef.logic[logicId].connections[parentNodeId][parentOutput]
         )[0];
         updates.push({
            path: ['logic', logicId, 'connections', actionId, 'success'],
            value: {
               [existingConnection]: { order: 0, to: existingConnection, uid: existingConnection },
            },
         });
      }
      dispatch(updateComponent(componentKey, updates));
   };

export const deleteComponent = (parentDef, componentId) => async (dispatch, getState) => {
   const components = getState().project.definition.components;
   const updates = [
      {
         path: ['components', parentDef.uid],
         value: {
            ...parentDef,
            children: parentDef.children.filter(function (item) {
               return item !== componentId;
            }),
         },
      },
      ...getAllChildUids(componentId, components).map((item) => {
         return { path: ['components', item], value: null };
      }),
   ];

   dispatch(updateProject(updates));
};

export const deletePage = (uid) => async (dispatch, getState) => {
   const state = getState();

   const page = state.project.definition.pages[uid];
   const components = state.project.definition.components;

   const updates = [
      {
         path: ['pages', uid],
         value: null,
      },
      ...getAllChildUids(page.root_component, components).map((item) => {
         return { path: ['components', item], value: null };
      }),
   ];

   console.log(updates);

   dispatch(updateProject(updates));
};

/**
 * 
 * @param {{path: string[], value: *}[]} updates list of updates to apply to design system 
 * @returns 
 */
export const updateDesignSystem = (updates) => async (dispatch, getState) => {
   if (checkNonEmptyArray(updates)) {
      console.warn('Couldn\'t update Design System with these arguments:\nupdates:', payload)
      return;
   }
   const cleanUpdates = updates.map(update => ({path: ['design_system', ...update.path], value: update.value}));
   console.log('Updating design system');
   return dispatch(updateProject(cleanUpdates))
};

export const createPage = (payload) => async (dispatch, getState) => {
   const componentId = uuid.v4();
   const pageId = 'page_' + componentId;

   const page = {
      uid: pageId,
      name: payload,
      root_component: componentId,
   };

   const component = {
      uid: componentId,
      name: 'Main Container',
      type: 'box',
      composite_key: [componentId],
      children: [],
      custom_styling: {
         width: {
            type: 'expand',
         },
         height: {
            type: 'expand',
         },
         position: {
            type: 'auto',
         },
      },
   };

   return await Promise.all([
      dispatch(updateProject([{ path: ['pages', pageId], value: page }])),
      dispatch(updateProject([{ path: ['components', componentId], value: component }])),
      dispatch(setPage(pageId)),
      // dispatch(setGlobalVar({ key: 'selected', value: component.composite_key })),
   ])
      .then((data) => {
         return componentId;
      })
      .catch((error) => {});
};

export const updatePage = (projectId, pageId, payload) => async (dispatch, getState) => {
   const updates = Object.keys(payload).map(
      (key) => ({
         path: ['pages', pageId, key],
         value: payload[key],
      })
   )
   console.log('Updating page', pageId);
   dispatch(updateProject(updates))
   // TODO: enable multiple envs
   // updates['/projects/' + projectId + '/dev/pages/' + pageId] = payload;
   // await realtimeDB.updateData(updates);
};

export const getCustomStylingForComponent = (type) => {
   switch (type) {
      case 'box':
      case 'boxList':
      case 'map':
         return {
            width: {
               type: 'expand',
            },
            height: {
               type: 'fit',
            },
            position: {
               type: 'auto',
            },
         };
      case 'input':
      case 'text':
         return {
            width: {
               type: 'fit',
            },
            height: {
               type: 'fit',
            },
            position: {
               type: 'auto',
            },
         };
      case 'image':
         return {
            width: {
               height: {
                  type: 'fit',
               },
            },
            height: {
               type: 'fixed',
               fixed: {
                  val: 200,
                  unit: 'px',
               },
            },
            position: {
               type: 'auto',
            },
         };
      case 'modal':
         return {
            background: {
               color: '#877e7e61',
               type: 'color',
            },
            layout: {
               direction: 'column',
               distribution: 'center',
            },
            position: {
               relative: {
                  bottom: {
                     val: '0',
                  },
                  left: {
                     val: '0',
                  },
                  right: {
                     val: '0',
                  },
                  top: {
                     val: '0',
                  },
               },
               type: 'fixed',
            },
         };
      default:
         return {};
   }
};

export const getPropsForComponent = (type) => {
   switch (type) {
      case 'box':
      case 'boxList':
      case 'map':
      case 'image':
         return {};
      case 'modal':
         return { is_hidden: true };
      case 'input':
         return {
            text: 'edit text...',
            input: {
               placeholder: 'placeholder text...',
               type: 'text',
            },
         };
      case 'text':
         return {
            text: 'edit text…',
         };
   }
};

/**
 * 
 * @param {string[]} parentKey 
 * @param {integer} position 
 * @param {*} payload new object to be added to the parent
 * @returns New component and modified parent
 */
export const createComponent = (parentKey=[], position=0, payload={}) => async (dispatch, getState) => {
   if (checkNonEmptyArray(parentKey)) {
      console.warn('Couldn\'t create component with these arguments:\nparent_key:',parentKey,'\nposition:',position,'\npayload:',payload)
      return;
   }
   const componentId = 'uid' in payload ? payload.uid : uuid.v4();

   // Update parent
   const parent = getState().project.definition.components[parentKey[parentKey.length - 1]]
   const parentChildren = parent?.children || [];
   const parentUpdates = [
      {
         path: ['children'],
         value: [
            // part of the array before the specified index
            ...parentChildren.slice(0, position),
            // inserted item
            componentId,
            // part of the array after the specified index
            ...parentChildren.slice(position)
          ]
      }
   ];
   
   // Create component
   const component = {
      uid: componentId,
      type: 'box',
      name: 'New ' + ('type' in payload ? payload.type : 'box') + ' component',
      ...payload,
      custom_styling: {
         ...(payload.custom_styling || {}),
      },
      composite_key: [...parent.composite_key, componentId],
   };
   
   await Promise.all([
      dispatch(updateProject([{
         path: ['components', componentId],
         value: component
      }])),
      dispatch(updateComponent(
         parent.composite_key,
         parentUpdates
      )),
      dispatch(setGlobalVar({ key: 'selected', value: component.composite_key })),
   ]).catch((error) => {
      console.warn('Error updating component', component, error);
   });

   return { [parent.uid]: {...parent, children: parentUpdates[0].value}, [componentId]: component };
};

/**
 *
 * @param {string[]} parent_key
 * @param {integer} position
 * @param {*} payload new object to be added to the parent
 * @returns New component and modified parent
 */
export const createComponentInEditor =
   (parent_key = [], position = 0, payload = {}) =>
   async (dispatch, getState) => {
      checkNonEmptyArray(parent_key);

      const { type } = payload;
      const componentId = 'uid' in payload ? payload.uid : uuid.v4();

      // Update parent
      const parent_children =
         getState().project.definition.components[parent_key.slice(-1)].children || [];
      const parentUpdates = [
         {
            path: ['children'],
            value: [
               // part of the array before the specified index
               ...parent_children.slice(0, position),
               // inserted item
               componentId,
               // part of the array after the specified index
               ...parent_children.slice(position),
            ],
         },
      ];

      const updates = [];

      // Create component
      const component = {
         uid: componentId,
         type: 'box',
         name: 'New ' + ('type' in payload ? type : 'box') + ' component',
         ...payload,
         custom_styling: getCustomStylingForComponent(type),
         properties: getPropsForComponent(type),
         composite_key:
            type == 'modal' ? [parent_key[0], componentId] : [...parent_key, componentId],
      };

      if (payload.type == 'modal') {
         const componentBoxId = uuid.v4();
         const componentBox = {
            uid: componentBoxId,
            composite_key: [parent_key[0], componentId, componentBoxId],
            type: 'box',
            name: 'Root modal',
            custom_styling: {
               height: {
                  fixed: {
                     unit: 'px',
                     val: 200,
                  },
                  type: 'fixed',
               },
               position: {
                  auto: {
                     position: 'center',
                  },
                  type: 'auto',
               },
               background: {
                  color: '#FFFFFF',
                  type: 'color',
               },
               width: {
                  fixed: {
                     unit: 'px',
                     val: 300,
                  },
                  type: 'fixed',
               },
            },
         };
         component.children = [componentBoxId];

         updates.push({
            path: ['components', componentBoxId],
            value: componentBox,
         });
      }

      updates.push({
         path: ['components', componentId],
         value: component,
      });

      const [updatedParent, _, __] = await Promise.all([
         dispatch(updateComponent(parent_key, parentUpdates)),
         dispatch(updateProject(updates)),
         // dispatch(setGlobalVar({ key: 'selected', value: component.composite_key })),
      ]).catch((error) => {
         console.warn('Error updating component', component, error);
      });

      return { [parent.uid]: updatedParent, [componentId]: component };
   };

export const duplicateComponent = (selectedKey, componentDef) => async (dispatch, getState) => {
   const state = getState();
   const {
      project: {
         definition: { components },
      },
   } = state;

   const parentDef = { ...components[selectedKey] };
   const { updatedComponents } = duplicate(componentDef, parentDef, components);

   const paths = Object.keys(updatedComponents).map((item) => {
      return {
         path: ['components', item],
         value: updatedComponents[item],
      };
   });

   await dispatch(updateProject([...paths])).catch((error) => {
      console.warn('Error creating component', selectedKey, error);
   });

   // dispatch(setGlobalVar({ key: 'selected', value: parentComponent.composite_key }));
};

export const duplicatePage = (pageUid) => async (dispatch, getState) => {
   const state = getState();
   const {
      project: {
         definition: { pages, components },
      },
   } = state;

   const currentPage = pages[pageUid];

   const { parentComponent, updatedComponents } = duplicate(
      components[currentPage.root_component],
      undefined,
      components
   );

   const paths = Object.keys(updatedComponents).map((item) => {
      return {
         path: ['components', item],
         value: updatedComponents[item],
      };
   });

   // const updates = [
   //    ...(paths)
   // ].push({
   //    path: ['components', parentComponent.uid],
   //    value: parentComponent
   // }).push({
   //    path: ['pages', 'page_' + parentComponent.uid],
   //    value: {
   //       name: 'dupl_' + parentComponent.name,
   //       uid: 'page_' + parentComponent.uid,
   //       root_component: parentComponent.uid
   //    }
   // })

   dispatch(createPage('dupl_' + currentPage.name));

   // await dispatch(updateProject(updates)).catch((error) => {
   //    console.warn('Error creating page', error);
   // });

   // dispatch(setGlobalVar({ key: 'selected', value: parentComponent.composite_key }));
};

export const deployProject = (projectDefinition) => async (dispatch, getState) => {
   const state = getState();
   const { project } = state;
   const { projectId } = project;

   dispatch(updateProject([{ path: ['projects', projectId, 'dev'], value: projectDefinition }], true));
};

export const addTableCollection = (projectId) => async (dispatch, getState) => {
   const collectionId = uuid.v4();
   const value = { uid: collectionId, value: [{ uid: uuid.v4(), value: 'New element' }] };
   await realtimeDB.writeData(`/data/${collectionId}/`, value);
};
export const addTable = (projectId, projectDefinition) => async (dispatch) => {
   const tableId = uuid.v4();
};

export const addDatabase = (projectId, databaseName) => async (dispatch) => {};

/**
 *
 * @param {{path: string[], value: *}[]} updates (list of updates to be applied to the project)
 * @param {boolean} withoutRoot Never used. TODO: remove it?
 * @returns
 */
export const updateProject = (updates, withoutRoot = false) => async (dispatch, getState) => {
   if (checkNonEmptyArray(updates)) {
      console.warn('Couldn\'t update project with these arguments:\nupdates:',updates)
      return;
   }
   const state = getState(),
      {editor, project} = state;
   const path_root = withoutRoot ? '' : '/projects/' + editor.project + '/dev/';

      const promises = updates.map(({ path, value }) => {
         console.log('Updating project', path_root + path.join('/'), 'with value:', value);
         return project.update(path_root + path.join('/'), value).catch(() => {
            project.create(path_root + path.join('/'), value);
         });
      });

      await Promise.all(promises).catch((error) => {
         console.log('Error updating project', updates, error);
      });
   };

const projectSlice = createSlice({
   name: 'project',
   initialState: {
      create: createFn,
      read: readFn,
      watch: watchFn,
      update: updateFn,
      delete: deleteFn,
      dataTables: {},
      dev: {},
      metadata: {},
      definition: {},
      isEditing: true,
      userStyleElements: {
         ...extraElementsComponent,
      },
   },
   reducers: {
      setProjectDefinition: (state, action) => {
         console.log('Setting project definition from DB');
         return {
            ...state,
            definition: {
               ...action.payload,
               components: {
                  ...action.payload.components,
                  ...state.userStyleElements,
               },
            },
         };
      },
      updateChildElementStyle: (state, action) => {
         const { styling } = action.payload;
         const extraStyles = styling
            ? styling
            : extraElementsComponent.childComponent.custom_styling;
         return {
            ...state,
            definition: {
               ...state.definition,
               components: {
                  ...state.definition.components,
                  childComponent: {
                     ...state.definition.components['childComponent'],
                     custom_styling: {
                        ...state.definition.components['childComponent'].custom_styling,
                        ...extraStyles,
                     },
                     ...extraStyles,
                  },
               },
            },
         };
      },
      setUpdateElementsStyle: (state, action) => {
         const { key, style } = action.payload;
         return {
            ...state,
            userStyleElements: {
               ...state.userStyleElements,
               [key]: {
                  ...state.userStyleElements[key],
                  style,
               },
            },
         };
      },
      setProjectId: (state, action) => {
         return { ...state, projectId: action.payload };
      },
      setEnvironmentId: (state, action) => {
         return { ...state, environmentId: action.payload };
      },
      dbCreateSuccess: (state, action) => {
         const { assetType } = action.payload;
         return {
            ...state,
            dataTables: {
               ...state.dataTables,
               [assetType]: {
                  loading: false,
                  data: {},
                  error: undefined,
               },
            },
         };
      },
      dbCreateError: (state, action) => {
         const { assetType, error } = action.payload;
         return {
            ...state,
            dataTable: {
               ...state.dataTable,
               [assetType]: {
                  loading: false,
                  data: {},
                  error,
               },
            },
         };
      },
      dbReadSuccess: (state, action) => {
         const { assetType, data } = action.payload;
         return {
            ...state,
            dataTable: {
               ...state.dataTable,
               [assetType]: {
                  loading: false,
                  data,
                  error: undefined,
               },
            },
         };
      },
      dbReadError: (state, action) => {
         const { assetType, error } = action.payload;
         return {
            ...state,
            dataTable: {
               ...state.dataTable,
               [assetType]: {
                  loading: false,
                  data: {},
                  error,
               },
            },
         };
      },
   },
});

async function createFn(path, data, isUserData = false) {
   if (isUserData) {
      if (!data.uid) {
         data.uid = uuid.v4();
      }
      if (!data.createdAt) {
         const creationDate = Date.now();
         data.createdAt = creationDate;
      }
      if (!data.createdBy) {
         const user = useStore().user;
         data.createdBy = user?.uid || 'anonymous';
      }
   }
   const assetLocation = 'firebase'; //store.getState().project; // definition?.data_indexes?.[assetType].provider
   switch (assetLocation) {
      case 'firebase':
         return realtimeDB.create(path, data);
      case 'firestore':
         return firestoreDB.create(path, data);
      case 'bubble':
         return bubbleDB.create(path, data);
      default:
         return [];
   }
}

async function readFn(path, constraints, view) {
   const assetLocation = 'firebase'; //store.getState().project; // definition?.data_indexes?.[assetType].provider
   switch (assetLocation) {
      case 'firebase':
         return realtimeDB.read(path, constraints, view);
      case 'firestore':
         return firestoreDB.read(path, constraints, view);
      case 'bubble':
         return bubbleDB.read(path, constraints, view);
      default:
         return [];
   }
}

async function watchFn(path, cb, assetType, constraints, view) {
   const assetLocation = 'firebase'; //store.getState().project; // definition?.data_indexes?.[assetType].provider
   switch (assetLocation) {
      case 'firebase':
         return realtimeDB.watch(path, cb, assetType, constraints, view);
      case 'firestore':
         return firestoreDB.watch(path, cb, assetType, constraints, view);
      case 'bubble':
         return bubbleDB.watch(path, cb, assetType, constraints, view);
      default:
         return [];
   }
}

async function updateFn(path, data) {
   const assetLocation = 'firebase'; //store.getState().project; // definition?.data_indexes?.[assetType].provider
   // if (!data.updatedAt) {
   //    data.updatedAt = Date.now();
   // }
   // if (!data.updatedBy) {
   //    data.updatedBy = store.getState().user?.uid || 'anonymous';
   // }
   switch (assetLocation) {
      case 'firebase':
         return realtimeDB.update(path, data);
      case 'firestore':
         return firestoreDB.update(path, data);
      case 'bubble':
         return bubbleDB.update(path, data);
      default:
         return [];
   }
}

async function deleteFn(path) {
   const assetLocation = 'firebase'; //store.getState().project.definition?.data_indexes?.[assetType].provider;
   switch (assetLocation) {
      case 'firebase':
         return realtimeDB.delete(path);
      case 'firestore':
         return firestoreDB.delete(path);
      case 'bubble':
         return bubbleDB.delete(path);
      default:
         return [];
   }
}

export const {
   setProjectDefinition,
   setUpdateElementsStyle,
   updateChildElementStyle,
   setProjectId,
   setEnvironmentId,
   // updateProject,
   dbCreateSuccess,
   dbCreateError,
   dbReadSuccess,
   dbReadError,
} = projectSlice.actions;

export default projectSlice.reducer;