import React, { useState } from 'react';
import { StyleSheet, Text, Modal, View, Pressable } from 'react-native';
import OutsidePressHandler from 'react-native-outside-press';
import { useDispatch, useSelector } from 'react-redux';

import { setHovered } from '../../store/EditorSlice';
import { createComponent } from '../../store/ProjectSlice';
import * as engineComponents from '../../engine/components';

const ComponentFactoryModal = ({ parent, position }) => {
   const dispatch = useDispatch();

   const projectId = useSelector((state) => state.editor.project);

   const [isHovered, setIsHovered] = useState(false);
   const [factoryOpen, setFactoryOpen] = useState(false);
   const [buttonLayout, setButtonLayout] = useState({
      height: 0,
      width: 0,
      top: 0,
      left: 0,
      x: 0,
      y: 0,
   });

   // console.log('comps', Object.keys(engineComponents));

   const toggleFactory = () => {
      setFactoryOpen(!factoryOpen);
   };

   const handleNewComponent = (type) => {
      // projectId, parent, position, type
      dispatch(createComponent(parent.composite_key, position, { type }));
      setFactoryOpen(false);
   };

   const handleMeasure = (event) => {
      setButtonLayout(event?.nativeEvent?.layout);
   };

   const calculateModalPosition = () => ({
      top: buttonLayout.top + buttonLayout.height, // Adjust the top position as needed
      left: buttonLayout.left,
   });

   return (
      <View
         style={styles.container}
         onMouseEnter={() => {
            setIsHovered(true);
            dispatch(setHovered(parent.uid));
         }}
         onMouseLeave={() => {
            setIsHovered(false);
         }}>
         {isHovered && (
            <Pressable onPress={toggleFactory} onLayout={handleMeasure} style={styles.createButton}>
               <Text style={styles.label}>+ Add Elmt</Text>
            </Pressable>
         )}
         <Modal
            animationType='fade'
            transparent
            visible={factoryOpen}
            onRequestClose={toggleFactory}>
            <OutsidePressHandler
               onOutsidePress={toggleFactory}
               style={[styles.factoryContainer, calculateModalPosition()]}>
               {/* TODO: handle components in a more generic manner, loop over components library */}
                  <Pressable onPress={() => handleNewComponent('box')} style={styles.factoryItem}>
                     <Text>Box</Text>
                  </Pressable>
                  <Pressable onPress={() => handleNewComponent('text')} style={styles.factoryItem}>
                     <Text>Text</Text>
                  </Pressable>
                  <Pressable onPress={() => handleNewComponent('image')} style={styles.factoryItem}>
                     <Text>Image</Text>
                  </Pressable>
                  <Pressable onPress={() => handleNewComponent('input')} style={styles.factoryItem}>
                     <Text>Input</Text>
                  </Pressable>
            </OutsidePressHandler>
         </Modal>
      </View>
   );
};

export default ComponentFactoryModal;

const styles = StyleSheet.create({
   container: {
      minHeight: 4,
   },
   modalOverlay: {
      flex: 1,
   },
   factoryContainer: {
      position: 'absolute',
      backgroundColor: '#f2f2f2',
      borderRadius: 4,
      elevation: 4,
      padding: 8,
   },
   factoryItem: {
      paddingVertical: 8,
   },
   createButton: {
      width: 'fit-content',
   },
   label: {
      fontSize: 11,
   },
});
