import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { Modal, StyleSheet, Text, Pressable, View, TouchableOpacity } from 'react-native';

const DefaultModal = ({
   children,
   title,
   description,
   onSuccess,
   onError,
   visible,
   okText,
   cancelText,
}) => {
   return (
      <Modal animationType='slide' transparent visible={visible}>
         <View style={styles.outerContainer}>
            <View style={styles.modalView}>
               <View style={[styles.modalContainer]}>
                  <View style={styles.headerContainer}>
                     <Text style={styles.titleText}>{title}</Text>
                     <TouchableOpacity style={styles.closeButton} onPress={onError}>
                        <AntDesign name='close' size={24} color='black' />
                     </TouchableOpacity>
                  </View>
                  <View>
                     <Text style={styles.descriptionText}>{description}</Text>
                  </View>
                  {children}
                  <View style={styles.buttonsContainer}>
                     {cancelText && (
                        <Pressable style={[styles.button, styles.buttonCancel]} onPress={onError}>
                           <Text style={styles.cancelTextStyle}>{cancelText}</Text>
                        </Pressable>
                     )}
                     {okText && (
                        <Pressable style={[styles.button, styles.buttonOk]} onPress={onSuccess}>
                           <Text style={styles.textStyle}>{okText}</Text>
                        </Pressable>
                     )}
                  </View>
               </View>
            </View>
         </View>
      </Modal>
   );
};

export default DefaultModal;

const styles = StyleSheet.create({
   cancelTextStyle: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   buttonsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
   },
   closeButton: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 28,
      height: 28,
      borderRadius: 4,
      padding: 4,
      gap: 10,
      backgroundColor: '#F5F6F8',
   },
   outerContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      bottom:0,
      backgroundColor: "#20202080",
   },
   modalContainer: {
      minWidth: 450,
      padding: 16,
      gap: 12,
   },
   centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
   },
   headerContainer: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
   },
   titleText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 20,
      color: '#262626',
   },
   descriptionText: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18,
      color: '#262626',
   },
   modalView: {
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
         width: 0,
         height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
   },
   button: {
      borderRadius: 8,
      padding: 10,
      height: 30,
      minWidth: 105,
      paddingVertical: 6,
      paddingHorizontal: 30,
      gap: 10,
   },
   buttonOpen: {
      backgroundColor: '#F194FF',
   },
   buttonOk: {
      backgroundColor: '#2E3137',
   },
   buttonCancel: {
      marginLeft: 20,
      backgroundColor: '#FFFFFF',
   },
   textStyle: {
      color: '#DFDFED',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   modalText: {
      marginBottom: 15,
      textAlign: 'center',
   },
});
