import { useFonts } from 'expo-font';
import _ from 'lodash';
import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';

import { sanitiseSchema } from '../../consts/sanitiseSchema';
import PageComponent from '../../engine/components/base/PageComponent';
import Loader from '../../components/base/Loader';

const EngineContainer = ({ componentId }) => {
   const fonts = useSelector((state) => state.project?.definition?.design_system?.fonts) || {};

   const fontsToLoad = Object.keys(fonts)?.reduce((acc, curr) => {
      const name = fonts[curr].name,
         path = fonts[curr].font.replace('http://', 'https://');

      acc[name] = path;
      return acc;
   }, {});

   const [fontsLoaded] = useFonts(fontsToLoad);

   if (!fontsLoaded) {
      return <Loader />;
   }

   return componentId ? <PageComponent componentId={componentId} /> : <></>;
};

export default EngineContainer;