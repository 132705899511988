import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import DatabaseSection from '../../components/DatabaseSection/DatabaseSection';
import DatabaseSidebar from '../../components/DatabaseSidebar/DatabaseSidebar';

const DataScreen = () => {
   return (
      <View style={styles.parent}>
         <DatabaseSidebar />
         <DatabaseSection style={styles.section} />
      </View>
   );
};

export default DataScreen;

const styles = StyleSheet.create({
   parent: {
      flexDirection: 'row',
      flex: '1',
      backgroundColor: '#FFF',
      // minHeight: '100%',
   },
   section: {
      maxWidth: '100%',
      border: '5px solid black',
   },
});
