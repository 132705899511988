import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, Text, View, Pressable } from "react-native";
import React, { useState, useEffect } from "react";
import InputField from "./base/InputField";
import { 
   CHAT_STATUS, CATEGORIES, addMessage,
   checkCategory, 
   setChatStatus, setRunUserConfirmation, finishRun, setRunPrompt,
} from "../store/AIAssistantSlice";
import { useDispatch, useSelector } from "react-redux";

const AIChat = () => {
   const dispatch = useDispatch();
   const chatStatus = useSelector(state => state.assistant.chatStatus);
   const currentRun = useSelector(state => state.assistant.run);
   const debugLevel = 3

   const [message, setMessage] = useState("");
   const [awaitingResponse, setAwaitingResponse] = useState(false);

   const messageList = useSelector(state => state.assistant.messages)

   const handleUserPrompt = () => {
      if (!message || chatStatus !== CHAT_STATUS.idle) {
         return
      }
      dispatch(addMessage({ content: message, role: 'user' }))
      if (currentRun.category) {
         console.error('I shouldn\'t be here')
         // TODO: continue ongoing process
      } else {
         dispatch(setRunPrompt(message))
         dispatch(checkCategory(message, debugLevel))
      }
      setMessage('')
   };

   const handleUserConfirmation = (value) => {
      dispatch(setRunUserConfirmation(value))
      if (value) {
         dispatch(setChatStatus(currentRun.category === CATEGORIES.structure ? CHAT_STATUS.handlingStructure : CHAT_STATUS.handlingComponent))
      } else {
         dispatch(finishRun())
         dispatch(setChatStatus(CHAT_STATUS.idle))
      } 
   }

   // Category
   useEffect(
      () => {
         if (currentRun.category) {
            if (chatStatus === CHAT_STATUS.idle) {
               if (debug > 0) console.error('Error: tryint to update a run category when chat is idle')
            }
            if (debugLevel > 2) console.log('Category update:', currentRun.category)
            if (currentRun.category in CATEGORIES) {
               dispatch(CATEGORIES[currentRun.category](debugLevel))
            } else {
               if (debugLevel > 1) console.warn('Unknown category: ', currentRun.category)
            }
            // switch (currentRun.category) {
            //    case CATEGORIES.structure:
            //    case CATEGORIES.component: {
            //       dispatch(enrichPrompt(messageList[messageList.length - 1].content, debugLevel))
            //       break;
            //    }
            //    case CATEGORIES.question: {
            //       dispatch(handleQuestion(debugLevel))
            //       break;
            //    }
            //    default: {
            //       if (debugLevel > 1) console.warn('Unknown category: ', currentRun.category)
            //    }
            // }
         }
      },
      [currentRun.category]
   )

   // Pre-handling modifications
   // useEffect(
   //    () => {
   //       if (debugLevel > 2) console.log('[AIChat][enrichedPromptUpdated]\n', currentRun.category, !currentRun.enrichedPrompt)
   //       if (!currentRun.enrichedPrompt) {
   //          return;
   //       }
   //       dispatch(setChatStatus(CHAT_STATUS.awaitingConfirmation))
   //       switch (currentRun.category) {
   //          // case CATEGORIES.structure: {
   //          //    dispatch(handleStructure(debugLevel))
   //          //    break;
   //          // }
   //          // case CATEGORIES.component: {
   //          //    dispatch(handleComponent(debugLevel))
   //          //    break;
   //          // }
   //          default: {
   //             if (debugLevel > 1) console.warn('Unexpected category for an enriched prompt: ', currentRun.category, currentRun.enrichedPrompt)
   //          }
   //       }
   //    },
   //    [currentRun.enrichedPrompt]
   // )

   const gptModal = useSelector((state) => state.globals.gptModal);

   if(!gptModal) {
      return <></>;
   }

   return (
      <View style={styles.container}>
         <View style={styles.messageList}>
            <Text>AIChat</Text>
            {messageList.map((message, index) => (
               <Text key={index} style={[message.role === 'user' ? styles.userMsg : styles.assistantMsg]}>{message.content}</Text>
            ))}
            {
               (
                  [CHAT_STATUS.handlingCategory, CHAT_STATUS.handlingQuestion].includes(chatStatus)
               ) && <Text style={styles.assistantMsg}>...</Text>
            }
            {chatStatus === CHAT_STATUS.handlingPrompt && <Text style={styles.assistantMsg}>Enriching prompt...</Text>}
            {chatStatus === CHAT_STATUS.handlingComponent && <Text style={styles.assistantMsg}>Refactoring component...</Text>}
            {chatStatus === CHAT_STATUS.handlingStructure && <Text style={styles.assistantMsg}>Making big changes...</Text>}
            {chatStatus === CHAT_STATUS.generatingStructure && <Text style={styles.assistantMsg}>Generating structure...</Text>}
            {chatStatus === CHAT_STATUS.generatingContent && <Text style={styles.assistantMsg}>Generating content...</Text>}
            {chatStatus === CHAT_STATUS.awaitingConfirmation && 
               <View style={styles.confirmation}>
                  <Pressable style={styles.confirmButton} onPress={() => handleUserConfirmation(true)}>
                     <Text >Yes</Text>
                  </Pressable>
                  <Pressable style={styles.denyButton} onPress={() => handleUserConfirmation(false)}>
                     <Text >No</Text>
                  </Pressable>
               </View>
            }
         </View>
         {
            chatStatus === CHAT_STATUS.idle &&
            <View>
               <InputField
                  style={styles.input}
                  placeholder="Type a message"
                  value={message}
                  handleValueUpdate={(val) => setMessage(val)}
                  handleSubmit={handleUserPrompt}
               />
               <Pressable style={styles.sendButton} onPress={handleUserPrompt}>
                  <Ionicons
                     name={"chevron-forward-outline"}
                     size={24}
                     color={'black'}
                  />
               </Pressable>
            </View>
         }
      </View>
   );
};

export default AIChat;

const styles = StyleSheet.create({
   container: {
      position: 'fixed',
      bottom: 5,
      left: 95 + 5,
      backgroundColor: '#D9D9D9',
      borderRadius: 10,
      padding: 10,
      width: 500,
   },
   messageList: {
      minHeight: 80,
      maxHeight: 800,
      overflow: 'scroll',
      gap: 8,
      paddingBottom: 8,
   },
   userMsg: {
      marginRight: 8,
      backgroundColor: '#F0F0F0',
      padding: 8,
      borderRadius: 8,
   },
   assistantMsg: {
      marginLeft: 8,
      backgroundColor: '#E5E5D5',
      padding: 8,
      borderRadius: 8,
   },
   input: {
      minHeight: 30,
   },
   sendButton: {
      position: 'absolute',
      right: 2,
      bottom: 2,
      top:2,
      alignItems: 'center',
   },
   confirmation: {
      flexDirection: 'row',
      columnGap: 10,
   },
   confirmButton: {
      padding: 4,
      backgroundColor: 'green',
   },
   denyButton: {
      padding: 4,
      backgroundColor: 'red',
   },
});
