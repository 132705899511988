import { A } from '@expo/html-elements';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import DocumentPicker from '../../components/componentsLibrary/utils/DocumentPicker';
import { realtimeDB } from '../../services/firebase';
import { deployProject } from '../../store/ProjectSlice';

const ProjectSettingsScreen = () => {
   const dispatch = useDispatch();

   const projectId = useSelector((state) => state.project.projectId);
   const projectName = useSelector(
      (state) => state.projectsMetadata.find((project) => project.uid === projectId).name
   );
   const projectDefinition = useSelector((state) => state.project.definition);

   const [dataLink, setDataLink] = useState(null);

   useEffect(() => {
      const blob = new Blob([JSON.stringify(projectDefinition, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      setDataLink(url);
   }, [projectDefinition]);

   const handleFileChange = async (file) => {
      try {
         const response = await fetch(file.uri);
         const json = await response.json();
         dispatch(deployProject(json));
      } catch (error) {
         console.log('Error retrieving JSON file:', error);
      }
   };

   return (
      <View>
         <Text style={styles.title}>Project Settings</Text>
         <Text>Project ID: {projectId}</Text>
         <View style={styles.container}>
            <Pressable
               style={styles.button}
               onPress={() => dispatch(deployProject(projectDefinition))}>
               <Text style={styles.buttonText}>Deploy</Text>
            </Pressable>

            <View style={styles.row}>
               <DocumentPicker
                  style={styles.documentPicker}
                  fileType='json'
                  onFileChange={handleFileChange}
               />

               <A style={styles.button} href={dataLink} download={projectName + '.json'}>
                  Download
               </A>
            </View>
         </View>
      </View>
   );
};

export default ProjectSettingsScreen;

const styles = StyleSheet.create({
   container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      gap: 20,
   },
   title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 20,
   },
   button: {
      backgroundColor: 'green',
      borderRadius: 4,
      padding: 10,
      color: '#fff',
      fontWeight: 'bold',
   },
   buttonText: {
      color: '#fff',
      fontWeight: 'bold',
   },
   documentPicker: {
      marginTop: 20,
   },
   row: {
      flexDirection: 'row',
      gap: 20,
   },
});
