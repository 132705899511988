import { Entypo } from '@expo/vector-icons';
import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';


const SortButton = ({ asc, onPress, style }) => {
    const [sort, setSort] = useState(false);
   return (
      <TouchableOpacity
         style={[styles.container, style]}
         onPress={() => setSort(!sort)}>
            <View style={{height: 8}}>
                <FontAwesome name="sort-up" size={15} color={sort ? '#262626' : '#93989B'} />
            </View>
            <View style={{height: 8, marginTop: -6}}>
                <FontAwesome name="sort-desc" size={15} color={sort ? '#93989B' : '#262626'} />
            </View>
      </TouchableOpacity>
   );
};

const styles = StyleSheet.create({
   container: {
      marginTop: -6,
      alignItems: 'center',
      justifyContent: 'center'
   },
});

export default SortButton;
