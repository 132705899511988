import { createSlice } from '@reduxjs/toolkit';
import uuid from 'react-native-uuid';

export const createProject = (userId, projectMetadata) => async (dispatch, getState) => {
   
   const state = getState(),
      {project} = state;

   const metadata = {
      uid: uuid.v4(),
      ...projectMetadata,
   };
   const componentId = uuid.v4();
   const pageId = 'page_' + componentId;
   const projectDefinitionTemplate = {
      design_system: {
         responsive_factor: {
            mobile: 12,
            tablet: 13,
            desktop: 14,
            xl: 16,
         },
      },
      page_index: pageId,
      pages: {
         [pageId]: {
            uid: pageId,
            name: 'Main Page',
            root_component: componentId,
         },
      },
      components: {
         [componentId]: {
            uid: componentId,
            name: 'Main Container',
            type: 'box',
            composite_key: [componentId],
            children: [],
            custom_styling: {
               width: {
                  type: 'expand',
               },
               height: {
                  type: 'expand',
               },
               position: {
                  type: 'auto'
               }
            }
         }
      }
   };

   const updates = [];
   // TODO: enable multiple envs
   updates.push({path: ['users', userId, 'projects', metadata.uid], value: true});
   updates.push({path: ['projects', metadata.uid, 'metadata'], value: metadata});
   updates.push({path: ['projects', metadata.uid, 'dev'], value: projectDefinitionTemplate});

   const promises = updates.map(({ path, value }) => {
      const currPath = path.join('/');
      return project.update(currPath, value).catch(() => {
         project.create(currPath, value);
      });
   });

   try {
      await Promise.all(promises);
   } catch (error) {
      console.log('Error updating project', updates, error);
   }
   
};

export const readProjectMetadata = (projectId) => async (dispatch, getState) => {
   try {
      const state = getState();
      state.project.watch('/projects/' + projectId + '/metadata', (data) => {
         dispatch(updateProject(data));
      });
   } catch (error) {
      // Handle login error
      console.log('Read project metadata error:', error);
   }
};

const projectsMetadataSlice = createSlice({
   name: 'projectsMetadata',
   initialState: [],
   reducers: {
      setProjects: (state, action) => {
         return action.payload;
      },
      addProject: (state, action) => {
         return [...state, action.payload];
      },
      updateProject: (state, action) => {
         return state.map((project) => {
            if (project.uid === action.payload.uid) {
               return action.payload;
            }
            return project;
         });
      },
   },
});

export default projectsMetadataSlice.reducer;
export const { setProjects, addProject, updateProject } = projectsMetadataSlice.actions;
