import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

const TextAlign = ({ componentDef, handleUpdate }) => {
   
   const [distribution, setDistribution] = useState(
        componentDef.custom_styling?.text?.alignment
   );
   useEffect(() => {
      setDistribution(componentDef.custom_styling?.text?.alignment);
   }, [componentDef.custom_styling?.text?.alignment]);

   const [alignItemsValue, setAlignItemsValue] = useState(distribution || 'left');

   return (
        <View style={layoutStyles.justifyContentButtons}>
            <TouchableOpacity
                onPress={() => {
                setAlignItemsValue('left');
                handleUpdate([
                    { path: ['custom_styling', 'text', 'alignment'], value: 'left' },
                ]);    
                }}
                style={[layoutStyles.jcButton, alignItemsValue == 'left' ? layoutStyles.jcButtonSelected : {}]}>
                <View style={layoutStyles.jcContainer}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.25 6.75H2.125M12.75 4.25H2.125M12.75 9.25H2.125M10.25 11.75H2.125" stroke={alignItemsValue == 'left' ? "#F5F9FF" : "#93989B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </View>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => {
                setAlignItemsValue('center');
                handleUpdate([
                    { path: ['custom_styling', 'text', 'alignment'], value: 'center' },
                ]);    
                }}
                style={[layoutStyles.jcButton, alignItemsValue == 'center' ? layoutStyles.jcButtonSelected : {}]}>
                <View style={layoutStyles.jcContainer}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6.75H4.5M13.875 4.25H2.625M13.875 9.25H2.625M12 11.75H4.5" stroke={alignItemsValue == 'center' ? "#F5F9FF" : "#93989B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </View>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => {
                setAlignItemsValue('right');
                handleUpdate([
                    { path: ['custom_styling', 'text', 'alignment'], value: 'right' },
                ]);
                }}
                style={[layoutStyles.jcButton, alignItemsValue == 'right' ? layoutStyles.jcButtonSelected : {}]}>
                <View style={layoutStyles.jcContainer}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.875 6.75H5.75M13.875 4.25H3.25M13.875 9.25H3.25M13.875 11.75H5.75" stroke={alignItemsValue == 'right' ? "#F5F9FF" : "#93989B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </View>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => {
                setAlignItemsValue('justify');
                handleUpdate([
                    { path: ['custom_styling', 'text', 'alignment'], value: 'justify' },
                ]);    
                }}
                style={[layoutStyles.jcButton, alignItemsValue == 'justify' ? layoutStyles.jcButtonSelected : {}]}>
                <View style={layoutStyles.jcContainer}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.375 6.75H2.125M13.375 11.75H2.125M13.375 4.25H2.125M13.375 9.25H2.125" stroke={alignItemsValue == 'justify' ? "#F5F9FF" : "#93989B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </View>
            </TouchableOpacity>
        </View>
   );
};

export default TextAlign;

const layoutStyles = StyleSheet.create({
   jcButton: {
      flex: 1,
      width: 30,
      borderRadius: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
   },
   jcButtonSelected: {
      backgroundColor: '#7B87A6',
   },
   jcContainer: {
      height: 30,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center'
   },
   justifyContentButtons: {
      flex: 1,
      width: 150,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#E0E4F4',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   }
});
