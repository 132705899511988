import { AntDesign, Entypo } from '@expo/vector-icons';
import { setGlobalVar } from '@reducers/GlobalsSlice';
import React, { useState, useEffect } from 'react';
import {
   FlatList,
   View,
   Text,
   ScrollView,
   TextInput,
   TouchableOpacity,
   Pressable,
   Dimensions,
   StyleSheet,
} from 'react-native';
import { NestableScrollContainer } from 'react-native-draggable-flatlist';
import { useDispatch, useSelector } from 'react-redux';

import MetaComponentContainer from './../../screens/editor/MetaComponentContainer';
import BasicElements from './BasicElements';
import LayersTree from './LayersTree';
import PageItem from './PageItem';
import { selectComponent, setHovered } from '../../store/EditorSlice';
import {
   createPage,
   deleteComponent,
   duplicateComponent,
   updateComponent,
} from '../../store/ProjectSlice';
import SearchInputField from '../DatabaseSection/base/SearchInputField';
import DefaultModal from '../base/DefaultModal';
import ExpandableContainer from '../base/ExpandableContainer';
import ComponentFactoryModal from '../componentConfig/ComponentFactoryModal';

const width = Dimensions.get('window').width,
   height = Dimensions.get('window').heigth;

const ProjectNavigator = ({ componentDef, parentDef, position, handleUpdate }) => {
   // TODO handle right-click to open context menu: Create child, Delete, Duplicate, etc.
   // TODO handle drag and drop to reorder children
   const dispatch = useDispatch();
   const [addPageVisible, setAddPageVisible] = useState(false);
   const [pageName, setPageName] = useState('');

   const definition = useSelector((state) => state.project.definition);
   const selectedPageId = useSelector((state) => state.editor.page);

   const components = useSelector((state) => state.project.definition.components);

   const selectedDefinition =
      components[definition?.pages[selectedPageId || definition?.page_index]?.root_component];

   const duplicatedComponent = useSelector((state) => state.globals.duplicatedComponent);
   const [showLeftContainer, setShowLeftContainer] = useState(true);
   const selected = useSelector((state) => state.globals.selected);

   useEffect(() => {
      const handleKeyDown = (event) => {
         // event.preventDefault();
         const code = event.which || event.keyCode;

         if (!selected) return;

         const prev = selected[selected.length - 2],
            last = selected[selected.length - 1];

         const charCode = String.fromCharCode(code).toLowerCase();
         if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
            dispatch(setGlobalVar({ key: 'duplicatedComponent', value: components[last] }));
         } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
            dispatch(duplicateComponent(last, duplicatedComponent));
         } else if (charCode === '\b') {
            dispatch(deleteComponent(components[prev], last));
         }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => window.removeEventListener('keydown', handleKeyDown);
   }, [selected]);

   return (
      <>
         <TouchableOpacity
            onPress={() => setShowLeftContainer(!showLeftContainer)}
            style={{
               alignItems: 'center',
               justifyContent: 'center',
               backgroundColor: '#FFFFFF',
               top: '50%',
               left: 0,
               zIndex: 1,
               height: 50,
               borderTopRightRadius: 50,
               borderTopRightColor: '#000000',
               borderBottomRightRadius: 50,
               borderBottomRightColor: '#000000',
               borderWidth: 1,
               position: 'absolute',
            }}>
            {showLeftContainer ? (
               <Entypo name='chevron-thin-left' size={24} color='#93989B' />
            ) : (
               <Entypo name='chevron-thin-right' size={24} color='#93989B' />
            )}
         </TouchableOpacity>
         {showLeftContainer && (
            <View style={{ width: 300, height, backgroundColor: '#FFFFFF' }}>
               <ScrollView>
                  <SearchInputField style={{ paddingHorizontal: 20, paddingTop: 20 }} />
                  <View style={{ width: '100%', height, alignItems: 'center' }}>
                     <ExpandableContainer
                        style={{ paddingTop: 16 }}
                        titleStyle={styles.titleText}
                        customRightButton={
                           <TouchableOpacity onPress={() => setAddPageVisible(!addPageVisible)}>
                              <View
                                 style={{
                                    width: 20,
                                    height: 20,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#2B55B7',
                                    borderRadius: 4,
                                 }}>
                                 <svg
                                    width='10'
                                    height='11'
                                    viewBox='0 0 10 11'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M5.00033 1.41699V9.58366M0.916992 5.50033H9.08366'
                                       stroke='#F5F9FF'
                                       strokeWidth='1.5'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </svg>
                              </View>
                           </TouchableOpacity>
                        }
                        title='Pages'>
                        <ScrollView contentContainerStyle={styles.pagesContainer}>
                           {definition?.pages ? (
                              <FlatList
                                 keyExtractor={(item) => item?.uid}
                                 style={styles.list}
                                 data={Object.keys(definition?.pages)}
                                 renderItem={({ item, index }) => {
                                    return (
                                       <PageItem
                                          handleUpdate={handleUpdate}
                                          item={definition?.pages[item]}
                                       />
                                    );
                                 }}
                              />
                           ) : (
                              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                 Add new page
                              </View>
                           )}
                        </ScrollView>
                     </ExpandableContainer>
                     <ExpandableContainer titleStyle={styles.titleText} title='Basic elements'>
                        <ScrollView contentContainerStyle={styles.basicElementsContainer}>
                           <BasicElements />
                        </ScrollView>
                     </ExpandableContainer>
                     {duplicatedComponent && (
                        <ExpandableContainer
                           titleStyle={styles.titleText}
                           title='Duplicated component'>
                           <View
                              style={{
                                 backgroundColor: '#F5F7F9',
                                 width: 240,
                                 height: 150,
                                 overflow: 'hidden',
                              }}>
                              <MetaComponentContainer componentId={duplicatedComponent.uid} />
                           </View>
                        </ExpandableContainer>
                     )}
                     <ExpandableContainer titleStyle={styles.titleText} title='Layers'>
                        <NestableScrollContainer contentContainerStyle={styles.layersContainer}>
                           {selectedPageId ? (
                              <LayersTree componentDef={selectedDefinition} />
                           ) : (
                              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                 <Text>You need choose page</Text>
                              </View>
                           )}
                        </NestableScrollContainer>
                     </ExpandableContainer>
                  </View>
                  <DefaultModal
                     title='Add new page'
                     description='Imagine name for new page'
                     visible={addPageVisible}
                     onSuccess={() => {
                        dispatch(createPage(pageName));
                        setAddPageVisible(!addPageVisible);
                     }}
                     onError={() => setAddPageVisible(!addPageVisible)}
                     okText='CREATE'
                     cancelText='CANCEL'>
                     <TextInput
                        placeholder='Page name'
                        autoFocus
                        value={pageName}
                        onChangeText={setPageName}
                        style={styles.input}
                     />
                  </DefaultModal>
               </ScrollView>
            </View>
         )}
      </>
   );
};

export default ProjectNavigator;

const styles = StyleSheet.create({
   container: {
      backgroundColor: '#F5F7F9',
   },
   pagesContainer: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
   },
   layersContainer: {
      flexDirection: 'column',
   },
   basicElementsContainer: {
      flexDirection: 'column',
   },
   paddingBottom: {
      height: 3,
   },
   list: {
      width: '100%',
   },
   label: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexShrink: 1,
      height: 30,
   },
   labeltext: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 12,
      color: '#4A4756',
      alignItems: 'center',
   },
   titleText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      alignItems: 'center',
   },
   selected: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 12,
      color: '#4A4756',
      alignItems: 'center',
   },
});
