import { Ionicons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { createLogicAction } from '../../store/ProjectSlice';

const NewActionNode = ({ logicWorkflow, previousNode, connection, hidePlus, style }) => {
   const dispatch = useDispatch();
   const [hovered, setHovered] = useState(false);

   return (
      <View
         style={[styles.container, style]}
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}>
         <View style={styles.connectionSegment} />
         {hovered && !hidePlus && (
            <Pressable
               style={{ position: 'absolute' }}
               onPress={() =>
                  dispatch(createLogicAction(logicWorkflow.uid, previousNode.uid, connection))
               }>
               <Ionicons name='add-circle-outline' size={20} color='#7B87A6' />
            </Pressable>
         )}
      </View>
   );
};

export default NewActionNode;

const diameter = 50;
const styles = StyleSheet.create({
   container: {
      height: diameter,
      width: diameter,
      borderRadius: 15,
      alignItems: 'center',
      justifyContent: 'center',
   },
   connectionSegment: {
      right: 0,
      left: 0,
      width: 50,
      height: 2,
      backgroundColor: '#E0E4F4',
   },
});
