import { Ionicons } from '@expo/vector-icons';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';

import { useSelector, useDispatch } from 'react-redux';

import {
   deselectAll
} from '../../../store/DatabaseSlice';

const DeselectButton = () => {
   const dispatch = useDispatch();
   const selectedRows = useSelector((state) => state.database.selectedRows);

   const selectedCount = Object.keys(selectedRows).length;

   return (
      <TouchableOpacity onPress={() => dispatch(deselectAll())} style={styles.container}>
         <Ionicons name='close' size={18} color='#7B87A6' />
         <Text style={styles.text}>{`Deselect ${
            selectedCount > 0 ? `(${selectedCount})` : ''
         }`}</Text>
      </TouchableOpacity>
   );
};

export default DeselectButton;

const styles = StyleSheet.create({
   container: {
      paddingTop: 6,
      paddingLeft: 16,
      paddingBottom: 6,
      paddingRight: 14,
      borderRadius: 8,
      gap: 10,
      height: 34,
      flexDirection: 'row',
   },
   text: {
      fontFamily: 'Inter',
      color: '#7B87A6',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
});
