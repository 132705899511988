import { Feather } from '@expo/vector-icons';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';

const DeleteSelectedButton = ({onPress}) => {
   return (
      <TouchableOpacity onPress={onPress} style={styles.container}>
         <Feather name='trash-2' size={18} color='#D15A5A' />
         <Text style={styles.text}>Delete selected</Text>
      </TouchableOpacity>
   );
};

export default DeleteSelectedButton;

const styles = StyleSheet.create({
   container: {
      paddingTop: 6,
      paddingLeft: 16,
      paddingBottom: 6,
      paddingRight: 14,
      borderRadius: 8,
      gap: 10,
      height: 34,
      flexDirection: 'row',
   },
   text: {
      fontFamily: 'Inter',
      color: '#D15A5A',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
});
