import { TouchableOpacity } from 'react-native';

const PlusButton = (props) => {
   return (
      <TouchableOpacity {...props}>
            <svg
               xmlns='http://www.w3.org/2000/svg'
               width='20'
               height='20'
               viewBox='0 0 20 20'
               fill='none'>
               <path
                  d='M10.0001 4.1665V15.8332M4.16675 9.99984H15.8334'
                  stroke='#F5F9FF'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               />
            </svg>
      </TouchableOpacity>
   );
};

export default PlusButton;
