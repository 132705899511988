import { TSectionBuilderPayload } from "../types";
import { generateComponent } from "../generators";
import { problemStatementContext } from "../../../consts/prompts";

export const createProblemStatement = (payload: TSectionBuilderPayload, debug: number = 0) => async ( 
   dispatch: (...args: any[]) => any, 
   getState: () => Record<string, any>
): Promise<any> => {
   if (debug > 2) console.log('[CreateFooter] Start')
   const { description, sections, rootId, rootIndex, pageId } = payload;
   const pageContext = getState().project.definition.pages[pageId].description
   
   const pbStatementDef = await dispatch(generateComponent(
      {
         name: 'Problem Statement Section',
         type: 'box',
         description: description,
         custom_styling: {
            padding: {
               top: { val: 2, unit: 'rf' },
               right: { val: 10, unit: '%' },
               bottom: { val: 2, unit: 'rf' },
               left: { val: 10, unit: '%' },
            },
            background: { type: 'color', color: '@secondary_bg' },
            layout: {
               direction: 'row',
               distribution: 'center',
            }
         },
      },
      [
         // {path: ['custom_styling', 'background', 'image', 'src'], type: 'image', context: bgImagePrompt }
      ],
      [rootId],
      rootIndex,
      debug
   ))
   
   const pbStatementTextDef = await dispatch(generateComponent(
      {
         name: 'Problem Statement text',
         type: 'text',
         description: description,
         custom_styling: {
            text: {
               color: 'secondary_txt',
               size: { val: 2, unit: 'rf' },
               weight: 500,
               line_height: '1.2',
               alignment: 'center',
            }
         },
      },
      [
         {
            path: ['properties', 'text'], 
            type: 'text', 
            context: problemStatementContext.concat({ role: 'user', content: pageContext }) 
         }
      ],
      pbStatementDef.composite_key,
      0,
      debug
   ))
}