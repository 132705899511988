import React, { useState, useRef } from 'react';
import { View, TextInput, FlatList, Text, Pressable, StyleSheet } from 'react-native';
import OutsidePressHandler from 'react-native-outside-press';

const AutocompleteInput = ({ data, value, onSelect }) => {
   const [query, setQuery] = useState(
      data.find((el) => el?.value === (typeof value === 'object' ? value.value : value))?.label ||
         value ||
         ''
   );
   const [results, setResults] = useState([]);
   const [activeIndex, setActiveIndex] = useState(0);
   const [focused, setFocused] = useState(false);
   const flatListRef = useRef(null);

   const handleSearch = (text) => {
      setQuery(text);
      const regex = new RegExp(`${text.trim()}`, 'i');
      setResults(
         data.filter((option) => {
            const label = typeof option === 'object' ? option.label : option;
            return label.search(regex) >= 0;
         })
      );
   };

   const handleKeyPress = ({ nativeEvent }) => {
      if (nativeEvent.key === 'ArrowDown') {
         setActiveIndex((prevActiveIndex) => Math.min(prevActiveIndex + 1, results.length - 1));
         flatListRef.current.scrollToIndex({ index: activeIndex });
      } else if (nativeEvent.key === 'ArrowUp') {
         setActiveIndex((prevActiveIndex) => Math.max(prevActiveIndex - 1, 0));
         flatListRef.current.scrollToIndex({ index: Math.max(activeIndex - 2, 0) });
      } else if (nativeEvent.key === 'Enter') {
         const selectedOption = results[activeIndex];
         const label = typeof selectedOption === 'object' ? selectedOption.label : selectedOption;
         setQuery(label);
         onSelect(selectedOption);
         setResults([]);
      }
   };

   return (
      <View style={styles.container}>
         <TextInput
            style={styles.input}
            value={query}
            onChangeText={handleSearch}
            onKeyPress={handleKeyPress}
            onFocus={() => {
               setFocused(true);
               setResults(data);
            }}
            onBlur={() => (!results ? setTimeout(() => {onBlur(); setFocused(false);}, 100) : {})}
            placeholder='Search...'
         />
         {focused && results.length > 0 && (
            <View style={styles.list}>
               <FlatList
                  ref={flatListRef}
                  data={results}
                  keyExtractor={(item) => (typeof item === 'object' ? item.value : item)}
                  renderItem={({ item, index }) => (
                     <Pressable
                        onPress={() => {
                           setQuery(typeof item === 'object' ? item.label : item);
                           onSelect(item);
                           setFocused(false);
                        }}
                        onMouseEnter={() => setActiveIndex(index)}>
                        <Text style={[styles.item, index === activeIndex && styles.activeItem]}>
                           {typeof item === 'object' ? item.label : item}
                        </Text>
                     </Pressable>
                  )}
                  // onContentSizeChange={() => flatListRef.current.scrollToIndex({ index: activeIndex })}
               />
            </View>
         )}
      </View>
   );
};

const styles = StyleSheet.create({
   container: {
      flex: 1,
      zIndex: 1,
   },
   input: {
      // flex: 1,
      padding: 4,
   },
   list: {
      flex: 1,
      // position: 'absolute',
      backgroundColor: '#FFF',
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 4,
      maxHeight: 200,
      elevation: 4,
      zIndex: 1000,
   },
   item: {
      backgroundColor: '#FFF',
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#ccc',
   },
   activeItem: {
      backgroundColor: '#ddd',
   },
   firstItem: {
      borderTopWidth: 1,
      borderTopColor: '#ccc',
   },
   valid: {},
   invalid: {
      borderColor: '#F00',
   },
});

export default AutocompleteInput;
