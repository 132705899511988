import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import DataScreen from './editor/DataScreen';
import ProjectDesignSystemScreen from './editor/DesignSystemScreen';
import ProjectEditor from './editor/ProjectEditorScreen';
import ProjectSettingsScreen from './editor/ProjectSettingsScreen';
import Sidebar from '../components/Sidebar';
import AIChat from '../components/AIChat';
import { setProjectDefinition, setProjectId } from '../store/ProjectSlice';

const ProjectScreen = () => {
   const dispatch = useDispatch();
   const [initializing, setInitializing] = useState(true);

   const projectId = useSelector((state) => state.editor.project);
   const view = useSelector((state) => state.editor.view);
   const project = useSelector((state) => state.project);

   const pageOptions = [
      { name: 'Editor', component: ProjectEditor },
      { name: 'Logic', component: ProjectEditor },
      { name: 'Data', component: DataScreen },
      { name: 'Design System', component: ProjectDesignSystemScreen },
      { name: 'Parameters', component: ProjectSettingsScreen },
      { name: 'Help', component: ProjectEditor },
   ];

   useEffect(() => {
      // TODO: enable multiple environments
      const unsubscribe = project.watch(
         '/projects/' + projectId + '/dev', 
         (data) => {
            dispatch(setProjectId(projectId));
            dispatch(setProjectDefinition(data));
            if (initializing) {
               setInitializing(false);
            }
         }
      );
      // return () => unsubscribe();
   }, []);

   const renderProject = () => {
      // Find the page whose name matches the current view
      const pageOption = pageOptions.find((el) => el.name === view);
      // If a matching section is found, render its component
      return pageOption ? <pageOption.component projectId={projectId} /> : <></>;
   };

   return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
         <Sidebar />
         {!initializing && renderProject()}
         <AIChat />
      </View>
   );
};

export default ProjectScreen;
