import { AntDesign } from '@expo/vector-icons';
import { Text, Pressable, StyleSheet, View, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Entypo } from '@expo/vector-icons';
import { useState } from 'react';
import { Foundation } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const DeleteSelectedContainer = ({dataToEdit, onOkPress, onCancelPress}) => {

   const [ showSelectedData, setShowSelectedData ] = useState(false);

   return (
      <View style={styles.container}>
            <View style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Text style={styles.fieldsToChage}>{`${Object.keys(dataToEdit).length} changes `}</Text>
                    <Text style={styles.madeText}>{'were made'}</Text>
                    <TouchableOpacity style={styles.iconContainer} onPress={() => setShowSelectedData(!showSelectedData)}>
                        { showSelectedData ? 
                            <AntDesign name="close" size={16} color="#646D72" />
                            :
                            <Entypo name="back-in-time" size={16} color="#646D72" />
                        }
                    </TouchableOpacity>
                </View>
                {
                    showSelectedData && 
                    <View style={{paddingLeft: 12}}>
                        {
                            Object.keys(dataToEdit).map((data, index) => {
                                    return (
                                        <View style={styles.currItemContainer}>
                                          <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                             <View style={{width: 20, borderTopWidth: 1, borderTopColor: '#7B87A6', borderStyle: 'dashed'}}></View>
                                             <AntDesign name="right" size={13} color="#7B87A6" />
                                          </View>
                                          <Text numberOfLines={1} style={[styles.idStyle, { maxWidth: 130, marginRight: 20 }]}>{`#${data}`}</Text>
                                          <Text style={styles.changedText}>{`${dataToEdit[data].oldValue} --> ${dataToEdit[data].data}`}</Text>
                                          <TouchableOpacity style={{width: 18, height: 18, marginHorizontal: 10}}>
                                             <Foundation name="info" size={15} color="#B8BDD0" />
                                          </TouchableOpacity>
                                          <TouchableOpacity>
                                             <MaterialCommunityIcons name="undo-variant" size={15} color="#646D72" />
                                          </TouchableOpacity>
                                        </View>
                                    )
                                }
                            )
                        }
                    </View>
                }
            </View>
            <View style={styles.buttonsContainer}>
                <Pressable style={[styles.button, styles.buttonCancel]} onPress={onCancelPress}>
                    <Text style={styles.cancelTextStyle}>{'Cancel'}</Text>
                </Pressable>
                <Pressable style={[styles.button, styles.buttonOk]} onPress={onOkPress}>
                    <Text style={styles.textStyle}>{'Save'}</Text>
                </Pressable>
            </View>
      </View>
   );
};

export default DeleteSelectedContainer;

const styles = StyleSheet.create({
   currItemContainer: {
      height: 40,
      borderLeftColor: '#7B87A6',
      borderLeftWidth: 1,
      borderStyle: 'dashed',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
   },
   textStyle: {
      color: '#FFFFFF',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   madeText: {
      color: '#93989B',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   changedText: {
      color: '#2B55B7',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   buttonOk: {
      backgroundColor: '#2E3137',
   },
   buttonCancel: {
      marginLeft: 20
   },
   cancelTextStyle: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
   },
   iconContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      padding: 4,
      width: 28,
      height: 28,
      marginLeft: 10,
   },
   buttonsContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      bottom: 0,
      right: 0,
   },
   button: {
      borderRadius: 8,
      padding: 10,
      height: 30,
      minWidth: 105,
      paddingVertical: 6,
      paddingHorizontal: 30,
      gap: 10,
   },
   fieldsToChage: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      textAlign: 'center',
      color: '#93989B',
   },
   idStyle: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      textAlign: 'center',
      color: '#93989B',
   },
   container: {
      position: 'absolute',
      minHeight: 54,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#F5F6F8',
      paddingHorizontal: 20,
      paddingVertical: 12,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
   },

});
