import reducer, { actions } from './slice'
import { checkCategory as _checkCategory, CATEGORIES as categories } from './categories'
import { 
   createProjectPrimaryColor as _createProjectPrimaryColor, 
   updateDesignToMatchPrimary as _updateDesignToMatchPrimary
} from './design'
import * as types from './types'


export default reducer
export const {
   setChatStatus, setRunCategory, setRunPrompt, 
   setRunEnrichedPrompt, setRunUserConfirmation, 
   addRunPatch, finishRun, resetMessages, addMessage 
} = actions;

export const { CHAT_STATUS } = types

export const checkCategory = _checkCategory
export const CATEGORIES = categories

export const createProjectPrimaryColor = _createProjectPrimaryColor
export const updateDesignToMatchPrimary = _updateDesignToMatchPrimary
