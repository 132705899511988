import React, { useState } from 'react';
import {
   StyleSheet,
   Text,
   View,
   ScrollView,
   Pressable,
   TouchableWithoutFeedback,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import LogicPanelWFCard from './LogicPanelWFCard';
import { setEditingLogicId } from '../../store/EditorSlice';
import { createComponentLogic } from '../../store/ProjectSlice';

const LogicPanel = ({ componentDef }) => {
   const dispatch = useDispatch();

   return (
      <ScrollView style={styles.container}>
         <Pressable
            onPress={() =>
               dispatch(
                  createComponentLogic(
                     componentDef.composite_key,
                     Object.keys(componentDef.logic || {}).length
                  )
               )
            }
            style={styles.newButton}>
            <Text style={styles.buttonText}>New Action +</Text>
         </Pressable>
         <View style={styles.itemList}>
            {Object.values(componentDef.logic || {}).map((item, index) => (
               <Pressable
                  onPress={() => {
                     dispatch(setEditingLogicId(item.uid));
                  }}
                  style={styles.item}
                  key={item.uid}>
                  <View
                     style={[styles.colorIndicator, { backgroundColor: item.colour || '#DFE2EE' }]}
                  />
                  <View style={styles.itemContent}>
                     <Text style={styles.itemName}>{item.name}</Text>
                     {item.description && (
                        <View style={styles.itemDescription}>
                           <Text style={styles.itemDescription}>{item.description}</Text>
                        </View>
                     )}
                     <View>
                        <Text style={styles.itemSteps}>
                           {Math.max(Object.keys(item.nodes || {}).length - 1, 0)} Step
                           {Object.keys(item.nodes || {}).length - 1 > 1 ? 's' : ''}
                        </Text>
                     </View>
                     <LogicPanelWFCard
                        componentKey={componentDef.composite_key}
                        logic={item}
                        style={styles.itemOptions}
                     />
                  </View>
               </Pressable>
            ))}
         </View>
      </ScrollView>
   );
};

export default LogicPanel;

const styles = StyleSheet.create({
   container: {
      padding: 20,
   },
   newButton: {
      paddingHorizontal: 20,
      paddingVertical: 8,
      backgroundColor: '#2B55B7',
      borderRadius: 6,
      marginBottom: 20,
   },
   buttonText: {
      color: '#DFDFED',
      justifyContent: 'center',
      textAlign: 'center',
   },
   itemList: {
      rowGap: 12,
   },
   item: {
      flexDirection: 'row',
      // flex: '1 0 0',
      borderRadius: 6,
      overflow: 'hidden',
   },
   itemContent: {
      flex: 1,
      padding: 12,
      backgroundColor: '#F5F6F8',
   },
   colorIndicator: {
      width: 4,
   },
   itemName: {
      color: '#262626',
      fontFamily: 'Inter',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '130%',
   },
   itemDescription: {
      color: '#262626',
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   itemSteps: {
      marginTop: 20,
      color: '#2B55B7',
      fontFamily: 'Inter',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   itemOptions: {
      position: 'absolute',
      top: 4,
      right: 8,
   },
});
