import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Entypo } from '@expo/vector-icons'; 

const ExpandableContainer = ({ 
    title,
    children,
    style,
    customRightButton,
    titleStyle
    }) => {

   const [expand, setExpand] = useState(true);

   return (
      <View style={[{ width: '100%' }, style]}>
         <View style={styles.row}>
            <View style={{flexDirection: 'column', flex: 1, paddingHorizontal: 20}}>
               <View style={styles.expandContainer}>
                  <Text style={[styles.spacesAndSizesText, titleStyle]}>
                     {title}
                  </Text>
                  { customRightButton ||
                    <TouchableOpacity onPress={() => setExpand(!expand)} style={{alignItems: 'center', justifyContent: 'center'}}>
                    {
                        expand ? 
                            <Entypo name="chevron-thin-down" size={24} color="#93989B" />
                            : 
                            <Entypo name="chevron-thin-up" size={24} color="#93989B" />
                    }
                    </TouchableOpacity>
                  }
               </View>
               {
                  expand && 
                     <View>
                        {children}
                     </View>
               }
            </View>
         </View>
      </View>
   );
};

export default ExpandableContainer;

const styles = StyleSheet.create({
   spacesAndSizesText: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
      color: '#646D72',
      paddingVertical: 10
   },
   row: {
      flex: 1,
      flexDirection: 'row',
      padding: 2,
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'fit-content',
   },
   expandContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
   }
});
